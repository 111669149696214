/* eslint-disable import/prefer-default-export */
function getEnumName(enumValues, value) {
    const keys = Object.keys(enumValues);
    const values = Object.values(enumValues);

    /* eslint-disable-next-line no-plusplus */
    for (let i = 0; i < values.length; i++) {
      if (values[i] === value) {        
        return keys[i]; // Return the key if you need to use it elsewhere
      }
    }
  
    return null; // Return null if no match is found
}

export {getEnumName};