import React, { useEffect, useContext, useReducer, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

import { SessionContext, NotificationContext } from 'contexts';
import { useAuthorization, useNavigationLinks, useUserService } from 'hooks';
import { useEmployeeService, useEmployeeValidation } from 'hooks/admin';
import { requestStatus } from 'constants/index';
import EmployeeEdit from './EmployeeEdit';
import EmployeeRoles from './EmployeeRoles';

const initialState = {
  employee: {
    agencyCode: '',
    email: '',
    employeeId: 0,
    newEmployeeId: 0,
    isLocalStaff: false,
    isUSDHSupervisor: false,
    mbcEmployeeId: 0,
    bureaus: [],
    missionId: 0,
    name: '',
    openNetId: '',
    payrollId: 0,
    positionTitle: '',
    postId: 0,
    sectionId: 0,
    supervisorId: 0,
    assignedRoles: [],
    availableRoles: [],
    assignedMissionRoles: [],
    assignedSystemRoles: [],
    userRoles: [],
    userRolesChanged: false,
    isBureauUser: false,
  },
  availableMissionRoles: [],
  availableSystemRoles: [],
  directReportsList: [],
  bureaus: [],
  missions: [],
  posts: [],
  sections: [],
  agencies: [],
  oktaResult: [],
  formHasChanged: false,
  isDataFromOkta: false,
  oktaSelectedRecord: null,
  oktaSearchTerm: '',
  isOktaSearchModalOpened: false,
  selectedNewRoleMissionId: 0,
  selectedNewRoleKey: '',
  oktaRequest: requestStatus.REQUEST_NOT_INITIATED,
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
  loadUserRolesRequest: requestStatus.REQUEST_NOT_INITIATED,
  rolesValid: undefined,
  isEmployeeTransferModalOpen: false,
  transferModalSelectedMission: 0,
  transferModalSelectedPost: 0,
};

const reducer = (state, action) => {
  let idx = -1;
  switch (action.type) {
    case 'FORCE_RELOAD':
      return { ...state, loadRequest: requestStatus.REQUEST_NOT_INITIATED, newEmployeeId: action.data };

    case 'START_EMPLOYEE_LOAD':
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'START_USER_ROLES_LOAD':
      return { ...state, loadUserRolesRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'RESET_USER_ROLES':
      return {
        ...state,
        employee: { ...state.employee, userRoles: [], userRolesChanged: false },
        loadUserRolesRequest: requestStatus.REQUEST_NOT_INITIATED,
      };

    case 'SET_USER_ROLES':
      return {
        ...state,
        employee: { ...state.employee, userRoles: action.data, userRolesChanged: false },
        loadUserRolesRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'ADD_NEW_USER_ROLE':
      return {
        ...state,
        employee: { ...state.employee, userRoles: [...state.employee.userRoles, action.data], userRolesChanged: true },
        selectedNewRoleKey: '',
      };

    case 'SET_SELECTED_NEW_ROLE_MISSION':
      return { ...state, selectedNewRoleMissionId: action.data };

    case 'SET_SELECTED_NEW_ROLE_KEY':
      return { ...state, selectedNewRoleKey: action.data };

    case 'REMOVE_USER_ROLE_BY_ID':
      idx = state.employee.userRoles.findIndex((item) => item.id === action.data);
      return {
        ...state,
        employee: {
          ...state.employee,
          userRoles: [
            ...state.employee.userRoles.slice(0, idx),
            { ...state.employee.userRoles[idx], removed: true },
            ...state.employee.userRoles.slice(idx + 1),
          ],
          userRolesChanged: true,
        },
      };

    case 'UNDO_REMOVE_USER_ROLE_BY_ID':
      idx = state.employee.userRoles.findIndex((item) => item.id === action.data);
      return {
        ...state,
        employee: {
          ...state.employee,
          userRoles: [
            ...state.employee.userRoles.slice(0, idx),
            { ...state.employee.userRoles[idx], removed: false },
            ...state.employee.userRoles.slice(idx + 1),
          ],
          userRolesChanged: true,
        },
      };

    case 'START_SUPERVISOR_LOAD':
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'START_DIRECT_REPORT_LOAD':
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'SET_EMPLOYEE':
      return {
        ...state,
        employee: {
          agencyCode: action.data.agencyCode,
          email: action.data.email,
          employeeId: action.data.employeeId,
          isLocalStaff: action.data.isLocalStaff,
          mbcEmployeeId: action.data.mbcEmployeeId,
          bureaus: action.data.bureaus,
          isBureauUser: action.data.isBureauUser,
          missionId: action.data.missionId,
          name: action.data.name,
          openNetId: action.data.openNetId,
          payrollId: action.data.payrollId,
          positionTitle: action.data.positionTitle,
          postId: action.data.postId,
          sectionId: action.data.sectionId,
          supervisorId: action.data.supervisorId,
          supervisorName: action.data.supervisorName,
          localSupervisorId: action.data.localSupervisorId,
          localSupervisorName: action.data.localSupervisorName,
          isUSDHSupervisor: action.data.isUSDHSupervisor,
          assignedRoles: action.data.assignedRoles,
          availableRoles: action.data.availableMissionRoles.filter(
            (item) => action.data.assignedRoles.some((assigned) => assigned.key === item.key) === false
          ),
          assignedMissionRoles: [...action.data.assignedRoles.filter((item) => item.organizationId != null)],
          assignedSystemRoles: [...action.data.assignedRoles.filter((item) => item.organizationId == null)],
        },
        selectedNewRoleMissionId: 0,
        selectedNewRoleKey: '',
        availableMissionRoles: action.data.availableMissionRoles,
        availableSystemRoles: action.data.availableSystemRoles,
        bureaus: action.data.bureaus,
        missions: action.data.missions,
        posts: action.data.posts,
        sections: action.data.sections,
        agencies: action.data.agencies,
        loadRequest: requestStatus.REQUEST_COMPLETED,
        isDirty: false,
      };

    case 'SET_EMPLOYEE_FIELD':
      return { ...state, employee: { ...state.employee, ...action.data }, formHasChanged: true, isDirty: true };

    case 'UPDATE_SUPERVISOR':
      return {
        ...state,
        employee: {
          ...state.employee,
          supervisorId: action.data?.employeeId,
          supervisorName: action.data?.name,
        },
        formHasChanged: true,
        isDirty: true,
      };

    case 'LOAD_DIRECT_REPORTS':
      return {
        ...state,
        directReportsList: action.data,
        loadRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'ADD_MISSION_ROLE':
      return {
        ...state,
        rolesValid: undefined,
        employee: {
          ...state.employee,
          assignedMissionRoles: [...state.employee.assignedMissionRoles, { key: null, organizationId: 0 }],
        },
      };

    case 'REMOVE_MISSION_ROLE':
      return {
        ...state,
        rolesValid: undefined,
        employee: {
          ...state.employee,
          assignedMissionRoles: state.employee.assignedMissionRoles.filter(
            (element, index) => index !== action.data.index
          ),
        },
      };

    case 'ADD_SYSTEM_ROLE':
      return {
        ...state,
        rolesValid: undefined,
        employee: {
          ...state.employee,
          assignedSystemRoles: [...state.employee.assignedSystemRoles, { key: null, organizationId: null }],
        },
      };

    case 'REMOVE_SYSTEM_ROLE':
      return {
        ...state,
        rolesValid: undefined,
        employee: {
          ...state.employee,
          assignedSystemRoles: state.employee.assignedSystemRoles.filter(
            (element, index) => index !== action.data.index
          ),
        },
      };

    case 'UPDATE_SYSTEM_ROLE':
      return {
        ...state,
        rolesValid: undefined,
        employee: {
          ...state.employee,
          assignedSystemRoles: state.employee.assignedSystemRoles.map((item, index) =>
            index === action.data.index ? { ...item, ...action.data.role } : item
          ),
        },
      };
    case 'OPEN_EMPLOYEE_TRANSFER_MODAL':
      return {
        ...state,
        isEmployeeTransferModalOpen: true,
      };

    case 'CLOSE_EMPLOYEE_TRANSFER_MODAL':
      return { ...state, isEmployeeTransferModalOpen: false };

    case 'TRANSFER_EMPLOYEE_POST':
      return {
        ...state,
        employee: { ...state.employee, missionId: action.data.selectedMissionId, postId: action.data.selectedPostId },
        isEmployeeTransferModalOpen: false,
        isDirty: true,
      };

    case 'OPEN_OKTA_SEARCH_MODAL':
      return {
        ...state,
        isOktaSearchModalOpened: true,
        oktaSearchTerm: '',
        oktaResult: [],
        oktaRequest: requestStatus.REQUEST_NOT_INITIATED,
        oktaSelectedRecord: null,
      };

    case 'CLOSE_OKTA_SEARCH_MODAL':
      return { ...state, isOktaSearchModalOpened: false };

    case 'SET_OKTA_SEARCH_TERM':
      return { ...state, oktaSearchTerm: action.data };

    case 'START_OKTA_REQUEST':
      return { ...state, oktaRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'SET_OKTA_RESULT':
      return {
        ...state,
        oktaResult: action.data,
        oktaSelectedRecord: null,
        oktaRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'SET_SELECTED_OKTA_RECORD':
      return { ...state, oktaSelectedRecord: action.data, positionTitle: action.data.title };

    case 'UPDATE_USER_FROM_OKTA_RECORD':
      return {
        ...state,
        employee: {
          ...state.employee,
          email: action.data.email,
          name: action.data.name,
          positionTitle: action.data.positionTitle,
          openNetId: action.data.openNetId,
        },
        isDataFromOkta: true,
        isOktaSearchModalOpened: false,
      };

    case 'RESET_FORM':
      return {
        ...state,
        employee: {
          email: '',
          name: '',
          openNetId: 0,
          positionTitle: '',
          postId: 0,
          sectionId: 0,
          supervisorId: 0,
          supervisorName: '',
          localSupervisorId: 0,
          localSupervisorName: '',
          isUSDHSupervisor: false,
        },
        formHasChanged: false,
        isDataFromOkta: false,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function EmployeeForm() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { sessionStore } = useContext(SessionContext);
  const { showSuccess, showAlertMessage, showWarn } = useContext(NotificationContext);
  const missionId = sessionStore.currentMissionId;
  const service = useEmployeeService();
  const userService = useUserService();
  const { mode, employeeId, feature } = useParams();
  const { goTo, goToWithQueryParams } = useNavigationLinks();
  const { validate } = useEmployeeValidation();
  const { checkRole } = useAuthorization();

  const getRequestData = () => {
    return {
      employeeId: state.employee.employeeId,
      name: state.employee.name?.trim(),
      positionTitle: state.employee.positionTitle?.trim(),
      email: state.employee.email?.trim(),
      openNetId: state.employee.email,
      bureaus: state.employee.bureaus,
      missionId: state.employee.missionId,
      postId: state.employee.postId === 0 ? null : state.employee.postId,
      sectionId: state.employee.sectionId,
      agencyCode: state.employee.agencyCode,
      isLocalStaff: state.employee.isLocalStaff,
      supervisorId: state.employee.supervisorId,
      isUSDHSupervisor: state.employee.isUSDHSupervisor,
      isBureauUser: state.employee.isBureauUser,
    };
  };

  const isEditMode = useCallback(() => mode.toLowerCase() === 'edit' || mode.toLowerCase() === 'sysedit', [mode]);
  const isNewMode = useCallback(() => mode.toLowerCase() === 'new', [mode]);

  const helperFunctions = {
    onChangeSupervisor: (value) => dispatch({ type: 'UPDATE_SUPERVISOR', data: value }),

    // TRANSFER EMPLOYEE
    onClickTransferEmployee: async () => {
      dispatch({ type: 'START_OKTA_REQUEST' });
      const response = await userService.listOktaUsers(state.oktaSearchTerm);
      if (response.ok) {
        dispatch({ type: 'SET_OKTA_RESULT', data: response.data });
      } else {
        helperFunctions.closeOktaSearchModal();
      }
    },

    onTransferEmployeeModalOpen: (value) => dispatch({ type: 'OPEN_EMPLOYEE_TRANSFER_MODAL', data: value }),

    closeTransferEmployeeModal: () => dispatch({ type: 'CLOSE_EMPLOYEE_TRANSFER_MODAL' }),

    onClickModalTransferEmployeeOk: (value) => dispatch({ type: 'TRANSFER_EMPLOYEE_POST', data: value }),

    onClickSave: async () => {
      const requestData = getRequestData();
      const validationResult = await validate(requestData);
      if (!validationResult.isValid) {
        showAlertMessage(validationResult.errors, 'Validation error');
        return;
      }
      const response = await service.save(requestData);
      if (response && response.ok) {
        showSuccess('Employee data successfully saved');
      }
      if (isNewMode() && response.ok) {
        goTo('employee_search');
      }
    },

    onClickDelete: async () => {
      const response = await service.deleteEmployee(employeeId);
      if (response && response.ok) {
        showSuccess('Successfully deleted employee');
        goToWithQueryParams('employee_search', { key: 'removeEmployee', value: employeeId });
      }
    },

    setEmployeeFromOktaRecord: async () => {
      if (state.oktaSelectedRecord) {
        dispatch({
          type: 'UPDATE_USER_FROM_OKTA_RECORD',
          data: service.newUserRecordFromOktaRecord(state.oktaSelectedRecord),
        });
        if (await helperFunctions.checkIfUserExists(state.oktaSelectedRecord.email)) {
          showAlertMessage('This email is already in use.', 'Warning', faExclamationTriangle);
        }
      }
    },

    onClickSearchOkta: async () => {
      dispatch({ type: 'START_OKTA_REQUEST' });
      const response = await userService.listOktaUsers(state.oktaSearchTerm);
      if (response.ok) {
        dispatch({ type: 'SET_OKTA_RESULT', data: response.data });
      } else {
        helperFunctions.closeOktaSearchModal();
      }
    },

    checkIfUserExists: async (login) => {
      const response = await service.getEmployeeByLogin(login);
      console.log('user exists: ', response.data);
      return response.ok;
    },

    openOktaSearchModal: () => dispatch({ type: 'OPEN_OKTA_SEARCH_MODAL' }),
    closeOktaSearchModal: () => dispatch({ type: 'CLOSE_OKTA_SEARCH_MODAL' }),
    onChangeOktaSearchTerm: (term) => dispatch({ type: 'SET_OKTA_SEARCH_TERM', data: term.trim() }),
    onSelectOktaRecord: (item) => dispatch({ type: 'SET_SELECTED_OKTA_RECORD', data: item }),
    resetForm: () => dispatch({ type: 'RESET_FORM' }),

    loadUserRoles: async (userId) => {
      dispatch({ type: 'START_USER_ROLES_LOAD' });
      const response = await userService.getUserRoles(userId);
      if (response.ok) {
        dispatch({ type: 'SET_USER_ROLES', data: response.data });
      }
    },

    onChangeNewRoleMission: (id) => dispatch({ type: 'SET_SELECTED_NEW_ROLE_MISSION', data: id }),
    onChangeNewRoleKey: (key) => dispatch({ type: 'SET_SELECTED_NEW_ROLE_KEY', data: key }),

    onClickAddUserRole: () => {
      const existingRole = state.employee.userRoles.find(
        (item) => item.missionId === state.selectedNewRoleMissionId && item.key === state.selectedNewRoleKey
      );
      if (existingRole) {
        if (existingRole.removed) dispatch({ type: 'UNDO_REMOVE_USER_ROLE_BY_ID', data: existingRole.id });
        else showWarn('This Mission/Role is already assigned');
        return;
      }
      if (state.selectedNewRoleMissionId !== sessionStore.currentMissionId && !checkRole('system_roles')) {
        showWarn('You cannot assign roles outside your current mission.');
        return;
      }

      const newRoleToAssign = {
        id: 0,
        employeeId: state.employee.employeeId,
        key: state.selectedNewRoleKey,
        missionId: state.selectedNewRoleMissionId,
        missionName: state.missions.find((item) => item.id === state.selectedNewRoleMissionId).name,
        name: state.availableMissionRoles.find((item) => item.key === state.selectedNewRoleKey).name,
      };
      console.log(newRoleToAssign);
      dispatch({ type: 'ADD_NEW_USER_ROLE', data: newRoleToAssign });
    },

    onClickAddSysAdminRole: () => {
      const sysAdminRole = {
        id: 0,
        employeeId: state.employee.employeeId,
        key: 'SystemAdmin',
        missionId: null,
        missionName: null,
        name: 'System Administrator',
      };
      dispatch({ type: 'ADD_NEW_USER_ROLE', data: sysAdminRole });
    },

    onClickRemoveUserRole: (id) => {
      dispatch({ type: 'REMOVE_USER_ROLE_BY_ID', data: id });
    },

    onClickSaveUserRoles: async () => {
      const requestData = { employeeId: state.employee.employeeId, employeeMissionRoles: [] };
      state.employee.userRoles.forEach((item) => {
        if (!item.removed)
          requestData.employeeMissionRoles.push({
            employeeId: item.employeeId,
            key: item.key,
            missionId: item.missionId,
          });
      });
      console.log('roles to save', requestData);
      const response = await userService.saveUserRoles(requestData);
      if (response && response.ok) {
        showSuccess('Employee assigned roles successfully saved');
        await helperFunctions.loadUserRoles(state.employee.employeeId);
      }
      return response.ok;
    },
    resetUserRoles: () => dispatch({ type: 'RESET_USER_ROLES' }),

    isEditMode,
    isNewMode,
  };

  useEffect(() => {
    const loadEmployee = async () => {
      dispatch({ type: 'START_EMPLOYEE_LOAD' });
      const response = await service.getFullEmployee(employeeId || 0, missionId);
      if (response && response.ok) {
        dispatch({ type: 'SET_EMPLOYEE', data: response.data });
      }
    };

    if (state.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadEmployee();
    }
  }, [employeeId, service, missionId, state.loadRequest, isEditMode, state.newEmployeeId]);

  if (isEditMode() && feature && feature.toLowerCase() === 'roles') {
    return <EmployeeRoles formStore={state} helperFunctions={helperFunctions} />;
  }

  return (
    <EmployeeEdit
      formStore={state}
      mode={mode.toLowerCase()}
      onChangeField={(fieldData) => dispatch({ type: 'SET_EMPLOYEE_FIELD', data: fieldData })}
      helperFunctions={helperFunctions}
    />
  );
}

export default EmployeeForm;
