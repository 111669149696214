import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

function SignOffComment({ comment, onChangeComment }) {
  return (
    <>
      <div className="my-1 font-weight-bold">Comment</div>
      <div>
        <Input type="textarea" name="text" value={comment || ''} onChange={(e) => onChangeComment(e.target.value)} />
      </div>
    </>
  );
}

SignOffComment.propTypes = {
  comment: PropTypes.string,
  onChangeComment: PropTypes.func.isRequired,
};
SignOffComment.defaultProps = {
  comment: '',
};

export default SignOffComment;
