import { format, formatDistance, addMinutes, isValid, formatISO } from 'date-fns';
import moment from 'moment';

const getDateUtcNow = () => {
  const date = new Date();
  return addMinutes(date, date.getTimezoneOffset());
};

const formatDate = (dateToFormat) => {
  if (!dateToFormat) return '';

  const date = new Date(dateToFormat);
  return format(addMinutes(date, date.getTimezoneOffset()), 'P');
};

const formatDateAndTime = (dateToFormat) => {
  if (!dateToFormat) return '';

  const date = new Date(dateToFormat);
  return format(addMinutes(date, date.getTimezoneOffset()), 'P pp');
};

const removeTimeFromDate = (theDate) => {
  return formatISO(theDate, { representation: 'date' });
};

const formatDistanceToUtcNow = (dateToFormat) => {
  if (!dateToFormat) return '';
  let dateString = dateToFormat;

  if (typeof dateToFormat === 'string' && dateToFormat.slice(-1) === 'Z') {
    dateString = dateToFormat.slice(0, -1);
  }

  const date = new Date(dateString);
  const utcNow = addMinutes(new Date(), date.getTimezoneOffset());
  return formatDistance(date, utcNow);
};

const nullOrDate = (dateString) => {
  return dateString !== null ? new Date(dateString) : null;
};

const formatUtcDate = (dateString) => {
  const date = new Date(dateString);
  const dateUtc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  return new Date(dateUtc);
};

const getMomentDate = (dateString) => {
  return moment(new Date(dateString)).format('MM/DD/YYYY');
};

export {
  formatDate,
  formatDateAndTime,
  formatDistanceToUtcNow,
  getDateUtcNow,
  formatUtcDate,
  isValid,
  removeTimeFromDate,
  nullOrDate,
  getMomentDate,
};
