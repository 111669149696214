import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';

import { SessionContext } from 'contexts';
import { formatDistanceToUtcNow, formatDateAndTime, getDateUtcNow } from 'utils/dateHelpers';
import { nominationStatus } from 'constants/index';
import { ActionButton } from 'components';
import NoteItem from './NoteItem';

function NotesSideBar({ formStore, onClickAddNote, onClickRemoveNote }) {
  const [noteValue, setNoteValue] = useState();
  const [isFilterCommentsOn, setFilterCommentsOn] = useState(false);
  const { sessionStore } = useContext(SessionContext);

  const addNote = () => {
    const note = {
      userEmployeeId: sessionStore.employeeUser.employeeId,
      userName: sessionStore.employeeUser.name,
      content: noteValue,
      createdDate: getDateUtcNow(),
      noteType: 'comment',
      noteState: 'new',
    };
    onClickAddNote(note);
    setNoteValue('');
  };

  const { canAddComments } = formStore.nomination;
  const histoyEvents = formStore.nomination.history || [];
  const events = isFilterCommentsOn ? histoyEvents.filter((item) => item.nominationNote) : histoyEvents;

  const getReasonForDisableComments = () => {
    if (formStore.nomination.nominationStatus === nominationStatus.DISAPPROVED) {
      return 'You cannot add comments for disapproved nominations';
    }

    return 'You need to save your nomination before you can add comments';
  };

  const renderBy = (event) => {
    if (event.impersonatorName)
      return (
        <>
          by {event.impersonatorName} on behalf of {event.employeeName}
        </>
      );
    return <>by {event.employeeName}</>;
  };

  return (
    <div className="w-100 h-100 right-sidebar container-fluid history-and-comments">
      <div className="d-flex">
        <h6 className="flex-fill">History & Comments</h6>
        <div className="small-text d-flex flex-fill justify-content-md-end">
          <span className="align-self-center mr-2">Show only comments ?</span>
          <InputSwitch checked={isFilterCommentsOn} onChange={() => setFilterCommentsOn(!isFilterCommentsOn)} />
        </div>
      </div>
      {!canAddComments && !formStore.nomination.id && (
        <div className="font-italic">{getReasonForDisableComments()}</div>
      )}

      {canAddComments && (
        <>
          <div>
            <InputTextarea
              value={noteValue}
              className="form-control"
              autoResize
              rows={2}
              placeholder="Add your comment"
              onChange={(evt) => setNoteValue(evt.target.value)}
            />
          </div>
          <div className="float-right">
            <Button
              label="Save Comment"
              icon="pi pi-save"
              className="p-button-primary p-button-sm mt-1 add-note-btn"
              disabled={!noteValue}
              onClick={() => addNote()}
            />
          </div>
        </>
      )}
      <div className="mt-5 pr-2 overflow-y-scroll overflow-x-hidden h-100">
        {events &&
          events.length > 0 &&
          events.map((event) => (
            <div key={event.id} className="history-event">
              <div className="small-text">{event.eventDescription}</div>
              {event.nominationNote && (
                <NoteItem
                  value={event.nominationNote.content}
                  removeItem={() => onClickRemoveNote(event.nominationNote.indexId)}
                  readOnly
                />
              )}
              <div className="small-text text-right font-italic">
                {renderBy(event)} {formatDistanceToUtcNow(event.createdDate)} ago
                <ActionButton
                  tooltip={formatDateAndTime(event.createdDate)}
                  icon={['far', 'watch']}
                  className="p-button-text p-button-plain px-2 btn-edit-image ml-2"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
NotesSideBar.propTypes = {
  formStore: PropTypes.object.isRequired,
  onClickAddNote: PropTypes.func.isRequired,
  onClickRemoveNote: PropTypes.func.isRequired,
};
export default NotesSideBar;
