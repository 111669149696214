import React from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from 'ckeditor4-react';
import { ckEditorConfig } from 'config/ckEditorConfig';
import { Skeleton } from 'primereact/skeleton';

function NominationJustification({
  value,
  readOnly,
  onChange,
  isLoadingInProgress,
  isJustificationDirty,
  isJustificationSaved,
  isBeingAutoSaved,
}) {
  return (
    <div className="row">
      <div className="form-group col-12">
        {/* <h6 className="mt-0">Justification</h6> */}
        {isLoadingInProgress && <Skeleton className="p-mb-2" height="12rem" />}
        {!isLoadingInProgress && (
          <CKEditor
            config={ckEditorConfig}
            type="classic"
            initData={value}
            onChange={(evt) => onChange(evt.editor.getData())}
            readOnly={readOnly}
            editorUrl="/vendor/ckeditor/ckeditor.js"
          />
        )}

        {isJustificationDirty && isBeingAutoSaved ? (
          <small className="text-primary p-2">
            <span className="spinner-border spinner-border-sm" /> saving
          </small>
        ) : (
          isJustificationSaved && (
            <small className="text-primary p-2">
              <span> saved</span>{' '}
            </small>
          )
        )}
      </div>
    </div>
  );
}
NominationJustification.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoadingInProgress: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isJustificationDirty: PropTypes.bool.isRequired,
  isJustificationSaved: PropTypes.bool.isRequired,
  isBeingAutoSaved: PropTypes.bool.isRequired,
};

export default React.memo(NominationJustification);
