import React from 'react';
import PropTypes from 'prop-types';

import Footer from 'views/Shared/Footer';
import TopBar from './TopBar';

function Layout({ children }) {
  return (
    <>
      <TopBar />
      <div className="page-wrapper">{children}</div>
      <Footer />
    </>
  );
}
Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default React.memo(Layout);
