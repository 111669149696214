import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Label, Row, Col, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Calendar } from 'primereact/calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { isValid, formatUtcDate, getMomentDate } from 'utils/dateHelpers';
import { SessionContext, NotificationContext } from 'contexts';
import { useNominationService } from 'hooks/nomination';
import { nominationStatus } from 'constants/index';
import { fi } from 'date-fns/locale';

function SendToOPSModal({
  listToProcess,
  modalMode,
  isSendToOPSModalOpened,
  onClickToggleModal,
  navigationFnAfterProcessed,
  setEffectiveDateAssigned,
  nominationAction,
}) {
  const { sessionStore } = useContext(SessionContext);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { showWarn, showSuccess } = useContext(NotificationContext);
  const { employeeId } = sessionStore.employeeUser;
  const service = useNominationService();

  const isAssignEffectiveDateMode = modalMode === nominationStatus.PENDING_EFFECTIVE_DATE;
  const isExcludeFromOPS = modalMode === nominationStatus.COMPLETED;
  const isSendToOPS = modalMode === nominationStatus.SEND_TO_OPS;

  let title = 'Send To OPS';
  let actionBtnLabel = 'Send To OPS';
  let warning = `When you click on the Send button, the nomination data required by OPS will be sent by each \
    nominee's name. For the OAS system, the nomination will be considered COMPLETED.`;

  const fiscalStripsGT70 =
    isSendToOPS &&
    listToProcess
      .flatMap((x) => x.nominationEmployees)
      .map((x) => x.fiscalStrip)
      .every((x) => x?.length <= 70);

  const fiscalStripWarning =
    !isAssignEffectiveDateMode && !isExcludeFromOPS && fiscalStripsGT70
      ? 'Fiscal strip will be trimmed to 70 characters while sending to OPS.'
      : null;

  if (isExcludeFromOPS) {
    title = 'Exclude From Sending To OPS';
    actionBtnLabel = 'Confirm';
    warning =
      'This will move the selected nomination(s) to completed without submission to OPS. You must manually process a personnel action in OPS.  Do you want to move the nomination(s) to completed without submission to OPS?';
  } else if (isAssignEffectiveDateMode) {
    title = 'Assign Effective Date';
    actionBtnLabel = 'Assign Date';
    warning = `When assigning an award payment <b>effective date</b>, be sure to choose a date at the beginning of a \
        pay period and observe notice periods/deadlines applicable to the processing of personnel actions for cash \
        awards.`;
  }

  if (isProcessing) {
    actionBtnLabel = 'Processing...';
  }

  const clickAction = async () => {
    if (isAssignEffectiveDateMode && !isValid(effectiveDate)) {
      showWarn('Effective date not valid');
      return;
    }
    setIsProcessing(true);
    const requestData = {
      nominationList: listToProcess.map((item) => item.id),
      userEmployeeId: employeeId,
      effectiveDate: getMomentDate(effectiveDate),
      nominationAction,
    };
    const response = await service.sendToOPS(requestData);

    let successMessage = 'Data successfully excluded from send to OPS';

    if (!isExcludeFromOPS) {
      successMessage = isAssignEffectiveDateMode
        ? 'Effective date was successfully saved'
        : 'Data successfully sent to OPS';
    }

    if (response && response.ok) {
      showSuccess(successMessage);
    }
    setIsProcessing(false);
    if (typeof navigationFnAfterProcessed === 'function') {
      navigationFnAfterProcessed();
      return;
    }

    if (typeof setEffectiveDateAssigned === 'function') {
      setEffectiveDateAssigned(!isAssignEffectiveDateMode);
    }
    onClickToggleModal(false); // force refresh = true
  };

  const dateTemplate = (date) => {
    const basePayDay = new Date('11/13/2022');
    const thisDate = new Date(`${date.month + 1}/${date.day}/${date.year}`);

    const diffTime = Math.abs(thisDate - basePayDay);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (date.selectable) {
      if (diffDays % 14 === 0) {
        return <strong style={{ textDecoration: 'line-through' }}>{date.day}</strong>;
      }
    }

    return date.day;
  };

  return (
    <Modal isOpen={isSendToOPSModalOpened} toggle={onClickToggleModal} className="modal-lg">
      <ModalHeader toggle={onClickToggleModal}>{title}</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div className="alert alert-warning d-flex justify-content-center align-items-center" role="alert">
              <FontAwesomeIcon icon={faExclamationCircle} size="2x" className="mr-2" />
              <div dangerouslySetInnerHTML={{ __html: warning }} />
            </div>
            {fiscalStripWarning && (
              <div className="alert alert-warning d-flex justify-content-left align-items-center" role="alert">
                <FontAwesomeIcon icon={faExclamationCircle} size="2x" className="mr-2" />
                <div dangerouslySetInnerHTML={{ __html: fiscalStripWarning }} />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="4">
            {isAssignEffectiveDateMode && (
              <FormGroup>
                <Label for="modalEffectiveDate">Select the effective date</Label>
                <Calendar
                  id="pendingEffectiveDate"
                  showIcon
                  value={effectiveDate && formatUtcDate(effectiveDate)}
                  disabledDays={[1, 2, 3, 4, 5, 6]}
                  minDate={new Date()}
                  onChange={(e) => setEffectiveDate(formatUtcDate(e.target.value))}
                  dateTemplate={dateTemplate}
                  className="w-100"
                  appendTo="self"
                />
              </FormGroup>
            )}
          </Col>
          <Col className="text-right pr-5">
            <Label for="modalNumberOfNominations">
              Number of nominations to be processed:
              <span className="ml-2 font-weight-bold">{listToProcess.length}</span>
            </Label>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="min-w-150px" color="primary" onClick={() => clickAction()} disabled={isProcessing}>
          {actionBtnLabel}
        </Button>
        <Button className="min-w-150px" color="secondary" onClick={() => onClickToggleModal()} disabled={isProcessing}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
SendToOPSModal.propTypes = {
  listToProcess: PropTypes.array.isRequired,
  modalMode: PropTypes.number.isRequired,
  isSendToOPSModalOpened: PropTypes.bool.isRequired,
  onClickToggleModal: PropTypes.func.isRequired,
  setEffectiveDateAssigned: PropTypes.func,
  navigationFnAfterProcessed: PropTypes.func,
  nominationAction: PropTypes.number.isRequired,
};
SendToOPSModal.defaultProps = {
  setEffectiveDateAssigned: undefined,
  navigationFnAfterProcessed: undefined,
};
export default React.memo(SendToOPSModal);
