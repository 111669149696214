import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { Label, Row, Col, Card } from 'reactstrap';
import Select from 'react-select';
import UserSearch from 'components/UserSearch';
import YesNoModal from 'components/YesNoModal';
import { roleType } from 'constants/index';

function PostAndRolesSettings({ store, dispatch, onSaveClick }) {
  const onClickNo = () => {
    dispatch({ type: 'RESET_JCAC_CHANGES' });
    dispatch({ type: 'SET_YES_NO_MODAL_CLOSE' });
  };

  const onClickYes = () => {
    dispatch({ type: 'SET_READY_FOR_SAVE' });
    dispatch({ type: 'SET_YES_NO_MODAL_CLOSE' });
  };

  useEffect(() => {
    if (store.isJCACMemberModified) {
      dispatch({ type: 'SET_YES_NO_MODAL_OPEN' });
    }
  }, [dispatch, store.isJCACMemberModified]);

  return (
    <>
      <Row>
        <Col xs="12" md="12" lg="12" xl="10">
          <div>
            <Label for="multiSelectPosts">Post Associated</Label>
          </div>
          <div>
            <Select
              id="cyclePosts"
              value={store.cyclePosts}
              options={store.missionPosts}
              isMulti
              getOptionLabel={(item) => item.value}
              getOptionValue={(item) => item.key}
              onChange={(e) => dispatch({ type: 'UPDATE_DATA', data: { cyclePosts: e } })}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12" md="12" lg="12" xl="10">
          <div>
            <Label>Cycle Assigned Roles</Label>
          </div>
          <Card>
            <div>
              <table className="table w-100">
                <thead className="thead-light">
                  <tr>
                    <th className="col-4">Roles</th>
                    <th className="col-8">Assignees</th>
                  </tr>
                </thead>
                <tbody>
                  {store.cycleAssignedEmployees.map((role) => (
                    <tr key={role.roleKey}>
                      <td>{role.roleName}</td>
                      <td>
                        <UserSearch
                          value={role.employees}
                          setValue={(assignees) => {
                            dispatch({
                              type: 'UPDATE_ASSIGNED_ROLES',
                              data: {
                                roleKey: role.roleKey,
                                assignees,
                              },
                            });
                          }}
                          isMulti
                          role={roleType[role.roleKey]}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="p-3">
        <Button
          label="Save"
          icon="pi pi-save"
          className="min-w-150px p-button-primary mx-1"
          onClick={() => onSaveClick()}
        />
      </Row>

      {store.YesNoModalOpen && (
        <YesNoModal
          isModalOpened={store.YesNoModalOpen}
          header="Warning!"
          message="Changing the JCAC Members will affect all nominations that are pending JCAC action."
          onClickYes={onClickYes}
          onClickNo={onClickNo}
        />
      )}
    </>
  );
}
PostAndRolesSettings.propTypes = {
  store: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
};

export default PostAndRolesSettings;
