import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'primereact/button';
import { useReactToPrint } from 'react-to-print';
import { processType } from 'constants/index';
import DS66 from 'views/Reports/Forms/DS-66';
import CustomerServiceAwardForm from '../Reports/Forms/CustomerServiceAwardForm';

function PrintFormModal({ isModalOpened, onClickCloseModal, nominationId, awardProcessType, nomination }) {
  const componentRef = useRef();

  const getDocumentTitle = () => {
    const nominees = nomination && (nomination.nominationEmployees).map(x => x.name);
    const nominee = nominees?.length === 1 ? nominees : nomination.nominationKey;
    return `DS-0066 - ${nominee}`;
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: nomination && getDocumentTitle(),    
  });
  const renderModalBody = () => {
    const currentProcessType = awardProcessType; // ?? formStore.nomination.awardProcessType

    if (currentProcessType === processType.ANNUAL)
      return  <DS66 nominationId={nominationId} ref={componentRef} />

    if (currentProcessType === processType.ANYTIME)
      return <CustomerServiceAwardForm nominationId={nominationId} ref={componentRef} />;

    return null;
  };

  return (
    <Modal
      centered
      scrollable
      className="mh-100"
      isOpen={isModalOpened} // ?? formStore.isPrintFormModalOpened}
      toggle={onClickCloseModal}
      size="xl"
    >
      <ModalHeader toggle={onClickCloseModal}>Printable Nomination Form</ModalHeader>
      <ModalBody>{renderModalBody()}</ModalBody>
      <ModalFooter>
        <Button label="Print" icon="pi pi-print" onClick={handlePrint} className="w-100px" />
        <Button
          label="Cancel"
          icon="pi pi-times-circle"
          onClick={() => onClickCloseModal()}
          className="p-button-secondary w-100px"
        />
      </ModalFooter>
    </Modal>
  );
}
PrintFormModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  onClickCloseModal: PropTypes.func.isRequired,
  nominationId: PropTypes.number,
  awardProcessType: PropTypes.number,
  nomination: PropTypes.object,
};

PrintFormModal.defaultProps = {
  nominationId: null,
  awardProcessType: null,
  nomination: null
};

export default PrintFormModal;
