import React, { useReducer, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { NotificationContext } from 'contexts';
import { Label, Row, Col, Input, FormGroup } from 'reactstrap';
import { Calendar } from 'primereact/calendar';
import { SaveCancel } from 'components';
import { useNavigationLinks } from 'hooks';
import { useParams } from 'react-router';
import { getDateUtcNow, formatUtcDate } from 'utils/dateHelpers';
import * as yup from 'yup';
import { requestStatus } from 'constants/index';
import WarningMessage from 'views/Shared/WarningMessage';
import { useBureauCycleService } from 'hooks/admin/useBureauCycleService';
import { SessionContext } from 'contexts/SessionContext';

const ACTION_TYPE = {
  START_LOAD_REQUEST: 'START_LOAD_REQUEST',
  UPDATE_DATA: 'UPDATE_DATA',
};

const initialState = {
  id: 0,
  year: '',
  name: '',
  openingDate: null,
  closingDate: null,
  votingStartDate: null,
  votingEndDate: null,
  validationResultErrors: [],
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
};

const stateValidateSchema = yup.object().shape({
  year: yup.string().required('Year should be informed.'),
  name: yup.string().required('Name is required.'),
  openingDate: yup.date().nullable().required('Opening Date should be informed.'),
  closingDate: yup
    .date()
    .nullable()
    .required('Closing Date should be informed.')
    .min(yup.ref('openingDate'), 'Opening Date should be before the Closing Date'),
  votingStartDate: yup
    .date()
    .nullable()
    .required('Voting Start Date should be informed.')
    .min(yup.ref('openingDate'), 'Opening Date should be before the Vote Starting Date'),
  votingEndDate: yup
    .date()
    .nullable()
    .required('Voting End Date should be informed.')
    .min(yup.ref('votingStartDate'), 'Vote Starting Date should be before the Vote Ending Date'),
});

const getNext3Years = () => {
  const thisDate = getDateUtcNow();
  const startYear = thisDate.getFullYear();
  return [startYear, startYear + 1, startYear + 2];
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.START_LOAD_REQUEST:
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };
    case ACTION_TYPE.UPDATE_DATA:
      return { ...state, ...action.data };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function BureauCycleBasicSettings({ headerData }) {
  const [store, dispatch] = useReducer(reducer, initialState);
  const { sessionStore } = useContext(SessionContext);
  const { goTo } = useNavigationLinks();
  const { showSuccess, showError } = useContext(NotificationContext);
  const bureauCycleService = useBureauCycleService();
  const { cycleId } = useParams();
  const { currentMissionId } = sessionStore;

  useEffect(() => {
    const loadCycle = async () => {
      dispatch({ type: ACTION_TYPE.START_LOAD_REQUEST });
      const response = await bureauCycleService.loadCycleSettings(cycleId);
      if (response && response.ok) {
        dispatch({ type: ACTION_TYPE.UPDATE_DATA, data: response.data });
      }
    };
    if (cycleId && store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      dispatch({ type: ACTION_TYPE.UPDATE_DATA, data: { id: cycleId } });
      loadCycle();
    }
  }, [bureauCycleService, cycleId, store.loadRequest]);

  useEffect(() => {
    headerData(store.year, store.name, store.id);
  }, [headerData, store.year, store.name, store.id]);

  const CreateCycle = async (bureauCycle) => {
    const response = await bureauCycleService.create(bureauCycle);
    if (response && response.ok) {
      if (response.data.errors.length > 0) {
        dispatch({ type: ACTION_TYPE.UPDATE_DATA, data: { validationResultErrors: response.data.errors } });
        showError('There was an error saving the bureau cycle settings.');
      } else {
        dispatch({ type: ACTION_TYPE.UPDATE_DATA, data: { id: response.data.id, validationResultErrors: [] } });
        showSuccess('Cycle data successfully saved!');
      }
    }
  };

  const EditCycle = async (bureauCycle) => {
    const response = await bureauCycleService.update(bureauCycle);
    if (response && response.ok) {
      if (response.data.errors.length > 0) {
        dispatch({ type: ACTION_TYPE.UPDATE_DATA, data: { validationResultErrors: response.data.errors } });
        showError('There was an error saving the bureau cycle settings.');
      } else {
        dispatch({ type: ACTION_TYPE.UPDATE_DATA, data: { validationResultErrors: [] } });
        showSuccess('Cycle data successfully updated!');
      }
    }
  };

  function saveData() {
    stateValidateSchema
      .validate(
        {
          year: store.year,
          name: store.name.trim(),
          openingDate: store.openingDate,
          closingDate: store.closingDate,
          votingStartDate: store.votingStartDate,
          votingEndDate: store.votingEndDate,
        },
        { abortEarly: false }
      )
      .then(async (bureauCycle) => {
        bureauCycle.bureauId = currentMissionId;
        if (!store.id) {
          await CreateCycle(bureauCycle);
        } else {
          bureauCycle.id = store.id;
          await EditCycle(bureauCycle);
        }
      })
      .catch((err) => {
        dispatch({ type: ACTION_TYPE.UPDATE_DATA, data: { validationResultErrors: err.errors } });
        showError('There was an error saving the bureau cycle settings.');
      });
  }

  return (
    <>
      <h4>Bureau Coordinator Cycles</h4>
      <Row>
        <Col xs="12" sm="9" md="4" lg="3" xl="3">
          <FormGroup>
            <Label for="cycleYear">Cycle Year</Label>
            {store.year && <Input type="text" id="cycleYear" readOnly value={store.year} />}
            {!store.year && (
              <Input
                type="select"
                id="cycleYear"
                onChange={(e) => dispatch({ type: ACTION_TYPE.UPDATE_DATA, data: { year: e.target.value } })}
              >
                <option />
                {getNext3Years().map((year) => (
                  <option key={year}>{year}</option>
                ))}
              </Input>
            )}
          </FormGroup>
        </Col>
        <Col xs="12" sm="12" md="4" lg="9" xl="9">
          <FormGroup>
            <Label for="cycleName">Name</Label>
            <Input
              type="text"
              id="cycleName"
              value={store.name}
              onChange={(e) => dispatch({ type: ACTION_TYPE.UPDATE_DATA, data: { name: e.target.value } })}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="9" md="4" lg="3" xl="3">
          <FormGroup>
            <Label for="openingDate">Opening Date</Label>
            <div>
              <Calendar
                id="openingDate"
                showIcon
                value={store.openingDate && formatUtcDate(store.openingDate)}
                onChange={(e) =>
                  dispatch({
                    type: ACTION_TYPE.UPDATE_DATA,
                    data: { openingDate: formatUtcDate(e.target.value) },
                  })
                }
                className="w-100"
              />
            </div>
          </FormGroup>
        </Col>
        <Col xs="12" sm="9" md="4" lg="3" xl="3">
          <FormGroup>
            <Label for="closingDate">Closing Date</Label>
            <div>
              <Calendar
                id="closingDate"
                showIcon
                value={store.closingDate && formatUtcDate(store.closingDate)}
                onChange={(e) =>
                  dispatch({
                    type: ACTION_TYPE.UPDATE_DATA,
                    data: { closingDate: formatUtcDate(e.target.value) },
                  })
                }
                className="w-100"
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="9" md="4" lg="3" xl="3">
          <FormGroup>
            <Label for="votingStartDate">Voting Start Date</Label>
            <div>
              <Calendar
                id="votingStartDate"
                showIcon
                value={store.votingStartDate && formatUtcDate(store.votingStartDate)}
                onChange={(e) =>
                  dispatch({
                    type: ACTION_TYPE.UPDATE_DATA,
                    data: { votingStartDate: formatUtcDate(e.target.value) },
                  })
                }
                className="w-100"
              />
            </div>
          </FormGroup>
        </Col>
        <Col xs="12" sm="9" md="4" lg="3" xl="3">
          <FormGroup>
            <Label for="votingEndDate">Voting End Date</Label>
            <div>
              <Calendar
                id="votingEndDate"
                showIcon
                value={store.votingEndDate && formatUtcDate(store.votingEndDate)}
                onChange={(e) =>
                  dispatch({
                    type: ACTION_TYPE.UPDATE_DATA,
                    data: { votingEndDate: formatUtcDate(e.target.value) },
                  })
                }
                className="w-100"
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="px-3">
        <div className="form-group col-6">
          {store.validationResultErrors.length > 0 && (
            <WarningMessage
              message="Form validation failed!"
              details={store.validationResultErrors}
              onClickClose={() => {
                dispatch({ type: ACTION_TYPE.UPDATE_DATA, data: { validationResultErrors: [] } });
              }}
            />
          )}
        </div>
      </Row>
      <Row className="px-3">
        <SaveCancel onCancelClick={() => goTo('bureau_cycles_list')} onSaveClick={() => saveData()} />
      </Row>
    </>
  );
}

BureauCycleBasicSettings.propTypes = {
  headerData: PropTypes.func.isRequired,
};

export default BureauCycleBasicSettings;
