import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Container, Row, Col, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { UserSearch, SkeletonTable } from 'components';
import { dynamicSort } from 'utils/sorting';
import { requestStatus, employeeType } from 'constants/index';
import { useEmployeeSearchService, useNavigationLinks, useAuthorization } from 'hooks';
import './EmployeeEdit.css';

const initialState = {
  availableParamsRequest: requestStatus.REQUEST_NOT_INITIATED,
  availableParams: {
    missions: [],
    posts: [],
  },
  searchParams: {
    selectedMissionId: 0,
    selectedPostId: null,
  },
  searchResult: [],
  searchRequestStatus: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_AVAILABLE_PARAMS':
      return { ...state, availableParams: { ...state.availableParams, ...action.data } };

    case 'START_REQUEST_SEARCH_PARAMS':
      return { ...state, availableParamsRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'UPDATE_SEARCH_PARAMS':
      return {
        ...state,
        searchParams: { ...state.searchParams, ...action.data },
        availableParamsRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'UPDATE_SEARCH_RESULT':
      return { ...state, searchResult: action.data, searchRequestStatus: requestStatus.REQUEST_COMPLETED };

    case 'START_SEARCH_REQUEST':
      return { ...state, searchRequestStatus: requestStatus.REQUEST_IN_PROGRESS };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const getMissionsAndBureausList = (bureaus, missions) => {
  const result = missions.map((item) => {
    const bureauName = bureaus.find((elem) => elem.key === item.bureauId).value;
    return { key: item.id, value: `${bureauName} - ${item.name}` };
  });
  return result.sort(dynamicSort('value'));
};

function EmployeeEdit({ formStore, mode, onChangeField, helperFunctions }) {
  const { goTo, goToWithQueryParams, goBack } = useNavigationLinks();
  const service = useEmployeeSearchService();
  const { checkRole } = useAuthorization();
  const isSysNew = mode === 'sysnew';
  const isEditMode = mode === 'edit' || mode === 'sysedit';
  const MIN_SEARCH_TERM_LENGTH = 3;
  const [isWashingtonUser, setIsWashingtonUser] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const isLoading = isWashingtonUser === null;

  useEffect(() => {
    if (!(checkRole('system_roles') || checkRole('hr_officer'))) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  useEffect(() => {
    const mission = state.availableParams.missions.find((m) => m.id === formStore.employee.missionId);
    if (mission) {
      setIsWashingtonUser(mission.name === 'Washington, DC');
    }
  }, [formStore.employee.missionId, state.availableParams.missions]);

  const setParamValue = (paramValue) => {
    dispatch({
      type: 'UPDATE_SEARCH_PARAMS',
      data: { ...paramValue },
    });
  };

  const getTitle = () => {
    return isEditMode ? 'Edit Employee' : 'Add New USDH Employee';
  };

  const onClickModalOk = () => {
    helperFunctions.setEmployeeFromOktaRecord();
  };

  function autoOpenPostsDropdown(length) {
    if (length > 1) {
      document.getElementById('searchposts').click();
    }
  }

  const onClickClose = () => {
    if (mode === 'new') {
      goBack();
    }
    goToWithQueryParams(mode === 'edit' ? 'employee_search' : 'sysadm_employee_search', {
      key: 'restoreResult',
      value: 'yes',
    });
  };

  async function loadPosts(selectedMissionId) {
    const response = await service.getAvailablePostsByMission(selectedMissionId);
    if (response && response.ok) {
      dispatch({
        type: 'UPDATE_AVAILABLE_PARAMS',
        data: {
          posts: response.data,
        },
      });
      dispatch({
        type: 'UPDATE_SEARCH_PARAMS',
        data: {
          selectedPostId: response.data[0].key,
        },
      });
    }
    autoOpenPostsDropdown(response.data.length);
  }

  const fullNameTemplate = (rowData) => `${rowData.lastName}, ${rowData.firstName} ${rowData.MiddleName || ''}`;

  useEffect(() => {
    async function loadSearchParameters() {
      dispatch({ type: 'START_REQUEST_SEARCH_PARAMS' });
      const response = await service.getSearchAvailableParameters(formStore.employee.missionId);
      if (response && response.ok) {
        dispatch({
          type: 'UPDATE_AVAILABLE_PARAMS',
          data: {
            bureaus: response.data.bureaus,
            sections: response.data.sections,
            missions: response.data.missions,
            posts: response.data.posts,
            missionsAndBureaus: getMissionsAndBureausList(response.data.bureaus, response.data.missions),
          },
        });
        dispatch({
          type: 'UPDATE_SEARCH_PARAMS',
          data: {
            selectedPostId: formStore.employee.postId,
            selectedMissionId: formStore.employee.missionId,
          },
        });
      }
    }

    if (formStore.employee.missionId && state.availableParamsRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadSearchParameters();
    }
  }, [
    service,
    formStore.employee.missionId,
    state.availableParamsRequest,
    formStore.employee.postId,
    state.searchParams.employeeId,
  ]);

  if (isLoading) {
    return <SkeletonTable colsSize={1} rowsSize={7} />;
  }
  return (
    <>
      <h4>{getTitle()}</h4>
      <Row className="my-2">
        <Col md="6">
          <Label>Email:</Label>
          <Input
            type="text"
            value={formStore.employee.email || ''}
            onChange={(e) => onChangeField({ email: e.target.value })}
            disabled={formStore.employee.isLocalStaff || formStore.isDataFromOkta}
          />
        </Col>
        <Col md="6">
          <div className="d-flex h-100">
            {!isEditMode && (
              <Button
                label="Query Okta"
                icon="pi pi-search"
                className="p-button-secondary mt-auto min-w-150px"
                onClick={() => helperFunctions.openOktaSearchModal()}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row className="my-2">
        <Col md="6">
          <Label>Name:</Label>
          <Input
            type="text"
            value={formStore.employee.name || ''}
            onChange={(e) => onChangeField({ name: e.target.value })}
            disabled={formStore.employee.isLocalStaff || formStore.isDataFromOkta}
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col md="6">
          <Label>Job Title:</Label>
          <Input
            type="text"
            value={formStore.employee.positionTitle || ''}
            onChange={(e) => onChangeField({ positionTitle: e.target.value })}
            disabled={formStore.employee.isLocalStaff || formStore.isDataFromOkta}
          />
        </Col>
      </Row>

      {isSysNew && (
        <Row className="my-2">
          <Col md="6">
            <div>Mission</div>
            <Dropdown
              value={formStore.employee.missionId}
              options={state.availableParams.missionsAndBureaus}
              optionLabel="value"
              optionValue="key"
              showClear
              className="w-100"
              onChange={(e) => {
                onChangeField({ missionId: e.target.value });
                loadPosts(e.target.value);
              }}
            />
          </Col>
        </Row>
      )}
      {isWashingtonUser && (
        <Row className="my-2">
          <Col>
            <Label>Is Bureau User:</Label>
            <div>
              <InputSwitch
                checked={formStore.employee.isBureauUser}
                onChange={(e) => onChangeField({ isBureauUser: e.target.value })}
              />
            </div>
          </Col>
        </Row>
      )}
      {formStore.employee.isBureauUser ? (
        <Row className="my-2">
          <Col md="6">
            <div>Bureau</div>
            <Select
              id="bureaus"
              classNamePrefix="selectbureaus"
              value={formStore.employee.bureaus}
              options={state.availableParams.bureaus}
              isMulti
              getOptionLabel={(item) => item.value}
              getOptionValue={(item) => item.key}
              onChange={(e) => onChangeField({ bureaus: e })}
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row className="my-2">
            <Col>
              <Label>Is Local Staff:</Label>
              <div>
                <InputSwitch
                  checked={formStore.employee.isLocalStaff}
                  onChange={(e) => onChangeField({ isLocalStaff: e.target.value })}
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="6">
              <div>Post</div>
              <Dropdown
                value={formStore.employee.postId}
                options={state.availableParams.posts}
                optionLabel="value"
                optionValue="key"
                showClear
                className="w-100"
                onChange={(e) => onChangeField({ postId: e.target.value })}
                disabled={formStore.employee.isLocalStaff}
              />
            </Col>
            <Col md="6">
              <div>USDH Supervisor</div>
              <UserSearch
                className="w-100"
                value={{
                  employeeId: formStore.employee.supervisorId || 0,
                  name: formStore.employee.supervisorName || '',
                }}
                setValue={(value) => helperFunctions.onChangeSupervisor(value)}
                disabled={formStore.employee.isLocalStaff}
                employeeTypeOption={employeeType.USDH}
                isGlobal
              />
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="6">
              <div>Agency</div>
              <Dropdown
                value={formStore.employee.agencyCode}
                options={formStore.agencies}
                optionLabel="value"
                optionValue="key"
                showClear
                className="w-100"
                onChange={(e) => onChangeField({ agencyCode: e.target.value })}
                disabled={formStore.employee.isLocalStaff}
              />
            </Col>
            <Col md="6">
              <div>Local Supervisor</div>
              <Input type="text" value={formStore.employee.localSupervisorName || ''} disabled />
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="6">
              <div>Section</div>
              <Dropdown
                value={formStore.employee.sectionId}
                options={formStore.sections}
                optionLabel="value"
                optionValue="key"
                showClear
                className="w-100"
                onChange={(e) => onChangeField({ sectionId: e.target.value })}
                disabled={formStore.employee.isLocalStaff}
              />
            </Col>
            <Col md="6">
              <div className={`anim-transition ${isEditMode ? 'opacity-0' : 'opacity-1'}`}>
                <Label>Assign USDH Supervisor Role:</Label>
                <div>
                  <InputSwitch
                    checked={formStore.employee.isUSDHSupervisor}
                    onChange={(e) => onChangeField({ isUSDHSupervisor: e.target.value })}
                    disabled={isEditMode}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}

      <Row className="my-3">
        <Col>
          <Button
            label="Save"
            icon="pi pi-save"
            className="p-button-primary min-w-150px mr-1"
            onClick={() => helperFunctions.onClickSave()}
            disabled={!formStore.isDirty}
          />
          <Button
            label="Assign Roles"
            icon="pi pi-users"
            className="p-button-info min-w-150px mr-1"
            disabled={!helperFunctions.isEditMode() || formStore.employee.isBureauUser}
            onClick={() => goTo('employee_assign_roles', { id: formStore.employee.employeeId })}
          />
          {!isEditMode && (
            <Button
              label="Reset Form"
              icon="pi pi-replay"
              className="p-button-warning min-w-150px mr-1"
              onClick={() => helperFunctions.resetForm()}
              disabled={!(formStore.isDataFromOkta || formStore.formHasChanged)}
            />
          )}

          {isEditMode && !formStore.employee.isLocalStaff && (
            <>
              <Button
                label="Transfer Post"
                icon="pi pi-arrow-right"
                className="p-button-warning min-w-150px mr-1"
                onClick={() => helperFunctions.onTransferEmployeeModalOpen()}
              />
              <Button
                label="Delete"
                icon="pi pi-times"
                className="p-button-danger min-w-150px mr-1"
                onClick={() => helperFunctions.onClickDelete()}
              />
            </>
          )}
          <Button
            label="Close/Cancel"
            icon="pi pi-times-circle"
            className="p-button-secondary min-w-150px mr-1"
            onClick={() => onClickClose()}
          />
        </Col>
      </Row>
      <div className="debug mt-3">
        debug Info =&gt; form Mode:
        {mode}
      </div>

      <Modal
        isOpen={formStore.isEmployeeTransferModalOpen}
        toggle={helperFunctions.onCloseTransferEmployeeModal}
        size="md"
      >
        <ModalHeader>Transfer Post for: {formStore.employee.name}</ModalHeader>
        <ModalBody>
          <Container fluid className="min-h-350px search-employee max-h-90">
            <div>
              <h5>Transfer From</h5>
              <FormGroup>
                <Row className="d-flex flex-wrap align-content-center">
                  <Col className="col-2">
                    {' '}
                    <Label className="d-flex flex-wrap align-content-center">Mission</Label>
                  </Col>
                  <Col className="d-flex">
                    <Dropdown
                      value={formStore.employee.missionId}
                      options={state.availableParams.missionsAndBureaus}
                      optionLabel="value"
                      optionValue="key"
                      disabled
                      className="w-100"
                    />
                  </Col>
                </Row>{' '}
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-2">
                    {' '}
                    <Label>Post</Label>
                  </Col>
                  <Col className="d-flex">
                    <Dropdown
                      id="oldposts"
                      value={formStore.employee.postId}
                      options={state.availableParams.posts}
                      optionLabel="value"
                      optionValue="key"
                      className="w-100"
                      showClear
                      disabled
                    />
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <div>
              <h5>Transfer To</h5>
              <FormGroup>
                <Row>
                  <Col className="col-2">
                    {' '}
                    <Label>Mission</Label>
                  </Col>
                  <Col className="d-flex">
                    <Dropdown
                      value={state.searchParams.selectedMissionId}
                      options={state.availableParams.missionsAndBureaus}
                      optionLabel="value"
                      optionValue="key"
                      filterBy="value"
                      filter
                      className="w-100"
                      onChange={(e) => {
                        setParamValue({ selectedMissionId: e.value });
                        loadPosts(e.value);
                      }}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-2">
                    {' '}
                    <Label>Post</Label>
                  </Col>
                  <Col className="d-flex">
                    <Dropdown
                      id="searchposts"
                      value={state.searchParams.selectedPostId}
                      options={state.availableParams.posts}
                      optionLabel="value"
                      optionValue="key"
                      className="w-100"
                      showClear
                      onChange={(e) => setParamValue({ selectedPostId: e.value })}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </div>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            label="Ok"
            icon="pi pi-check"
            className="p-button-primary w-100px"
            onClick={() => {
              helperFunctions.onClickModalTransferEmployeeOk({
                selectedMissionId: state.searchParams.selectedMissionId,
                selectedPostId: state.searchParams.selectedPostId,
              });
            }}
            disabled={state.searchParams.selectedMissionId === 0 || state.searchParams.selectedPostId === 0}
          />
          <Button
            label="Cancel"
            icon="pi pi-times-circle"
            className="p-button-secondary w-100px"
            onClick={() => helperFunctions.closeTransferEmployeeModal()}
          />
        </ModalFooter>
      </Modal>

      <Modal isOpen={formStore.isOktaSearchModalOpened} toggle={helperFunctions.closeOktaSearchModal} size="lg">
        <ModalHeader toggle={helperFunctions.closeOktaSearchModal}>Search Okta User</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="5">
              <FormGroup>
                <Label>Part of Name or Email</Label>
              </FormGroup>
            </Col>
            <Col md="5">
              <FormGroup>
                <Input
                  type="text"
                  defaultValue={formStore.oktaSearchTerm}
                  onChange={(e) => helperFunctions.onChangeOktaSearchTerm(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md="2">
              <Button
                label="Search"
                icon="pi pi-search"
                onClick={() => helperFunctions.onClickSearchOkta()}
                disabled={formStore.oktaSearchTerm.length < MIN_SEARCH_TERM_LENGTH}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {formStore.oktaRequest === requestStatus.REQUEST_IN_PROGRESS && (
                <SkeletonTable colsSize={3} rowsSize={3} />
              )}
              {formStore.oktaRequest !== requestStatus.REQUEST_IN_PROGRESS && (
                <DataTable
                  value={formStore.oktaResult}
                  className="p-datatable-striped all-awards-datatable-result w-100"
                  scrollable
                  scrollHeight="400px"
                  autoLayout
                  metaKeySelection={false}
                  dataKey="id"
                  emptyMessage="No records found."
                  selectionMode="single"
                  onSelectionChange={(e) => helperFunctions.onSelectOktaRecord(e.value)}
                  onRowDoubleClick={() => onClickModalOk()}
                >
                  <Column className="w-25" field="name" header="Name" body={fullNameTemplate} />
                  <Column className="w-20" field="email" header="Email" />
                  <Column className="w-25" field="title" header="title" />
                </DataTable>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            label="Ok"
            icon="pi pi-check"
            className="p-button-primary w-100px"
            onClick={() => onClickModalOk()}
            disabled={formStore.oktaSelectedRecord === null}
          />
          <Button
            label="Cancel"
            icon="pi pi-times-circle"
            className="p-button-secondary w-100px"
            onClick={() => helperFunctions.closeOktaSearchModal()}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}
EmployeeEdit.propTypes = {
  formStore: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  helperFunctions: PropTypes.object.isRequired,
};

export default EmployeeEdit;
