import React, { useState, useContext, useRef, useReducer, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { SessionContext, NotificationContext } from 'contexts';
import { useEmployeeSearchService, useFormatting, useReportSearchService } from 'hooks';
import { nominationStatus, requestStatus, approvalStatus, votingStatus } from 'constants/index';
import { Row, Col } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Grid, SkeletonTable } from 'components';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { dynamicSort } from 'utils/sorting';
import { faUser, faUserFriends } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import download from 'downloadjs';


const loadingRows = new Array(5);
const rowLoadingTemplate = () => <Skeleton />;
const bureauNominationStatuses = [
  { key: null, value: 'All' },
  nominationStatus.PENDING_BUREAU_REVIEW, 
  nominationStatus.PENDING_BUREAU_COMMITTEE_VOTE, 
  nominationStatus.PENDING_BUREAU_COMMITTEE_CHAIR,
  nominationStatus.PENDING_POST_REVIEW,
  nominationStatus.PENDING_POST_PROCESSING];

const initialState = {
    availableParamsRequest: requestStatus.REQUEST_NOT_INITIATED,
    availableParams: {
        missions: [],
        posts: [],
        nominationStatuses: [],
        votingStatuses: [],
        burauCycles: [],
    },
    searchParams: {
        selectedMissionId: 0,
        selectedPostId: 0,
        selectedNominationStatusId: 0,
        selectedVotingStatusId: 0,
        bureauCycle: null,
        textNominee: ''
    },
    selectedRecords: [],
    searchResult: [],
    searchRequestStatus: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_AVAILABLE_PARAMS':
        return {
          ...state,
          availableParams: { ...state.availableParams, ...action.data },
        };
  
      case 'START_REQUEST_SEARCH_PARAMS':
        return {
          ...state,
          availableParamsRequest: requestStatus.REQUEST_IN_PROGRESS,
        };
  
      case 'UPDATE_SEARCH_PARAMS':
        return {
          ...state,
          searchParams: { ...state.searchParams, ...action.data },
          availableParamsRequest: requestStatus.REQUEST_COMPLETED,
        };
  
      case 'UPDATE_SEARCH_RESULT':
        return {
          ...state,
          searchResult: action.data,
          searchRequestStatus: requestStatus.REQUEST_COMPLETED,
        };
  
      case 'START_SEARCH_REQUEST':
        return {
          ...state,
          searchRequestStatus: requestStatus.REQUEST_IN_PROGRESS,
        };

      case 'UPDATE_SELECTED_RECORDS':
        return { ...state, selectedRecords: action.data };
  
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

const BureauAwards = () => {
    const { sessionStore } = useContext(SessionContext);
    const { currentMissionId } = sessionStore;
    const { mode } = useParams();
    const { showSuccess } = useContext(NotificationContext);
    const reportSearchService = useReportSearchService();
    const employeeSearchService = useEmployeeSearchService();
    const { formatCycle } = useFormatting();
    const [selectedStatus, setSelectedStatus] = useState([0]);   

    const isAllAwardsMode = useCallback(() => mode.toLowerCase() === 'all', [mode]);
    const isApprovedMode = useCallback(() => mode.toLowerCase() === 'approved', [mode]);

    const [state, dispatch] = useReducer(reducer, initialState);
    const dt = useRef(null);

    const setSelectedRecords = (selectedRecords) => {
      dispatch({
        type: 'UPDATE_SELECTED_RECORDS',
        data: selectedRecords,
      });
    };

    const setParamValue = (paramValue) => {
        dispatch({
          type: 'UPDATE_SEARCH_PARAMS',
          data: { ...paramValue },
        });
      };

      function autoOpenPostsDropdown(length) {
        if (length > 1) {
          document.getElementById('searchposts').click();
        }
      }

      async function onResetNominationsSearchClick() {
        setSelectedStatus([0]);
        dispatch({ type: 'RESET_SEARCH_FORM', data: [] });
      }

      const getKeyValueList = (items) => {
        const result = items?.map((item) => {
          return { key: item.id, value: item.name };
        });
        return result.sort(dynamicSort('value'));
      };

      const addDefaultValueToList = (list) => {
        list.unshift({
          key: 0,
          value: 'All',
        });
        return list;
      };   

      async function loadPosts(selectedMissionId) {
        const response = await employeeSearchService.getAvailablePostsByMission(
          selectedMissionId
        );
        if (response && response.ok) {
          dispatch({
            type: 'UPDATE_AVAILABLE_PARAMS',
            data: {
              posts: addDefaultValueToList(response.data),
            },
          });
          dispatch({
            type: 'UPDATE_SEARCH_PARAMS',
            data: {
              selectedPostId: response.data[0].key,
            },
          });
        }
        autoOpenPostsDropdown(response.data.length);
      }

      const generateBureauReportData = (nominations) => {
        const output = nominations.flatMap((obj) =>
        obj.nominationEmployees.map((arr) => ({          
          Nomination_Key: obj.nominationKey,
          Status: obj.nominationStatusName,
          AwardType: obj.awardTypeName,
          Type: obj.isGroup ? 'Group' : 'Individual',
          EmployeeId: arr.payrollId,
          PositionTitle: arr.positionTitle,
          Nominees: arr.name,
          EmployeeSection: arr.sectionShortName,
          EmployeeMission: arr.missionName,
          EmployeePost: arr.postName,
          EmployeeType: arr.isLocalStaff ? 'LE' : 'USDH',
          NominatorName: obj.nominatorName,
          Citation: obj.citation,
          BureauVotingStatus: obj.bureauVotingStatus,
          BureauEntryDate: obj.bureauEntryDate,
          BureauExitDate: obj.bureauExitDate
        }))
      );
          return output;
      };

      const generateApprovedBureauReportData = (nominations) => {
        const output = nominations.flatMap((obj) =>
        obj.nominationEmployees.map((arr) => ({  
          EmployeeMission: arr.missionName,
          EmployeePost: arr.postName, 
          EmployeeType: arr.isLocalStaff ? 'LE' : 'USDH', 
          AwardType: obj.awardTypeName,
          Type: obj.isGroup ? 'Group' : 'Individual',
          EmployeeId: arr.payrollId,
          PositionTitle: arr.positionTitle,
          Nominees: arr.name,      
          NominatorName: obj.nominatorName,
          Citation: obj.citation,
          ApprovedDate: obj.bureauExitDate
        }))
      );
          return output;
      };

      const saveAsExcelFile = (buffer, fileName) => {
        const EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });
        const filename = `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`;
    
        download(data, filename);
      };

      const exportExcel = () => {       
        import('xlsx').then((xlsx) => {
          const worksheet = xlsx.utils.json_to_sheet(isAllAwardsMode ? generateBureauReportData(state.selectedRecords.length > 0 ? state.selectedRecords : state.searchResult) :
                            generateApprovedBureauReportData(state.selectedRecords.length > 0 ? state.selectedRecords : state.searchResult)
                          );          
          
          const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
          const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
          });
          saveAsExcelFile(excelBuffer, 'Report');
        });
      };

    const onSearchClick = async () => {
        const requestData = {
            bureauId: currentMissionId,
            missionId: state.searchParams.selectedMissionId,
            postId: state.searchParams.selectedPostId,
            nominationStatusId: state.searchParams.selectedNominationStatusId,
            votingStatusId: isApprovedMode ? votingStatus.APPROVED : state.searchParams.selectedVotingStatusId,
            cycleId: state.searchParams.selectedCycleId,
        };

        dispatch({ type: 'START_SEARCH_REQUEST' });
        const response = await reportSearchService.bureauReportSearch(requestData);
        if (response && response.ok) {
            dispatch({
                type: 'UPDATE_SEARCH_RESULT',
                data: response.data,
            });
        }
    };

    

    const getCyclesList = useCallback(
      (cycles) => {
        const result = cycles?.map((item) => {
          return { key: item.id, value: formatCycle(item) };
        });
        return result.sort(dynamicSort('value'));
      },
      [formatCycle]
    );

    useEffect(() => {
        const loadSearchParameters = async () => {
            dispatch({ type: 'START_REQUEST_SEARCH_PARAMS' });
            const response = await reportSearchService.getSearchAvailableParameters(
                currentMissionId,
                true
            );

            if (response && response.ok) {
                dispatch({
                    type: 'UPDATE_AVAILABLE_PARAMS',
                    data: {
                        missions: addDefaultValueToList(getKeyValueList(response.data.missions.filter(x => x.bureauId === currentMissionId))),
                        posts: response.data.posts,
                        bureaus: response.data.bureaus,
                        bureauCycles: addDefaultValueToList(getCyclesList(response.data.bureauCycles)),
                        votingStatuses: addDefaultValueToList(response.data.votingStatuses),
                        nominationStatuses: addDefaultValueToList(                         
                            response.data.nominationStatuses.filter(x => bureauNominationStatuses.includes(x.key))
                          )                        
                    },
                });
                dispatch({
                    type: 'UPDATE_SEARCH_PARAMS',
                    data: {
                        // selectedMissionId: ,
                        selectedPostId: response.data.posts[0]?.key,
                    },
                });
            }
        };

        if (currentMissionId && state.availableParamsRequest === requestStatus.REQUEST_NOT_INITIATED) {
            loadSearchParameters();
        }
    }, [currentMissionId, state.availableParamsRequest, reportSearchService, getCyclesList]); 

    const nomineeBodyTemplate = (rowData) => {
      const body = rowData.nominationEmployees.length
        ? rowData.nominationEmployees.map((e) => {
            return <div key={e.id}>{e.name}</div>;
          })
        : '-';
      return <div>{body}</div>;
    }; 

    const getNominationStatus = (status) => {
      switch (status) {
        case nominationStatus.PENDING_BUREAU_REVIEW:
          return 'Pending Bureau Review';
        case nominationStatus.PENDING_BUREAU_COMMITTEE_VOTE:
          return 'Pending Bureau Committee Vote';
        case nominationStatus.PENDING_BUREAU_COMMITTEE_CHAIR:
          return 'Pending Bureau Committee Chair';
        case nominationStatus.PENDING_POST_REVIEW:
          return 'Pending Post Review';
        default:
          return 'Pending Post Processing';
      }
    };

    const nominationStatusTemplate = (rowData) => {
      return (
        <div>
          <span>
            {getNominationStatus(rowData.nominationStatus)}
          </span>
        </div>
      );
    };

    const bureauVotingStatusTemplate = (rowData) => {
      return (
        <div>
          <span>
            {rowData.bureauVotingStatus}
          </span>
        </div>
      );
    };

    return (
        <div className="all-awards">
            <h4>{isApprovedMode && 'Approved' } Reports - Bureau Awards</h4>
            <Row>
            <Col sm={1}>
                Bureau
                <div>
                    {state.availableParams.bureaus?.length > 1 
                    && state.availableParams.bureaus.find(x => x.key === currentMissionId)?.value}
                </div>
            </Col>
                <Col>
                    Mission
                    <div>
                        <Dropdown
                            value={state.searchParams.selectedMissionId}
                            options={state.availableParams.missions}
                            optionLabel="value"
                            optionValue="key"
                            filterBy="value"
                            filter
                            className="w-100"
                            showClear
                            onChange={(e) => {
                               const id = e.value ?? 0;
                                setParamValue({ selectedMissionId: id });
                                if(id > 0) loadPosts(id);
                            }}
                        />
                    </div>
                </Col>
                <Col>
                    Post
                    <div>
                        <Dropdown
                        id="searchposts"
                        value={state.searchParams.selectedPostId}
                        options={state.availableParams.posts}
                        optionLabel="value"
                        optionValue="key"
                        className="w-100"
                        showClear
                        onChange={(e) => setParamValue({ selectedPostId: e.value })}
                        />
                    </div>
                </Col>
                <Col>
                  <div>
                    Cycle
                    <Dropdown
                      value={state.searchParams.selectedCycleId}
                      options={state.availableParams.bureauCycles}
                      optionLabel="value"
                      optionValue="key"
                      showClear
                      onChange={(e) => setParamValue({ selectedCycleId: e.value })}
                      placeholder="All"
                      className="w-100"
                    />
                  </div>
                </Col>
                {
                  isAllAwardsMode() && 
                  <Col>
                    Voting Status
                      <div>
                          <Dropdown
                          value={state.searchParams.selectedVotingStatusId}
                          options={state.availableParams.votingStatuses}
                          optionLabel="value"
                          optionValue="key"
                          className="w-100"
                          showClear
                          onChange={(e) => setParamValue({ selectedVotingStatusId: e.value ?? 0 })}
                          />
                      </div>
                  </Col>
                }             
                <Col>
                    Nomination Status
                    <div>
                        <Dropdown
                        value={state.searchParams.selectedNominationStatusId}
                        options={state.availableParams.nominationStatuses}
                        optionLabel="value"
                        optionValue="key"
                        className="w-100"
                        showClear
                        onChange={(e) => setParamValue({ selectedNominationStatusId: e.value  ?? 0})}
                        />
                    </div>
                </Col>
                <Col className="mt-4">
                    <div className="text-right">
                        <Button
                        label="Search"
                        icon="pi pi-search"
                        className="p-button-primary w-100 min-w-100px"
                        onClick={() => onSearchClick()}
                        />
                    </div>    
                    <div className="text-right mt-2 align-left">      
                    <Button
                      label="Export to Excel"
                      icon="pi pi-file-excel"
                      onClick={() => exportExcel()}                      
                      className="min-w-80px btn-sm ml-4"
                    />
                    </div>      
                </Col>
            </Row>
            <Row className="my-2">
                <Col>
                {state.searchRequestStatus ===
                    requestStatus.REQUEST_NOT_INITIATED && (
                    <div>Please, fill the parameters and click on Search Button</div>
                )}
                 {state.searchRequestStatus === requestStatus.REQUEST_IN_PROGRESS && (
            <SkeletonTable colsSize={4} rowsSize={3} />
          )}
                {state.searchRequestStatus === requestStatus.REQUEST_COMPLETED && (
            <Grid
             items={state.searchResult}
             className="p-datatable-striped all-awards-datatable-result"
             scrollable
             scrollHeight="400px"
             autoLayout
             metaKeySelection={false}
             dataKey="id"
             ref={dt}
             emptyMessage="No records found."
             selectedRecords={state.selectedRecords}
             onSelectionChange={(e) => setSelectedRecords(e.value)}
            >             
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: '3em' }}
                />      
                {
                  isAllAwardsMode && (
                    <>
                      <Column
                        field="nominationKey"
                        header="Key"
                        sortable
                      /> 
                      <Column
                        field="cycle"
                        header="Cycle"
                        sortable
                      />   
                    </>
                  )
                }    
              <Column
                field="mission"
                header="Mission"    
                sortable            
              />
              <Column
                field="post"
                header="Post" 
                sortable
               />    
              <Column
                field="nominationStatus"
                header="Status"
                body={nominationStatusTemplate}
                sortable
              />         
              <Column
                field="bureauVotingStatus"
                header="Voting Status"
                body={bureauVotingStatusTemplate}
                sortable
              />
              <Column
                field="nominationEmployees"
                header="Nominees"
                body={nomineeBodyTemplate}
              />
              <Column
                field="approvedAmount"
                header="Amount (USD)"
                sortable
              />
              <Column
                field="nominatorName"
                header="Nominator"
                sortable
              />
              <Column
                field="bureauEntryDate"
                header="Entry Date"
                sortable
              />
              <Column
                field="bureauExitDate"
                header="Exit Date"
                sortable
              />
            </Grid>
          )}
                </Col>
            </Row>
        </div>
    );
};

export default BureauAwards;