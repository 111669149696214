import * as yup from 'yup';

const useFiscalStripValidation = () => {
  // const forwardSlashRegex =
  const validateFiscalStripSchema = yup.object().shape({
    agency: yup
      .number()
      .nullable()
      .transform((value) => (Number.isNaN(value) || value === null || value === undefined ? 0 : value))
      .max(9999, 'Must be max of 4 characters'),
    bbfy: yup
      .number()
      .nullable()
      .transform((value) => (Number.isNaN(value) || value === null || value === undefined ? 0 : value))
      .max(9999, 'Must be max of 4 characters'),
    ebfy: yup
      .number()
      .nullable()
      .transform((value) => (Number.isNaN(value) || value === null || value === undefined ? 0 : value))
      .max(9999, 'Must be max of 4 characters'),
    appropriation: yup
      .string()
      .nullable()
      .max(14, 'Must be max of 14 characters')
      .matches(/^[^/]*$/, {
        message: 'No forward slash',
      }),
    bureau: yup
      .string()
      .nullable()
      .max(3, 'Must be max 3 characters')
      .matches(/^[^/]*$/, {
        message: 'No forward slash',
      }),
    allotment: yup
      .string()
      .nullable()
      .max(15, 'Must be max of 15 characters')
      .matches(/^[^/]*$/, {
        message: 'No forward slash',
      }),
    operatingAllowance: yup
      .string()
      .nullable()
      .max(15, 'Must be max of 15 characters')
      .matches(/^[^/]*$/, {
        message: 'No forward slash',
      }),
    obligation: yup
      .string()
      .nullable()
      .max(30, 'Must be max of 30 characters')
      .matches(/^[^/]*$/, {
        message: 'No forward slash',
      }),
    objectCode: yup
      .string()
      .nullable()
      .max(6, 'Must be max of 6 characters')
      .matches(/^[^/]*$/, {
        message: 'No forward slash',
      }),
    functionCode: yup
      .string()
      .nullable()
      .max(8, 'Must be max of 8 characters')
      .matches(/^[^/]*$/, {
        message: 'No forward slash',
      }),
    projectCode: yup
      .string()
      .nullable()
      .max(8, 'Must be max of 8 characters')
      .matches(/^[^/]*$/, {
        message: 'No forward slash',
      }),
    organizationCode: yup
      .number()
      .nullable()
      .transform((value) => (Number.isNaN(value) || value === null || value === undefined ? 0 : value))
      .max(999999, 'Must be max of 6 characters'),
  });

  const validate = async (fiscalStrip) => {
    const validationResult = {
      isValid: true,
      errors: [],
    };

    await validateFiscalStripSchema
      .validate(fiscalStrip, { abortEarly: false })
      .then((value) => {
        console.log('validated value: ', value);
      })
      .catch((err) => {
        const errorObj = {};

        const listOfErrors = err.inner.map((item) => ({
          field: item.path,
          error: item.message,
        }));

        listOfErrors.forEach((x) => {
          const keyExists = errorObj[x.field];

          if (keyExists) {
            errorObj[x.field].push(x.error);
          } else {
            const individualErrors = [];
            individualErrors.push(x.error);
            errorObj[x.field] = individualErrors;
          }
        });

        validationResult.isValid = false;
        validationResult.error = errorObj;
      });
    return validationResult;
  };

  return { validate };
};

export default useFiscalStripValidation;
