import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { requestStatus } from 'constants/index';
import './Voting.css';

function VotingNominationDetails({ nomination, helperFunctions, onClickMoreDetails }) {
  const showCitation = () => {
    return (
      !nomination.isHideCitation && (
        <div className="row m-2">
          <div className="col-12 font-weight-bold">Citation</div>
          <div className="col-12 voting-text-values min-h-50px">{nomination.citation}</div>
        </div>
      )
    );
  };

  useEffect(() => {
    if (nomination.id && nomination.nominationLoadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      helperFunctions.onClickItem(nomination.id);
    }
  }, [nomination.nominationLoadRequest, helperFunctions, nomination.id]);

  return (
    <>
      <div className="row m-2">
        <div className="col-6 font-weight-bold">Justification</div>
        <div className="col-6 pr-0 text-right">
          <Button className="p-0" color="link" size="sm" onClick={() => onClickMoreDetails(nomination)}>
            More Details
          </Button>
        </div>
        <div
          className="col-12 voting-text-values min-h-100px max-h-100px overflow-y-scroll justification"
          dangerouslySetInnerHTML={{ __html: nomination.justification }}
        />
      </div>
      {showCitation()}
    </>
  );
}
VotingNominationDetails.propTypes = {
  nomination: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object.isRequired,
  onClickMoreDetails: PropTypes.func.isRequired,
};

export default React.memo(VotingNominationDetails);
