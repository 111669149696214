import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Label } from 'reactstrap';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';

function ReturnToModal({ nominationId, isModalOpened, onClickCloseModal, returnToOptions, confirmReturnToClick }) {
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState(0);
  const NOTE_MIN_LENGTH = 5;

  return (
    <Modal centered scrollable isOpen={isModalOpened} toggle={onClickCloseModal} size="lg">
      <ModalHeader toggle={onClickCloseModal}>
        <Container>
          <Row>
            <Col>
              <Label>Return To Status:</Label>
            </Col>
            <Col>
              <Dropdown
                value={status}
                optionLabel="value"
                optionValue="key"
                placeholder="Select a Status"
                options={returnToOptions}
                onChange={(e) => {
                  setStatus(e.value);
                }}
                className="w-400px"
              />
            </Col>
          </Row>
        </Container>
      </ModalHeader>
      <ModalBody>
        <h6>
          When returning to a previous step all data supplied at that step will be removed,
          <br />
          including signatures and votes.
        </h6>
        <InputTextarea
          value={comment}
          className="form-control"
          autoResize
          rows={4}
          placeholder={`Add your comment (minimum of ${NOTE_MIN_LENGTH} characters)`}
          onChange={(evt) => setComment(evt.target.value)}
        />
        <small>* Comment is mandatory</small>
      </ModalBody>

      <ModalFooter>
        <Button
          label="Confirm Return"
          disabled={status === 0 || comment.trim().length < NOTE_MIN_LENGTH}
          onClick={() => confirmReturnToClick(nominationId, status, comment)}
        />
        <Button
          label="Cancel"
          icon="pi pi-times-circle"
          onClick={() => onClickCloseModal()}
          className="p-button-secondary w-100px"
        />
      </ModalFooter>
    </Modal>
  );
}
ReturnToModal.propTypes = {
  nominationId: PropTypes.number.isRequired,
  isModalOpened: PropTypes.bool.isRequired,
  onClickCloseModal: PropTypes.func.isRequired,
  returnToOptions: PropTypes.array.isRequired,
  confirmReturnToClick: PropTypes.func.isRequired,
};

export default ReturnToModal;
