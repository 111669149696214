import useApi from '../useApi';

export const useSignOffService = () => {
  const api = useApi();

  const getNominations = async (requestData) => api.post('/signoff/GetNominations', requestData);

  const getCycleSummary = async (requestData) => api.post('/signoff/GetCycleSummary', requestData);

  const getBureauCycleSummary = async (requestData) => api.post('/signoff/GetBureauCycleSummary', requestData);

  const getCycleCounters = async (requestData) => api.post('/signoff/GetCycleCounters', requestData);

  const getBureauCycleCounters = async (requestData) => api.post('/signoff/GetBureauCycleCounters', requestData);

  const signOffNominations = async (requestData) => api.post('/signoff/SignOffNominations', requestData);

  const loadSignOffOptions = async (id) => api.get(`/signoff/LoadSignOffOptions/${id}`);

  const overrideNomination = async (requestData) => api.post('/signoff/OverrideNomination', requestData);

  const saveFiscalStrips = async (requestData) => api.post('/signoff/SaveFiscalStrips', requestData);

  return {
    getNominations,
    getCycleSummary,
    getBureauCycleSummary,
    getCycleCounters,
    getBureauCycleCounters,
    signOffNominations,
    loadSignOffOptions,
    overrideNomination,
    saveFiscalStrips,
  };
};

export default useSignOffService;
