/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/pro-solid-svg-icons';
import { useNavigationLinks } from 'hooks';
import MenuItem from './MenuItem';
import './SideBar.css';

function SideBar({ menuItems, isVisible, onClickToggle }) {
  const { getURL } = useNavigationLinks();
  return (
    <>
      <div id="sidebar" className="sidebar-wrapper overflow-auto d-print-none">
        {menuItems.map((item) => (
          <MenuItem
            key={item.key}
            label={item.label}
            icon={item.icon}
            target={getURL(item.key)}
            subItems={item.subItems}
          />
        ))}
      </div>
      <button
        type="button"
        className="btn d-print-none sidebar-toggle-btn"
        style={{ left: isVisible ? '-50px' : '0px', bottom: '0px' }}
        onClick={onClickToggle}
      >
        <FontAwesomeIcon className="d-print-none" icon={isVisible ? faAngleDoubleLeft : faAngleDoubleRight} />
      </button>
    </>
  );
}
SideBar.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClickToggle: PropTypes.func.isRequired,
};

export default SideBar;
