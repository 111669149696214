import React from 'react';
import PropTypes from 'prop-types';
import './Loading.css';

// implemented using https://loading.io/css/

function Loading({ type, backdrop, local, active, color }) {
  function renderLoading() {
    switch (type) {
      case 'ring':
        return (
          <div className={`lds-ring ${color}`}>
            <div />
            <div />
            <div />
            <div />
          </div>
        );
      case 'roller':
        return (
          <div className={`lds-roller ${color}`}>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        );
      default:
        return <div />;
    }
  }

  if (local) {
    return (
      <div className={`loading-overlay ${active ? 'active' : ''}`}>
        <div className="text-center">{renderLoading()}</div>;
      </div>
    );
  }

  return (
    <>
      {backdrop && <div className="backdrop" />}
      <div className="loading">{renderLoading()}</div>
    </>
  );
}
Loading.propTypes = {
  type: PropTypes.oneOf(['ring', 'roller']),
  backdrop: PropTypes.bool,
  local: PropTypes.bool,
  color: PropTypes.oneOf(['', 'gray']),
  active: PropTypes.bool,
};
Loading.defaultProps = {
  type: 'ring',
  backdrop: false,
  local: false,
  color: '',
  active: false,
};

export default Loading;
