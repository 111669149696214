import React, { useContext, useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmDialog } from 'primereact/confirmdialog';
import { faPrint } from '@fortawesome/pro-regular-svg-icons';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Badge } from 'primereact/badge';
import { useNavigationLinks } from 'hooks';
import { requestStatus, nominationAction, nominationStatus, processType } from 'constants/index';
import { NominationFormContext, NominationFormProvider, NotificationContext, SessionContext } from 'contexts';
import WarningMessage from 'views/Shared/WarningMessage';
import Loading from 'views/Shared/Loading';
import MessageModal from 'components/MessageModal';
import { useNominationService } from 'hooks/nomination';
import NominationFormHeader from './NominationFormHeader';
import ReasonAndTypeModal from './ReasonAndTypeModal';
import NomineesList from './NomineesList';
import EmployeeSearchModal from './EmployeeSearchModal';
import NominationHistoryModal from './NominationHistoryModal';
import NominationApproverModal from './NominationApproverModal';
import NominationJustification from './NominationJustification';
import NominationCitation from './NominationCitation';
import FormOfRecognition from './FormOfRecognition';
import NotesSideBar from './NotesSideBar';
import PrintFormModal from './PrintFormModal';
import SendToOPSModal from '../NominationsList/SendToOPSModal';
import ConfirmationWithComment from './ConfirmationWithComment';
import ReturnToModal from './ReturnToModal';
import CertificatePrintModal from '../Reports/CertificatePrintModal';

import './NominationForm.css';

let isExcludeFromOPSClicked;

function NominationFormComponent() {
  const { goTo, goBack } = useNavigationLinks();
  const { showSuccess, showError, showAlertMessage } = useContext(NotificationContext);
  const service = useNominationService();
  const { formStore, helperFunctions } = useContext(NominationFormContext);
  const { isRightSideBarVisible } = formStore;
  const [isWarningMessageVisible, toogleWarningMessage] = useState(false);
  const [nominationId, setNominationId] = useState(helperFunctions.getNominationId());
  const { sessionStore } = useContext(SessionContext);
  const mode = helperFunctions.getMode();
  const isOngoingNomination = useCallback(() => mode === 'ongoing', [mode]);
  const isBureauOngoingNomination = useCallback(() => mode === 'bureauongoing', [mode]);
  const isDisapprovedNomination = useCallback(() => mode === 'disapproved', [mode]);
  const isFinancialMode = useCallback(() => mode === 'financialinfo', [mode]);
  const isApproveMode = useCallback(() => mode === 'approve', [mode]);
  const isReviewMode = useCallback(() => mode === 'review', [mode]);
  const isSavingInProgress = formStore.nominationSaveRequest === requestStatus.REQUEST_IN_PROGRESS;
  const isLoadingInProgress = formStore.nominationLoadRequest === requestStatus.REQUEST_IN_PROGRESS;
  const activeIndex = isFinancialMode() ? null : 0;
  const { employeeId } = sessionStore.employeeUser;
  const [isBeingAutoSaved, setIsBeingAutoSaved] = useState(false);
  const [isLastNomination, setIsLastNomination] = useState(false);
  const [isFirstNomination, setIsFirstNomination] = useState(false);

  const doesCurrentSupervisorHaveMultipleNominees =
    formStore.nomination?.nominees.map((item) => item.usdhSupervisorId === employeeId).length > 1;

  const getNavigatonTarget = useCallback((action) => {
    switch (action.clientId) {
      case 'save':
      case 'saveAndSubmit':
        return 'my_nominations_list';

      case 'approve_nominee':
      case 'request_rewrite':
        return 'pending_approval_list';

      case 'request_hr_rewrite':
      case 'reject_archive':
      case 'send_for_approval':
      case 'ready_for_jcac':
      case 'save_in_review':
        return 'pending_review_list';

      case 'pending_usdh_list':
        return 'pending_usdh_list';

      case 'financial_info':
        return 'finance_analyst_list';

      case 'send_to_ops':
      case 'exclude_from_send_to_ops':
        return 'send_to_ops_list';

      case 'externalApprovalSaveBtn':
      case 'externalRejectSaveBtn':
        return 'external_approval_list';
      case 'delete_in_review':
        return 'my_nominations_list';
      default:
        return '';
    }
  }, []);

  const navToListAfterSendForApproval = () =>
    goTo(`${mode === 'ongoing' ? 'ongoing_nominations_list' : 'pending_review_list'}`);

  const processSaveAction = useCallback(
    async (actionButton) => {
      const justificationDirty = formStore.nomination.isJustificationDirty;
      const citationDirty = formStore.nomination.isCitationDirty;
      const ok = await helperFunctions.saveNomination(actionButton.action);
      if (ok) {
        helperFunctions.setAutoSave(false);
        setIsBeingAutoSaved(false);
        if (justificationDirty) {
          helperFunctions.updateJustificationSaved(true);
        }
        if (citationDirty) {
          helperFunctions.updateCitationSaved(true);
        }

        if (actionButton.action !== nominationAction.OTHER) {
          showSuccess(actionButton.successMessage);
        }

        if (
          ['save_in_review'].includes(actionButton.clientId) ||
          actionButton.isAutoSave ||
          (mode !== 'new' && doesCurrentSupervisorHaveMultipleNominees)
        ) {
          helperFunctions.forceReload();
        } else if (isApproveMode()) {
          goBack();
        } else {
          const targetNav = getNavigatonTarget(actionButton);
          if (targetNav !== '') {
            goTo(targetNav);
          } else {
            goBack();
          }
        }
        return;
      }
      toogleWarningMessage(true);
    },
    [
      getNavigatonTarget,
      goTo,
      goBack,
      isApproveMode,
      helperFunctions,
      showSuccess,
      formStore.nomination,
      doesCurrentSupervisorHaveMultipleNominees,
      mode,
    ]
  );

  const openAnytimeApproversModal = async () => {
    const ok = await helperFunctions.onClickOpenAnytimeApproversModal();
    if (ok.length) {
      showError(ok.join('\n'));
    }
  };

  const clickAction = async (actionButton) => {
    if (actionButton.action === nominationAction.OTHER) {
      if (actionButton.clientId === 'send_for_approval') {
        if (formStore.nomination.formOfRecognition == null) {
          helperFunctions.onOpenMessageModalClick();
          return;
        }
        if (formStore.nomination.recommendedAmount === 0) {
          confirmDialog({
            message: 'Are you sure you want to proceed with ZERO recommended amount?',
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => openAnytimeApproversModal(),
          });
          return;
        }
        openAnytimeApproversModal();
        return;
      }
      return;
    }

    if (actionButton.action === nominationAction.REJECT) {
      helperFunctions.onClickOpenModalForReject(actionButton);
      return;
    }

    if (actionButton.action === nominationAction.DELETE) {
      confirmDialog({
        message: 'Are you sure you want to delete this nomination?',
        header: 'Delete Nomination',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: async () => await processSaveAction(actionButton),
      });
      return;
    }

    if (
      actionButton.action === nominationAction.FORWARD &&
      helperFunctions.isReviewMode() &&
      formStore.nomination.recommendedAmount === 0
    ) {
      confirmDialog({
        message: 'Are you sure you want to proceed with ZERO recommended amount?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: async () => await processSaveAction(actionButton),
      });
      return;
    }

    if (['pending_effective_date'].includes(actionButton.clientId)) {
      helperFunctions.onClickOpenSendToOpsModal();
      return;
    }

    await processSaveAction(actionButton);
  };

  const onClickApproveNominee = async (nomineeItemId, approveFlag, skipCommentCheck) => {
    if (!approveFlag && !skipCommentCheck) {
      helperFunctions.onClickOpenModalForDisapproveNominee({
        clientId: 'confirm_reject_nominee',
        label: 'Confirm Disapproval',
        icon: 'pi pi-thumbs-down',
        cssClass: 'p-button-danger',
        nomineeItemId,
      });
      return;
    }

    const response = await helperFunctions.updateNomineeStatus(nomineeItemId, approveFlag);
    if (response.ok) {
      helperFunctions.updateNominee(response.data.nominationEmployee);
      await processSaveAction({ action: nominationAction.OTHER });
      showSuccess(`Nominee was ${approveFlag ? 'approved' : 'rejected'} successfully!`);
      if (response.data.hasNominationStatusChanged) {
        const message = response.data.hasNominationBeenApproved
          ? 'The nomination was approved and will now continue to the HR for review'
          : 'The nomination was rejected and will be archived';
        showAlertMessage(message, 'Nomination Update');

        const targetNav = getNavigatonTarget({ clientId: 'approve_nominee' });
        if (targetNav) goTo(targetNav);
      }
    } else {
      showError('There was an error on saving the action.');
    }
  };

  const onClickRemoveNotEligibleNominee = async (nomineeItemId, approveFlag) => {
    const response = await helperFunctions.updateNomineeStatus(nomineeItemId, approveFlag);
    const requestData = {
      employeeId: response.data.nominationEmployee.employeeId,
      nominationId: response.data.nominationEmployee.nominationId,
    };
    if (response.ok) {
      const responseEmail = await service.sendEmailOfNotEligibleNomineeToNominator(requestData);
      helperFunctions.updateNotEligibleNominee(response.data.nominationEmployee);
      showSuccess(`Nominee was ${approveFlag ? 'approved' : 'rejected'} successfully!`);
      if (response.data.hasNominationStatusChanged) {
        const message = response.data.hasNominationBeenApproved
          ? 'The nomination status was changed to approved by HR and nominee is being removed'
          : 'The nomination was rejected and will be archived';
        showAlertMessage(message, 'Nomination Update');
      }
    } else {
      showError('There was an error on saving the action.');
    }
  };

  const closeNomination = () => {
    if (isReviewMode()) {
      goTo('pending_review_list');
    } else {
      goBack();
    }
  };

  const confirmReturnToClick = async (nomId, status, comment) => {
    const response = await service.returnNominationTo(sessionStore.employeeUser.employeeId, nomId, status, comment);

    if (response.ok) {
      helperFunctions.onClickCloseReturnToModal();
      closeNomination();
    }
  };

  const processConfirmationAction = async (actionButton) => {
    const noteToAdd = helperFunctions.getNewNote(formStore.confirmationComment);
    await helperFunctions.onClickAddNote(noteToAdd);

    // form save actions
    if (
      ['request_rewrite', 'request_hr_rewrite', 'reject_archive', 'externalRejectSaveBtn', 'return_to_post'].includes(
        actionButton.clientId
      )
    ) {
      await processSaveAction(actionButton);
    }
    // nominee reject action
    if (actionButton.clientId === 'confirm_reject_nominee') {
      await onClickApproveNominee(actionButton.nomineeItemId, false, true);
    }

    helperFunctions.onClickCloseNominationRejectModal();
  };

  useEffect(() => {
    if (!nominationId) {
      setNominationId(helperFunctions.getNominationId());
    }

    if (
      nominationId &&
      !helperFunctions.isNewMode() &&
      formStore.nominationLoadRequest === requestStatus.REQUEST_NOT_INITIATED
    ) {
      helperFunctions.loadNomination(nominationId);
    }
  }, [helperFunctions, formStore.nominationLoadRequest, nominationId]);

  useEffect(() => {
    if (
      formStore.nominationLoadRequest === requestStatus.REQUEST_COMPLETED &&
      formStore.historyLoadRequest === requestStatus.REQUEST_NOT_INITIATED
    ) {
      helperFunctions.loadHistoryAndComments();
    }
  }, [helperFunctions, formStore.historyLoadRequest, formStore.nominationLoadRequest]);

  const autoSaveInterval = 60000;

  useEffect(() => {
    const saveData = async () => {
      await processSaveAction({
        action: nominationAction.NONE,
        isAutoSave: true,
        successMessage: 'Your changes are successfully saved.',
      });
      helperFunctions.resetJustificationFlag();
      helperFunctions.resetCitationFlag();
    };
    if (formStore.nomination.isAutoSave && !isBeingAutoSaved) {
      saveData();
    }
  }, [formStore.nomination.isAutoSave, processSaveAction, helperFunctions, isBeingAutoSaved]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (!isBeingAutoSaved) {
        if (formStore.nomination.isJustificationDirty && formStore.nomination.isCitationDirty) {
          helperFunctions.setAutoSave(true);
          setIsBeingAutoSaved(true);
        }

        if (formStore.nomination.isJustificationDirty && !formStore.nomination.isCitationDirty) {
          helperFunctions.setAutoSave(true);
          setIsBeingAutoSaved(true);
        }

        if (formStore.nomination.isCitationDirty && !formStore.nomination.isJustificationDirty) {
          helperFunctions.setAutoSave(true);
          setIsBeingAutoSaved(true);
        }
      }
    }, autoSaveInterval);
    return () => {
      clearTimeout(timer);
    };
  }, [
    helperFunctions,
    processSaveAction,
    formStore.nomination.isJustificationDirty,
    formStore.nomination.isCitationDirty,
    isBeingAutoSaved,
    formStore.nomination.isAutoSave,
  ]);

  const shouldDisableButton = (action) => {
    switch (helperFunctions.getMode()) {
      case 'review':
        return (
          (action.clientId !== 'save_in_review' &&
            formStore.nomination.awardProcessType !== formStore.nomination.loadedAwardProcessType) ||
          (action.clientId === 'save_in_review' && formStore.nomination.recommendedAmount === null)
        );

      case 'new':
      case 'edit':
        return (
          (action.clientId === 'saveAndSubmit' || action.clientId === 'save_in_draft') &&
          formStore.nomination.proposedAmount === null
        );

      default:
        return false;
    }
  };

  const generateCertificate = async () => {
    helperFunctions.onClickOpenCertificatePrintModal();
  };

  useEffect(() => {
    if (isReviewMode()) {
      const items = JSON.parse(localStorage.getItem('loadedNominations'));
      const lastItem = items && items[items.length - 1];
      const firstItem = items[0];

      if (firstItem === nominationId) {
        setIsFirstNomination(true);
      } else {
        setIsFirstNomination(false);
      }

      if (lastItem === nominationId) {
        setIsLastNomination(true);
      } else {
        setIsLastNomination(false);
      }
    }
  }, [nominationId, formStore.nominationLoadRequest, isReviewMode]);

  const onClickNexOrPrevioustNomination = (type) => {
    const items = JSON.parse(localStorage.getItem('loadedNominations'));
    const index = items.indexOf(nominationId);
    const nextNominationId = type === 'next' ? items[index + 1] : items[index - 1];

    goTo('pending_review_form', { id: nextNominationId });
    helperFunctions.forceReload();
    setNominationId(nextNominationId);
  };

  const showReturnToSection = () => {
    return (
      ((isOngoingNomination() || isDisapprovedNomination()) &&
        formStore.nomination.nominationStatus !== nominationStatus.DISAPPROVED_BY_SUPERVISOR &&
        formStore.nomination.nominationStatus !== nominationStatus.PENDING_REWRITE &&
        formStore.nomination.nominationStatus !== nominationStatus.PENDING_POST_REVIEW &&
        formStore.nomination.nominationStatus !== nominationStatus.PENDING_BUREAU_REVIEW &&
        formStore.nomination.nominationStatus !== nominationStatus.PENDING_BUREAU_COMMITTEE_VOTE &&
        formStore.nomination.nominationStatus !== nominationStatus.PENDING_BUREAU_COMMITTEE_CHAIR) ||
      (isBureauOngoingNomination() &&
        (formStore.nomination.nominationStatus === nominationStatus.PENDING_BUREAU_COMMITTEE_VOTE ||
          formStore.nomination.nominationStatus === nominationStatus.PENDING_BUREAU_COMMITTEE_CHAIR))
    );
  };

  function renderActionButtons() {
    return (
      <>
        {!isOngoingNomination() &&
          formStore.availableActions
            .sort((a, b) => a.order - b.order)
            .map((action) => (
              <Button
                id={action.clientId}
                label={action.label}
                icon={action.icon}
                key={action.clientId}
                type="submit"
                className={`min-w-150px mr-2 ${action.cssClass || 'p-button-primary'}`}
                onClick={() => clickAction(action)}
                disabled={shouldDisableButton(action)}
              />
            ))}
        {showReturnToSection() && (
          <>
            <Button
              label="Return to a Previous Step"
              key="return_to_prev_step"
              icon="pi pi-directions-alt"
              type="submit"
              className="p-button-primary mr-2"
              onClick={() => helperFunctions.onClickOpenReturnToModal()}
            />
            {formStore.nomination.awardProcessType === processType.ANYTIME && (
              <Button
                label="Update Anytime Approvers"
                key="update_anytime_approver"
                icon="pi pi-pencil"
                type="submit"
                className="p-button-success mr-2"
                onClick={() =>
                  clickAction({
                    clientId: 'send_for_approval',
                    label: 'Update Anytime Approvers',
                    action: nominationAction.OTHER,
                    successMessage: 'Nomination was returned to Review',
                  })
                }
              />
            )}
          </>
        )}
        {mode === 'completed' && (
          <Button
            label="Generate Certificate"
            icon="pi pi-star"
            className="p-button-secondary mr-2"
            onClick={() => generateCertificate()}
          />
        )}
        <Button
          label="Close/Cancel"
          icon="pi pi-times-circle"
          className="p-button-secondary mr-2"
          onClick={() => {
            closeNomination();
          }}
        />
      </>
    );
  }

  const showWarning =
    isWarningMessageVisible && !formStore.nomination.isValid && formStore.validationResultErrors.length > 0;

  const divColWidth = isRightSideBarVisible ? 'col-8' : 'col-12';

  const renderTitle = () => {
    const appendKey = formStore.nomination.nominationKey ? ` - ${formStore.nomination.nominationKey}` : '';
    const appendPrintIcon =
      helperFunctions.isPrintIconVisible() &&
      formStore.nomination.nominationStatus !== nominationStatus.DISAPPROVED_BY_SUPERVISOR ? (
        <Button className="px-2 pb-2 p-button-link" onClick={() => helperFunctions.onClickOpenPrintModal()}>
          <FontAwesomeIcon icon={faPrint} />
        </Button>
      ) : null;

    let badgeSeverity = 'info';
    if (formStore.nomination.nominationStatus === nominationStatus.COMPLETED) badgeSeverity = 'success';
    if (
      formStore.nomination.nominationStatus === nominationStatus.DISAPPROVED ||
      formStore.nomination.nominationStatus === nominationStatus.DISAPPROVED_BY_SUPERVISOR
    )
      badgeSeverity = 'danger';

    return (
      <h4>
        {`${helperFunctions.getTitle()}${appendKey}`}
        <Badge
          className="mx-2"
          value={formStore.nomination.nominationStatusName}
          severity={badgeSeverity}
          size="large"
        />
        {appendPrintIcon}
      </h4>
    );
  };

  return (
    <>
      {isSavingInProgress && <Loading backdrop />}
      {renderTitle(formStore.nomination.nominationKey)}
      <fieldset>
        <NominationFormHeader
          formStore={formStore}
          helperFunctions={helperFunctions}
          readOnly={!formStore.nomination.isEditable || helperFunctions.isViewMode()}
        />
        <div className="row mt-2">
          <div className={`anim-transition ${divColWidth}`}>
            <NomineesList
              formStore={formStore}
              selectedNomination={null}
              helperFunctions={helperFunctions}
              rightBarPlaceholder={isRightSideBarVisible}
              onClickApproveNominee={onClickApproveNominee}
              onClickRemoveNotEligibleNominee={onClickRemoveNotEligibleNominee}
            />
            <Accordion activeIndex={activeIndex ?? null} className="nominees-accordion">
              <AccordionTab className="mb-2" header="Justification">
                <NominationJustification
                  value={formStore.nomination.justification}
                  onChange={helperFunctions.onChangeJustification}
                  rightBarPlaceholder={isRightSideBarVisible}
                  readOnly={
                    (!formStore.nomination.isEditable ||
                      helperFunctions.isViewMode() ||
                      formStore.nomination.awardTypeId === 0) &&
                    !(isApproveMode() || isReviewMode())
                  }
                  isLoadingInProgress={isLoadingInProgress}
                  isJustificationDirty={formStore.nomination.isJustificationDirty}
                  isJustificationSaved={formStore.nomination.isJustificationSaved}
                  isBeingAutoSaved={formStore.nomination.isAutoSave}
                />
              </AccordionTab>
            </Accordion>
            <Accordion activeIndex={activeIndex} className="nominees-accordion">
              <AccordionTab className="mb-2" header="Citation (space limited to 500 characters, including spaces)">
                <NominationCitation
                  value={formStore.nomination.citation}
                  onChange={helperFunctions.onChangeCitation}
                  rightBarPlaceholder={isRightSideBarVisible}
                  readOnly={
                    (!formStore.nomination.isEditable ||
                      helperFunctions.isViewMode() ||
                      formStore.nomination.awardTypeId === 0) &&
                    !(isApproveMode() || isReviewMode())
                  }
                  isLoadingInProgress={isLoadingInProgress}
                  isCitationDirty={formStore.nomination.isCitationDirty}
                  isCitationSaved={formStore.nomination.isCitationSaved}
                  isBeingAutoSaved={formStore.nomination.isAutoSave}
                />
              </AccordionTab>
            </Accordion>
            <FormOfRecognition
              formStore={formStore}
              helperFunctions={helperFunctions}
              readOnly={!formStore.nomination.isEditable || helperFunctions.isViewMode()}
            />
            {/* <FinancialInfo
              formStore={formStore}
              helperFunctions={helperFunctions}
              readOnly={!formStore.nomination.isFiscalDataEditable}
            /> */}
          </div>
          <div className="col anim-transition box-outer pl-0">
            <NotesSideBar
              formStore={formStore}
              onClickAddNote={helperFunctions.onClickAddNote}
              onClickRemoveNote={helperFunctions.onClickRemoveNote}
            />
          </div>
        </div>
      </fieldset>

      {showWarning && (
        <WarningMessage
          message="Form validation failed!"
          details={formStore.validationResultErrors}
          onClickClose={toogleWarningMessage}
        />
      )}
      <div className="row">
        <div className="col-8 action-buttons">{renderActionButtons()}</div>
      </div>
      {isReviewMode() && (
        <div className="d-flex justify-content-center">
          <Button
            className="p-button-link"
            icon="pi pi-chevron-left"
            onClick={() => onClickNexOrPrevioustNomination('previous')}
            disabled={isFirstNomination}
          />
          <Button
            className="p-button-link"
            icon="pi pi-chevron-right"
            onClick={() => onClickNexOrPrevioustNomination('next')}
            disabled={isLastNomination}
          />
        </div>
      )}

      <ReasonAndTypeModal />
      <EmployeeSearchModal
        isModalOpened={formStore.isEmployeeSearchModalOpened}
        onClickCloseModal={helperFunctions.onClickCloseEmployeeSearchModal}
        addSelected={helperFunctions.addSelectedNominees}
      />
      <NominationHistoryModal
        isModalOpened={formStore.isNominationHistoryModalOpened}
        onClickCloseModal={helperFunctions.onClickCloseHistoryModal}
        employeeHistory={formStore.employeeHistory}
        employeeHistoryLoadRequest={formStore.employeeHistoryLoadRequest}
        hideNominessGender={false}
        nominationId={null}
      />
      {formStore.nomination.awardProcessType === processType.ANYTIME && (
        <NominationApproverModal
          formStore={formStore}
          helperFunctions={helperFunctions}
          isModalOpened={formStore.isNominationApproverModalOpened}
          navigateToList={navToListAfterSendForApproval}
        />
      )}
      <ConfirmationWithComment
        isModalOpened={formStore.isConfirmationModalOpened}
        onClickCloseModal={helperFunctions.onClickCloseNominationRejectModal}
        onClickActionButton={processConfirmationAction}
        title={formStore.confirmationModalTitle}
        isCommentRequired={formStore.confirmationCommentRequired}
        comment={formStore.confirmationComment}
        setComment={helperFunctions.onChangeConfirmationComment}
        actionButton={formStore.confirmationActionButton}
      />
      <ReturnToModal
        nominationId={nominationId}
        isModalOpened={formStore.isReturnToModalOpened}
        onClickCloseModal={helperFunctions.onClickCloseReturnToModal}
        returnToOptions={formStore.nomination.returnToStatuses}
        confirmReturnToClick={confirmReturnToClick}
      />
      <PrintFormModal
        isModalOpened={formStore.isPrintFormModalOpened}
        onClickCloseModal={helperFunctions.onClickClosePrintModal}
        nominationId={nominationId}
        awardProcessType={formStore.awardProcessTypeForPrintModal ?? formStore.nomination.awardProcessType}
      />
      {[nominationStatus.PENDING_SEND_TO_OPS, nominationStatus.PENDING_EFFECTIVE_DATE].includes(
        formStore.nomination.nominationStatus
      ) && (
        <SendToOPSModal
          isSendToOPSModalOpened={formStore.isSendToOPSModalOpened}
          onClickToggleModal={helperFunctions.onClickCloseSendToOpsModal}
          modalMode={isExcludeFromOPSClicked ? nominationStatus.COMPLETED : formStore.nomination.nominationStatus}
          listToProcess={[{ id: formStore.nomination.nominationId }]}
          navigationFnAfterProcessed={() => goTo('send_to_ops_list')}
          isExcludeFromOPS={formStore.isExcludeFromOPS}
          nominationAction={formStore.nominationAction}
        />
      )}
      {formStore.nomination.missionId && (
        <CertificatePrintModal
          isModalOpened={formStore.isCertificatePrintModalOpened}
          onClickCloseModal={helperFunctions.onClickCloseCertificatePrintModal}
          nominationList={[nominationId]}
          generateCertificates={helperFunctions.generateCertificate}
          generateCertificatesRequest={formStore.generateCertificatesRequest}
          missionId={formStore.nomination.missionId}
        />
      )}

      {formStore.isMessageModalOpen && (
        <MessageModal
          isModalOpened={formStore.isMessageModalOpen}
          onClickCloseModal={helperFunctions.onCloseMessageModalClick}
          header="Warning!"
          message="Please select Form of Recognition before selecting Approver(s)."
        />
      )}
    </>
  );
}

function NominationForm() {
  return (
    <NominationFormProvider>
      <NominationFormComponent />
    </NominationFormProvider>
  );
}

export default NominationForm;
