import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import flatMap from 'lodash/flatMap';
import uniqBy from 'lodash/uniqBy';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { Dropdown as PrimeDropDown } from 'primereact/dropdown';
import { SessionContext } from 'contexts';
import { requestStatus } from 'constants/index';
import { useFormatting } from 'hooks';

function SignOffHeader({ formStore, helperFunctions }) {
  const { formatCycle } = useFormatting();
  const { sessionStore } = useContext(SessionContext);

  function renderLoadingBlock() {
    return (
      <div className="col-3">
        <div className="my-3">
          <Skeleton width="80%" />
        </div>
        <div className="my-3">
          <Skeleton width="80%" />
        </div>
        <div className="my-3">
          <Skeleton width="80%" />
        </div>
      </div>
    );
  }

  function renderCycleInfo() {
    let cyclesOptions = [{ value: 0, label: 'Not Available' }];
    if (!helperFunctions.isManagementMode()) {
      cyclesOptions =
        formStore.cyclesList && formStore.cyclesList.length
          ? formStore.cyclesList.map((item) => ({
              value: item.id,
              label: item.number ? formatCycle(item) : item.name,
            }))
          : [];
    }

    if (helperFunctions.isManagementMode()) return null;

    return (
      <div className="col-3">
        <h6 className="mt-0">
          Cycle:&nbsp;
          <PrimeDropDown
            value={formStore.currentCycleId}
            options={cyclesOptions}
            onChange={(e) => helperFunctions.setCurrentCycle(e.value)}
            className="ml-2"
            disabled={cyclesOptions.length === 1}
          />
        </h6>
      </div>
    );
  }

  function renderPosts() {
    const postList = Object.values(sessionStore.missionPosts).map((item) => ({
      value: item.id,
      label: item.name,
    }));

    postList.unshift({ value: 0, label: 'All' });

    return (
      <div className="col-3">
        <h6 className="mt-0">
          Post:&nbsp;
          <PrimeDropDown
            value={formStore.currentPostId}
            options={postList}
            onChange={(e) => helperFunctions.setCurrentPostId(e.value)}
            className="ml-2 w-25"
            disabled={postList.length === 1}
          />
        </h6>
      </div>
    );
  }            

  const getTotalNominations = () => (formStore.filteredNominations ? formStore.filteredNominations.length : 0);

  const getTotalNominees = () =>
    formStore.filteredNominations ? uniqBy(flatMap(formStore.filteredNominations, 'nominees'), 'key').length : 0;

  return (
    <div className="row">
      {formStore.loadRequest === requestStatus.REQUEST_IN_PROGRESS && renderLoadingBlock()}
      {formStore.loadRequest !== requestStatus.REQUEST_IN_PROGRESS && (
        <>
          {renderCycleInfo()}
          {renderPosts()}
          <div className="col-3">
            <div>
              Total Nominations:
              <span className="ml-2 font-weight-bold">{getTotalNominations()}</span>
            </div>
            <div>
              Total Nominees:
              <span className="ml-2 font-weight-bold">{getTotalNominees()}</span>
            </div>
          </div>
        </>
      )}
      {formStore.loadRequest === requestStatus.REQUEST_IN_PROGRESS && renderLoadingBlock()}
      <div className="col-3">
        <span className="p-input-icon-left w-100">
          <i className="pi pi-search" />
          <InputText
            type="Search"
            placeholder="Global Filter"
            onChange={(e) => helperFunctions.setFilterTerm(e.target.value)}
            value={formStore.term}
            className="w-100"
          />
        </span>
      </div>
    </div>
  );
}

SignOffHeader.propTypes = {
  formStore: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object.isRequired,
};

export default SignOffHeader;
