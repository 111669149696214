import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'primereact/button';
import { useNominationService } from 'hooks/nomination';

function SupervisorEmailModal({ isModalOpened, onClickCloseModal, nominationId, supervisorId, supervisorName }) {
  const service = useNominationService();

  const sendEmail = async () => {
    console.log('SupervisorEmailModal supervisorId:', supervisorId);
    const requestData = { employeeId: supervisorId, nominationId };
    const response = await service.sendEmailToSupervisor(requestData);
    if (response) {
      onClickCloseModal();
    }
  };

  return (
    <Modal centered scrollable className="mh-100" isOpen={isModalOpened} toggle={onClickCloseModal} size="md">
      <ModalHeader toggle={onClickCloseModal}>Email Supervisor</ModalHeader>
      <ModalBody>Send email to USDH Supervisor {supervisorName}</ModalBody>

      <ModalFooter>
        <Button label="Ok" icon="pi pi-check-circle" onClick={sendEmail} className="p-button-primary w-100px" />

        <Button
          label="Cancel"
          icon="pi pi-cross-circle"
          onClick={() => onClickCloseModal()}
          className="p-button-secondary w-100px"
        />
      </ModalFooter>
    </Modal>
  );
}

SupervisorEmailModal.propTypes = {
  isModalOpened: PropTypes.bool,
  onClickCloseModal: PropTypes.func.isRequired,
  nominationId: PropTypes.number.isRequired,
  supervisorId: PropTypes.number.isRequired,
  supervisorName: PropTypes.string,
};

SupervisorEmailModal.defaultProps = {
  isModalOpened: null,
  supervisorName: null,
};

export default SupervisorEmailModal;
