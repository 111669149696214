import { useReducer, useContext, useCallback } from 'react';
import { useParams } from 'react-router';
import differenceBy from 'lodash/differenceBy';
import { useAuthorization } from 'hooks';
import { SessionContext } from 'contexts';
import {
  requestStatus,
  nominationStatus,
  nominationListMode,
  nominationAction,
  nominationFormMode,
  approvalStatus,
} from 'constants/index';
import { useSignOffService } from 'hooks/signoff';
import { useNominationService } from 'hooks/nomination';

const initialState = {
  currentMode: null,
  loadListRequest: requestStatus.REQUEST_NOT_INITIATED,
  signOffRequest: requestStatus.REQUEST_NOT_INITIATED,
  loadedNominations: [],
  filteredNominations: [],
  cyclesList: [],
  currentCycleId: 0,
  currentPostId: 0,
  detailedNomination: {},
  detailModalOpened: false,
  execSignOffModalOpened: false,
  accordionActiveIndexes: [],
  filterType: 0,
  filterTerm: '',
  nomination: null,
  currentNominationId: 0,
  fiscalData: null,
};

const filterNominationsByTerm = (nominations, filterTerm) => {
  if (!filterTerm) return nominations;

  const term = filterTerm.toLowerCase();
  return nominations.filter((item) => {
    let found = false;
    if (item.nominees && item.nominees.join('; ').toLowerCase().includes(term)) {
      found = true;
    }
    if (!found && item.nominationKey) {
      found = item.nominationKey.toLowerCase().includes(term);
    }
    return found || item.awardTypeName.toLowerCase().includes(term);
  });
};

const filterNominationsByType = (nominations, typeFilter) => {
  if (typeFilter === 0) {
    return nominations;
  }
  return nominations.filter((item) => item.awardTypeId === typeFilter);
};

const filterNominations = (nominations, filterTerm, filterType) => {
  return filterNominationsByType(filterNominationsByTerm(nominations, filterTerm), filterType);
};

const reducer = (state, action) => {
  let idx = -1;
  let idx2 = -1;
  switch (action.type) {
    case 'START_LOAD_REQUEST':
      return { ...state, loadListRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'SET_CURRENT_MODE':
      return {
        ...state,
        currentMode: action.data.toLowerCase(),
        loadListRequest: requestStatus.REQUEST_NOT_INITIATED,
        loadedNominations: [],
        filteredNominations: [],
        accordionActiveIndexes: [],
        currentCycleId: 0,
        isCycleDefined: false,
      };

    case 'SET_LOADED_NOMINATIONS':
      return {
        ...state,
        loadListRequest: requestStatus.REQUEST_COMPLETED,
        loadedNominations: action.data.map((item) => {
          item.nominationLoadRequest = requestStatus.REQUEST_NOT_INITIATED;
          return item;
        }),
        filteredNominations: action.data.map((item) => {
          item.nominationLoadRequest = requestStatus.REQUEST_NOT_INITIATED;
          return item;
        }),
        accordionActiveIndexes: [],
      };

    case 'REMOVE_NOMINATION':
      idx = state.loadedNominations.findIndex((item) => item.id === action.data);
      idx2 = state.accordionActiveIndexes.indexOf(
        state.filteredNominations.findIndex((item) => item.id === action.data)
      );
      return {
        ...state,
        loadedNominations: [...state.loadedNominations.slice(0, idx), ...state.loadedNominations.slice(idx + 1)],
        accordionActiveIndexes: [
          ...state.accordionActiveIndexes.slice(0, idx2),
          ...state.accordionActiveIndexes.slice(idx2 + 1),
        ],
      };

    case 'UPDATE_CYCLES_LIST':
      return { ...state, cyclesList: action.data };

    case 'SET_CURRENT_CYCLE':
      return {
        ...state,
        currentCycleId: action.data,
        loadListRequest: requestStatus.REQUEST_NOT_INITIATED,
        loadedNominations: [],
        filteredNominations: [],
      };

    case 'SET_CURRENT_POST_ID':
      return {
        ...state,
        currentPostId: action.data,
        loadListRequest: requestStatus.REQUEST_NOT_INITIATED,
        loadedNominations: [],
        filteredNominations: [],
      };

    case 'SET_NOMINATION_DETAILS':
      idx = state.loadedNominations.findIndex((item) => item.id === action.data.id);
      return {
        ...state,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),
          {
            ...state.loadedNominations[idx],
            detailsLoaded: true,
            showCommentBox: false,
            comment: '',
            justification: action.data.justification,
            citation: action.data.citation,
            missionName: action.data.missionName,
            sectionName: action.data.sectionName,
            agencyName: action.data.agencyName,
            awardTypeName: action.data.awardTypeName,
            awardReasons: action.data.awardReasons,
            formOfRecognition: action.data.formOfRecognition,
            formOfRecognitionDescription: action.data.formOfRecognitionDescription,
            fiscalStrip: action.data.fiscalStrip,
            proposedAmount: action.data.proposedAmount,
            recommendedAmount: action.data.recommendedAmount,
            approvedAmount: action.data.approvedAmount,
          },
          ...state.loadedNominations.slice(idx + 1),
        ],
      };

    case 'SET_NOMINATION_HISTORY_AND_COMMENTS':
      idx = state.loadedNominations.findIndex((item) => item.id === action.data.nominationId);
      return {
        ...state,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),
          { ...state.loadedNominations[idx], history: action.data.history, historyLoaded: true },
          ...state.loadedNominations.slice(idx + 1),
        ],
        detailedNomination: { ...state.detailedNomination, history: action.data.history, historyLoaded: true },
        loadCommentsRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'OPEN_NOMINATION_DETAILS_MODAL':
      return { ...state, detailModalOpened: true, detailedNomination: action.data };

    case 'CLOSE_NOMINATION_DETAILS_MODAL':
      return { ...state, detailModalOpened: false };

    case 'OPEN_EXEC_SIGNOFF_MODAL':
      return {
        ...state,
        execSignOffModalOpened: true,
        currentNominationId: action.data,
      };

    case 'SET_AWARD_TYPES': {
      return {
        ...state,
        awardTypes: action.data,
      };
    }

    case 'CLOSE_EXEC_SIGNOFF_MODAL':
      return { ...state, execSignOffModalOpened: false };

    case 'SET_MESSAGE_MODAL_OPEN':
      return { ...state, isMessageModalOpen: true };

    case 'SET_MESSAGE_MODAL_CLOSE':
      return { ...state, isMessageModalOpen: false };

    case 'UPDATE_ACCORDION_ACTIVE_INDEXES':
      return { ...state, accordionActiveIndexes: action.data };

    case 'SET_FILTER_TERM':
      return {
        ...state,
        filterTerm: action.data,
        filteredNominations: filterNominations(state.loadedNominations, action.data, state.filterType),
      };

    case 'SET_FILTER_TYPE':
      return {
        ...state,
        filterType: action.data,
        filteredNominations: filterNominations(state.loadedNominations, state.filterTerm, action.data),
      };

    case 'RESET_FILTERED_NOMINATIONS':
      return {
        ...state,
        filteredNominations: filterNominations(state.loadedNominations, state.filterTerm, state.filterType),
      };

    case 'TOGGLE_NOMINATION_ALL':
      return {
        ...state,
        filteredNominations: state.filteredNominations.map((item) => {
          item.selected = action.data;
          return item;
        }),
      };

    case 'TOGGLE_NOMINATION_SELECTED':
      idx = state.loadedNominations.findIndex((item) => item.id === action.data);
      return {
        ...state,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),
          { ...state.loadedNominations[idx], selected: !state.loadedNominations[idx].selected },
          ...state.loadedNominations.slice(idx + 1),
        ],
      };

    case 'UPDATE_COMMENT':
      idx = state.loadedNominations.findIndex((item) => item.id === action.data.id);
      idx2 = state.filteredNominations.findIndex((item) => item.id === action.data.id);
      return {
        ...state,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),
          { ...state.loadedNominations[idx], comment: action.data.comment },
          ...state.loadedNominations.slice(idx + 1),
        ],
        filteredNominations: [
          ...state.filteredNominations.slice(0, idx2),
          { ...state.filteredNominations[idx2], comment: action.data.comment },
          ...state.filteredNominations.slice(idx2 + 1),
        ],
      };

    case 'UPDATE_NOMINATION_CHANGES': {
      idx = state.filteredNominations.findIndex((item) => item.id === action.data.id);

      return {
        ...state,
        filteredNominations: [
          ...state.filteredNominations.slice(0, idx),
          { ...state.filteredNominations[idx], ...action.data, isNominationChanged: true },
          ...state.filteredNominations.slice(idx + 1),
        ],
      };
    }

    case 'START_LOAD_NOMINATION': {
      idx = state.filteredNominations.findIndex((item) => item.id === action.data);
      return {
        ...state,
        filteredNominations: [
          ...state.filteredNominations.slice(0, idx),
          {
            ...state.filteredNominations[idx],
            ...action.data,
            nominationLoadRequest: requestStatus.REQUEST_IN_PROGRESS,
          },
          ...state.filteredNominations.slice(idx + 1),
        ],
      };
    }

    case 'SET_NOMINATION': {
      idx = state.filteredNominations.findIndex((item) => item.id === action.data.nominationId);
      return {
        ...state,
        filteredNominations: [
          ...state.filteredNominations.slice(0, idx),
          { ...state.filteredNominations[idx], ...action.data, nominationLoadRequest: requestStatus.REQUEST_COMPLETED },
          ...state.filteredNominations.slice(idx + 1),
        ],
      };
    }

    // updated for fiscal strip modal
    case 'OPEN_FISCAL_STRIP_MODAL':
      return {
        ...state,
        isFiscalStripModalOpen: true,
        fiscalData: {
          type: 'nominee',
          nomineeEmployeeId: action.data.nomineeEmployeeId,
          fiscalStrip: action.data.nomineeFiscalStrip,
        },
      };

    case 'CLOSE_FISCAL_STRIP_MODAL':
      return {
        ...state,
        isFiscalStripModalOpen: false,
      };

    case 'UPDATE_INDIVIDUAL_FISCAL_STRIP': {
      const nomId = state.filteredNominations.findIndex((x) => x.id === action.data.nominationId);
      idx = state.filteredNominations[nomId]?.nominees.findIndex(
        (item) => item.employeeId === action.data.nomineeEmployeeId
      );

      return {
        ...state,
        isFiscalStripModalOpen: false,
        filteredNominations: [
          ...state.filteredNominations.slice(0, nomId),
          {
            ...state.filteredNominations[nomId],
            isFiscalStripDirty: action.data.fiscalStrip.trim().length > 0,
            nominees: [
              ...state.filteredNominations[nomId].nominees.slice(0, idx),
              {
                ...state.filteredNominations[nomId].nominees[idx],
                fiscalStrip: action.data.fiscalStrip.trim(),
              },
              ...state.filteredNominations[nomId].nominees.slice(idx + 1),
            ],
          },
          ...state.filteredNominations.slice(nomId + 1),
        ],
      };
    }

    case 'UPDATE_FISCAL_STRIP_TO_ALL_NOMINEES': {
      const nomId = state.filteredNominations.findIndex((x) => x.id === action.data.nomId);

      return {
        ...state,
        filteredNominations: [
          ...state.filteredNominations.slice(0, nomId),
          {
            ...state.filteredNominations[nomId],
            nominees: [
              ...state.filteredNominations[nomId].nominees.map((x) => {
                return x.approvalStatus === approvalStatus.DISAPPROVED
                  ? x
                  : {
                      ...x,
                      fiscalStrip: action.data.value,
                    };
              }),
            ],
            isFiscalStripDirty: true,
          },
          ...state.filteredNominations.slice(nomId + 1),
        ],
      };
    }
    // end for fiscal strip

    case 'SET_SAVE_CHANGES_BUTTON_FALSE':
      idx = state.filteredNominations.findIndex((x) => x.id === action.data);
      return {
        ...state,
        filteredNominations: [
          ...state.filteredNominations.slice(0, idx),
          {
            ...state.filteredNominations[idx],
            isFiscalStripDirty: false,
          },
          ...state.filteredNominations.slice(idx + 1),
        ],
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const useSignOffForm = () => {
  const { sessionStore } = useContext(SessionContext);
  const [store, dispatch] = useReducer(reducer, initialState);
  const service = useSignOffService();
  const nominationService = useNominationService();
  const { getAvailableCyclesForFeature } = useAuthorization();
  const { employeeId } = sessionStore.employeeUser;
  const { mode } = useParams();

  const formMode = mode.toLowerCase();

  const getMode = () => formMode;
  const getSelected = () => store.filteredNominations.filter((item) => item.selected);

  const getNominationById = (id) => store.filteredNominations.find((item) => item.id === id);

  const isSignOffMode = useCallback(() => mode.toLowerCase() === 'exec', [mode]);

  const isExecMode = () => store.currentMode === 'exec';
  const isFinanceMode = () => store.currentMode === 'finance';
  const isManagementMode = () => store.currentMode === 'management';
  const isAnytimeMode = () => store.currentMode === 'anytimeapprover';

  const helperFunctions = {
    getListMode: () => {
      let listMode = nominationListMode.FINANCE_SIGNOFF;
      if (isExecMode()) listMode = nominationListMode.EXEC_SIGNOFF;
      if (isManagementMode()) listMode = nominationListMode.MANAGEMENT_SIGNOFF;
      if (isAnytimeMode()) listMode = nominationListMode.ANYTIME_SIGNOFF;
      return listMode;
    },
    loadNominations: async () => {
      dispatch({ type: 'START_LOAD_REQUEST' });
      let statusList = [nominationStatus.PENDING_FINANCIAL_SIGNOFF];
      let cycleId = store.currentCycleId;
      const postId = store.currentPostId;
      const { currentMissionId } = sessionStore;

      if (isExecMode()) {
        statusList = [
          nominationStatus.PENDING_FINANCIAL_INFORMATION,
          nominationStatus.PENDING_FINANCIAL_SIGNOFF,
          nominationStatus.PENDING_EXEC_SIGNOFF,
        ];
      }
      if (isManagementMode()) {
        statusList = [nominationStatus.PENDING_MANAGEMENT_APPROVAL];
        cycleId = 0;
      }
      if (isAnytimeMode()) {
        statusList = [nominationStatus.PENDING_ANYTIME_APPROVER];
        cycleId = 0;
      }
      const requestData = {
        userEmployeeId: employeeId,
        missionId: currentMissionId,
        cycleId,
        postId,
        statusList,
        listMode: helperFunctions.getListMode(),
      };
      const response = await service.getNominations(requestData);
      if (response && response.ok) {
        dispatch({ type: 'SET_LOADED_NOMINATIONS', data: response.data });
      }
    },

    loadDetails: async (index) => {
      const nomination = store.filteredNominations[index];
      if (nomination.detailsLoaded) {
        return;
      }

      const requestData = {
        nominationId: nomination.id,
        userEmployeeId: sessionStore.employeeUser.employeeId,
      };
      const response = await nominationService.getNominationDetails(requestData);
      if (response && response.ok) {
        dispatch({ type: 'SET_NOMINATION_DETAILS', data: response.data });
        dispatch({ type: 'RESET_FILTERED_NOMINATIONS' });
      }
    },

    loadAwardTypes: async (nominationId) => {
      console.log(`nominationId -${nominationId}`);
      const response = await service.loadSignOffOptions(nominationId);
      if (response && response.ok) {
        dispatch({ type: 'SET_AWARD_TYPES', data: response.data });
      }
    },

    signOffSelected: async () => {
      const requestData = {
        userEmployeeId: sessionStore.employeeUser.employeeId,
        action: nominationAction.FORWARD,
        nominationList: getSelected().map((item) => item.id),
        listMode: helperFunctions.getListMode(),
      };

      const response = await service.signOffNominations(requestData);
      if (response.ok) {
        dispatch({
          type: 'SET_LOADED_NOMINATIONS',
          data: differenceBy(store.loadedNominations, getSelected(), 'id'),
        });
      }
      return response.ok;
    },

    endorseNomination: async (nomination, action) => {
      const requestData = {
        userEmployeeId: sessionStore.employeeUser.employeeId,
        nominationList: [nomination.id],
        action,
        comment: nomination.comment.trim(),
        listMode: helperFunctions.getListMode(),
      };

      const response = await service.signOffNominations(requestData);
      if (response.ok) {
        dispatch({ type: 'REMOVE_NOMINATION', data: nomination.id });
        dispatch({ type: 'RESET_FILTERED_NOMINATIONS' });
      }
      return response.ok;
    },

    overrideNomination: async (nomination) => {
      const requestData = {
        userEmployeeId: sessionStore.employeeUser.employeeId,
        nominationId: nomination.id,
        awardTypeId: nomination.awardTypeId,
        approvedAmount: nomination.approvedAmount,
        formOfRecognition: nomination.formOfRecognition,
        isOverridden: nomination.overrideVoting,
      };

      const response = await service.overrideNomination(requestData);
      if (response && response.ok) {
        dispatch({ type: 'REMOVE_NOMINATION', data: nomination.id });
        dispatch({ type: 'RESET_FILTERED_NOMINATIONS' });
      }

      return response.ok;
    },

    // fiscal Strip
    openFiscalStripModal: (nomineeEmployeeId, nomineeFiscalStrip) => {
      dispatch({
        type: 'OPEN_FISCAL_STRIP_MODAL',
        data: { nomineeEmployeeId, nomineeFiscalStrip },
      });
    },

    closeFiscalStripModal: () => {
      dispatch({ type: 'CLOSE_FISCAL_STRIP_MODAL' });
    },

    onUpdateFiscalStrip: async (fiscalStripData) => {
      const temp = Object.values(fiscalStripData.data);
      temp.forEach(function (v, i) {
        if (!temp[i]) {
          temp[i] = '';
        }
      });
      const assembledFiscalStrip = temp.join('/');

      dispatch({
        type: 'UPDATE_INDIVIDUAL_FISCAL_STRIP',
        data: {
          nominationId: fiscalStripData.nominationId,
          nomineeEmployeeId: fiscalStripData.nomineeEmployeeId,
          fiscalStrip: assembledFiscalStrip,
        },
      });
    },

    saveFiscalStrips: async (nomination) => {
      const requestData = {
        userEmployeeId: sessionStore.employeeUser.employeeId,
        nominationId: nomination.id,
        nominationEmployees: nomination.nominees.filter((x) => x.approvalStatus === approvalStatus.APPROVED),
      };

      const response = await service.saveFiscalStrips(requestData);

      return response.ok;
    },

    disableSaveButton: (nominationId) => {
      dispatch({ type: 'SET_SAVE_CHANGES_BUTTON_FALSE', data: nominationId });
    },

    toggleAccordionActiveIndex: (idx) => {
      const indexesArr = [...store.accordionActiveIndexes];
      const foundIndex = indexesArr.indexOf(idx);
      if (foundIndex === -1) {
        indexesArr.push(idx);
      } else {
        indexesArr.splice(foundIndex, 1);
      }
      dispatch({ type: 'UPDATE_ACCORDION_ACTIVE_INDEXES', data: indexesArr });
    },

    setFilterTerm: (term) => dispatch({ type: 'SET_FILTER_TERM', data: term }),

    onClickMoreDetails: async (nomination) => {
      dispatch({ type: 'OPEN_NOMINATION_DETAILS_MODAL', data: nomination });
      if (nomination.historyLoaded) {
        return;
      }
      const response = await nominationService.getNominationHistory({
        userEmployeeId: sessionStore.employeeUser.employeeId,
        nominationId: nomination.id,
      });
      if (response && response.ok) {
        dispatch({
          type: 'SET_NOMINATION_HISTORY_AND_COMMENTS',
          data: { history: response.data, nominationId: nomination.id },
        });
      }
    },

    onClickCheck: (nominationId) => {
      dispatch({ type: 'TOGGLE_NOMINATION_SELECTED', data: nominationId });
      dispatch({ type: 'RESET_FILTERED_NOMINATIONS' });
    },

    onClickSelectAll: (flag) => {
      dispatch({ type: 'TOGGLE_NOMINATION_ALL', data: flag });
    },

    onClickTypeFilter: (selectedType) => {
      dispatch({ type: 'SET_FILTER_TYPE', data: selectedType });
    },

    setCurrentCycle: (cycleId) => dispatch({ type: 'SET_CURRENT_CYCLE', data: cycleId }),

    setCurrentPostId: (postId) => dispatch({ type: 'SET_CURRENT_POST_ID', data: postId }),

    getAvailableCycles: () => {
      if (store.cyclesList && store.cyclesList.length > 0) return;

      const result = [{ id: 0, name: 'All Cycles or Anytime', year: null, number: null }];
      if (!helperFunctions.isManagementMode()) {
        result.push(...getAvailableCyclesForFeature(helperFunctions.isExecMode() ? 'exec_signoff' : 'fmo_signoff'));
      }
      dispatch({ type: 'UPDATE_CYCLES_LIST', data: result });
    },

    setAccordionActiveIndexes: (indexes) => {
      dispatch({ type: 'UPDATE_ACCORDION_ACTIVE_INDEXES', data: indexes });
    },

    onChangeComment: (nominationId, value) => {
      dispatch({ type: 'UPDATE_COMMENT', data: { id: nominationId, comment: value } });
    },

    loadNomination: async (nominationId) => {
      dispatch({ type: 'START_LOAD_NOMINATION', data: nominationId });
      const requestData = {
        userEmployeeId: employeeId,
        nominationId: typeof nominationId === 'string' ? parseInt(nominationId, 10) : nominationId,
        formMode: nominationFormMode.SIGN_OFF,
        listMode: helperFunctions.getListMode(),
      };
      const response = await nominationService.getNomination(requestData);

      if (response && response.ok) {
        dispatch({
          type: 'SET_NOMINATION',
          data: {
            nominationId: response.data.nominationId,
            nominationKey: response.data.nominationKey,
            isEditable: response.data.isEditable,
            isJustificationCitationEditable: response.data.isJustificationCitationEditable,
            isCycleVisible: response.data.isCycleVisible,
            isCycleEditable: response.data.isCycleEditable,
            isReasonAndTypeEditable: response.data.isReasonAndTypeEditable,
            isRecommendedAmountVisible: response.data.isRecommendedAmountVisible,
            isRecommendedAmountEditable: response.data.isRecommendedAmountEditable,
            isApprovedAmountVisible: response.data.isApprovedAmountVisible,
            isProposedAmountVisible: response.data.isProposedAmountVisible,
            isFiscalDataVisible: response.data.isFiscalDataVisible,
            isFiscalDataEditable: response.data.isFiscalDataEditable,
            canAddComments: response.data.canAddComments,
            isGroup: response.data.isGroup,
            nominationStatus: response.data.nominationStatus,
            nominationStatusName: response.data.nominationStatusName,
            nominatorUserId: response.data.nominatorId,
            nominatorName: response.data.nominatorName,
            nominatorPositionTitle: response.data.nominatorPositionTitle,
            missionId: response.data.missionId,
            missionName: response.data.missionName,
            postId: response.data.postId,
            postName: response.data.postName,
            sectionId: response.data.sectionId,
            sectionShortName: response.data.sectionShortName,
            awardReasons: response.data.awardReasons.map((item) => item.awardReasonName),
            awardTypeName: response.data.awardTypeName,
            otherReason: response.data.otherReason,
            awardTypeId: response.data.awardTypeId,
            awardProcessType: response.data.awardProcessType,
            loadedAwardProcessType: response.data.awardProcessType,
            cycleId: response.data.cycleId,
            justification: response.data.justification,
            citation: response.data.citation,
            formOfRecognition: response.data.formOfRecognition,
            proposedAmount: response.data.proposedAmount,
            recommendedAmount: response.data.recommendedAmount,
            approvedAmount: response.data.approvedAmount,
            fiscalStrip: response.data.fiscalStrip,
            nominees: response.data.nominationEmployees,
            approvers: response.data.nominationApprovers,
            usdhApprovalDate: response.data.usdhApprovalDate,
            notes: [],
            isJustificationSaved: false,
            isJustificationDirty: false,
            isCitationSaved: false,
            isCitationDirty: false,
            nominationLoadRequest: requestStatus.REQUEST_COMPLETED,
          },
        });
      }
    },

    onChangeButtonClick: (nominationId) => {
      dispatch({ type: 'OPEN_EXEC_SIGNOFF_MODAL', data: nominationId });
    },

    onChangeIndividualFiscalStrip: (nomId, nominee, fiscalStrip) =>
      dispatch({
        type: 'UPDATE_INDIVIDUAL_FISCAL_STRIP',
        data: { employeeId: nominee.employeeId, fiscalStrip, nomId },
      }),

    onClickAssignFiscalStripToAllNominees: (nomId, value) =>
      dispatch({ type: 'UPDATE_FISCAL_STRIP_TO_ALL_NOMINEES', data: { nomId, value } }),

    getMode,
    isSignOffMode,
    getSelected,
    getNominationById,
    isExecMode,
    isFinanceMode,
    isManagementMode,
    isAnytimeMode,
  };

  return { formStore: store, dispatch, helperFunctions };
};

export default useSignOffForm;
