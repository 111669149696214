import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';

function SaveCancel({ onCancelClick, onSaveClick, onlySave }) {
  return (
    <>
      <Button
        label="Save"
        icon="pi pi-save"
        className="min-w-150px p-button-primary mx-1"
        onClick={() => onSaveClick()}
      />
      {!onlySave && (
        <Button
          label="Close/Cancel"
          icon="pi pi-times-circle"
          className="p-button-secondary mx-1 min-w-150px"
          onClick={() => onCancelClick()}
        />
      )}
    </>
  );
}
SaveCancel.defaultProps = {
  onlySave: false,
};
SaveCancel.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onlySave: PropTypes.bool,
};
export default SaveCancel;
