import React from 'react';
import PropTypes from 'prop-types';
import { Label, Row, Col, FormGroup } from 'reactstrap';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';

import { FileUpload } from 'components';
import { requestStatus } from 'constants/index';
import './MissionProfile.css';
import { InputSwitch } from 'primereact/inputswitch';

function MissionBasicSettings({ store, dispatch, onSaveClick }) {
  const {
    missionShortName,
    missionSealImage,
    maxCustomerServiceAwards,
    missionCertificateSignatureImage,
    hideNomineesGender,
    hideCitation,
  } = store;

  const setMissionSealImage = (fileObj) => {
    dispatch({ type: 'UPDATE_DATA', data: { missionSealImage: fileObj } });
  };

  const setCertificateSignatureImage = (fileObj) => {
    dispatch({ type: 'UPDATE_DATA', data: { missionCertificateSignatureImage: fileObj } });
  };

  const isLoading = store.loadRequest === requestStatus.REQUEST_IN_PROGRESS;

  return (
    <>
      <Row>
        <Col xs="6" md="4">
          <FormGroup>
            <Label for="sealImage">Seal Image</Label>
            {isLoading && <Skeleton width="10rem" className="mb-2" />}
            {!isLoading && <FileUpload file={missionSealImage} setFile={setMissionSealImage} />}
          </FormGroup>
          <FormGroup>
            <Label for="sealImage">Certificate Signature</Label>
            {isLoading && <Skeleton width="10rem" className="mb-2" />}
            {!isLoading && (
              <FileUpload file={missionCertificateSignatureImage} setFile={setCertificateSignatureImage} />
            )}
          </FormGroup>
        </Col>
        <Col xs="4" md="3">
          <Row>
            <FormGroup>
              <Label for="missionShortName">Mission Short Name</Label>
              {isLoading && <Skeleton width="10rem" className="mb-2" />}
              {!isLoading && (
                <input
                  type="text"
                  className="form-control"
                  id="missionShortName"
                  value={missionShortName || ''}
                  maxLength="10"
                  onChange={(e) =>
                    dispatch({ type: 'UPDATE_DATA', data: { missionShortName: e.target.value.toUpperCase() } })
                  }
                />
              )}
            </FormGroup>
          </Row>
          <Row />
          <Row />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="maxCustomerServiceAwards">Maximum Customer Service Awards by Nominee</Label>
            <Row>
              <Col lg="1" md="2">
                {isLoading && <Skeleton width="10rem" className="mb-2" />}
                {!isLoading && (
                  <input
                    type="number"
                    min="0"
                    max="4"
                    className="form-control max-w-100px"
                    id="maxCustomerServiceAwards"
                    value={maxCustomerServiceAwards}
                    maxLength="2"
                    onChange={(e) =>
                      dispatch({ type: 'UPDATE_DATA', data: { maxCustomerServiceAwards: e.target.value } })
                    }
                  />
                )}
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="hideNomineesGender">Hide Nominee(s) Names</Label>
            <Row>
              <Col lg="1" md="2">
                {isLoading && <Skeleton width="10rem" className="mb-2" />}
                {!isLoading && (
                  <InputSwitch
                    checked={hideNomineesGender}
                    onChange={(e) => dispatch({ type: 'UPDATE_DATA', data: { hideNomineesGender: e.target.value } })}
                  />
                )}
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="hideCitation">Hide Citations</Label>
            <Row>
              <Col lg="1" md="2">
                {isLoading && <Skeleton width="10rem" className="mb-2" />}
                {!isLoading && (
                  <InputSwitch
                    checked={hideCitation}
                    onChange={(e) => dispatch({ type: 'UPDATE_DATA', data: { hideCitation: e.target.value } })}
                  />
                )}
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row className="p-3">
        <Button label="Save" icon="pi pi-save" className="p-button-primary min-w-150px" onClick={() => onSaveClick()} />
      </Row>
    </>
  );
}
MissionBasicSettings.propTypes = {
  store: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
};

export default MissionBasicSettings;
