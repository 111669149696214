import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/pro-light-svg-icons';

import Layout from './Layout';
import './ErrorPage.css';

const humanizeErrorType = (text) => text.replace('_', ' ');

function ErrorPage() {
  const history = useHistory();
  const { errorType } = useParams();

  const goToHome = () => history.push('/Home');

  return (
    <Layout>
      <div id="errorPageDiv">
        <div className="error-page">
          <div className="text-right">
            <FontAwesomeIcon icon={faFrown} fixedWidth size="5x" />
          </div>
          <div>
            <h4 className="">{humanizeErrorType(errorType)}</h4>
            <div className="pb-4">
              There was an unexpected error on our server. <br />
              Please try again later or contact the system administration!
            </div>
            <button type="button" onClick={goToHome} className="btn btn-primary">
              Go To Home
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default React.memo(ErrorPage);
