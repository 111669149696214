import React, { useReducer, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { approvalStatus, requestStatus, formOfRecognition, nominationStatus } from 'constants/index';
import { useNominationService } from 'hooks/nomination';
import { formatDate } from 'utils/dateHelpers';
import PropTypes from 'prop-types';
import stateSeal from 'assets/images/us-department-of-state-1.svg';
import ribbon from 'assets/images/ExtraMile.png';
import timeOffRibbon from 'assets/images/TimeOffRibbon.png';
import FormBlock from './FormBlock';
import './Form.css';

const initState = {
  requestStatus: requestStatus.REQUEST_NOT_INITIATED,
  submissionDate: null,
  approvalDate: null,
  finalApprovedDate: null,
  timerKeeperSignDate: null,
  nominatorName: '',
  nomineeName: '',
  supervisorName: '',
  grade: '',
  missionName: '',
  postName: '',
  recommededAmmount: '',
  approvedAmount: '',
  finalApproverName: '',
  timekeeperName: '',
  awardReasons: [],
  postBugetOfficer: '',
  justification: '',
  formTitle: '',
  labelTitle: '',
  footerText1: '',
  footerText2: '',
  isFormForTimeOff: false,
  nominationEmployees: [],
  effectiveApproverName: '',
  effectiveApproveDate: null,
  approvedByName: '',
  financeOfficerName: '',
  financeApprovalDate: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, requestStatus: requestStatus.REQUEST_IN_PROGRESS };

    case 'LOAD_DATA':
      return { ...state, ...action.data, requestStatus: requestStatus.REQUEST_COMPLETED };

    case 'RESET_FORM':
      return { ...initState };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

// eslint-disable-next-line react/display-name
const CustomerServiceAwardForm = React.forwardRef(({ nominationId }, ref) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [currentFormType, setCurrentFormType] = useState('');
  const service = useNominationService();
  const { formType } = useParams();

  if (formType !== currentFormType) {
    setCurrentFormType(formType);
    dispatch({ type: 'RESET_FORM' });
  }

  const isTimeOffMode = useCallback(
    () => state.formOfRecognition === formOfRecognition.TIMEOFF_ONLY,
    [state.formOfRecognition]
  );

  useEffect(() => {
    const loadData = async () => {
      dispatch({ type: 'START_LOADING' });
      const response = await service.getFormData(nominationId);      
      if (response && response.ok) {
        dispatch({ type: 'LOAD_DATA', data: response.data });
      }
    };
    if (state.requestStatus === requestStatus.REQUEST_NOT_INITIATED) {
      loadData();
    }
  }, [nominationId, service, state.requestStatus]);

  // const renderCheckbox = (text) => {
  //   return (
  //     <>
  //       <FontAwesomeIcon
  //         icon={state.awardReasons.some((item) => item.awardReasonName === text) ? faCheckSquare : faSquare}
  //         size="lg"
  //       />{' '}
  //       {text}
  //     </>
  //   );
  // };
  const renderAwardReasons = () => {
    return (
      <>
        {state.awardReasons.map((item) => (
          <span className="pr-2" key={item.id}>
            {item.awardReasonName === 'Other' && state.otherReason ? state.otherReason : item.awardReasonName}{' '}
          </span>
        ))}
      </>
    );
  };

  const typeOfAward = () => {
    const individualOrGroup = state.nominationEmployees.length;
    if (individualOrGroup > 1) {
      return <p>Group</p>;
    }
    return <p>Individual</p>;
  };

  const nomineeNames = state.nominationEmployees.filter(x => x.approvalStatus !== approvalStatus.DISAPPROVED).map((item) => item.name).join(', ');

  const fiscalStrips = state.nominationEmployees.map((item) => item.fiscalStrip).join(', ');  
  return (
    <div ref={ref} className="px-3 "style={{fontSize : '15px'}}>
      <div className="container-fluid mb-0 pt-0 printable-form">
        <div className="row border-b">
          <div className="col-12">
            <div className="pl-2 pt-2 pb-2 text-center">
              <div className="pt-3 float-left position-absolute">
                <img className="usseal" src={stateSeal} alt="US Department of State" />
              </div>
            </div>
            <div className="pt-2 text-center font-arial">US Mission {state.missionName}</div>
            <div className="h3 pt-2 text-center font-weight-bold font-arial">{typeOfAward()}</div>
            <div className="pl-2 text-center h2 font-weight-bold font-arial">{state.formTitle}</div>
          </div>
        </div>

        <div className="row">
          <div className="col-3 border-r border-b py-1 mb-0">1. Employee Name (Last, First, MI)</div>
          <div className="col-2 border-r border-b py-1 mb-0">2. Payroll ID</div>
          <div className="col-1 border-r border-b py-1 mb-0">3. Grade</div>
          <div className="col-1 border-r border-b py-1 mb-0">4. Post</div>
          <div className="col-3 border-r border-b py-1 mb-0">5. Supervisor&apos;s Name</div>
          <div className="col-2 border-b py-1 mb-0">6. Approved Date (mm/dd/yyyy)</div>
        </div>
        

        {state.nominationEmployees
          .filter((x) => x.approvalStatus !== approvalStatus.DISAPPROVED)
          .map((nominee, idx) => (    

            <div
              className={`row ${idx < state.nominationEmployees.length - 1 ? 'border-dotted-b' : 'border-b'}`}
              key={nominee.employeeId}
            >
              <div className="col-3 border-r">
                <FormBlock
                  Rows={[
                    {
                      line2: nominee.name,
                    },
                  ]}
                />
              </div>
              <div className="col-2 border-r">
                <FormBlock
                  Rows={[
                    {
                      line2: nominee.payrollId,
                    },
                  ]}
                />
              </div>
              <div className="col-1 border-r">
                <FormBlock
                  Rows={[
                    {
                      line2: nominee.grade,
                    },
                  ]}
                />
              </div>
              <div className="col-1 border-r">
                <FormBlock
                  Rows={[
                    {
                      line2: nominee.postName,
                    },
                  ]}
                />
              </div>
              <div className="col-3 border-r">
                <FormBlock
                  Rows={[
                    {
                      line2: nominee?.usdhSupervisorName,
                    },
                  ]}
                />
              </div>
              <div className="col-2">
                <FormBlock
                  Rows={[
                    {
                      line2: nominee.approvalDate && formatDate(nominee.approvalDate),
                    },
                  ]}
                />
              </div>
              </div>           
              ))}  

        <div className="row border-b">
          <div className="col-5 border-r">
            <FormBlock
              Rows={[
                {
                  line1: '7. Nominator Name (Last, First MI)',
                  line2: state.nominatorName,
                },
              ]}
            />
          </div>
          <div className="col-7">
            <FormBlock
              Rows={[
                {
                  line1: '8. Date Nomination Submitted (mm/dd/yyyy)',
                  line2: formatDate(state.submissionDate),
                },
              ]}
            />
          </div>
        </div>        
        <div className="row border-b">
          <div className="col-12">
            <FormBlock
              Rows={[
                {
                  line1: '9. Reason For Award',
                  line2: renderAwardReasons(),
                },
              ]}
            />
          </div>
        </div>

        <div className="row border-b">
          <div className="col-12 ">
            <FormBlock
              Rows={[
                {
                  line1: '10. Justification',
                  line2: <div dangerouslySetInnerHTML={{ __html: state.justification }} />,
                },
              ]}
            />
          </div>
        </div>
        <div className="row border-b">
          <div className="col-3 col-md-3 border-r">
            <FormBlock
              Rows={[
                {
                  line1: '11. Approved Amount',
                  line2: state.approvedAmount,
                },
              ]}
            />
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col-6 border-r border-b">
                <FormBlock
                  Rows={[
                    {
                      line1: '12. Approving Official (Last, First MI)',
                      line2: state.effectiveApproverName,
                    },
                  ]}
                />
              </div>
              <div className="col-6 border-b">
                <FormBlock
                  Rows={[
                    {
                      line1: '13. Approved on (mm/dd/yyyy)',
                      line2: formatDate(state.effectiveApproveDate),
                    },
                  ]}
                />
              </div>
            </div>
            {!isTimeOffMode() && (
              <>
                <div className="row">
                  <div className="col-6 border-r border-b">
                    <FormBlock
                      Rows={[
                        {
                          line1: '14. Budget Officer (Last, First MI)',
                          line2: state.financeOfficerName,
                        },
                      ]}
                    />
                  </div>
                  <div className="col-6 border-b">
                    <FormBlock
                      Rows={[
                        {
                          line1: '15. Approved on (mm/dd/yyyy)',
                          line2: formatDate(state.financeApprovalDate),
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <FormBlock
                      Rows={[
                        {
                          line1: '16. Accounting Classification Assigned Completed by Bureau/Post Budget Officer)',
                          line2: fiscalStrips,
                        },
                      ]}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>        

        <div className="row ">
          <div className="col-8">
            <div className="row justify-content-md-center">
              <div className="col col-lg-4 text-center">
                <h5 className="pt-4 pb-2">
                  U.S. Mission {state.missionName} {isTimeOffMode() ? 'Time-Off' : 'Customer Service'} Award Presented
                  To
                </h5>
                <span className="text-center">{nomineeNames}</span>
              </div>
            </div>
            <div className="pt-2 row justify-content-md-center">
              <h5 className="pb-4 pl-4 text-center">
                <span>For your special efforts that have impacted Mission operations, programs or procedures.</span>
              </h5>
            </div>
            <p className="pt-0 pb-4 text-center" />
            <hr className="col-6" />
            <p className="pt-0 pb-2 text-center">Nominator</p>
            <p className="pt-0 pb-0 text-center">{formatDate(state.effectiveApproveDate)}</p>
            <hr className="col-6" />
            <p className="pt-0 pb-4 text-center">Date (mm/dd/yyyy)</p>
          </div>
          <div className="col-4 align-items-center align-self-center">
            <img
              className="mx-auto my-auto d-block max-h-360px"
              src={isTimeOffMode() ? timeOffRibbon : ribbon}
              alt="US Department of State"
            />
          </div>
        </div>
        {!isTimeOffMode() && (
          <div className="row text-center pt-1">
            <FormBlock
              Rows={[
                {
                  line1: state.footerText1,
                },
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
});

CustomerServiceAwardForm.propTypes = {
  nominationId: PropTypes.number,
};

CustomerServiceAwardForm.defaultProps = {
  nominationId: null,
};

export default CustomerServiceAwardForm;
