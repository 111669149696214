import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'primereact/skeleton';

function SkeletonTable({ colsSize, rowsSize }) {
  const tableRows = [];
  const tableCells = [];

  for (let i = 0; i <= colsSize; i += 1)
    tableCells.push(
      <td className="col text-center" key={`_temp_${i}`}>
        <Skeleton width="90%" height="20px" className="m-2" />
      </td>
    );
  for (let i = 0; i <= rowsSize; i += 1)
    tableRows.push(
      <tr className="row" key={`_temp_${i}`}>
        {tableCells}
      </tr>
    );

  return (
    <table className="table">
      <tbody>{tableRows}</tbody>
    </table>
  );
}
SkeletonTable.propTypes = {
  colsSize: PropTypes.number,
  rowsSize: PropTypes.number,
};
SkeletonTable.defaultProps = {
  colsSize: 4,
  rowsSize: 4,
};
export default SkeletonTable;
