import React, { useState, useEffect, useContext, useReducer, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { faIdBadge } from '@fortawesome/pro-solid-svg-icons';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import download from 'downloadjs';

import { ActionButton, UserSearch, Grid, SkeletonTable } from 'components';
import { SessionContext, NotificationContext } from 'contexts';
import { useEmployeeService } from 'hooks/admin';
import { useEmployeeSearchService, useNavigationLinks, useQuery, useAuthorization } from 'hooks';
import { dynamicSort } from 'utils/sorting';
import { requestStatus, employeeType } from 'constants/index';
import './EmployeeSearch.css';

const LSKEY = 'manageEmployeeResult';

const initialState = {
  availableParamsRequest: requestStatus.REQUEST_NOT_INITIATED,
  availableParams: {
    loaded: false,
    bureaus: [],
    missions: [],
    posts: [],
    sections: [],
    missionsAndBureaus: [],
  },
  searchParams: {
    isLocalStaff: false,
    nameTerm: '',
    emailTerm: '',
    positionTerm: '',
  },
  employeeTypeFilter: employeeType.ALL,
  searchResult: [],
  listSuperviseesResult: [],
  selectedRecords: [],
  searchRequestStatus: requestStatus.REQUEST_NOT_INITIATED,
  listSuperviseesStatus: requestStatus.REQUEST_NOT_INITIATED,
  saveSupervisorRequest: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_AVAILABLE_PARAMS':
      return {
        ...state,
        availableParams: { ...state.availableParams, ...action.data },
      };

    case 'START_REQUEST_SEARCH_PARAMS':
      return {
        ...state,
        availableParamsRequest: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'UPDATE_FILTER':
      return { ...state, employeeTypeFilter: action.data };

    case 'UPDATE_SEARCH_PARAMS':
      return {
        ...state,
        searchParams: { ...state.searchParams, ...action.data },
        availableParamsRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'UPDATE_SEARCH_RESULT':
      return {
        ...state,
        searchResult: action.data,
        searchRequestStatus: requestStatus.REQUEST_COMPLETED,
      };

    case 'UPDATE_LIST_SUPERVISEES_RESULT':
      return {
        ...state,
        listSuperviseesResult: action.data,
        listSuperviseesStatus: requestStatus.REQUEST_COMPLETED,
      };

    case 'UPDATE_SELECTED_RECORDS':
      return { ...state, selectedRecords: action.data };

    case 'START_SAVE_SUPERVISOR_REQUEST':
      return {
        ...state,
        saveSupervisorRequest: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'COMPLETE_SAVE_SUPERVISOR_REQUEST':
      return {
        ...state,
        saveSupervisorRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'RESET_SELECTED_RECORDS':
      return {
        ...state,
        selectedRecords: initialState.selectedRecords,
      };

    case 'START_SEARCH_REQUEST':
      return {
        ...state,
        searchRequestStatus: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'RESET_SEARCH_PARAMS':
      return {
        ...state,
        searchParams: initialState.searchParams,
        searchResult: initialState.searchResult,
        searchRequestStatus: requestStatus.REQUEST_NOT_INITIATED,
        employeeTypeFilter: initialState.employeeTypeFilter,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const getMissionsAndBureausList = (bureaus, missions) => {
  const result = missions.map((item) => {
    const bureauName = bureaus.find((elem) => elem.key === item.bureauId).value;
    return { key: item.id, value: `${bureauName} - ${item.name}` };
  });
  return result.sort(dynamicSort('value'));
};

function EmployeeSearch() {
  const { showSuccess, showError } = useContext(NotificationContext);
  const [selectedSupervisor, setSelectedSupervisor] = useState({});
  const { checkRole } = useAuthorization();
  const { goTo } = useNavigationLinks();
  const { sessionStore } = useContext(SessionContext);
  const { currentMissionId } = sessionStore;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { mode } = useParams();
  const isSysAdmin = mode.toLowerCase() === 'sysadmin';
  const service = useEmployeeSearchService();
  const employeeService = useEmployeeService();
  const query = useQuery();

  const setParamValue = (paramValue) => {
    dispatch({
      type: 'UPDATE_SEARCH_PARAMS',
      data: { ...paramValue },
    });
  };

  const getTitle = () => {
    let area = 'Mission Admin';
    if (isSysAdmin) area = 'System Admin';
    return `${area} - Manage Employees`;
  };

  const onClickAddNew = (mod) => {
    goTo(`${isSysAdmin ? 'employee_sys_new' : 'employee_new'}`, { mod });
  };

  const reset = () => {
    setSelectedSupervisor({});
  };

  const persistResultList = useCallback(() => {
    sessionStorage.setItem(LSKEY, JSON.stringify(state.searchResult));
  }, [state.searchResult]);

  function onResetClick(refresh) {
    dispatch({
      type: 'RESET_SEARCH_PARAMS',
    });
    dispatch({
      type: 'RESET_SELECTED_RECORDS',
    });

    if (refresh) {
      dispatch({
        type: 'UPDATE_SEARCH_PARAMS',
        data: {
          selectedMissionId: refresh ? currentMissionId : '',
        },
      });
    }

    reset();
  }

  async function onClickOpenSuperviseesList(id) {
    const response = await employeeService.getEmployeesBySupervisorId(id);
    if (response && response.ok) {
      dispatch({
        type: 'UPDATE_LIST_SUPERVISEES_RESULT',
        data: response.data,
      });
    }
    return goTo('employees_by_supervisor', { id });
  }

  const actionTemplate = (rowData) => {
    const actionsBuffer = [];
    const editPage = isSysAdmin ? 'sysadm_employee_edit' : 'employee_edit';
    actionsBuffer.push(
      <ActionButton
        key={`edit_${rowData.employeeId}`}
        tooltip="Edit Employee"
        className="p-button-text p-button-plain px-2"
        icon={['far', 'edit']}
        onClick={() => {
          persistResultList();
          goTo(editPage, { id: rowData.employeeId });
        }}
      />
    );

    if (rowData.isSupervisor) {
      actionsBuffer.push(
        <ActionButton
          key={`sup_by_${rowData.employeeId}`}
          className="p-button-text p-button-plain px-2"
          tooltip={`Employees supervised by ${rowData.name}`}
          icon={['far', 'user-cog']}
          onClick={() => onClickOpenSuperviseesList(rowData.employeeId)}
        />
      );
    }
    return <div>{actionsBuffer}</div>;
  };

  function autoOpenPostsDropdown(length) {
    if (length > 1) {
      document.getElementById('searchposts').click();
    }
  }

  const nameWithBadgeTemplate = (rowData) => {
    let colorClass = 'badge-les';
    let titleDesc = 'Local Staff';
    if (!rowData.isLocalStaff) {
      colorClass = 'badge-usdh';
      titleDesc = 'USDH';
    }
    return (
      <>
        <i className={colorClass} title={titleDesc}>
          <FontAwesomeIcon size="lg" icon={faIdBadge} fixedWidth />
        </i>
        <span>{rowData.name}</span>
      </>
    );
  };

  const setSelectedRecords = (selectedRecords) => {
    dispatch({
      type: 'UPDATE_SELECTED_RECORDS',
      data: selectedRecords,
    });
  };

  async function onSearchClick() {
    const searchRequestData = {
      missionId: state.searchParams.selectedMissionId,
      postId: state.searchParams.selectedPostId,
      isLocalStaff: state.searchParams.isLocalStaff,
      nameTerm: state.searchParams.nameTerm,
      emailTerm: state.searchParams.emailTerm,
      positionTerm: state.searchParams.positionTerm,
      isArchived: state.searchParams.isArchived,
    };
    dispatch({ type: 'START_SEARCH_REQUEST' });
    const response = await service.search(searchRequestData);
    if (response && response.ok) {
      const employeeResultList = response.data.map((item) => ({
        ...item,
        localStaff: `${item.isLocalStaff ? 'Yes' : 'No'}`,
        assignment: `${item.sectionName} @ ${item.postName}`,
      }));
      dispatch({ type: 'UPDATE_SEARCH_RESULT', data: employeeResultList });
    }
  }

  const updateSupervisorNamesInGrid = (updatedSupervisorName) => {
    const newUsers = state.selectedRecords.map((x) => ({
      ...x,
      supervisorName: updatedSupervisorName,
    }));

    const newArr = state.searchResult
      .map((s) => newUsers.find((t) => t.employeeId === s.employeeId) || s)
      .concat(newUsers.filter((s) => !state.searchResult.find((t) => t.employeeId === s.employeeId)));
    // const newArr = newUsers.reduce((res, item) => res.filter(i => i.employeeId !== item.employeeId).concat(item), state.searchResult);
    dispatch({ type: 'UPDATE_SEARCH_RESULT', data: newArr });
  };

  const onSaveSupervisorClick = async () => {
    const dataToSave = {
      supervisorId: selectedSupervisor.employeeId,
      employeeList: state.selectedRecords.map((item) => item.employeeId),
    };

    console.log('data to be saved:', dataToSave);
    dispatch({ type: 'START_SAVE_SUPERVISOR_REQUEST' });
    let response = '';
    if (!selectedSupervisor.isLocalStaff) {
      response = await employeeService.saveSupervisorForEmployees(dataToSave);
      showSuccess(`${selectedSupervisor.name} is now assigned as USDH supervisor for the selected employees.`);
      updateSupervisorNamesInGrid(selectedSupervisor.name);

      if (response && response.ok) {
        dispatch({
          type: 'RESET_SELECTED_RECORDS',
        });
        setSelectedSupervisor({});
      }
    } else {
      showError('An LES cannot be selected as a USDH Supervisor.');
      dispatch({
        type: 'RESET_SELECTED_RECORDS',
      });
      reset();
    }
    dispatch({ type: 'COMPLETE_SAVE_SUPERVISOR_REQUEST' });
    return response.ok;
  };

  const hasSelectedSupervisor = () => selectedSupervisor?.employeeId > 0;

  async function loadPosts(selectedMissionId) {
    if (selectedMissionId) {
      const response = await service.getAvailablePostsByMission(selectedMissionId);

      if (response && response.ok) {
        dispatch({
          type: 'UPDATE_AVAILABLE_PARAMS',
          data: {
            posts: response.data,
          },
        });
        dispatch({
          type: 'UPDATE_SEARCH_PARAMS',
          data: {
            selectedPostId: response.data[0].key,
          },
        });
        autoOpenPostsDropdown(response.data.length);
      }
    } else {
      onResetClick(false);
    }
  }

  useEffect(() => {
    if (query.get('restoreResult') === 'yes' && state.searchRequestStatus === requestStatus.REQUEST_NOT_INITIATED) {
      dispatch({
        type: 'UPDATE_SEARCH_RESULT',
        data: JSON.parse(sessionStorage.getItem(LSKEY)),
      });
      goTo(isSysAdmin ? 'sysadm_employee_search' : 'employee_search');
    } else if (query.get('removeEmployee' && state.searchRequestStatus === requestStatus.REQUEST_NOT_INITIATED)) {
      const results = JSON.parse(sessionStorage.getItem(LSKEY));
      const newArr = results.filter((item) => item.employeeId !== parseInt(query.get('removeEmployee'), 10));
      dispatch({ type: 'UPDATE_SEARCH_RESULT', data: newArr });
    } else {
      persistResultList();
    }
  }, [goTo, persistResultList, query, state.searchRequestStatus, isSysAdmin]);

  useEffect(() => {
    async function loadSearchParameters() {
      dispatch({ type: 'START_REQUEST_SEARCH_PARAMS' });
      const response = await service.getSearchAvailableParameters(currentMissionId);
      if (response && response.ok) {
        dispatch({
          type: 'UPDATE_AVAILABLE_PARAMS',
          data: {
            bureaus: response.data.bureaus,
            sections: response.data.sections,
            missions: response.data.missions,
            posts: response.data.posts,
            missionsAndBureaus: getMissionsAndBureausList(response.data.bureaus, response.data.missions),
          },
        });
        dispatch({
          type: 'UPDATE_SEARCH_PARAMS',
          data: {
            selectedMissionId: currentMissionId,
            selectedEmployee: response.data.isLocalStaff,
            selectedEmployeeId: response.data.employeeId,
          },
        });
      }
    }

    if (currentMissionId && state.availableParamsRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadSearchParameters();
    }
  }, [service, currentMissionId, state.availableParamsRequest, state.searchParams.employeeId, employeeService]);

  useEffect(() => {
    let doesHaveAccess = false;
    if (isSysAdmin) {
      doesHaveAccess = checkRole('system_roles');
    } else {
      doesHaveAccess = checkRole('hr_officer');
    }
    if (!doesHaveAccess) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo, isSysAdmin]);

  const filteredResult = useCallback(
    () =>
      state.searchResult.filter((item) => {
        if (state.employeeTypeFilter === employeeType.ALL) {
          return true;
        }
        return item.isLocalStaff === (state.employeeTypeFilter === employeeType.LESTAFF);
      }),
    [state.employeeTypeFilter, state.searchResult]
  );

  const saveAsExcelFile = (buffer, fileName) => {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    const filename = `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`;

    download(data, filename);
  };

  const getRecordsForExport = () => {
    const forExport = state.selectedRecords.length > 0 ? state.selectedRecords : state.searchResult;
    return forExport.map((item) => ({
      'Employee ID': item.employeeId,
      'Payroll ID': item.payrollId,
      'Name': item.name,
      'Position Title': item.positionTitle,
      'Email': item.email,
      'Local Staff': item.localStaff,
      'Mission ID': item.missionId,
      'Post ID' : item.postId,
      'Post Name': item.postName,
      'Agency Name': item.agencyName,
      'Section Name': item.sectionName,
      'USDH Supervisor Name': item.supervisorName,
      'LE Staff Supervisor Name': item.localSupervisorName,
    }));
  };

  const onExcelClick = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(getRecordsForExport());
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };  

      // Protect the worksheet
      // worksheet['!protect'] = {
      //   password: "oas_admin", // Optional: set a password
      //   sheet: true, // Ensure the sheet itself is protected
      //   objects: true,
      //   scenarios: true
      // };

      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAsExcelFile(excelBuffer, 'Employees List');
    });
  };

  return (
    <Container fluid className="min-h-400px search-employee max-h-90">
      <Row>
        <Col>
          <h4>{getTitle()}</h4>
        </Col>
        <Col>
          <div>
            <Button
              label="Export to Excel"
              icon="pi pi-file-excel"
              onClick={() => onExcelClick()}
              disabled={state.searchResult.length === 0}
              className="min-w-80px btn-sm ml-4 exportButton"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="2" md="3" xs="6" className="mt-1">
          Mission
          <div>
            <Dropdown
              value={state.searchParams.selectedMissionId}
              options={state.availableParams.missionsAndBureaus}
              optionLabel="value"
              optionValue="key"
              filterBy="value"
              filter
              showClear
              className="w-100"
              disabled={!isSysAdmin}
              onChange={(e) => {
                setParamValue({ selectedMissionId: e.value });
                loadPosts(e.value);
              }}
            />
          </div>
        </Col>
        <Col lg="2" md="3" xs="6" className="mt-1">
          Post
          <div>
            <Dropdown
              id="searchposts"
              value={state.searchParams.selectedPostId}
              options={state.availableParams.posts}
              optionLabel="value"
              optionValue="key"
              className="w-100"
              showClear
              onChange={(e) => setParamValue({ selectedPostId: e.value })}
            />
          </div>
        </Col>
        <Col lg="2" md="3" xs="6" className="mt-1">
          <span className="text-nowrap">Employee&apos;s Name</span>
          <InputText
            value={state.searchParams.nameTerm}
            onChange={(e) => setParamValue({ nameTerm: e.target.value })}
            className="w-100"
          />
        </Col>
        <Col lg="2" md="3" xs="6" className="mt-1">
          Email
          <InputText
            value={state.searchParams.emailTerm}
            onChange={(e) => setParamValue({ emailTerm: e.target.value })}
            className="w-100"
          />
        </Col>
        <Col lg="2" md="6" xs="6" className="mt-1">
          <div className="text-right">
            <Button
              label="Search"
              icon="pi pi-search"
              className="p-button-primary w-100 min-w-100px"
              onClick={() => onSearchClick()}
            />
          </div>
          <div className="text-right mt-1">
            <Button
              label="Reset"
              icon="pi pi-refresh"
              className="p-button-primary p-button-xs p-button-outlined w-100 min-w-100px"
              onClick={() => onResetClick(true)}
            />
          </div>
        </Col>
        <Col lg="2" md="6" xs="6" className="mt-1">
          <div className="text-right">
            <Button
              label="Add New"
              icon="pi pi-user-plus"
              className="p-button-information min-w-150px"
              title="Add New Employee"
              onClick={onClickAddNew}
            />
          </div>
          <div className="text-right mt-1 text-nowrap">
            <FontAwesomeIcon className="mb-1 mr-1 color-primary" size="lg" icon={faFilter} fixedWidth />
            <span className="p-buttonset">
              <Button
                type="button"
                label="All"
                className={`p-button-xs p-button-primary ${
                  state.employeeTypeFilter === employeeType.ALL ? '' : 'p-button-outlined'
                }`}
                onClick={() => dispatch({ type: 'UPDATE_FILTER', data: employeeType.ALL })}
              />
              <Button
                type="button"
                label="LES"
                className={`p-button-xs p-button-primary ${
                  state.employeeTypeFilter === employeeType.LESTAFF ? '' : 'p-button-outlined'
                }`}
                onClick={() =>
                  dispatch({
                    type: 'UPDATE_FILTER',
                    data: employeeType.LESTAFF,
                  })
                }
              />

              <Button
                type="button"
                label="USDH"
                className={`p-button-xs p-button-primary ${
                  state.employeeTypeFilter === employeeType.USDH ? '' : 'p-button-outlined'
                }`}
                onClick={() => dispatch({ type: 'UPDATE_FILTER', data: employeeType.USDH })}
              />
            </span>
          </div>
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          {state.searchRequestStatus === requestStatus.REQUEST_IN_PROGRESS && (
            <SkeletonTable colsSize={6} rowsSize={6} />
          )}
          {state.searchRequestStatus === requestStatus.REQUEST_COMPLETED && (
            <Grid
              items={filteredResult()}
              selectedRecords={state.selectedRecords}
              onSelectionChange={(e) => setSelectedRecords(e.value)}
              dataKey="employeeId"
              sortMode="single"
            >
              <Column selectionMode="multiple" headerStyle={{ width: '2em' }} />
              <Column field="name" header="Name" body={nameWithBadgeTemplate} sortable />
              <Column field="email" header="Email" sortable />
              <Column field="positionTitle" header="Position" sortable />
              <Column field="assignment" header="Section/Post" sortable />
              <Column field="supervisorName" header="USDH Supervisor" sortable />
              <Column header="Action" body={actionTemplate} />
            </Grid>
          )}
        </Col>
      </Row>
      <div className="row">
        {state.selectedRecords.length > 0 ? (
          <>
            <UserSearch
              className="w-25 mr-2 show-item"
              value={selectedSupervisor}
              setValue={(supervisor) => setSelectedSupervisor(supervisor)}
              menuPlacement="top"
              isGlobal
            />
            <span className="show-item">
              <Button
                label="Assign USDH Supervisor"
                className="p-button-info"
                onClick={onSaveSupervisorClick}
                disabled={!hasSelectedSupervisor()}
              />
            </span>
          </>
        ) : null}
      </div>
    </Container>
  );
}

export default EmployeeSearch;
