import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';

function WarningMessage({ message, details, isVisible, onClickClose }) {
  const [isMoreDetailsVisible, toggleMoreDetails] = useState(false);
  const detailsArray = Array.isArray(details) ? details : new Array(details);
  return (
    <div className={`p-message p-component p-message-warn p-message-enter-done ${isVisible ? 'd-block' : 'd-none'}`}>
      <div className="p-message-wrapper">
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <span className="p-message-icon pi  pi-exclamation-triangle" />
            <span className="p-message-detail">{message}</span>
            <Button
              label={`${isMoreDetailsVisible ? 'Hide' : 'Show'} more details`}
              className="p-button-link"
              onClick={() => toggleMoreDetails(!isMoreDetailsVisible)}
            />
          </div>
          <ul className={isMoreDetailsVisible ? 'd-block' : 'd-none'}>
            {detailsArray.map((detail, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={idx} className="list-item">
                {detail}
              </li>
            ))}
          </ul>
        </div>
        <button type="button" className="p-message-close p-link" onClick={() => onClickClose()}>
          <i className="p-message-close-icon pi pi-times" />
        </button>
      </div>
    </div>
  );
}
WarningMessage.propTypes = {
  message: PropTypes.string.isRequired,
  details: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isVisible: PropTypes.bool,
  onClickClose: PropTypes.func.isRequired,
};
WarningMessage.defaultProps = {
  details: '',
  isVisible: true,
};
export default React.memo(WarningMessage);
