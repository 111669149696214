import React from 'react';
import { useNavigationLinks } from 'hooks';
import Layout from './Layout';

function Logout() {
  const { goTo } = useNavigationLinks();
  const onClickLanding = () => goTo('landing');

  return (
    <Layout>
      <div className="container d-flex">
        <div className="row col-12 mt-4 mb-3 card">
          <div className="card-body">
            <h4 className="card-title text-center">User successfully logged out!</h4>
          </div>
          <div className="text-center pb-3">
            <button type="button" onClick={onClickLanding} className="btn btn-primary btn-lg">
              Go To Main
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default React.memo(Logout);
