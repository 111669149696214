/* eslint-disable prettier/prettier */
/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

const useAnytimeValidation = () => {
  const validateAnytimeSchema = yup.object().shape({
    isMGTApprovalRequired: yup.boolean(),
    isCustomApprovalRequired: yup.boolean(),
    formOfRecognition: yup.number().positive().integer(),
    mgtOfficers: yup
      .array()
      .when("isMGTApprovalRequired", {
        is: true,
        then: yup.array().min(1, "Must select a management officer.")      }),
      
    approver1: yup.object()
      .when("isCustomApprovalRequired", {
        is: true,
        then: yup.object().shape({
          employeeId: yup.number()
            .required("Must select approver 1.")
            .positive(),
          name: yup.string()
        })
      }),

    approver2: yup.object().nullable(),
    approver3: yup.object().nullable(),  

    financialOfficers: yup
      .array()
      .when("formOfRecognition", {
        is: 1,
        then: yup.array().min(1, "Must select a financial officer.")
      }),
    financialAnalysts: yup
      .array()
      .when("formOfRecognition", {
        is: 1,
        then: yup.array().min(1, "Must select a financial analyst.")
      }),
    timeKeepers: yup.array()
      .of(
        yup.object().shape({
          employeeId: yup.number()
            .required()
            .positive()
            .label("EmployeeId"),
          name: yup.string()
            .required()
            .label("Name"),
        })
      )
      .when("formOfRecognition", {
        is: 2,
        then: yup.array()
        .of(
          yup.object().shape({
            employeeId: yup.number()
              .positive()
              .label("EmployeeId"),
            name: yup.string()
              .label("Name"),
          })
        )
        .required()
        .min(1, "Must select time keeper."),
      }),
  });

  const validate = async (requestData) => {
    const validationResult = {
      isValid: true,
      errors: [],
    };

    await validateAnytimeSchema
      .validate(requestData, { abortEarly: false })
      .then((value) => {
        console.log('validated value: ', value);
      })
      .catch((err) => {
        validationResult.isValid = false;
        validationResult.errors = err.errors;
      });
    return validationResult;
  };

  return { validate };
};

export default useAnytimeValidation;
