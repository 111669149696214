import * as yup from 'yup';

const useEmployeeValidation = () => {
  const validateEmployeeSchema = yup.object().shape({
    name: yup.string().required('Name is required.'),        
    // bureauId: yup.number().nullable().positive('Bureau is required.').integer(),
    // postId: yup.number().nullable().positive('Post is required.').integer(),
    email: yup.string().email('Invalid email format'),
  });
  const validate = async (fullEmployee) => {
    const validationResult = {
      isValid: true,
      errors: [],
    };

    await validateEmployeeSchema
      .validate(fullEmployee, { abortEarly: false })
      .then((value) => {
        console.log('validated value: ', value);
      })
      .catch((err) => {
        validationResult.isValid = false;
        validationResult.errors = err.errors;
      });
    return validationResult;
  };

  return { validate };
};

export default useEmployeeValidation;
