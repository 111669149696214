import useApi from '../useApi';

const useEmployeeService = () => {
  const api = useApi();

  const getEmployeeRoles = async (id) => api.get(`/employee/GetEmployeeRoles/${id}`);

  const getFullEmployee = async (employeeId, missionId) =>
    api.get(
      missionId
        ? `/employee/GetFullEmployeeAndFormDataById/${employeeId}/${missionId}`
        : `/employee/GetFullEmployeeAndFormDataById/${employeeId}`
    );

  const getEmployeesBySupervisorId = async (supervisorId) =>
    api.get(`/employee/GetEmployeesBySupervisorId/${supervisorId}`);

  const save = async (requestData) => api.post('/employee/Save', requestData);

  const deleteEmployee = async (employeeId) => await api.delete(`/employee/Delete/${employeeId}`);

  const saveSupervisorForEmployees = async (requestData) =>
    api.post(`/employee/SaveSupervisorForEmployees`, requestData);

  const saveOrganizationRoles = async (requestData) => api.post('/employee/assignOrganizationRoles', requestData);

  const saveRoles = async (requestData) => api.post('/employee/assignRoles', requestData);

  const addRoles = async (requestData) => api.post('/employee/addRoles', requestData);

  const listOktaUserByEmail = async (email) => api.get(`/user/ListOktaUserByEmail/${encodeURI(email)}`);

  const newUserRecordFromOktaRecord = (oktaRecord) => ({
    email: oktaRecord.email,
    name: `${oktaRecord.lastName}, ${oktaRecord.firstName}${oktaRecord.middleName ? ` ${oktaRecord.middleName}.` : ''}`,
    positionTitle: oktaRecord.title || '',
    openNetId: oktaRecord.login,
  });

  const getMissions = async () => api.get(`/employee/GetMissions`);

  const assignProxy = async (assignData) => await api.post(`/employee/assignProxy/`, assignData);
  const removeProxy = async (employeeId) => await api.put(`/employee/removeProxy/${employeeId}`);

  // bubble_404 will be used in the api.js to ignore 404 (not found) errors and skip redirection to error page
  const getEmployeeByLogin = async (login) =>
    await api.get(`/employee/GetEmployeeByLogin/${login}`, {}, { headers: { bubble_404: true } });

  return {
    getFullEmployee,
    getEmployeesBySupervisorId,
    getEmployeeRoles,
    save,
    deleteEmployee,
    saveSupervisorForEmployees,
    saveOrganizationRoles,
    saveRoles,
    addRoles,
    listOktaUserByEmail,
    newUserRecordFromOktaRecord,
    getEmployeeByLogin,
    getMissions,
    assignProxy,
    removeProxy,
  };
};

export default useEmployeeService;
