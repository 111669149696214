import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import adgLogo from 'assets/images/ADG01.png';
import Layout from './Layout';

function Landing() {
  const history = useHistory();
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      history.push('/Home');
    }
  });

  const onClickLogin = () => {
    sessionStorage.setItem('privacyStatementRead', true);
    oktaAuth.signInWithRedirect();
  };

  const showPrivacyStatement = !sessionStorage.getItem('privacyStatementRead');

  function renderPrivacyStatement() {
    return (
      <div className="card-body pb-0">
        <h4 className="card-title text-center">
          <img className="card-img-top w-auto max-h-70px" src={adgLogo} alt="ADG" />
        </h4>
        <h5>System Use Notice</h5>
        <p className="card-text text-justify">
          You are about to enter a Department of State computer system or network. Use by unauthorized persons, or for
          unauthorized personal business, is prohibited and may constitute a violation of 18 U.S.C. 1030 and other
          Federal law, as well as applicable policies and procedures.
        </p>
        <p>
          You have&nbsp;
          <b>NO REASONABLE EXPECTATION OF PRIVACY</b>
          &nbsp;while using this computer system or network. All data contained and/or herein may be monitored,
          intercepted, recorded, read, copied, or captured in any any manner by authorized personnel. System management
          personnel or supervisors may give law enforcement officials or appropriate Department managers any potential
          evidence of of crime, fraud, or employee misconduct found on this computer system or network, and employees
          may be subject to discipline for misuse. Furthermore, law enforcement officials may be authorized to access
          and collect evidence from this computer system or network, or from any portable devices that have been
          connected to this computer system system or network, therefore:
          <br />
          USE OF THIS APPLICATION BY ANY USER, AUTHORIZED OR UNAUTHORIZED, CONSTITUTES EXPRESS CONSENT TO THIS
          MONITORING.
        </p>
      </div>
    );
  }

  function renderStartMessage() {
    return (
      <div className="card-body">
        <h4 className="card-title text-center">Landing Page - Session timed out or not started</h4>
      </div>
    );
  }

  return (
    <Layout>
      <div className="container d-flex">
        <div className="row col-12 mt-4 mb-3 card">
          {showPrivacyStatement && renderPrivacyStatement()}
          {!showPrivacyStatement && renderStartMessage()}
          <div className="text-center pb-3">
            <button type="button" onClick={onClickLogin} className="btn btn-primary btn-lg">
              Login
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default React.memo(Landing);
