import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup } from 'reactstrap';
import { Button as PrimeButton } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTachometerAltFast,
  faConciergeBell,
  faUsers,
  faMedal,
  faFlask,
  faBiohazard,
  faEllipsisH,
  faShippingFast,
  faMegaphone,
} from '@fortawesome/pro-light-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

import { processType } from 'constants/index';
import { NominationFormContext, NotificationContext } from 'contexts';

library.add(faTachometerAltFast, faConciergeBell, faUsers, faMedal, faFlask, faBiohazard, faEllipsisH);
const noCriteriaSelected = 'Please select the type on the left to see a the description of the criteria.';

function ReasonAndTypeModal() {
  const { formStore, helperFunctions } = useContext(NominationFormContext);
  const { showAlertMessage } = useContext(NotificationContext);
  const { awardReasons } = formStore.nomination;
  const [typeFilter, setTypeFilter] = useState(processType.ANYTIME);

  const getSelectedType = useCallback(
    () => formStore.types.find((item) => item.id === formStore.nomination.awardTypeId),
    [formStore.nomination.awardTypeId, formStore.types]
  );

  const getCriteriaTitle = useCallback(
    () => (getSelectedType() ? getSelectedType().name : 'Criteria'),
    [getSelectedType]
  );

  const getCriteriaText = useCallback(
    () => ({
      __html: getSelectedType() ? getSelectedType().criteria : noCriteriaSelected,
    }),
    [getSelectedType]
  );

  const isOtherReasonSelected = useCallback(
    () => awardReasons.includes(formStore.reasons.length ? formStore.reasons.find((item) => item.isOtherReason).id : 0),
    [awardReasons, formStore.reasons]
  );

  const onClickOk = () => {
    if (
      helperFunctions.isReviewMode() &&
      formStore.nomination.awardProcessType !== formStore.nomination.loadedAwardProcessType
    ) {
      showAlertMessage(
        'You have changed the Award Type with a different process flow. You need to save the nomination in order to get the proper actions for this nomination',
        'Warning',
        faExclamationTriangle
      );
    }
    if (!isOtherReasonSelected()) {
      formStore.nomination.otherReason = '';
    }
    helperFunctions.onClickOkReasonAndTypeModal();
  };

  useEffect(() => {
    if (getSelectedType() != null) {
      setTypeFilter(getSelectedType().awardProcessType);
    }
  }, [getSelectedType]);

  return (
    <Modal isOpen={formStore.isReasonAndTypeModalOpened} toggle={helperFunctions.onClickCancel} size="lg">
      <ModalHeader toggle={helperFunctions.onClickCancel}>Reason and Type</ModalHeader>
      <ModalBody>
        <div className="float-none w-100">
          <div className="row">
            <h6 className="ml-3">Reason for Award (select one or more)</h6>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              {formStore.reasons.map((item) => (
                <div key={item.id} className="text-center">
                  <Button
                    className="btn-reason"
                    color={awardReasons.includes(item.id) ? 'primary' : 'outline-secondary'}
                    onClick={() => helperFunctions.onClickToggleAwardReason(item)}
                  >
                    <div className="d-block">
                      <FontAwesomeIcon size="2x" icon={item.iconClass && item.iconClass.split(' ')} />
                    </div>
                    {item.name}
                  </Button>
                </div>
              ))}
            </div>
          </div>

          <div className={`anim-transition other-reason-input mt-2 ${isOtherReasonSelected() ? '' : 'fade-in'}`}>
            <div className="col-8 offset-4 d-flex flex-row justify-content-end pr-1">
              <Label for="otherReason" className="mt-1">
                Please specify other reason:
              </Label>
              <input
                id="otherReason"
                type="text"
                className="form-control w-50 ml-2"
                value={formStore.nomination.otherReason || ''}
                onChange={(e) => helperFunctions.onChangeOtherReason(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="float-none w-100 mt-1">
          <div className="row">
            <h6 className="ml-3">Type of Award Recommended</h6>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <div className="row ml-1">
                <ButtonGroup className="w-100">
                  <Button
                    className="w-100"
                    color={typeFilter === processType.ANYTIME ? 'primary' : 'outline-secondary'}
                    onClick={() => setTypeFilter(processType.ANYTIME)}
                  >
                    <FontAwesomeIcon icon={faShippingFast} fixedWidth className="mr-2" />
                    Anytime
                  </Button>
                  <Button
                    className="w-100"
                    color={typeFilter === processType.ANNUAL ? 'primary' : 'outline-secondary'}
                    onClick={() => setTypeFilter(processType.ANNUAL)}
                  >
                    <FontAwesomeIcon icon={faMegaphone} fixedWidth className="mr-2" />
                    Annual
                  </Button>
                </ButtonGroup>
              </div>
              <div className="row mt-3 ml-1">
                <div className="types-list w-100">
                  {formStore.types
                    .filter((item) => item.awardProcessType === typeFilter)
                    .map((item, idx) => (
                      <div key={item.id} className={idx + 1 < formStore.types.length ? 'mb-2' : ''}>
                        <Button
                          className="w-100"
                          color={item.id === formStore.nomination.awardTypeId ? 'primary' : 'outline-secondary'}
                          onClick={() => helperFunctions.onClickToggleAwardType(item)}
                        >
                          {item.name}
                        </Button>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-6 h-100">
              <div className="card py-2 pl-2 mb-0">
                <div className="font-weight-bold mb-2 text-center">{getCriteriaTitle()}</div>
                <div className="criteria-text">
                  <div dangerouslySetInnerHTML={getCriteriaText()} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <PrimeButton
          label="Ok"
          icon="pi pi-check"
          onClick={() => onClickOk()}
          className="p-button-primary min-w-150px"
          disabled={formStore.nomination.awardReasons.length <= 0 || !formStore.nomination.awardTypeId}
        />

        <PrimeButton
          label="Cancel"
          icon="pi pi-times-circle"
          onClick={() => helperFunctions.onClickCancelReasonAndTypeModal()}
          className="p-button-secondary min-w-150px"
        />
      </ModalFooter>
    </Modal>
  );
}
export default ReasonAndTypeModal;
