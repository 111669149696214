import React, { useReducer, useEffect, useCallback, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/pro-light-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { approvalStatus, requestStatus } from 'constants/index';
import { useNominationService } from 'hooks/nomination';
import { formatDate } from 'utils/dateHelpers';
import stateSeal from 'assets/images/us-department-of-state-1.svg';
import PropTypes from 'prop-types';
import { nominationStatus, formOfRecognition } from '../../../constants/index';
import FormBlock from './FormBlock';
import './Form.css';


const initState = {
  requestStatus: requestStatus.REQUEST_NOT_INITIATED,
  submissionDate: null,
  approvalDate: null,
  finalApprovedDate: null,
  chairApprovalDate: null,
  chairName: null,
  bureauChairApprovalDate: null,
  bureauChairName: '',  
  nominatorName: '',
  nominatorTitle: '',
  nominatorAgency: '',
  nominationKey: '',
  missionName: '',
  postName: '',
  proposedAmount: '',
  jcacApprovedAmount: '',
  approvedAmount: '',
  finalApproverName: '',
  awardReasons: [],
  formOfRecognition: '',
  postBugetOfficer: '',
  justification: '',
  citation: '',
  nominationEmployees: [],
  otherReason: null,
  execApprovalDate: null,
  execOfficerName: null,
  financeOfficerName: null,
  chairTitle: null,
  execOfficerTitle: null,
  financeOfficerTitle: null,
  jcacApprovalStatus: null,
  execApprovalStatus: null,
  bureauApprovalStatus: null,
  bureauChairRemark: null,
  execRemark: null
};

const awardReasonValues = [
  [
    'Performance',
    'Special Act'
  ],
  [
    'Customer Service',
    'Innovation',
  ],
  [
    'Teamwork',
    'Crisis Management',
  ],
  [
    'Other',
  ],  
]

const typeOfAwardRecommended = [
  [
    "The Secretary's Award",
    'Award for Heroism',
    "Secretary's Career Achievement Award",
    "Distinguished Honor Award",
  ],
  [
    "Superior Honor Award",
    "Meritorious Honor Award",
    "Franklin Award",
    "Foreign Affairs Award for Public Service",
  ],
  [
    "Time Off From Duty Award",
    "Cash",
    "Other",
  ],
];

const approvals = [
  'Approved',
  'Disapproved',
];

const reducer = (state, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, requestStatus: requestStatus.REQUEST_IN_PROGRESS };

    case 'LOAD_DATA':
      return {
        ...state,
        ...action.data,
        requestStatus: requestStatus.REQUEST_COMPLETED,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
// eslint-disable-next-line react/display-name
const DS66 = React.forwardRef(({ nominationId }, ref) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const service = useNominationService();
  const hiddenDivRef = useRef(null);
  const [firstPartJustification, setFirstPartJustification] = useState('');
  const [secondPartsJustification, setSecondPartsJustification] = useState([]);

  const emptyLineRegex = /(<p>&nbsp;<\/p>\s*)+$/;
    // check for combined citation and justification
 const citationAndJustification = state.citation?.replace(emptyLineRegex, '').concat("<br /><br />").concat(state.justification?.replace(emptyLineRegex, ''));


  useEffect(() => {
    if(citationAndJustification !== ''){   
     
    // Set the innerHTML of the hidden div
    setFirstPartJustification(citationAndJustification);   
    const hiddenDiv = hiddenDivRef.current;

    if (hiddenDiv) {
      const measurementDiv = document.createElement('div');
      hiddenDiv.appendChild(measurementDiv);
      measurementDiv.style.visibility = 'hidden';
      measurementDiv.style.width = '100%';

    let height = 0;
    let splitIndex = 0;

    // Split the content into words for more precise control
    const words = citationAndJustification.split(/(\s+|\n)/);
    let accumulatedWords = [];


      // Find the point where the height exceeds 650px    
      /* eslint-disable-next-line no-plusplus */
     for (let i = 0; i < words.length; i++) {
      accumulatedWords.push(words[i]);
      measurementDiv.innerHTML = accumulatedWords.join(' ');
      height = measurementDiv.scrollHeight;
      if (height > 620) {
        splitIndex = i;
        break;
      }
    }   

    if(splitIndex > 0){    
        // Set the first part of the content
        setFirstPartJustification(words.slice(0, splitIndex).join(' '));
        let remainingWords = words.slice(splitIndex);       
        const secondPartsTemp = [];

        while (remainingWords.length > 0) {
          accumulatedWords = [];
          measurementDiv.innerHTML = '';
          
          /* eslint-disable-next-line no-plusplus */
          for (let i = 0; i < remainingWords.length; i++) {
            accumulatedWords.push(remainingWords[i]);
            measurementDiv.innerHTML = accumulatedWords.join('');
            height = measurementDiv.scrollHeight;
            if (height > 1200) {
              secondPartsTemp.push(accumulatedWords.slice(0, -1).join(''));
              remainingWords = remainingWords.slice(i);
              break;
            }
            if (i === remainingWords.length - 1) {
              secondPartsTemp.push(accumulatedWords.join(''));
              remainingWords = [];
            }
          } 
        }

          // Update the state with the split parts
          setSecondPartsJustification(secondPartsTemp);

          // Clean up the temporary div
          hiddenDiv.removeChild(measurementDiv);
        }
      }
    }
  }, [citationAndJustification]);   

  useEffect(() => {
    const loadData = async () => {
      dispatch({ type: 'START_LOADING' });
      const response = await service.getFormData(nominationId);
      if (response && response.ok) {        
        dispatch({ type: 'LOAD_DATA', data: response.data });
      }
    };
    if (state.requestStatus === requestStatus.REQUEST_NOT_INITIATED) {
      loadData();
    }
  }, [nominationId, service, state.requestStatus]);

  const GreyHeaderRow = useCallback((text) => {
    return (
      <div className="row ">
        <div className="col-12 center-block text-center bg-light pt-0 px-0 border-b align-items-center">
          <p className="h6 pt-2">{text}</p>          
        </div>
      </div>
    );
  }, []);

  const GreyHeaderRowWithItalic = useCallback((text, italicText) => {
    return (
      <div className="row">
        <div className="col-12 bg-light pt-0 px-0 border-b d-flex align-items-center m-0">
          <p className="h6 mb-0 pl-2">{text}</p>
          {italicText && <i className="pl-2">{italicText}</i>}
        </div>
      </div>
    );
  }, []);
  

  const getUnitSymbol = (option) => {
    if (!option) return '';
    return option === formOfRecognition.CASH_ONLY ? 'USD' : 'Hours';
  };

  const renderCheckbox = (field, text) => {
    let values = [];

    if(field === 'awardReasons'){
      values = state.awardReasons.map((item) => item.awardReasonName?.toLowerCase());
    }    

    if(field === 'awardTypeName'){      
      const awardType = typeOfAwardRecommended.flat().map(x => x.toLowerCase()).includes(state.awardTypeName.toLowerCase()) ? state.awardTypeName : 'other';
      values = [awardType.toLowerCase()];
    }

    if(field === 'jcacApproval'){
      values = [state.jcacApprovalStatus?.toLowerCase()];
    }

    if(field === 'execApproval'){
      values = [state.execApprovalStatus?.toLowerCase()];
    }

    if(field === 'bureauApproval'){
      values = [state.bureauApprovalStatus?.toLowerCase()];
    }

    if(text === undefined) return null;

    return (
     <>       
        <FontAwesomeIcon
          icon={
            values.includes(text.toLowerCase()) ? faCheckSquare: faSquare
          }
          size="lg"
        />{' '}{text}   
    </>
    );    
  };

  const renderFiscalStrips = () => {
    return (
      <>
        {state.nominationEmployees.map((nominee) => (
          <div className="row border-dotted-b" key={nominee.fiscalStrip}>
            <p className="mb-1 col-6">{nominee.name}</p>
            <p className="mb-1 col-6 text-break"> {nominee.fiscalStrip}</p>
          </div>
        ))}
      </>
    );
  };

  const renderAwardReasons = () => {
    return (
      <>
        {state.awardReasons.map((item) => (
          <>
            {/* <FontAwesomeIcon className="pr-1" icon={faCheckSquare} size="lg" /> */}
            <span className="pr-2" key={item.id}>
              {item.awardReasonName === 'Other' && state.otherReason
                ? state.otherReason
                : item.awardReasonName}{' '}
            </span>
          </>
        ))}
      </>
    );
  };
  
  const renderAwardTypes = () => {
    return (
      <>
        {/* <FontAwesomeIcon className="pr-1" icon={faCheckSquare} size="lg" /> */}
        <span className="pr-2">{state.awardTypeName}</span>
      </>
    );
  };

  const typeOfAward = () => {
    const individualOrGroup = state.nominationEmployees.length;
    if (individualOrGroup > 1) {
      return <p>Group</p>;
    }
    return <p>Individual</p>;
  };

  // const displayPartVIIStatus = () => {
  //   return
  //     // <span className="pr-2">line1:
  //     //   <FontAwesomeIcon icon="faSquare" size="lg" />
  //     //   line2:
  //     //   <FontAwesomeIcon icon="faSquare" size="lg" />
  //     //   </span>
  //     <>hi</>;

  // };

  const displayNominationStatus = () => {
    let returnStatus = '';
    switch (state.nominationStatus) {
      case nominationStatus.DISAPPROVED:
        returnStatus = 'DISAPPROVED';
        break;
      case nominationStatus.DISAPPROVED_BY_SUPERVISOR:
        returnStatus = 'DISAPPROVED';
        break;
      case nominationStatus.DELETED:
        returnStatus = 'DISAPPROVED';
        break;
      default:
        returnStatus = 'APPROVED';
    }
    return returnStatus;
  };

  const renderSupervisors = () => {
  
    const approvingSupervisors = state.nominationEmployees
      .filter(x => x.approvalStatus === approvalStatus.APPROVED);

    const uniqueSupervisors = approvingSupervisors.filter(x => x.usdhSupervisorId !== state.nominatorId).map(
      (item) => item?.usdhSupervisorName
    );;

    if(uniqueSupervisors.length === 0) return '';
    return (
      <>
        {uniqueSupervisors
          .filter((q, idx) => uniqueSupervisors.indexOf(q) === idx)
          .map((item) => (
            <span className="pr-2" key={item?.uSDHSupervisorId}>
              {`/s/ ${item}`}
              {'; '}
            </span>
          ))}
      </>
    );
  };

  const checkAgency =() =>
  {
    const isState = state.nominationEmployees.filter(x => x.approvalStatus === approvalStatus.APPROVED).some(obj => obj?.agencyCode?.includes('ST')) 
    const isUsaid = state.nominationEmployees.filter(x => x.approvalStatus === approvalStatus.APPROVED).some(obj => obj?.agencyCode?.includes('AM'))
    return { isState,  isUsaid}
  } 

  const renderIndividualFiscalStrip = () => {
    const approvedNominees = state.nominationEmployees.filter(x => x.approvalStatus === approvalStatus.APPROVED); 
    const fiscalStrip = approvedNominees.length === 1 ? `${approvedNominees[0].fiscalStrip} ${state.approvedAmount}` : 'See Below';
    return (    
        <div className="row">
          <div className="col-10 border-r">
            <div className='row'>
            <div className='p-2'>     
              <strong>Accounting Classification</strong> <span>(Completed by Bureau/Post Budget Officer)</span>
             </div>        
             <div className="row p-2">
                <div className='col-1'>
                  <small>Agency</small>
                </div>
                <div className='col-2 text-center'>
                  <small>Appropriation</small>
                </div>
                <div className='col-1 text-center'>
                  <small>Allotment</small>
                </div>
                <div className='col-2 text-center'>
                  <small>Obligation No.</small>
                </div>
                <div className='col-2 text-center'>
                  <small>Org. Code</small>
                </div>
                <div className='col-1 text-center'>
                  <small>Function</small>
                </div>
                <div className='col-1 text-center'>
                  <small>Object</small>
                </div>
                <div className='col-2 text-center'>
                  <small>Award Amount</small>
                </div>                
              </div>
            </div>
            <div className='text-break'>            
            {fiscalStrip}
            </div>
          </div>
          <div className="col-2 text-center">
              <div className=' text-center bg-light row border-b '>
              <div className="col-12 center-block text-center">
                <strong>For Gift Cheque Use Only</strong>
              </div>          
              </div>
              <div className=' row border-b '>
              <div className="col-6 center-block text-center border-r" style={{height:'100px'}}>
                  Obligation
                </div>
                <div className="col-6 center-block text-center">
                  Net
                </div>
              </div>
          </div>
        </div>   
    );
  };

  return (
    <>
    {state.requestStatus === requestStatus.REQUEST_IN_PROGRESS && (
      <div className="text-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )}
    {state.requestStatus === requestStatus.REQUEST_COMPLETED && (

    <div ref={ref} key={state.nominationKey} className="px-3">
      <div className="container-fluid mb-0 pt-0 pb-0 printable-form">
        {GreyHeaderRow('FOR OFFICE USE')}
        <div className="row border-b">
          <div className="col-2 border-r">
            <FormBlock
              Rows={[
                {
                  line1: <><span>State</span>
                  <FontAwesomeIcon
                  icon={
                    checkAgency().isState
                      ? faCheckSquare
                      : faSquare
                  }
                  size="lg"
                />
                  <span className='ml-2'>USAID</span>
                  <FontAwesomeIcon
                  icon={
                    checkAgency().isUsaid
                      ? faCheckSquare
                      : faSquare
                  }
                  size="lg"
                /></>,
                },
              ]}
            />
          </div>
          <div className="col-5 border-r">
            <FormBlock
              Rows={[
                {
                  line1: <span>Date Received by Awards Office (mm-dd-yyyy)</span>,
                  line2: formatDate(state.usdhApprovalDate),
                },
              ]}
            />
          </div>          
          <div className="col-5">
            <FormBlock
              Rows={[
                {
                  line1: 'Date Related to Personnel Records (mm-dd-yyyy)',
                },
              ]}
            />
          </div>
        </div>
        <div className="row border-b">
          <div className="col-12">
            <div className="pl-2 pt-2 pb-2 text-center">
              <div className=" float-left position-absolute">
                <img
                  className="usseal"
                  src={stateSeal}
                  alt="US Department of State"
                />
              </div>
            </div>
            <div className="text-center font-arial">
                U.S. Department of State
            </div>        
            <div className="pl-2 text-center h2 font-weight-bold font-arial">
              NOMINATION FOR AWARD
            </div>
          </div>
        </div>
        {GreyHeaderRow('Part I - NOMINATION ')}
        <div className="row border-b">
          <div className="col-6 border-r py-1 mb-0">
          <FormBlock
              Rows={[
                {
                  line1: 'Name of Nominee (Last, First, MI)',
                  line2: state.nominationEmployees?.length === 1 ? state.nominationEmployees[0]?.name : state.nominationKey,
                },
              ]}
            />                        
          </div>
          
          <div className="col-3 border-r py-1 mb-0">
          <FormBlock
              Rows={[
                {
                  line1: 'Social Security Number',
                  line2: !state.isGroup ? state.nominationEmployees[0]?.payrollId : ''
                },
              ]}
            /> </div>
          <div className="col-3 py-1 mb-0">

          <FormBlock
              Rows={[
                {
                  line1: 'ORG Symbol or Post',
                  line2: !state.isGroup ? state.nominationEmployees[0]?.postName : '',
                },
              ]}
            /> 
          </div>
        </div>

        <div className="row border-b">
          <div className="col-6 border-r py-1 mb-0">
          <FormBlock
              Rows={[
                {
                  line1: 'Present Position Title and Grade',
                  line2: !state.isGroup ? `${state.nominationEmployees[0]?.positionTitle},  ${state.nominationEmployees[0]?.grade ?? ''}`  : '',
                },
              ]}
            />                        
          </div>    
          <div className="col-6 py-1 mb-0">
          <FormBlock
              Rows={[
                {
                  line1: 'Position held during period covered by nomination if different than present',
                  line2: '',
                },
              ]}
            />             
          </div>            
        </div>
        
        {GreyHeaderRow('REASON FOR AWARD')}
        <div className="row border-b">
          {awardReasonValues.map((item, idx) => (
            <div className="col-3">
              <FormBlock
                Rows={[
                  {
                    line1: <span>{renderCheckbox('awardReasons', item[0])}</span>,
                    line2: <span>{renderCheckbox('awardReasons', item[1])}</span>,
                  },
                ]}
              />
            </div>
          ))}
        </div>
        {GreyHeaderRow('TYPE OF AWARD RECOMMENDED')}
        <div className="row border-b">
          {typeOfAwardRecommended.map((item, idx) => (
            <div className="col-4">
              <FormBlock
                Rows={[
                  {
                    line1: <span>{renderCheckbox('awardTypeName', item[0])}</span>,
                    line2: <span>{renderCheckbox('awardTypeName', item[1])}</span>,
                    line3: <span>{renderCheckbox('awardTypeName', item[2])}</span>,
                    line4: <span>{renderCheckbox('awardTypeName', item[3])}</span>,
                  },
                ]}
              />
            </div>
          ))}
        </div>

        <div className="row border-b">
          <div className="col-6 border-r">
            <FormBlock
              Rows={[
                {
                  line1: 'Recommended Amount (Cash/Time Off Hours)',
                  line2: state.proposedAmount,
                },
              ]}
            />
          </div>
          <div className="col-3 bg-light text-center border-l">
            <FormBlock
              Rows={[
                {
                  line1: <span className='font-weight-bold'>Approved Amount</span>,
                  line2: state.approvedAmount
                },
              ]}
            />
          </div>
          <div className="col-3 bg-light text-center">
            <FormBlock
              Rows={[
                {
                  line1: <span className='font-weight-bold'>Approved Award</span>,
                  line2: state.awardTypeName
                },
              ]}
            />
          </div>
        </div>

        <div className="row border-b">
          <div className="col-12" style={{height:"650px"}}>
            <FormBlock
              Rows={[
                {
                  line1: 'Citation: ',
                  line2: (
                    <div 
                      dangerouslySetInnerHTML={{ __html: firstPartJustification }}
                    />
                  ),                 
                },
              ]}
            />
          </div>
        </div>        
        <div className="row border-b">
          <div className="col-10 border-r">
            <FormBlock
              Rows={[
                {
                  line1: 'Nominated By (Name, Title, Signature)',
                  line2: `${state.nominatorName}  -  ${state.nominatorTitle ?? 'N/A'}`,
                },
              ]}
            />
          </div>
          <div className="col-2 text-right">
            <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                  line2: formatDate(state.submissionDate),
                },
              ]}
            />
          </div>
        </div>
        <div className="row border-b">
          <div className="col-10 border-r">
            <FormBlock
              Rows={[
                {
                  line1:
                    'Approved By (Supervisor’s Full Name, Title).  Applicable only if nominated by other than supervisor.',
                  line2: <span>{renderSupervisors()}</span>,
                },
              ]}
            />
          </div>
          <div className="col-2 text-right">
            <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                  line2: formatDate(state.usdhApprovalDate),
                },
              ]}
            />
          </div>      
        </div>
        </div>     
           { secondPartsJustification.length > 0 && 
               secondPartsJustification.map((part, index) => 
            <div key={uuidv4()} className="container-fluid mb-0 pt-0 pb-0 printable-form page-break" style={{height: '98vh'}}>                
            {GreyHeaderRowWithItalic('Justification for Award Continuation Sheet', 'Include a concise citation to be used on the award certificate.')} 
            <div className="row "> 
              <div className='col-12'>                    
                <FormBlock
                  Rows={[
                    {
                      line1: (
                        <div style={{pageBreakAfter: 'always'}}
                          dangerouslySetInnerHTML={{ __html: part }}
                        />
                      ),
                    },
                  ]}
                /> 
              </div>
            </div>
            </div>        
           )
          }
      
        <div className="container-fluid mb-0 pt-0 pb-0 printable-form page-break">
        {GreyHeaderRow(
          'PART II – ACTION TAKEN/TIME OFF FROM DUTY AWARD – Optional – for period not to exceed one work day.'
        )}

        <div className="row border-b">
          <div className="col-7 border-r px-0">
            <div className="border-b">
              <div className="col-12">
                <FormBlock
                  Rows={[
                    {
                      line1: 'Bureau/Post Approval (Name, Title)',
                    },
                  ]}
                />
              </div>
            </div>
            <div>
              <div className="col-12">
                <FormBlock
                  Rows={[
                    {
                      line1: 'Bureau/Post Approval Signature',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="col-5">
            <span>Date (mm/dd/yyyy)</span>
          </div>
        </div>

    {GreyHeaderRow(
      'PART III - ACTION TAKEN BY JOINT COUNTRY AWARDS COMMITTEE'
    )}

    <div className="row border-b">
      <div className="col-6 border-r">
        <div className="row border-b">
          <div className="col-6 border-r px-0">      
           {
            approvals.map((item, idx) => (
              <div className="pl-2 " key={item}>
                <FormBlock
                  Rows={[
                    {
                      line1: <span className=''>{renderCheckbox('jcacApproval', item)} </span>,
                    },
                  ]}
                />
              </div>
            ))
          }    
          </div>
          <div className="col-6">            
            <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                  line2: formatDate(state.chairApprovalDate),
                },
              ]}
            />
          </div>
        </div>        
          <div className="row border-b pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Cash Awards Only - Approved Amount',
                  line2: state.jcacApprovedAmount
                },
              ]}
            />
          </div>
          <div className="row  pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Typed Name of Committee Chairperson',
                  line2: state.chairName
                },
              ]}
            />
          </div>        
          </div>
          <div className="col-6">
            <strong>Remarks</strong>
              <p>{`JCAC ${state.jcacApprovalStatus?.toLowerCase()} ${ getUnitSymbol(state.formOfRecognition)} ${state.jcacApprovedAmount}`}</p> 
          </div>
    </div>
        <div className="row border-b">          
              <div className="pl-2 ">
                <FormBlock
                  Rows={[
                    {
                      line1: 'Signature of Committee Chairperson',
                      line2: state.chairName ? `/s/ ${state.chairName}` : '',
                    },
                  ]}
                />              
          </div>  
        </div>
      {GreyHeaderRow('PART IV - ACTION TAKEN BY CHIEF OF MISSION')}                         
      <div className="row border-b">
      <div className="col-6 border-r">
        <div className="row border-b">
        <div className="col-6 border-r px-0">
        {
            approvals.map((item, idx) => (
              <div className="pl-2 " key={item}>
                <FormBlock
                  Rows={[
                    {
                      line1: <span className=''>{renderCheckbox('execApproval', item)} </span>,
                    },
                  ]}
                />
              </div>
            ))
          }
          </div>
          <div className="col-6">
          <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                  line2: formatDate(state.execApprovalDate),
                },
              ]}
            />
          </div>
        </div>        
          <div className="row border-b pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Cash Awards Only - Approved Amount',
                  line2: state.approvedAmount
                },
              ]}
            />
          </div>
          <div className="row  pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Typed Name of Chief of Mission',
                  line2: state.execOfficerName
                },
              ]}
            />
          </div>        
          </div>
          <div className="col-6">
            <strong>Remarks</strong>
            <div className="d-flex flex-column h-75 ">    
          <div className=''>
            <span>{state.execRemark}</span>
          </div>    
          </div>
          </div>
        </div>

        <div className="row border-b">
              <div className="pl-2 ">
                <FormBlock
                  Rows={[
                    {
                      line1: 'Signature of Chief of Mission',
                      line2:  state.execOfficerName ? `/s/ ${state.execOfficerName}` : '',
                    },
                  ]}
                />
          </div>
        </div>

        {GreyHeaderRow('PART V - ACTION TAKEN BY AREA AWARDS COMMITTEE')}

        <div className="row border-b">
        <div className="col-6 border-r">
        <div className="row border-b">
          <div className="col-6 border-r px-0">          
          {
            approvals.map((item, idx) => (
              <div className="pl-2 " key={item}>
                <FormBlock
                  Rows={[
                    {
                      line1: <span className=''>{renderCheckbox('bureauApproval', item)} </span>,
                    },
                  ]}
                />
              </div>
            ))
          } 
          </div>
          <div className="col-6">
          <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                  line2: formatDate(state.bureauChairApprovalDate),
                },
              ]}
            />
          </div>
        </div>        
          <div className="row border-b pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Cash Awards Only - Approved Amount',
                  line2: state.bureauApprovalStatus?.toLowerCase() === 'approved' && state.approvedAmount
                },
              ]}
            />
          </div>
          <div className="row  pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Typed Name of Committee Chairperson',
                  line2: state.bureauChairName
                },
              ]}
            />
          </div>        
          </div>
          <div className='col-6'>
          <div className=""><strong>Remarks</strong></div>
          <div className="d-flex flex-column h-75 ">
            <div className="mt-auto">              
              <small>Certification: All Committee members reviewing this nomination have attended
              Diversity Awareness Training for awards committee members.</small>
              </div>
          </div>
          </div>
        </div>

        <div className="row border-b">          
          <div className="pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Signature of Committee Chairperson',
                  line2:  state.bureauChairName ? `/s/ ${state.bureauChairName}` : '',
                },
              ]}
            />              
          </div>  
        </div>        

        {GreyHeaderRow(
          'PART VI - ACTION TAKEN BY DEPARTMENT AWARDS COMMITTEE'
        )}

    <div className="row border-b">
      <div className="col-6 border-r">
        <div className="row border-b">
          <div className="col-6 border-r px-0">          
            <div className="pl-2 ">
                <FormBlock
                  Rows={[
                    {
                      line1: <span>{renderCheckbox('APPROVE')}</span>,
                      line2: <span>{renderCheckbox('DISAPPROVE')}</span>,
                    },
                  ]}
                />
              </div>
          </div>
          <div className="col-6">
            <span>Date (mm/dd/yyyy)</span>
          </div>
        </div>        
          <div className="row border-b pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Cash Awards Only - Approved Amount',
                  line2: ''
                },
              ]}
            />
          </div>
          <div className="row  pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Typed Name and Title',
                  line2: ' '
                },
              ]}
            />
          </div>        
          </div>
          <div className="col-6">
            <strong>Remarks</strong>
          </div>
        </div>

        <div className="row border-b">
              <div className="pl-2 ">
                <FormBlock
                  Rows={[
                    {
                      line1: 'Signature',
                    },
                  ]}
                />
          </div>
        </div>
        {GreyHeaderRow('PART VII - FISCAL DATA')}
        <div className="row border-b">
          <div className="col-10 border-r">
            <FormBlock
              Rows={[
                {
                  line1: 'Bureau/Post Budget Officer (Name, Signature)',
                  line2: state.financeOfficerName,
                },
              ]}
            />
          </div>
          <div className="col-2 text-right">
            <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                  line2: formatDate(state.financeApprovalDate),
                },
              ]}
            />
          </div>
        </div>
      {renderIndividualFiscalStrip()}
      {GreyHeaderRow('PART VIII - PAYROLL OFFICE INFORMATION - FOR GIFT CHEQUE USE ONLY')}
      <div className="row border-b">
          <div className="col-10 border-r">
            <FormBlock
              Rows={[
                {
                  line1:
                    'Bureau/Post Awards Officer (Name, Signature)',
                  line2: '',
                },
              ]}
            />
          </div>
          <div className="col-2 text-right">
            <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                  line2: '',
                },
              ]}
            />
          </div>
        </div>
        <div className='border-b row p-2'>
          <strong>Payroll Information </strong> (Completed by FMP)
        </div>
        <div className='row'  style={{height:'60px'}}>
          <div className='col-2 border-r'>
            <small>Gross Amount</small>
          </div>
          <div className='col-2 border-r'>
            <small>Federal Tax Withheld</small>
          </div>
          <div className='col-2 border-r'>
            <small>State Tax Withheld</small>
          </div>
          <div className='col-2 border-r'>
            <small>OASDI Tax Withheld</small>
          </div>
          <div className='col-2 border-r'>
            <small>FHI Tax Withheld</small>
          </div>
          <div className='col-2'>
            <small>Net Amount</small>
          </div>
        </div>
        </div>  
              {state.isGroup && 
         
         <div className="page-break container-fluid mb-0 pt-0 pb-0 printable-form">
          {GreyHeaderRow('NOMINEES')}
            {GreyHeaderRow(  
                <div className="row  px-3">
                <div className="col-3 ">Name</div>
                <div className="col-2 ">Social Security Number</div>
                <div className="col-2 ">Post</div>
                <div className="col-3 ">Title and Grade</div>
                <div className="col-2 ">Previous Title</div>
              </div>
              )
            }
         
            {state.nominationEmployees
              .filter((x) => x.approvalStatus === approvalStatus.APPROVED)
              .map((nominee, idx) => (
                <div
                  className="row border-b "
                  key={nominee.employeeId}
                >
                  <div className="col-3 border-r">
                    <FormBlock
                      Rows={[
                        {
                          line2: nominee.name,
                        },
                      ]}
                    />
                  </div>
                  <div className="col-9">
                    <div className="row">
                      <div className="col-3 border-r">
                        <FormBlock
                          Rows={[
                            {
                              line2: `${nominee.payrollId}`,
                            },
                          ]}
                        />
                      </div>       
                      <div className="col-2 border-r">
                        <FormBlock
                          Rows={[
                            {
                              line2: `${nominee.postName}`,
                            },
                          ]}
                        />
                      </div>
                      <div className="col-4 border-r">
                        <FormBlock
                          Rows={[
                            {
                              line2: `${nominee.positionTitle}, ${nominee.grade ?? ''}`
                            },
                          ]}
                        />
                      </div>
                      <div className="col-3">
                        <FormBlock
                          Rows={[
                            {
                              line2: nominee.previousPositionTitle,
                            },
                          ]}
                        />
                      </div>
                
                  </div>
                  {nominee.fiscalStrip && <div className='row border-top'>                 
                    <span className='m-1 ml-3'>{`Fiscal strip: ${nominee.fiscalStrip}/ $${state.approvedAmount} `}</span>
                      </div>}
                    </div>                        
                </div>
            ))} 
         </div>
            
        } 
            <div className="page-break container-fluid mb-0 pt-0 pb-0 printable-form" ref={hiddenDivRef} style={{visibility: 'hidden' }} />
       
        </div>
    )}
    </>
  );
});

DS66.propTypes = {
  nominationId: PropTypes.number,
};

DS66.defaultProps = {
  nominationId: null,
};

export default DS66;
