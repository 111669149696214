import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { useAuthorization, useNavigationLinks } from 'hooks';
import { ActionButton, Grid, SkeletonTable } from 'components';
import { SessionContext } from 'contexts';
import { requestStatus } from 'constants/index';

function EmployeeRoles({ formStore, helperFunctions }) {
  const { sessionStore } = useContext(SessionContext);
  const { checkRole } = useAuthorization();
  const { goBack, goTo } = useNavigationLinks();

  useEffect(() => {
    if (formStore.loadUserRolesRequest === requestStatus.REQUEST_NOT_INITIATED && formStore.employee.employeeId) {
      helperFunctions.loadUserRoles(formStore.employee.employeeId);
    }
  }, [formStore.employee.employeeId, formStore.loadUserRolesRequest, helperFunctions]);

  const roleTemplate = (rowData) => {
    let spanClass = '';
    if (rowData.missionId === null) {
      spanClass = 'color-text-danger font-weight-bold';
    }
    if (rowData.removed) spanClass += ' font-line-through text-muted';
    if (rowData.id === 0) spanClass += ' font-italic';

    return <span className={spanClass}>{rowData.name}</span>;
  };

  const missionTemplate = (rowData) => {
    let spanClass = '';
    let { missionName } = rowData;
    if (rowData.missionId === null) {
      spanClass = 'color-text-danger font-weight-bold';
      missionName = '(Global)';
    }
    if (rowData.removed) spanClass += ' font-line-through text-muted';
    if (rowData.id === 0) spanClass += ' font-italic';

    return <span className={spanClass}>{missionName}</span>;
  };

  const actionTemplate = (rowData) => {
    const cannotRemoveRole =
      rowData.removed ||
      (!checkRole('system_roles') &&
        (rowData.missionId !== sessionStore.currentMissionId || rowData.key === 'SystemAdmin'));

    return (
      <ActionButton
        className="p-button-text p-button-plain"
        icon={['fas', 'trash']}
        disabled={cannotRemoveRole}
        onClick={() => helperFunctions.onClickRemoveUserRole(rowData.id)}
      />
    );
  };

  const getMissionsByRole = () => {
    if (checkRole('system_roles')) {
      return formStore.missions;
    }
    return formStore.missions.filter((x) => x.id === sessionStore.currentMissionId);
  };

  useEffect(() => {
      if( !checkRole('hr_officer')) {
        if(!checkRole('system_roles')) {
        goTo('not_authorized');
      } 
      };
  }, [checkRole, goTo]);

  return (
    <>
      <h4>
        Assign System or Mission Role for <span className="font-italic">{formStore.employee.name}</span>
      </h4>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-12">
            {formStore.loadUserRolesRequest !== requestStatus.REQUEST_COMPLETED && (
              <SkeletonTable colsSize={2} rowsSize={3} />
            )}
            {formStore.loadUserRolesRequest === requestStatus.REQUEST_COMPLETED && (
              <Grid items={formStore.employee.userRoles} dataKey="employeeId" sortMode="single">
                <Column field="missionName" header="Mission" body={missionTemplate} sortable />
                <Column field="name" header="Role" body={roleTemplate} sortable />
                <Column header="Action" body={actionTemplate} />
              </Grid>
            )}
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-4">
            <Dropdown
              value={formStore.selectedNewRoleMissionId}
              options={getMissionsByRole()}
              optionLabel="name"
              optionValue="id"
              filterBy="name"
              filter
              className="w-100"
              onChange={(e) => helperFunctions.onChangeNewRoleMission(e.value)}
            />
          </div>
          <div className="col-5">
            <Dropdown
              value={formStore.selectedNewRoleKey}
              options={formStore.availableMissionRoles}
              optionLabel="name"
              optionValue="key"
              className="w-100"
              onChange={(e) => helperFunctions.onChangeNewRoleKey(e.value)}
            />
          </div>
          <div className="col-3">
            <ActionButton
              className="p-button-primary"
              onClick={() => helperFunctions.onClickAddUserRole()}
              icon={['fas', 'user-plus']}
              disabled={!(formStore.selectedNewRoleMissionId && formStore.selectedNewRoleKey)}
              text="Add Mission Role"
            />
          </div>
        </div>
        {checkRole('system_roles') && (
          <div className="row pt-2">
            <div className="col-4">
              <ActionButton
                className="p-button-warning"
                onClick={() => helperFunctions.onClickAddSysAdminRole()}
                icon={['fas', 'user-plus']}
                text="Add System Admin Role"
              />
            </div>
          </div>
        )}
        <div className="row pt-2">
          <div className="col-5">
            <Button
              label="Save Changes"
              icon="pi pi-save"
              className="p-button-primary w-150px"
              disabled={!(formStore.employee && formStore.employee.userRolesChanged)}
              onClick={() => helperFunctions.onClickSaveUserRoles()}
            />
            <Button
              label="Close/Cancel"
              icon="pi pi-ban"
              className="p-button-secondary ml-1 w-150px"
              onClick={() => {
                helperFunctions.resetUserRoles();
                goBack();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

EmployeeRoles.propTypes = {
  formStore: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object.isRequired,
};

export default EmployeeRoles;
