import React from 'react';
import PropTypes from 'prop-types';
import { Label, Row, Col, Input, FormGroup } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { useNavigationLinks } from 'hooks';
import { SaveCancel } from 'components';
import { getDateUtcNow, formatUtcDate } from 'utils/dateHelpers';
import WarningMessage from 'views/Shared/WarningMessage';

const getNext3Years = () => {
  const thisDate = getDateUtcNow();
  const startYear = thisDate.getFullYear();
  return [startYear, startYear + 1, startYear + 2];
};

function CycleBasicSettings({
  mode,
  store,
  dispatch,
  onSaveClick,
  onCancelClick,
  isWarningMessageVisible,
  toogleWarningMessage,
}) {
  const showWarning = isWarningMessageVisible;
  const { goTo } = useNavigationLinks();

  const onAwardTypeSettingsClick = () => {
    goTo('awards_settings');
  };

  return (
    <>
      <Row>
        <Col xs="12" sm="9" md="4" lg="3" xl="3">
          <FormGroup>
            <Label for="cycleYear">Cycle Year</Label>
            {mode === 'edit' && <Input type="text" id="cycleYear" readOnly value={store.year} />}
            {mode === 'new' && (
              <Input
                type="select"
                id="cycleYear"
                onChange={(e) => dispatch({ type: 'UPDATE_DATA', data: { year: e.target.value } })}
              >
                <option />
                {getNext3Years().map((year) => (
                  <option key={year}>{year}</option>
                ))}
              </Input>
            )}
          </FormGroup>
        </Col>
        <Col xs="12" sm="9" md="4" lg="3" xl="3">
          <FormGroup>
            <Label for="cycleNumber">Number</Label>
            <Input
              type="number"
              id="cycleNumber"
              readOnly={mode === 'edit'}
              value={store.number}
              onChange={(e) => dispatch({ type: 'UPDATE_DATA', data: { number: e.target.value } })}
            />
          </FormGroup>
        </Col>
        <Col xs="12" sm="12" md="4" lg="6" xl="6">
          <FormGroup>
            <Label for="cycleName">Name</Label>
            <Input
              type="text"
              id="cycleName"
              value={store.name}
              onChange={(e) => dispatch({ type: 'UPDATE_DATA', data: { name: e.target.value } })}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="9" md="4" lg="3" xl="3">
          <FormGroup>
            <Label for="callDate">Call Date</Label>
            <div>
              <Calendar
                id="callDate"
                showIcon
                value={store.callDate && formatUtcDate(store.callDate)}
                onChange={(e) => dispatch({ type: 'UPDATE_DATA', data: { callDate: formatUtcDate(e.target.value) } })}
                className="w-100"
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="9" md="4" lg="3" xl="3">
          <FormGroup>
            <Label for="openingDate">Opening Date</Label>
            <div>
              <Calendar
                id="openingDate"
                showIcon
                value={store.openingDate && formatUtcDate(store.openingDate)}
                onChange={(e) =>
                  dispatch({ type: 'UPDATE_DATA', data: { openingDate: formatUtcDate(e.target.value) } })
                }
                className="w-100"
              />
            </div>
          </FormGroup>
        </Col>
        <Col xs="12" sm="9" md="4" lg="3" xl="3">
          <FormGroup>
            <Label for="closingDate">Closing Date</Label>
            <div>
              <Calendar
                id="closingDate"
                showIcon
                value={store.closingDate && formatUtcDate(store.closingDate)}
                onChange={(e) =>
                  dispatch({ type: 'UPDATE_DATA', data: { closingDate: formatUtcDate(e.target.value) } })
                }
                className="w-100"
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="9" md="4" lg="3" xl="3">
          <FormGroup>
            <Label for="committeeStartDate">JCAC Start Date</Label>
            <div>
              <Calendar
                id="committeeStartDate"
                showIcon
                value={store.committeeStartDate && formatUtcDate(store.committeeStartDate)}
                onChange={(e) =>
                  dispatch({ type: 'UPDATE_DATA', data: { committeeStartDate: formatUtcDate(e.target.value) } })
                }
                className="w-100"
              />
            </div>
          </FormGroup>
        </Col>
        <Col xs="12" sm="9" md="4" lg="3" xl="3">
          <FormGroup>
            <Label for="committeeEndDate">JCAC End Date</Label>
            <div>
              <Calendar
                id="committeeEndDate"
                showIcon
                value={store.committeeEndDate && formatUtcDate(store.committeeEndDate)}
                onChange={(e) =>
                  dispatch({ type: 'UPDATE_DATA', data: { committeeEndDate: formatUtcDate(e.target.value) } })
                }
                className="w-100"
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="9" md="4" lg="3" xl="3">
          <FormGroup>
            <Label for="ceremonies">Ceremony</Label>
            <div>
              <Dropdown
                value={store.ceremonyId}
                options={store.ceremonies}
                optionLabel="name"
                optionValue="id"
                onChange={(e) => dispatch({ type: 'UPDATE_DATA', data: { ceremonyId: e.target.value } })}
                placeholder="Select a Ceremony"
                className="w-100"
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="px-3">
        <div className="form-group col-6">
          {showWarning && (
            <WarningMessage
              message="Form validation failed!"
              details={store.validationResultErrors}
              onClickClose={toogleWarningMessage}
            />
          )}
        </div>
      </Row>
      <Row className="px-3">
        <SaveCancel onCancelClick={onCancelClick} onSaveClick={onSaveClick} />
        <Button
          label="Award Type Settings"
          icon="pi pi-arrow-up-right"
          className="p-button-help mx-1 min-w-150px"
          onClick={() => onAwardTypeSettingsClick()}
        />
      </Row>
    </>
  );
}
CycleBasicSettings.propTypes = {
  mode: PropTypes.string.isRequired,
  store: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  isWarningMessageVisible: PropTypes.bool.isRequired,
  toogleWarningMessage: PropTypes.func.isRequired,
};

export default React.memo(CycleBasicSettings);
