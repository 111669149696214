import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { votingStatus, decisionType, nominationStatus } from 'constants/index';

function DecisionButtons({ nomination, helperFunctions, onClickDecision }) {
  return (
    <>
      {[votingStatus.APPROVED, votingStatus.DISAPPROVED, votingStatus.PENDING_TIE].includes(nomination.votingStatus) &&
        !helperFunctions.isBureauVoting() && (
          <div className="text-center mb-1">
            <Button
              key="change_nomination_btn"
              label="Change"
              icon="pi pi-bolt"
              className="w-100 p-button-sm p-button-success mt-2"
              onClick={helperFunctions.onChangeModalClick}
              disabled={helperFunctions.isDecisionButtonsDisabled()}
            />
          </div>
        )}

      {nomination.isNominationChanged && (
        <div className="text-center mb-1">
          <Button
            label={`${nomination.overrideVoting ? 'Approve & Sign Off' : 'Apply Changes'}`}
            icon={`pi pi-${nomination.overrideVoting ? 'thumbs-up' : 'check'}`}
            className="w-100 p-button-sm p-button-primary"
            onClick={() => {
              onClickDecision(nomination.id, decisionType.ENDORSE);
            }}
          />
        </div>
      )}

      {(!nomination.isNominationChanged || nomination.isNominationChanged === undefined) &&
        !helperFunctions.isBureauVoting() &&
        [votingStatus.APPROVED, votingStatus.DISAPPROVED].includes(nomination.votingStatus) && (
          <div className="text-center mb-1">
            <Button
              label="Endorse & Sign Off"
              icon="pi pi-thumbs-up"
              className="w-100 p-button-sm p-button-primary"
              disabled={helperFunctions.isSignOffMode() && helperFunctions.isDecisionButtonsDisabled()}
              onClick={() =>
                onClickDecision(
                  nomination.id,
                  nomination.votingStatus === votingStatus.APPROVED ? decisionType.ENDORSE : decisionType.DISAPPROVED
                )
              }
            />
          </div>
        )}

      {(!nomination.isNominationChanged || nomination.isNominationChanged === undefined) &&
        ((helperFunctions.isBureauVoting() &&
          nomination.nominationStatus === nominationStatus.PENDING_BUREAU_COMMITTEE_CHAIR) ||
          [votingStatus.PENDING_TIE].includes(nomination.votingStatus)) && (
          <div className="text-center mb-1">
            <Button
              label="Approve & Sign Off"
              icon="pi pi-thumbs-up"
              className="w-100 p-button-sm p-button-success"
              onClick={() => onClickDecision(nomination.id, decisionType.ENDORSE)}
            />
          </div>
        )}
      {(!nomination.isNominationChanged || nomination.isNominationChanged === undefined) &&
        ((helperFunctions.isBureauVoting() &&
          nomination.nominationStatus === nominationStatus.PENDING_BUREAU_COMMITTEE_CHAIR) ||
          [votingStatus.PENDING_TIE].includes(nomination.votingStatus)) && (
          <div className="text-center mb-1">
            <Button
              label="Disapprove & Sign Off"
              icon="pi pi-thumbs-down"
              className="w-100 p-button-sm p-button-danger"
              onClick={() => onClickDecision(nomination.id, decisionType.DISAPPROVED)}
            />
          </div>
        )}
      {(!nomination.isNominationChanged || nomination.isNominationChanged === undefined) &&
        !helperFunctions.isBureauVoting() &&
        [votingStatus.DISAPPROVED, votingStatus.PENDING_TIE].includes(nomination.votingStatus) && (
          <div className="text-center mb-1">
            <Button
              label="Return to HR"
              icon="pi pi-pencil"
              className="w-100 p-button-sm p-button-warning"
              onClick={() => onClickDecision(nomination.id, decisionType.REQUEST_REWRITE)}
            />
          </div>
        )}
      {(!nomination.isNominationChanged || nomination.isNominationChanged === undefined) && (
        <div className="text-center mb-1">
          <Button
            label="Save Comment"
            icon="pi pi-comment"
            className="w-100 p-button-sm p-button-secondary"
            onClick={() => onClickDecision(nomination.id, decisionType.NONE)}
          />
        </div>
      )}

      <div className="text-center">
        <Button
          label={`${nomination.showCommentBox ? 'Hide' : 'Show'} Comment`}
          className="w-100 p-button-sm p-button-link"
          onClick={() => helperFunctions.onClickShowComments(nomination.id)}
        />
      </div>
    </>
  );
}
DecisionButtons.propTypes = {
  nomination: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object.isRequired,
  onClickDecision: PropTypes.func.isRequired,
};

export default React.memo(DecisionButtons);
