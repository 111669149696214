import React, { useContext, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown as PrimeReactDropdown } from 'primereact/dropdown';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBellOn,
  faBellSlash,
  faSignOut,
  faUserCircle,
  faUserUnlock,
  faDoorOpen,
} from '@fortawesome/pro-solid-svg-icons';
import { faUser, faUserFriends } from '@fortawesome/pro-regular-svg-icons';
import { SessionContext, NotificationContext } from 'contexts';
import gtmLogo from 'assets/images/GTM-Logo-LockUp-Reverse.png';
import awardsLogo from 'assets/images/oas_logo.png';
import { useNavigationLinks, useAuthorization, useUserService } from 'hooks';

function TopBar() {
  const { showSuccess } = useContext(NotificationContext);
  const { sessionStore, sessionHelpers } = useContext(SessionContext);
  const { employeeUser } = sessionStore;
  const [isDropdownUserMenuOpen, setDropdownUserMenuOpen] = useState(false);
  const { getURL } = useNavigationLinks();
  const { checkRole, checkPermission } = useAuthorization();
  const userService = useUserService();

  const switchMission = (organization) => {
    sessionHelpers.switchMission(organization);
  };

  const getEnvMode = useCallback(() => {
    const mode = process.env.NODE_ENV;
    if (mode !== 'production') {
      return mode;
    }
    return '';
  }, []);

  const turnoffNotifications = async () => {
    const response = await userService.setTurnOffNotifications(employeeUser.employeeId);
    if (response.ok) {
      sessionHelpers.updateNotificationToggle(!employeeUser.turnOffNotifications);
      showSuccess('Notification setting updated.');
    }
  };

  function renderNotifications() {
    const value = employeeUser.turnOffNotifications;
    return (
      <>
        <FontAwesomeIcon className="mr-2 min-w-25px" icon={value ? faBellSlash : faBellOn} />
        Turn Notification <span>{value ? 'ON' : 'OFF'}</span>
      </>
    );
  }

  function renderDropdownUserMenus() {
    const items = [];
    if (!sessionHelpers.isAuthenticated()) return items;

    if (checkPermission('turnoff_notifications'))
      items.push(
        <DropdownItem
          key="ddi_toggle_notifications"
          label="EmailNotificationOff"
          onClick={() => turnoffNotifications()}
          className="color-primary"
        >
          {renderNotifications()}
        </DropdownItem>
      );

    if (checkRole('assign_proxy'))
      items.push(
        <DropdownItem
          key="ddi_assign_proxy"
          tag={Link}
          to={getURL('assign_proxy')}
          label="Assign Proxy"
          className={sessionStore.isImpersonating ? 'color-disabled' : 'color-primary'}
          disabled={sessionStore.isImpersonating}
        >
          <FontAwesomeIcon className="mr-2 min-w-25px" icon={faUserFriends} /> Assign Proxy
        </DropdownItem>
      );

    if (sessionStore.hasProxyingUsers)
      items.push(
        <DropdownItem
          key="ddi_proxy_user"
          tag={Link}
          to={getURL('impersonate_proxy')}
          label="Proxy User"
          className={sessionStore.isImpersonating ? 'color-disabled' : 'color-primary'}
          disabled={sessionStore.isImpersonating}
        >
          <FontAwesomeIcon className="mr-2 min-w-25px" icon={faUser} /> Proxy User
        </DropdownItem>
      );

    items.push(
      <DropdownItem
        key="ddi_check_roles"
        tag={Link}
        to={getURL('my_assigned_roles')}
        label="Check Roles &amp; Permissions"
        className="color-primary"
      >
        <FontAwesomeIcon className="mr-2 min-w-25px" icon={faUserUnlock} /> Check Roles &amp; Permissions
      </DropdownItem>
    );
    items.push(
      <DropdownItem
        key="ddi_logout"
        label="Logout"
        onClick={() => sessionHelpers.logoutSession()}
        className="color-primary"
      >
        <FontAwesomeIcon className="mr-2 min-w-25px" icon={sessionStore.isImpersonating ? faDoorOpen : faSignOut} />
        {sessionStore.isImpersonating ? 'Stop Sign In On Behalf of' : 'Log Out'}
      </DropdownItem>
    );
    const itemsWithDivider = [];
    let idx = 0;
    items.forEach((item) => {
      itemsWithDivider.push(item);
      if (idx < items.length - 1) itemsWithDivider.push(<DropdownItem key={`ddi_divider_${idx}`} divider />);
      idx += 1;
    });
    return itemsWithDivider;
  }

  return (
    <header className="bg-dark d-print-none">
      <div className="row">
        <div id="brand" className="col-12">
          <nav className="navbar bg-dark mx-auto col-12">
            <div className="col-md-6">
              <Link className="navbar-brand" to="/">
                <img
                  src={gtmLogo}
                  className="align-top gtm-logo d-none d-sm-inline"
                  alt="Bureau of Global Talent Management logo with Official Seal of the United States"
                />
                <img src={awardsLogo} className="align-top awards-logo d-none d-sm-inline" alt="ADG logo" />
              </Link>
              <small className="text-white">{sessionStore.applicationVersion} </small>
              <small className="text-white"> {getEnvMode()}</small>
            </div>

            <div className="col-md-6">
              <div className="row" />
              <div className="row">
                <div className="col-sm-8 text-white text-sm-left">
                  {sessionHelpers.isAuthenticated() && (
                    <h4 className="ml-3">
                      {sessionStore.isBureauUser
                        ? `${sessionStore.currentMissionName}`
                        : `Mission ${sessionStore.currentMissionName}`}
                    </h4>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 text-white text-center text-sm-right">
                  {sessionStore.isImpersonating && (
                    <>
                      <strong>{sessionStore.impersonatorUserName}</strong>&nbsp;is signed in on behalf of&nbsp;
                    </>
                  )}
                  {sessionStore.availableOrganizations.filter(
                    (item) => item.type === 'Mission' || item.type === 'Bureau'
                  ).length > 1 && (
                    <PrimeReactDropdown
                      value={sessionStore.currentOrganizationId}
                      options={sessionStore.availableOrganizations}
                      optionLabel="name"
                      optionValue="organizationId"
                      className="mission-switcher"
                      onChange={(e) => {
                        switchMission(e.value);
                      }}
                    />
                  )}
                  <Dropdown
                    isOpen={isDropdownUserMenuOpen}
                    toggle={() => setDropdownUserMenuOpen(!isDropdownUserMenuOpen)}
                  >
                    <DropdownToggle
                      tag="span"
                      data-toggle="dropdown"
                      color="link"
                      className="col-sm-12 text-white text-center text-sm-right"
                      caret
                    >
                      {employeeUser.name && (
                        <span className="bg-dark">
                          <FontAwesomeIcon className="mr-1 text-white" icon={faUserCircle} />
                          {employeeUser.name}
                        </span>
                      )}
                    </DropdownToggle>
                    <DropdownMenu>{renderDropdownUserMenus()}</DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <hr className="header-divider" />
      </div>
    </header>
  );
}
export default TopBar;
