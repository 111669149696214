import * as yup from 'yup';

const useNominationValidation = () => {
  const validateDraftSchema = yup.object().shape({
    postId: yup.number().positive().integer().required().typeError('Post cannot be empty'),
    sectionId: yup
      .number()
      .positive()
      .integer()
      .required()
      .typeError(
        'Section is required. Please contact HR Awards Coordinator to update your profile before starting a nomination.'
      ),
    awardTypeId: yup.number().positive('Type of Award is required.'),
    proposedAmount: yup.number('Proposed amount can not be empty'),
    recommendedAmount: yup.number('Proposed amount can not be empty'),
    approvedAmount: yup.number('Proposed amount can not be empty'),
  });

  const validateSchema = yup.object().shape({
    sectionId: yup
      .number()
      .positive()
      .integer()
      .required()
      .typeError(
        'Section is required. Please contact HR Awards Coordinator to update your profile before starting a nomination.'
      ),
    awardReasons: yup.array().min(1, 'Select at least one nomination reason.'),
    awardTypeId: yup.number().positive('Type of Award is required'),
    justification: yup.string().required('Justification is required'),
    citation: yup.string().when('awardTypeName', {
      is: (awardTypeName) => {
        if (awardTypeName === 'Mission Time-Off Award' || awardTypeName === 'Customer Service Award') {
          return true;
        }
        return false;
      },
      then: yup.string().notRequired(),
      otherwise: yup.string().required('Citation is required'),
    }),
    proposedAmount: yup.number('Proposed amount can not be empty'),
    recommendedAmount: yup.number('Proposed amount can not be empty'),
    approvedAmount: yup.number('Proposed amount can not be empty'),
    nominees: yup
      .array()
      .of(
        yup.object().shape({
          employeeId: yup.number().required().positive().integer(),
        })
      )
      .min(1, 'Select at least one nominee.'),
  });

  const schemaNewCommentRequired = yup.object().shape({
    notes: yup
      .array()
      .test('at-least-one-new', 'a comment is required', (items) => items.some((item) => item.noteState === 'new')),
  });

  const validate = async (nomination, isSubmitting) => {
    const validationResult = {
      isValid: true,
      errors: [],
    };
    const schemaToUse = isSubmitting ? validateSchema : validateDraftSchema;

    await schemaToUse
      .validate(nomination, { abortEarly: false })
      .then((value) => {
        console.log('validated value: ', value);
      })
      .catch((err) => {
        validationResult.isValid = false;
        validationResult.errors = err.errors;
      });
    return validationResult;
  };

  const validateCommentRequired = async (nomination, isApproving) => {
    console.log('isApproving:', isApproving);
    const validationResult = {
      isValid: true,
      errors: [],
    };
    if (isApproving) return validationResult;

    await schemaNewCommentRequired
      .validate(nomination, { abortEarly: false })
      .then((value) => {
        console.log('validated value: ', value);
      })
      .catch((err) => {
        validationResult.isValid = false;
        validationResult.errors = err.errors;
      });
    return validationResult;
  };

  return { validate, validateCommentRequired };
};

export default useNominationValidation;
