import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

import { formatDistanceToUtcNow } from 'utils/dateHelpers';

function VoteComment({ lastComment, newComment, voteCreatedDate, isVoteDateVisible, onChangeNewComment }) {
  const lastVoteDate = useCallback(() => {
    if (!voteCreatedDate) return '';
    return `voted ${formatDistanceToUtcNow(voteCreatedDate)} ago`;
  }, [voteCreatedDate]);

  return (
    <>
      {isVoteDateVisible && <div className="text-center vote-created-ago w-100 min-h-24px">{lastVoteDate()}</div>}
      <div className="my-1 font-weight-bold">Your Last Comment</div>
      <div>
        <Input type="textarea" name="text" value={lastComment || ''} readOnly />
      </div>
      <div className="my-1 font-weight-bold">New Comment</div>
      <div>
        <Input
          type="textarea"
          name="text"
          value={newComment || ''}
          onChange={(e) => onChangeNewComment(e.target.value)}
        />
      </div>
    </>
  );
}

VoteComment.propTypes = {
  lastComment: PropTypes.string,
  newComment: PropTypes.string,
  voteCreatedDate: PropTypes.string,
  isVoteDateVisible: PropTypes.bool.isRequired,
  onChangeNewComment: PropTypes.func.isRequired,
};
VoteComment.defaultProps = {
  lastComment: '',
  newComment: '',
  voteCreatedDate: null,
};

export default React.memo(VoteComment);
