import React from 'react';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

const getTypesFromNominationsList = (nominations) => {
  const typesList = nominations.map((item) => ({ value: item.awardTypeId, text: item.awardTypeName }));
  typesList.unshift({ value: 0, text: 'All' });
  return uniqBy(typesList, 'value');
};

function SignOffToolbar({ formStore, onClickSelect, onClickSignOff, onClickTypeFilter }) {
  return (
    <div className="card container-fluid py-1 px-0 signoff-toolbar border-0">
      <div className="row">
        <div className="col-3 text-center">Select Nominations</div>
        <div className="col-3 text-center">Filter by Award Type</div>
        <div className="col-3 text-center" />
        <div className="col-3 text-center">&nbsp;</div>
      </div>
      <div className="row">
        <div className="col-3 text-center btn-group" role="group">
          <Button
            label="All"
            icon="pi pi-check-square"
            className="p-button-outlined mr-1 w-100"
            onClick={() => onClickSelect(true)}
          />
          <Button
            label="None"
            icon="pi pi-times"
            className="p-button-outlined ml-1 w-100"
            onClick={() => onClickSelect(false)}
          />
        </div>
        <div className="col-3 text-center">
          <Dropdown
            value={formStore.filterType}
            options={getTypesFromNominationsList(formStore.loadedNominations)}
            onChange={(e) => onClickTypeFilter(e.value)}
            optionValue="value"
            optionLabel="text"
            className="w-100"
          />
        </div>
        <div className="col-3 text-center" />
        <div className="col-3 text-center">
          <Button
            label="Sign Off Selected"
            icon="pi pi-check"
            className="w-100 p-button-primary"
            onClick={() => onClickSignOff()}
          />
        </div>
      </div>
    </div>
  );
}

SignOffToolbar.propTypes = {
  formStore: PropTypes.object.isRequired,
  onClickSelect: PropTypes.func.isRequired,
  onClickSignOff: PropTypes.func.isRequired,
  onClickTypeFilter: PropTypes.func.isRequired,
};

export default SignOffToolbar;
