import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, FormFeedback } from 'reactstrap';

function InputField({ id, type, value, onChange, errors }) {
  return (
    <Col>
      <Input id={id} onChange={onChange} invalid={errors?.length > 0} type={type} value={value} />
      {errors && <FormFeedback invalid>{errors.length > 1 ? errors.join(', ') : errors[0]}</FormFeedback>}
    </Col>
  );
}

InputField.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  errors: PropTypes.array,
  type: PropTypes.string,
};

InputField.defaultProps = {
  type: 'text',
  errors: [],
};

export default React.memo(InputField);
