import * as yup from 'yup';

const useCeremonyValidation = () => {
  const validateCeremonySchema = yup.object().shape({
    name: yup.string().required('Name is required.'),
    description: yup.string().required('Description is required.'),
  });
  const validate = async (ceremony) => {
    const validationResult = {
      isValid: true,
      errors: [],
    };

    await validateCeremonySchema
      .validate(ceremony, { abortEarly: false })
      .then((value) => {
        console.log('validated value: ', value);
      })
      .catch((err) => {
        validationResult.isValid = false;
        validationResult.errors = err.errors;
      });
    return validationResult;
  };

  return { validate };
};

export default useCeremonyValidation;
