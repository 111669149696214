import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button as ButtonStrap, ButtonGroup } from 'reactstrap';
import { Skeleton } from 'primereact/skeleton';

import { SessionContext } from 'contexts';
import { ActionButton } from 'components';
import { useAwardTypeService } from 'hooks/admin/';
import { requestStatus, processType } from 'constants/index';
import { useNavigationLinks } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShippingFast, faMegaphone } from '@fortawesome/pro-light-svg-icons';

function MissionSpecificAwards() {
  const [awardTypes, setAwardTypes] = useState([]);
  const [loadRequest, setLoadRequest] = useState(requestStatus.REQUEST_NOT_INITIATED);
  const { sessionStore } = useContext(SessionContext);
  const { currentMissionId } = sessionStore;
  const { getURL, goTo } = useNavigationLinks();
  const awardTypeService = useAwardTypeService();
  const [typeFilter, setTypeFilter] = useState(processType.ANYTIME);

  useEffect(() => {
    const loadAwardTypes = async () => {
      setLoadRequest(requestStatus.REQUEST_IN_PROGRESS);
      setAwardTypes([]);
      const requestData = {
        missionId: currentMissionId,
        awardProcessType: typeFilter,
      };
      const response = await awardTypeService.getList(requestData);
      if (response && response.ok) {
        setAwardTypes(response.data);
        setLoadRequest(requestStatus.REQUEST_COMPLETED);
      }
    };
    if (loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadAwardTypes();
    }
  }, [awardTypeService, loadRequest, currentMissionId, typeFilter]);

  const navToEditSpecificAward = (awardTypeId) => {
    goTo('edit_mission_specific_award', { id: awardTypeId });
  };

  function getSkeletonLines() {
    const lines = [];
    for (let i = 0; i <= 4; i += 1) {
      lines.push(
        <tr className="row" key={`_temp_${i}`}>
          <td className="col-3">
            <Skeleton width="75%" className="m-2" />
          </td>
          <td className="col-3">
            <Skeleton width="75%" className="m-2" />
          </td>
          <td className="col-2">
            <Skeleton width="75%" className="m-2" />
          </td>
          <td className="col-2">
            <Skeleton width="75%" className="m-2" />
          </td>
          <td className="col-2">
            <Skeleton width="75%" className="m-2" />
          </td>
        </tr>
      );
    }
    return lines;
  }

  return (
    <>
      <Row>
        <Col xs="8" md="8" lg="6" xl="6">
          <ButtonGroup className="w-100">
            <ButtonStrap
              className="w-150"
              color={typeFilter === processType.ANYTIME ? 'primary' : 'outline-secondary'}
              onClick={() => {
                setTypeFilter(processType.ANYTIME);
                setLoadRequest(requestStatus.REQUEST_NOT_INITIATED);
              }}
            >
              <FontAwesomeIcon icon={faShippingFast} fixedWidth className="mr-2" />
              Anytime
            </ButtonStrap>
            <ButtonStrap
              className="w-150"
              color={typeFilter === processType.ANNUAL ? 'primary' : 'outline-secondary'}
              onClick={() => {
                setTypeFilter(processType.ANNUAL);
                setLoadRequest(requestStatus.REQUEST_NOT_INITIATED);
              }}
            >
              <FontAwesomeIcon icon={faMegaphone} fixedWidth className="mr-2" />
              Annual
            </ButtonStrap>
          </ButtonGroup>
        </Col>
        <Col xs="12">
          <Link to={getURL('new_mission_specific_award')} className="float-right btn btn-primary mb-2">
            Add New Award
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Card>
            <div className="container-fluid table-responsive py-0">
              <table className="table w-100 mb-0">
                <thead className="thead-light">
                  <tr className="row">
                    <th className="col-3">Award Name</th>
                    <th className="col-3">Approval Process Type</th>
                    <th className="col-2">Form of Recognition</th>
                    <th className="col-2">Is External Approval Required</th>
                    <th className="col-2 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loadRequest === requestStatus.REQUEST_IN_PROGRESS && getSkeletonLines()}
                  {awardTypes.map((awardType) => (
                    <tr className="row" key={awardType.id}>
                      <td className="col-3">{awardType.name}</td>
                      <td className="col-3">{awardType.awardProcessTypeDescription}</td>
                      <td className="col-2">{awardType.formOfRecognitionDescription}</td>
                      <td className="col-2">{awardType.isExternalApprovalRequired.toString()}</td>
                      <td className="col-2 text-center">
                        <ActionButton
                          className="p-button-text p-button-plain px-2"
                          onClick={() => navToEditSpecificAward(awardType.awardTypeId)}
                          icon={['far', 'pencil']}
                        />
                      </td>
                    </tr>
                  ))}
                  {awardTypes.length === 0 && loadRequest === requestStatus.REQUEST_COMPLETED && (
                    <tr className="row">
                      <td colSpan="5" className="col-12">
                        No records found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default MissionSpecificAwards;
