import useApi from './useApi';

const useUserService = () => {
  const api = useApi();

  const getUserData = async () => await api.get(`/user/currentuser`);

  const listOktaUsers = async (searchTerm) => await api.get(`/user/ListOktaUsers/${searchTerm}`);

  const isUserAuthenticated = () => !!api.accessToken;

  const setTurnOffNotifications = async (id) => await api.post(`/user/SetTurnOffNotifications/${id}`);

  const getUserRoles = async (employeeId) => await api.post(`/user/GetUserRoles/${employeeId}`);

  const saveUserRoles = async (requestData) => await api.post('/user/SaveUserRoles', requestData);

  return {
    getUserData,
    listOktaUsers,
    isUserAuthenticated,
    setTurnOffNotifications,
    getUserRoles,
    saveUserRoles,
  };
};

export default useUserService;
