import React, { useReducer, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { NotificationContext, SessionContext } from 'contexts';
import { Label, Row, Col, Card } from 'reactstrap';
import { UserSearch, SkeletonTable } from 'components';
import { Button } from 'primereact/button';
import { requestStatus } from 'constants/index';
import { useBureauCycleService } from 'hooks/admin/useBureauCycleService';

const ACTION_TYPE = {
  START_LOAD_REQUEST: 'START_LOAD_REQUEST',
  LOAD_DATA: 'LOAD_DATA',
  UPDATE_ASSIGNED_ROLES: 'UPDATE_ASSIGNED_ROLES',
};

const initialState = {
  cycleAssignedRoles: [],
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.START_LOAD_REQUEST:
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };
    case ACTION_TYPE.LOAD_DATA:
      return { ...state, cycleAssignedRoles: action.data, loadRequest: requestStatus.REQUEST_COMPLETED };
    case ACTION_TYPE.UPDATE_ASSIGNED_ROLES: {
      const updatedRoleIndex = state.cycleAssignedRoles.findIndex((role) => {
        return role.roleKey === action.data.roleKey;
      });
      return {
        ...state,
        cycleAssignedRoles: [
          ...state.cycleAssignedRoles.slice(0, updatedRoleIndex),
          {
            ...state.cycleAssignedRoles[updatedRoleIndex],
            employees: action.data.assignees,
          },
          ...state.cycleAssignedRoles.slice(updatedRoleIndex + 1),
        ],
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function BureauCycleRoleSettings({ cycleId }) {
  const [store, dispatch] = useReducer(reducer, initialState);
  const bureauCycleService = useBureauCycleService();
  const { showSuccess, showError } = useContext(NotificationContext);
  const { sessionStore, sessionHelpers } = useContext(SessionContext);
  const { currentMissionId } = sessionStore;
  const isLoading = store.loadRequest !== requestStatus.REQUEST_COMPLETED;

  useEffect(() => {
    const loadAssignedEmployees = async () => {
      dispatch({ type: ACTION_TYPE.START_LOAD_REQUEST });
      const response = await bureauCycleService.getAssignedEmployees(cycleId);
      if (response && response.ok) {
        dispatch({ type: ACTION_TYPE.LOAD_DATA, data: response.data });
      }
    };
    if (store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadAssignedEmployees();
    }
  }, [bureauCycleService, cycleId, store.loadRequest]);

  async function onSaveClick() {
    const response = await bureauCycleService.updateAssignedEmployees(cycleId, store.cycleAssignedRoles);
    if (response && response.ok) {
      showSuccess('Cycle data successfully saved!');
    } else {
      showError('There was an error saving the bureau cycle role assignees.');
    }
    await sessionHelpers.initSession();
  }

  return (
    <>
      <Row>
        <Col xs="12" md="12" lg="12" xl="10">
          <div>
            <Label>Cycle Assigned Roles</Label>
          </div>
          {isLoading && <SkeletonTable colsSize={1} rowsSize={1} />}
          {!isLoading && (
            <Card>
              <div>
                <table className="table w-100">
                  <thead className="thead-light">
                    <tr>
                      <th className="col-4">Roles</th>
                      <th className="col-8">Assignees</th>
                    </tr>
                  </thead>
                  <tbody>
                    {store.cycleAssignedRoles.map((role) => (
                      <tr key={role.roleKey}>
                        <td>{role.roleName}</td>
                        <td>
                          <UserSearch
                            value={role.employees}
                            setValue={(assignees) => {
                              dispatch({
                                type: ACTION_TYPE.UPDATE_ASSIGNED_ROLES,
                                data: {
                                  roleKey: role.roleKey,
                                  assignees,
                                },
                              });
                            }}
                            isMulti
                            bureauId={currentMissionId}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          )}
        </Col>
      </Row>
      <Row className="p-3">
        <Button
          label="Save"
          icon="pi pi-save"
          className="min-w-150px p-button-primary mx-1"
          onClick={() => onSaveClick()}
        />
      </Row>
    </>
  );
}

BureauCycleRoleSettings.propTypes = {
  cycleId: PropTypes.string.isRequired,
};

export default BureauCycleRoleSettings;
