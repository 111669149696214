import React, { useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { NotificationContext } from 'contexts';
import { requestStatus, nominationAction } from 'constants/index';
import { useSignOffForm } from 'hooks/signoff';
import MessageModal from 'components/MessageModal';
import SignOffFormHeader from './SignOffHeader';
import SignOffNominationsList from './SignOffNominationsList';
import SignOffToolbar from './SignOffToolbar';
import VotingNominationDetailsModal from '../VotingForm/VotingNominationDetailsModal';
import ExecSignOffModal from './ExecSignOffModal';

function SignOffForm() {
  const { showSuccess, showWarn } = useContext(NotificationContext);
  const { formStore, dispatch, helperFunctions } = useSignOffForm();
  const { mode } = useParams();
  const { loadListRequest } = formStore;

  if (mode.toLowerCase() !== formStore.currentMode) {
    dispatch({ type: 'SET_CURRENT_MODE', data: mode });
  }

  const getTitle = useCallback(() => {
    let title = 'Finance';
    if (helperFunctions.isExecMode()) title = 'Exec';
    if (helperFunctions.isManagementMode()) title = 'Management';
    if (helperFunctions.isAnytimeMode()) title = 'Anytime Approver';
    return `${title} - Sign Off`;
  }, [helperFunctions]);

  useEffect(() => {
    const load = async () => {
      helperFunctions.getAvailableCycles();
      await helperFunctions.loadNominations();
    };
    if (loadListRequest === requestStatus.REQUEST_NOT_INITIATED) {
      load();
    }
  }, [loadListRequest, helperFunctions, formStore.currentNominationId]);

  const onClickCheck = (event) => {
    const nominationId = event.value;
    event.originalEvent.stopPropagation();

    helperFunctions.onClickCheck(nominationId);
    return false;
  };

  const onClickSignOff = async () => {
    if (helperFunctions.getSelected().length === 0) {
      showWarn('You should select at least one nomination.');
      return;
    }

    confirmDialog({
      message: 'Are you sure you want to sign off the selected nominations?',
      header: 'Sign Off Nomination',
      icon: 'pi pi-info-circle',
      acceptClassName: 'ui-button-success',
      accept: async () => {
        if (await helperFunctions.signOffSelected()) {
          showSuccess('The nominations were successfully signed off.');
        }
      },
    });
  };

  const onClickApplyChanges = async (nomination, nominationActionType) => {
    let response = false;

    confirmDialog({
      message: 'Are you sure you want to apply Changes?',
      header: 'Apply Nomination Changes',
      icon: 'pi pi-check-circle',
      acceptClassName: 'p-button-success',
      accept: async () => {
        if (nominationActionType === nominationAction.CHANGE) {
          response = await helperFunctions.overrideNomination(nomination);
        } else {
          response = await helperFunctions.saveFiscalStrips(nomination);
        }
        if (response) {
          if (nominationActionType !== nominationAction.SKIPSTEP) {
            helperFunctions.disableSaveButton(nomination.id);
          }

          showSuccess('The nomination changes were successfully applied.');
        }
      },
    });
  };

  const onClickEndorse = async (nomination, action) => {
    let successMessage = '';
    if ([nominationAction.REJECT, nominationAction.RETURN].includes(action) && !nomination.comment.trim()) {
      showWarn('You must type a comment for this action.');
      return;
    }

    if (action === nominationAction.REJECT) {
      confirmDialog({
        message: 'Are you sure you want to reject this nomination?',
        header: 'Reject Nomination',
        icon: 'pi pi-times-circle"',
        acceptClassName: 'p-button-danger',
        accept: async () => {
          if (await helperFunctions.endorseNomination(nomination, action)) {
            showSuccess('The nomination was successfully rejected.');
          }
        },
      });
      return;
    }

    if (action === nominationAction.CHANGE) {
      confirmDialog({
        message: 'Are you sure you want to apply Changes?',
        header: 'Apply Nomination Changes',
        icon: 'pi pi-check-circle',
        acceptClassName: 'p-button-success',
        accept: async () => {
          if (await helperFunctions.overrideNomination(nomination)) {
            showSuccess('The nomination changes were successfully applied.');
          }
        },
      });
      return;
    }

    if (action === nominationAction.OTHER) {
      dispatch({ type: 'OPEN_EXEC_SIGNOFF_MODAL', data: nomination.id });
      return;
    }

    if (action === nominationAction.RETURN) {
      successMessage = `The nomination was returned to ${
        helperFunctions.isFinanceMode() ? 'Financial Analyst to edit Fiscal Strip' : 'HR for review.'
      } `;
    }

    if (action === nominationAction.FORWARD) {
      successMessage = 'The nomination was successfully signed off.';
    }

    if (action === nominationAction.NONE) {
      successMessage = 'The nomination changes were successully saved.';
    }

    if (await helperFunctions.endorseNomination(nomination, action)) {
      showSuccess(successMessage);
    }
  };

  const onSaveNominationChanges = (updatedNominations) => {
    dispatch({ type: 'UPDATE_NOMINATION_CHANGES', data: updatedNominations });
    dispatch({ type: 'CLOSE_EXEC_SIGNOFF_MODAL' });
    dispatch({ type: 'SET_MESSAGE_MODAL_OPEN' });
  };

  const onCloseMessageModalClick = () => {
    dispatch({ type: 'SET_MESSAGE_MODAL_CLOSE' });
  };

  return (
    <>
      <h4>{getTitle()}</h4>

      <SignOffFormHeader formStore={formStore} helperFunctions={helperFunctions} />

      <SignOffToolbar
        formStore={formStore}
        onClickSelect={helperFunctions.onClickSelectAll}
        onClickSignOff={onClickSignOff}
        onClickTypeFilter={helperFunctions.onClickTypeFilter}
      />

      <SignOffNominationsList
        formStore={formStore}
        helperFunctions={helperFunctions}
        onClickCheck={(e) => onClickCheck(e)}
        onClickEndorse={onClickEndorse}
        onClickApplyChanges={onClickApplyChanges}
      />

      <VotingNominationDetailsModal
        isModalOpened={formStore.detailModalOpened}
        nomination={formStore.detailedNomination}
        onClickClose={() => dispatch({ type: 'CLOSE_NOMINATION_DETAILS_MODAL' })}
      />

      {formStore.execSignOffModalOpened && (
        <ExecSignOffModal
          isModalOpened={formStore.execSignOffModalOpened}
          onClickClose={() => dispatch({ type: 'CLOSE_EXEC_SIGNOFF_MODAL' })}
          formStore={formStore}
          currentNomination={formStore.filteredNominations.find(
            (nomination) => nomination.id === formStore.currentNominationId
          )}
          onUpdateChanges={onSaveNominationChanges}
        />
      )}

      {formStore.isMessageModalOpen && (
        <MessageModal
          isModalOpened={formStore.isMessageModalOpen}
          onClickCloseModal={onCloseMessageModalClick}
          header="Changes Confirmation"
          message="Please click the `Apply Changes' button to apply changes."
          showCancel={false}
        />
      )}

      <div className="debug mt-3">
        debug Info =&gt; form Mode:
        {mode}
      </div>
    </>
  );
}

export default SignOffForm;
