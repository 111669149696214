import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Card, Badge } from 'reactstrap';
import { format } from 'date-fns';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { SessionContext } from 'contexts';
import { requestStatus } from 'constants/index';
import { useCycleService } from 'hooks/admin';
import { useNavigationLinks, useFormatting, useAuthorization } from 'hooks';
import { ActionButton, Grid } from 'components';
import { Column } from 'primereact/column';

const getPostsNames = (cycle) => {
  return cycle.cyclePosts.map((item) => (
    <Badge key={item.postId} color="info" className="mr-1">
      {item.postName}
    </Badge>
  ));
};

const postNamesNameBodyTemplate = (rowData) => {
  return (
      <div>
         {getPostsNames(rowData)}
      </div>
  );
};

const closingDateBodyTemplate = (rowData) => {
  return (
      <div>
        {rowData.closingDate &&
                          format(new Date(rowData.closingDate), 'PPP')}
      </div>
  );
};



function CycleList() {
  const [cycles, setCycles] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadRequest, setLoadRequest] = useState(
    requestStatus.REQUEST_NOT_INITIATED
  );
  const { sessionStore } = useContext(SessionContext);
  const missionId = sessionStore.currentMissionId;
  const cycleService = useCycleService();
  const { formatCycle } = useFormatting();
  const { checkRole } = useAuthorization();
  const { goTo } = useNavigationLinks();
  const navToEditCycle = (cycleId) => {
    goTo('edit_cycle', { id: cycleId });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <ActionButton
      className="p-button-text p-button-plain px-2"
      onClick={() => navToEditCycle(rowData.id)}
      icon={['far', 'pencil']}
    />
    );
  };

  useEffect(() => {
    const loadCycles = async () => {
      setLoadRequest(requestStatus.REQUEST_IN_PROGRESS);
      const response = await cycleService.getListByMission(missionId);
      if (response && response.ok) {
        setCycles(response.data);
        setLoadRequest(requestStatus.REQUEST_COMPLETED);
      }
    };
    if (loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadCycles();
    }
  }, [cycleService, loadRequest, missionId]);

  useEffect(() => {
    if (!checkRole('hr_officer')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  function getSkeletonLines() {
    const lines = [];
    for (let i = 0; i <= 5; i += 1) {
      lines.push(
        <tr className="row" key={`_temp_${i}`}>
          <td className="col-2">
            <Skeleton width="75%" className="m-2" />
          </td>
          <td className="col-4">
            <Skeleton width="75%" className="m-2" />
          </td>
          <td className="col-3">
            <Skeleton width="75%" className="m-2" />
          </td>
          <td className="col-2">
            <Skeleton width="75%" className="m-2" />
          </td>
          <td className="col-1">
            <Skeleton width="75%" className="m-2" />
          </td>
        </tr>
      );
    }
    return lines;
  }

  return (
    <>
      <h4>Mission Admin - Cycles</h4>
      <Row className="mt-3">
      <span className="p-input-icon-left ml-3">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Global Filter"
            />
          </span>
        <Col xs="12">
          <Button
            icon="pi pi-plus"
            label="New Cycle"
            onClick={() => goTo('new_cycle')}
            className="p-button-primary btn-new-cycle"
          />
        </Col>
      </Row>
      <Row className="">
      <div className="container-fluid table-responsive">
      <Grid
              items={cycles}
              dataKey="id"
              sortMode="single"
              sortField="modifiedDate"
              sortOrder={-1}
              globalFilter={globalFilter}
            >
              <Column field="name" header="Name" sortable />
              <Column field="cyclePosts" header="Name" body={postNamesNameBodyTemplate} sortable />
              <Column field="closingDate" header="Closing Date" body={closingDateBodyTemplate} sortable />
              <Column header="Closing Date" body={actionBodyTemplate}/>
            </Grid>
      </div>
      </Row>
    </>
  );
}

export default CycleList;
