import React, { useEffect, useState, useContext } from 'react';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import { SessionContext } from 'contexts';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagAlt, faThumbsUp, faThumbsDown, faEquals, faClock } from '@fortawesome/pro-solid-svg-icons';
import { useAuthorization } from 'hooks';
import ExecSignOffModal from 'views/SignOff/ExecSignOffModal';
import MessageModal from 'components/MessageModal';

const statusOptions = [
  { value: 'all', text: 'All', colorClass: 'text-secondary', icon: faFlagAlt },
  { value: 'approved', text: 'Approved', colorClass: 'text-success', icon: faThumbsUp },
  { value: 'disapproved', text: 'Disapproved', colorClass: 'text-danger', icon: faThumbsDown },
  { value: 'pending', text: 'Pending', colorClass: 'text-warning', icon: faClock },
  { value: 'pending_tie', text: 'Tie', colorClass: 'text-warning', icon: faEquals },
];

const statusOptionTemplate = (option) => {
  return (
    <div>
      <FontAwesomeIcon icon={option.icon} className={option.colorClass} />
      <span className="ml-2">{option.text}</span>
    </div>
  );
};

const getTypesFromNominationsList = (nominations) => {
  const typesList = nominations.map((item) => ({ value: item.awardTypeId, text: item.awardTypeName }));
  typesList.unshift({ value: 0, text: 'All' });
  return uniqBy(typesList, 'value');
};

function SignoffToolbar({
  formStore,
  helperFunctions,
  onClickSelect,
  onClickStatusFilter,
  onClickTypeFilter,
  onClickSignOff,
  onClickChangeSelected,
}) {
  const { checkCyclePermission } = useAuthorization();
  const { currentCycleId, isCycleDefined } = formStore;
  const selectedNominations = formStore.filteredNominations?.filter((item) => item.selected);
  const { sessionStore } = useContext(SessionContext);

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isChangeReadOnly, setIsChangeReadOnly] = useState(true);
  const [isApplyChangesReadOnly, setIsApplyChangesReadOnly] = useState(true);

  useEffect(() => {
    if (isCycleDefined) {
      setIsReadOnly(true);
      const permission = sessionStore.isBureauUser ? 'can_signoff_bureau' : 'jcac_chair';
      if (checkCyclePermission(permission, currentCycleId)) {
        setIsReadOnly(false);

        if (!isReadOnly && selectedNominations.length > 0) {
          if (
            selectedNominations.every(
              (x) =>
                x.awardTypeId === selectedNominations[0].awardTypeId &&
                x.formOfRecognition === selectedNominations[0].formOfRecognition
            )
          )
            setIsChangeReadOnly(false);
          else setIsChangeReadOnly(true);
        }
      }
    }
  }, [
    isCycleDefined,
    checkCyclePermission,
    currentCycleId,
    isReadOnly,
    formStore.filteredNominations,
    selectedNominations,
    sessionStore.isBureauUser,
  ]);

  useEffect(() => {
    if (!isChangeReadOnly) {
      if (selectedNominations.length > 0) {
        if (selectedNominations.every((x) => x.isNominationChanged === true)) setIsApplyChangesReadOnly(false);
        else setIsApplyChangesReadOnly(true);
      }
    }
  }, [isChangeReadOnly, selectedNominations, isApplyChangesReadOnly]);

  return (
    <div className="card container-fluid py-1 px-0 signoff-toolbar border-0">
      <div className="row">
        <div className="col-2 text-center">Select Nominations</div>
        <div className="col-2 text-center">Filter by Vote Result</div>
        {!helperFunctions.isBureauVoting() && <div className="col-2 text-center">Filter by Award Type</div>}
        <div className="col-6 text-center">&nbsp;</div>
      </div>
      <div className="row">
        <div className="col-2 text-center btn-group" role="group">
          <Button
            label="All"
            icon="pi pi-check-square"
            className="p-button-outlined mr-1 w-100"
            onClick={() => onClickSelect(true)}
          />
          <Button
            label="None"
            icon="pi pi-times"
            className="p-button-outlined ml-1 w-100"
            onClick={() => onClickSelect(false)}
          />
        </div>
        <div className="col-2 text-center">
          <Dropdown
            value={formStore.filterStatus}
            options={statusOptions}
            onChange={(e) => onClickStatusFilter(e.value)}
            valueTemplate={statusOptionTemplate}
            itemTemplate={statusOptionTemplate}
            optionValue="value"
            optionLabel="text"
            className="w-100"
          />
        </div>
        {!helperFunctions.isBureauVoting() && (
          <div className="col-2 text-center">
            <Dropdown
              value={formStore.filterType}
              options={getTypesFromNominationsList(formStore.loadedNominations)}
              onChange={(e) => onClickTypeFilter(e.value)}
              optionValue="value"
              optionLabel="text"
              className="w-100"
            />
          </div>
        )}
        <div className="col-2 text-center">
          <Button
            label="Sign Off Selected"
            icon="pi pi-check"
            className="w-100 p-button-primary"
            disabled={isReadOnly}
            onClick={() => onClickSignOff()}
          />
        </div>
        {!helperFunctions.isBureauVoting() && (
          <>
            <div className="col-2 text-center">
              <Button
                label="Change Selected"
                icon="pi pi-bolt"
                className="w-100 p-button-success"
                disabled={isChangeReadOnly}
                onClick={() => onClickChangeSelected()}
              />
            </div>
            <div className="col-2 text-center">
              <Button
                label="Apply Changes"
                icon="pi pi-check"
                className="w-100 p-button-secondary"
                disabled={isApplyChangesReadOnly}
                onClick={helperFunctions.signOffSelected}
              />
            </div>
          </>
        )}
      </div>
      {formStore.isChangeModalOpen && (
        <ExecSignOffModal
          isModalOpened={formStore.isChangeModalOpen}
          onClickClose={helperFunctions.onCloseChangeModalClick}
          formStore={formStore}
          onUpdateChanges={helperFunctions.onSaveNominationChanges}
          selectedNominations={selectedNominations}
        />
      )}
      {formStore.isMessageModalOpen && (
        <MessageModal
          isModalOpened={formStore.isMessageModalOpen}
          onClickCloseModal={helperFunctions.onCloseMessageModalClick}
          header="Changes Confirmation"
          message="To confirm that these changes were approved by the JCAC members, please click the `Apply Changes' button to apply changes."
          showCancel={false}
        />
      )}
    </div>
  );
}

SignoffToolbar.propTypes = {
  formStore: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object.isRequired,
  onClickSelect: PropTypes.func.isRequired,
  onClickStatusFilter: PropTypes.func.isRequired,
  onClickTypeFilter: PropTypes.func,
  onClickSignOff: PropTypes.func.isRequired,
  onClickChangeSelected: PropTypes.func,
};

SignoffToolbar.defaultProps = {
  onClickTypeFilter: () => {},
  onClickChangeSelected: () => {},
};

export default SignoffToolbar;
