/* eslint-disable import/prefer-default-export */

/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} propertyName Key of the object to sort.
 */
function dynamicSort(propertyName) {
  let sortOrder = 1;
  let property = propertyName;

  if (propertyName[0] === '-') {
    sortOrder = -1;
    property = propertyName.substr(1);
  }

  return (a, b) => {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property]);
    }
    return a[property].localeCompare(b[property]);
  };
}

export { dynamicSort };
