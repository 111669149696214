import React, { useState, useEffect, useContext } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { useNavigationLinks } from 'hooks';
import { SessionContext } from 'contexts';
import BureauCycleBasicSettings from './BureauCycleBasicSettings';
import BureauCycleRoleSettings from './BureauCycleRoleSettings';

function BureauCycleSettings() {
  const [cycleDate, setCycleDate] = useState(0);
  const [cycleName, setCycleName] = useState('');
  const [cycleId, setCycleId] = useState(0);
  const { goTo } = useNavigationLinks();
  const { sessionStore } = useContext(SessionContext);
  const { isBureauUser } = sessionStore;

  useEffect(() => {
    if (!isBureauUser) {
      goTo('not_authorized');
    }
  }, [goTo, isBureauUser]);

  const setHeader = (pCycleDate, pCycleName, pCycleId) => {
    setCycleDate(pCycleDate);
    setCycleName(pCycleName);
    setCycleId(pCycleId);
  };

  return (
    <>
      <h4>
        Cycle Settings - {cycleDate} - {cycleName}
      </h4>
      <TabView>
        <TabPanel header="Basic & Dates">
          <BureauCycleBasicSettings headerData={setHeader} />
        </TabPanel>
        <TabPanel header="Roles" disabled={cycleId === 0}>
          <BureauCycleRoleSettings cycleId={cycleId} />
        </TabPanel>
      </TabView>
    </>
  );
}

export default BureauCycleSettings;
