import React, { useReducer, useEffect } from 'react';
import { Table } from 'reactstrap';
import { InputSwitch } from 'primereact/inputswitch';
import { ActionButton } from 'components';
import { requestStatus } from 'constants/index';
import { useBureauService } from 'hooks/admin/useBureauService';
import { useNavigationLinks, useAuthorization } from 'hooks';
import { Skeleton } from 'primereact/skeleton';
import './Bureaus.css';

const ACTION_TYPE = {
  START_LOAD_REQUEST: 'START_LOAD_REQUEST',
  LOAD_BUREAUS_LIST: 'LOAD_BUREAUS_LIST',
  UPDATE_BUREAU: 'UPDATE_BUREAU',
};

const initialState = {
  bureaus: [],
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.START_LOAD_REQUEST:
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };
    case ACTION_TYPE.LOAD_BUREAUS_LIST:
      return {
        ...state,
        bureaus: action.data,
        loadRequest: requestStatus.REQUEST_COMPLETED,
      };
    case ACTION_TYPE.UPDATE_BUREAU: {
      return {
        ...state,
        bureaus: state.bureaus.map((b) => {
          if (b.id === action.data.id) {
            b.isEnabled = action.data.value;
          }
          return b;
        }),
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function getSkeletonLines() {
  const lines = [];
  for (let i = 0; i < 5; i += 1) {
    lines.push(
      <tr key={`_temp_${i}`}>
        <td>
          <Skeleton width="100%" height="3rem" />
        </td>
        <td>
          <Skeleton width="100%" height="3rem" />
        </td>
        <td>
          <Skeleton width="100%" height="3rem" />
        </td>
      </tr>
    );
  }
  return lines;
}

function Bureaus() {
  const [store, dispatch] = useReducer(reducer, initialState);
  const bureauService = useBureauService();
  const { goTo } = useNavigationLinks();
  const { checkRole } = useAuthorization();
  const isLoading = store.loadRequest === requestStatus.REQUEST_IN_PROGRESS;

  useEffect(() => {
    const loadBureaus = async () => {
      dispatch({ type: ACTION_TYPE.START_LOAD_REQUEST });
      const response = await bureauService.getBureaus();
      if (response && response.ok) {
        dispatch({ type: ACTION_TYPE.LOAD_BUREAUS_LIST, data: response.data });
      }
    };
    if (store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadBureaus();
    }
  }, [bureauService, store.loadRequest]);

  useEffect(() => {
    if (!checkRole('system_roles')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  async function enableDisableClick(id, value) {
    dispatch({
      type: ACTION_TYPE.UPDATE_BUREAU,
      data: { id, value },
    });
    await bureauService.updateBureau({ id, isEnabled: value });
  }

  return (
    <>
      <h4>Bureaus</h4>
      <Table striped>
        <thead>
          <tr>
            <th className="width45">Name</th>
            <th className="width25">Short Name</th>
            <th className="text-center">Enabled/Disable</th>
            <th className="text-center">Manage Roles</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && getSkeletonLines()}
          {!isLoading &&
            store.bureaus.map((b) => (
              <tr key={b.id}>
                <td>{b.name}</td>
                <td>{b.shortName}</td>
                <td className="text-center">
                  <InputSwitch id={b.id} checked={b.isEnabled} onChange={(e) => enableDisableClick(b.id, e.value)} />
                </td>
                <td className="text-center">
                  <ActionButton
                    tooltip="Manage Roles"
                    className="p-button-text p-button-plain px-2"
                    icon={['far', 'pencil']}
                    onClick={() => goTo('bureau_manage_roles', { id: b.id })}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}

export default Bureaus;
