import React, { useReducer, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { Label, Row, Col, Card } from 'reactstrap';
import { requestStatus } from 'constants/index';
import { SaveCancel, UserSearch, SkeletonTable } from 'components';
import { useBureauService } from 'hooks/admin/useBureauService';
import { useNavigationLinks, useAuthorization } from 'hooks';
import { NotificationContext } from 'contexts';

const ACTION_TYPE = {
  START_LOAD_REQUEST: 'START_LOAD_REQUEST',
  LOAD_EMPLOYEES: 'LOAD_EMPLOYEES',
  UPDATE_ASSIGNED_ROLES: 'UPDATE_ASSIGNED_ROLES',
};

const initialState = {
  id: 0,
  bureauName: '',
  bureauAssignedRoles: [],
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.START_LOAD_REQUEST:
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };
    case ACTION_TYPE.LOAD_EMPLOYEES: {
      return {
        ...state,
        id: action.data.bureauId,
        bureauName: action.data.bureauName,
        bureauAssignedRoles: action.data.bureauAssignedRoles.map((r) => {
          return { ...r };
        }),
        loadRequest: requestStatus.REQUEST_COMPLETED,
      };
    }
    case ACTION_TYPE.UPDATE_ASSIGNED_ROLES: {
      const updatedRoleIndex = state.bureauAssignedRoles.findIndex((role) => {
        return role.roleKey === action.data.roleKey;
      });
      return {
        ...state,
        bureauAssignedRoles: [
          ...state.bureauAssignedRoles.slice(0, updatedRoleIndex),
          {
            ...state.bureauAssignedRoles[updatedRoleIndex],
            employees: action.data.assignees,
          },
          ...state.bureauAssignedRoles.slice(updatedRoleIndex + 1),
        ],
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function BureauManageRoles() {
  const [store, dispatch] = useReducer(reducer, initialState);
  const { id } = useParams();
  const { goTo } = useNavigationLinks();
  const { checkRole } = useAuthorization();
  const bureauService = useBureauService();
  const { showSuccess, showError } = useContext(NotificationContext);
  const isLoading = store.loadRequest !== requestStatus.REQUEST_COMPLETED;

  useEffect(() => {
    const loadEmployees = async () => {
      dispatch({ type: ACTION_TYPE.START_LOAD_REQUEST });
      const response = await bureauService.getRoleEmployees(id);
      if (response && response.ok) {
        dispatch({ type: ACTION_TYPE.LOAD_EMPLOYEES, data: response.data });
      }
    };
    if (store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadEmployees();
    }
  }, [bureauService, store.loadRequest, id]);

  useEffect(() => {
    if (!checkRole('system_roles')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  const onSaveClick = async () => {
    const response = await bureauService.saveRoleEmployees(store.id, store.bureauAssignedRoles);
    if (response && response.ok) {
      showSuccess('Bureau roles successfully saved!');
    } else {
      showError('There was an error saving the bureau roles.');
    }
  };

  return (
    <>
      <h4>Bureau Settings - {store.bureauName}</h4>
      <Row className="mt-3">
        <Col xs="12" md="12" lg="12" xl="10">
          <div>
            <Label>Assigned Roles</Label>
          </div>
          {isLoading && <SkeletonTable colsSize={1} rowsSize={1} />}
          {!isLoading && (
            <Card>
              <div>
                <table className="table w-100">
                  <thead className="thead-light">
                    <tr>
                      <th className="col-4">Roles</th>
                      <th className="col-8">Assignees</th>
                    </tr>
                  </thead>
                  <tbody>
                    {store.bureauAssignedRoles.map((role) => (
                      <tr key={role.roleKey}>
                        <td>{role.roleName}</td>
                        <td>
                          <UserSearch
                            value={role.employees}
                            setValue={(assignees) => {
                              dispatch({
                                type: 'UPDATE_ASSIGNED_ROLES',
                                data: {
                                  roleKey: role.roleKey,
                                  assignees,
                                },
                              });
                            }}
                            isMulti
                            bureauId={store.id}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          )}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <SaveCancel onCancelClick={() => goTo('sysadm_bureaus')} onSaveClick={onSaveClick} />
        </Col>
      </Row>
    </>
  );
}

export default BureauManageRoles;
