import React, { useEffect, useState, useContext } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { UserSearch } from 'components';
import { SessionContext, NotificationContext } from 'contexts';
import { useEmployeeService } from 'hooks/admin';
import { useUserService, useAuthorization, useNavigationLinks } from 'hooks';

function AdminRoles() {
  const employeeService = useEmployeeService();
  const userService = useUserService();
  const { goTo } = useNavigationLinks();
  const { checkRole } = useAuthorization();
  const { showSuccess } = useContext(NotificationContext);
  const { sessionHelpers } = useContext(SessionContext);
  const [loading, setLoading] = useState(false);
  const [missions, setMissions] = useState([]);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(0);
  const [selectedAdminUser, setSelectedAdminUser] = useState({});
  const [selectedAdminRoles, setSelectedAdminRoles] = useState([]);

  const addAdminUserRole = async () => {
    const requestData = { employeeId: selectedAdminUser.employeeId, roles: [] };
    requestData.roles.push({
      employeeId: selectedAdminUser.employeeId,
      key: 'LocalAwardsCoordinator',
      organizationId: selectedOrganizationId,
    });
    const response = await employeeService.addRoles(requestData);
    if (response && response.ok) {
      showSuccess('Added roles successfully saved');
    }
    await sessionHelpers.initSession();
  };

  const onSelectedUser = async (user) => {
    setSelectedAdminUser(user);
    const response = await userService.getUserRoles(user.employeeId);
    if (response.ok) {
      console.log('roles ', response.data);
      setSelectedAdminRoles(response.data.filter((x) => x.key === 'LocalAwardsCoordinator'));
    }
  };

  function renderLocalAwardsCoordinatorMissions(data) {
    return (
      <>
        <div className="row mt-3">
          <div className="col-md-12">User has the Local Awards Coordinator Role for Mission(s):</div>
        </div>
        <ul>
          {data.map((item) => (
            <strong>
              <li>{item.missionName}</li>
            </strong>
          ))}
        </ul>
      </>
    );
  }

  function renderData(label, data) {
    return (
      <>
        <div className="row mt-3">
          <div className="col">{label}</div>
        </div>
        <div className="row">
          <div className="col">
            {!data && <span>&nbsp;</span>}
            {data && <strong>{data}</strong>}
          </div>
        </div>
      </>
    );
  }

  function renderSelectedUser(selectedUser) {
    return (
      <>
        {renderData('Name', selectedUser.name)}
        {renderData('Email', selectedUser.email)}
        {renderData('Position Title', selectedUser.positionTitle)}
      </>
    );
  }

  useEffect(() => {
    async function loadMissionParameter() {
      const response = await employeeService.getMissions();
      if (response && response.ok) {
        setMissions(response.data);
        setLoading(true);
      }
    }

    if (!loading) {
      loadMissionParameter();
    }
  }, [employeeService, loading, setLoading, setMissions]);

  
  useEffect(() => {   
    if (!checkRole('system_roles')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  return (
    <>
      <h4>Add a Local Awards Coordinator Role for a mission</h4>
      <div className="row pt-4" />
      <h5>Admin User</h5>
      <div className="row pt-2">
        <div className="col-12">Find the user that you want to assign a Local Awards Coordinator Role</div>
        <div className="col-6">
          <UserSearch value={selectedAdminUser} isGlobal setValue={(user) => onSelectedUser(user)} className="w-100" />
        </div>
      </div>
      {renderSelectedUser(selectedAdminUser)}
      {renderLocalAwardsCoordinatorMissions(selectedAdminRoles)}
      <div className="row pt-4" />
      <h5>Mission</h5>
      <div className="row pt-2">
        <div className="col-12">Select a mission</div>
        <div className="col-6">
          <Dropdown
            value={selectedOrganizationId}
            options={missions}
            optionLabel="name"
            optionValue="organizationId"
            filterBy="name"
            filter
            className="w-100"
            onChange={(e) => {
              setSelectedOrganizationId(e.value);
            }}
          />
        </div>
      </div>
      <div className="row pt-4">
        <div className="form-group col-6">
          <Button
            label="Add Role"
            icon="pi pi-save"
            className="p-button-primary mr-1"
            onClick={() => addAdminUserRole(true)}
            disabled={!selectedAdminUser.email}
          />
        </div>
      </div>
    </>
  );
}

export default React.memo(AdminRoles);
