import useApi from '../useApi';

export const useCeremonyService = () => {
  const api = useApi();

  const getListByMission = async (missionId) => {
    if (!missionId) {
      console.log('missionId is null');
      return null;
    }
    return api.get(`/ceremony/GetListByMission/${missionId}`);
  };

  const load = async (ceremonyId) => {
    if (!ceremonyId) {
      console.log('ceremonyId is null');
      return null;
    }
    return api.get(`/ceremony/LoadCeremony/${ceremonyId}`);
  };

  const save = async (data) => api.post('/ceremony/Save', data);

  return {
    getListByMission,
    load,
    save,
  };
};

export default useCeremonyService;
