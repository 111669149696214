import { useState, useReducer, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import differenceBy from 'lodash/differenceBy';

import { useAuthorization } from 'hooks';
import { useSignOffService } from 'hooks/signoff';
import { SessionContext } from 'contexts';
import {
  requestStatus,
  votingStatus,
  voteType,
  decisionType,
  nominationStatus,
  nominationAction,
} from 'constants/index';
import { useVotingService } from './useVotingService';
import { useNominationService } from '../nomination/useNominationService';

const initialState = {
  currentMode: null,
  currentUser: null,
  loadListRequest: requestStatus.REQUEST_NOT_INITIATED,
  loadCycleSummaryRequest: requestStatus.REQUEST_NOT_INITIATED,
  loadCommentsRequest: requestStatus.REQUEST_NOT_INITIATED,
  voteRequest: requestStatus.REQUEST_NOT_INITIATED,
  votersNamesRequest: requestStatus.REQUEST_NOT_INITIATED,
  requestNominationId: 0,
  isCycleDefined: false,
  currentCycleId: 0,
  currentPostId: 0,
  cycleSummary: {
    currentDate: null,
    cycle: null,
    chairName: null,
    members: [],
    nominationCounters: {
      nominationsCount: 0,
      nominatorsCount: 0,
      nomineesCount: 0,
    },
  },
  filterStatus: 'all',
  filterType: 0,
  filterTerm: '',
  filterSummary: 'all',
  loadedNominations: [],
  filteredNominations: [],
  detailedNomination: {},
  detailModalOpened: false,
  accordionActiveIndexes: [],
  isChangeModalOpen: false,
  isHideCitation: false,
  filters: {},
};

const filterNominationsByTerm = (nominations, filterTerm) => {
  if (!filterTerm) return nominations;

  const term = filterTerm.toLowerCase();
  return nominations.filter((item) => {
    let found = false;
    if (item.nominees && item.nominees.join('; ').toLowerCase().includes(term)) {
      found = true;
    }
    if (!found && item.nominationKey) {
      found = item.nominationKey.toLowerCase().includes(term);
    }
    return found || item.awardTypeName.toLowerCase().includes(term);
  });
};

const filterNominationsByStatus = (nominations, statusFilter) => {
  if (statusFilter === 'all') {
    return nominations;
  }
  let statusToFilter = null;
  if (statusFilter === 'approved') statusToFilter = votingStatus.APPROVED;
  if (statusFilter === 'disapproved') statusToFilter = votingStatus.DISAPPROVED;
  if (statusFilter === 'pending') statusToFilter = votingStatus.PENDING;
  if (statusFilter === 'pending_tie') statusToFilter = votingStatus.PENDING_TIE;
  return nominations.filter((item) => item.votingStatus === statusToFilter);
};

const filterNominationsBySummary = (nominations, summaryFilter, currentUser) => {
  // if (summaryFilter === 'all') {
  //   return nominations;
  // }

  if (summaryFilter === 'on_hold')
    return nominations.filter((item) => item.summary.totalOnHoldVoters?.includes(currentUser?.name));
  if (summaryFilter === 'disapproved')
    return nominations.filter((item) => item.summary.totalDisapprovedVoters?.includes(currentUser?.name));
  if (summaryFilter === 'approved')
    return nominations.filter((item) => item.summary.totalApprovedVoters?.includes(currentUser?.name));
  if (summaryFilter === 'recused')
    return nominations.filter((item) => item.summary.totalRecusedVoters?.includes(currentUser?.name));
  if (summaryFilter === 'not_voted')
    return nominations.filter(
      (item) => item.summary.notVotedYet === null || item.summary.notVotedYet?.includes(currentUser?.name)
    );
  return nominations;
};

const filterNominationsByType = (nominations, typeFilter) => {
  if (typeFilter === 0) {
    return nominations;
  }
  return nominations.filter((item) => item.awardTypeId === typeFilter);
};

const filterNominations = (nominations, filterTerm, filterStatus, filterType) => {
  return filterNominationsByType(
    filterNominationsByStatus(filterNominationsByTerm(nominations, filterTerm), filterStatus),
    filterType
  );
};

const reducer = (state, action) => {
  let idx = -1;
  let idx2 = -1;
  let idy = -1;
  switch (action.type) {
    case 'SET_CURRENT_MODE':
      return {
        ...state,
        currentMode: action.data,
        loadListRequest: requestStatus.REQUEST_NOT_INITIATED,
        loadCycleSummaryRequest: requestStatus.REQUEST_NOT_INITIATED,
        loadedNominations: [],
        filteredNominations: [],
        accordionActiveIndexes: [],
        currentCycleId: 0,
        isCycleDefined: false,
      };

    case 'LIST_REQUEST_STARTED':
      return { ...state, loadListRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'SET_CURRENT_USER':
      return { ...state, currentUser: action.data };

    case 'CYCLE_SUMMARY_REQUEST_STARTED':
      return {
        ...state,
        loadCycleSummaryRequest: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'COMMENTS_REQUEST_STARTED':
      return {
        ...state,
        loadCommentsRequest: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'VOTE_REQUEST_STARTED':
      return {
        ...state,
        voteRequest: requestStatus.REQUEST_IN_PROGRESS,
        requestNominationId: action.data,
      };

    case 'VOTERS_NAMES_REQUEST_STARTED':
      return {
        ...state,
        votersNamesRequest: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'SET_NOMINATIONS':
      return {
        ...state,
        loadedNominations: action.data,
        filteredNominations: action.data,
        loadListRequest: requestStatus.REQUEST_COMPLETED,
        accordionActiveIndexes: [],
      };

    case 'REMOVE_NOMINATION':
      idx = state.loadedNominations.findIndex((item) => item.id === action.data);
      idx2 = state.accordionActiveIndexes.indexOf(
        state.filteredNominations.findIndex((item) => item.id === action.data)
      );
      return {
        ...state,
        loadedNominations: [...state.loadedNominations.slice(0, idx), ...state.loadedNominations.slice(idx + 1)],
        voteRequest: requestStatus.REQUEST_COMPLETED,
        accordionActiveIndexes: [
          ...state.accordionActiveIndexes.slice(0, idx2),
          ...state.accordionActiveIndexes.slice(idx2 + 1),
        ],
      };

    case 'SET_NOMINATION_DETAILS': {
      idx = state.loadedNominations.findIndex((item) => item.id === action.data.id);
      idy = state.filteredNominations.findIndex((item) => item.id === action.data.id);

      return {
        ...state,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),
          {
            ...state.loadedNominations[idx],
            detailsLoaded: true,
            showCommentBox: false,
            newComment: '',
            justification: action.data.justification,
            citation: action.data.citation,
            missionName: action.data.missionName,
            sectionName: action.data.sectionName,
            agencyName: action.data.agencyName,
            awardTypeName: action.data.awardTypeName,
            awardReasons: action.data.awardReasons,
            activeVote: action.data.voteResult.activeVote || 0,
            activeVoteDescription: action.data.voteResult.activeVoteDescription || '',
            voteCreatedDate: action.data.voteResult.voteCreatedDate || null,
            lastComment: action.data.voteResult.lastComment,
            formOfRecognition: action.data.formOfRecognition,
            proposedFormOfRecognition: action.data.proposedFormOfRecognition,
            recommendedFormOfRecognition: action.data.recommendedFormOfRecognition,
            approvedFormOfRecognition: action.data.approvedFormOfRecognition,
            formOfRecognitionDescription: action.data.formOfRecognitionDescription,
            proposedAmount: action.data.proposedAmount,
            recommendedAmount: action.data.recommendedAmount,
            nominatorName: action.data.nominatorName,
            nominatorPositionTitle: action.data.nominatorPositionTitle,
            isNominationChanged: false,
            isHideCitation: action.data.isHideCitation,
          },
          ...state.loadedNominations.slice(idx + 1),
        ],
        filteredNominations: [
          ...state.filteredNominations.slice(0, idy),
          {
            ...state.filteredNominations[idy],
            detailsLoaded: true,
            showCommentBox: false,
            newComment: '',
            justification: action.data.justification,
            citation: action.data.citation,
            missionName: action.data.missionName,
            sectionName: action.data.sectionName,
            agencyName: action.data.agencyName,
            awardTypeName: action.data.awardTypeName,
            awardReasons: action.data.awardReasons,
            activeVote: action.data.voteResult.activeVote || 0,
            activeVoteDescription: action.data.voteResult.activeVoteDescription || '',
            voteCreatedDate: action.data.voteResult.voteCreatedDate || null,
            lastComment: action.data.voteResult.lastComment,
            formOfRecognition: action.data.formOfRecognition,
            proposedFormOfRecognition: action.data.proposedFormOfRecognition,
            recommendedFormOfRecognition: action.data.recommendedFormOfRecognition,
            approvedFormOfRecognition: action.data.approvedFormOfRecognition,
            formOfRecognitionDescription: action.data.formOfRecognitionDescription,
            proposedAmount: action.data.proposedAmount,
            recommendedAmount: action.data.recommendedAmount,
            nominatorName: action.data.nominatorName,
            nominatorPositionTitle: action.data.nominatorPositionTitle,
            isNominationChanged: false,
            isHideCitation: action.data.isHideCitation,
          },
          ...state.filteredNominations.slice(idy + 1),
        ],
      };
    }

    case 'SET_NOMINATION_HISTORY_AND_COMMENTS':
      idx = state.loadedNominations.findIndex((item) => item.id === action.data.nominationId);
      return {
        ...state,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),
          {
            ...state.loadedNominations[idx],
            history: action.data.history,
            historyLoaded: true,
          },
          ...state.loadedNominations.slice(idx + 1),
        ],
        detailedNomination: {
          ...state.detailedNomination,
          history: action.data.history,
          historyLoaded: true,
        },
        loadCommentsRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'SET_VOTERS_NAMES':
      idx = state.loadedNominations.findIndex((item) => item.id === action.data.nominationId);
      idy = state.filteredNominations.findIndex((item) => item.id === action.data.nominationId);
      return {
        ...state,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),
          {
            ...state.loadedNominations[idx],
            approvedBy: action.data.approvedBy,
            disapprovedBy: action.data.disapprovedBy,
            onHoldBy: action.data.onHoldBy,
            recusedBy: action.data.recusedBy,
            votersNamesLoaded: true,
          },
          ...state.loadedNominations.slice(idx + 1),
        ],
        filteredNominations: [
          ...state.filteredNominations.slice(0, idy),
          {
            ...state.filteredNominations[idy],
            approvedBy: action.data.approvedBy,
            disapprovedBy: action.data.disapprovedBy,
            onHoldBy: action.data.onHoldBy,
            recusedBy: action.data.recusedBy,
            votersNamesLoaded: true,
          },
          ...state.filteredNominations.slice(idy + 1),
        ],
        votersNamesRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'RESET_FILTERED_NOMINATIONS':
      return {
        ...state,
        filteredNominations: filterNominations(
          state.loadedNominations,
          state.filterTerm,
          state.filterStatus,
          state.filterType
        ),
      };

    case 'SET_FILTER_TERM':
      return {
        ...state,
        filterTerm: action.data,
        filteredNominations: filterNominations(
          state.loadedNominations,
          action.data,
          state.filterStatus,
          state.filterType
        ),
        accordionActiveIndexes: [],
      };

    case 'SET_FILTER_STATUS':
      return {
        ...state,
        filterStatus: action.data,
        filteredNominations: filterNominations(
          state.loadedNominations,
          state.filterTerm,
          action.data,
          state.filterType
        ),
        accordionActiveIndexes: [],
      };

    case 'SET_FILTER_TYPE': {
      const filters = {
        typeFilter: action.data,
        summaryFilter: state.filters.summaryFilter,
      };

      let newFilteredNominations = null;

      if (filters.typeFilter !== null) {
        newFilteredNominations = filterNominations(
          state.loadedNominations,
          state.filterTerm,
          state.filterStatus,
          action.data
        );
      }

      if (filters.summaryFilter) {
        newFilteredNominations = filterNominationsBySummary(
          newFilteredNominations ?? state.loadedNominations,
          filters.summaryFilter,
          state.currentUser
        );
      }

      return {
        ...state,
        filteredNominations: newFilteredNominations,
        filters,
        filterType: action.data,
        accordionActiveIndexes: [],
      };
    }

    case 'SET_FILTER_SUMMARY': {
      const filters = {
        summaryFilter: action.data,
        typeFilter: state.filters.typeFilter,
        currentUserName: state.currentUser,
      };

      let newFilteredNominations = null;

      if (filters.summaryFilter) {
        newFilteredNominations = filterNominationsBySummary(state.loadedNominations, action.data, state.currentUser);
      }

      if (filters.typeFilter) {
        newFilteredNominations = filterNominations(
          newFilteredNominations,
          state.filterTerm,
          state.filterStatus,
          filters.typeFilter
        );
      }

      return {
        ...state,
        filterSummary: action.data,
        filteredNominations: newFilteredNominations,
        filters,
        accordionActiveIndexes: [],
      };
    }

    case 'SET_CURRENT_CYCLE':
      return {
        ...state,
        currentCycleId: action.data,
        isCycleDefined: true,
        loadListRequest: requestStatus.REQUEST_NOT_INITIATED,
        loadCycleSummaryRequest: requestStatus.REQUEST_NOT_INITIATED,
        loadedNominations: [],
        filteredNominations: [],
      };

    case 'SET_CURRENT_POST':
      return {
        ...state,
        currentPostId: action.data,
        loadListRequest: requestStatus.REQUEST_NOT_INITIATED,
        loadCycleSummaryRequest: requestStatus.REQUEST_NOT_INITIATED,
        loadedNominations: [],
        filteredNominations: [],
      };

    case 'SET_CYCLE_SUMMARY':
      return {
        ...state,
        loadCycleSummaryRequest: requestStatus.REQUEST_COMPLETED,
        cycleSummary: action.data,
      };

    case 'UPDATE_CYCLE_COUNTERS':
      return {
        ...state,
        cycleSummary: {
          ...state.cycleSummary,
          nominationCounters: action.data,
        },
      };

    case 'UPDATE_VOTE':
      idx = state.loadedNominations.findIndex((item) => item.id === action.data.nominationId);
      idy = state.filteredNominations.findIndex((item) => item.id === action.data.nominationId);

      return {
        ...state,
        voteRequest: requestStatus.REQUEST_COMPLETED,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),

          {
            ...state.loadedNominations[idx],
            activeVote: action.data.voteOption,
            hasRecused: action.data.voteOption === voteType.RECUSED,
            showVotes: action.data.voteOption === voteType.APPROVED || action.data.activeVote === voteType.DISAPPROVED,
          },
          ...state.loadedNominations.slice(idx + 1),
        ],
        filteredNominations: [
          ...state.filteredNominations.slice(0, idy),
          {
            ...state.filteredNominations[idy],
            activeVote: action.data.voteOption,
            hasRecused: action.data.voteOption === voteType.RECUSED,
            showVotes: action.data.voteOption === voteType.APPROVED || action.data.activeVote === voteType.DISAPPROVED,
          },
          ...state.filteredNominations.slice(idy + 1),
        ],
      };

    case 'SET_VOTE_RESULT':
      idx = state.loadedNominations.findIndex((item) => item.id === action.data.nominationId);
      idy = state.filteredNominations.findIndex((item) => item.id === action.data.nominationId);

      if (action.data.activeVote === voteType.RECUSED) {
        return {
          ...state,
          voteRequest: requestStatus.REQUEST_COMPLETED,
          loadedNominations: [
            ...state.loadedNominations.slice(0, idx),
            {
              ...state.loadedNominations[idx],
              activeVote: action.data.activeVote,
              activeVoteDescription: action.data.activeVoteDescription,
              voteCreatedDate: action.data.voteCreatedDate,
              lastComment: action.data.lastComment,
              newComment: '',
              hasRecused: action.data.activeVote === voteType.RECUSED,
              summary: null,
              showVotes: true,
              votersNamesLoaded: false,
              approvedBy: null,
              disapprovedBy: null,
              onHoldBy: null,
              recusedBy: null,
            },
            ...state.loadedNominations.slice(idx + 1),
          ],
          filteredNominations: [
            ...state.filteredNominations.slice(0, idy),
            {
              ...state.filteredNominations[idy],
              activeVote: action.data.activeVote,
              activeVoteDescription: action.data.activeVoteDescription,
              voteCreatedDate: action.data.voteCreatedDate,
              lastComment: action.data.lastComment,
              newComment: '',
              hasRecused: action.data.activeVote === voteType.RECUSED,
              summary: null,
              showVotes: true,
              votersNamesLoaded: false,
              approvedBy: null,
              disapprovedBy: null,
              onHoldBy: null,
              recusedBy: null,
            },
            ...state.filteredNominations.slice(idy + 1),
          ],
        };
      }

      return {
        ...state,
        voteRequest: requestStatus.REQUEST_COMPLETED,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),
          {
            ...state.loadedNominations[idx],
            activeVote: action.data.activeVote,
            activeVoteDescription: action.data.activeVoteDescription,
            voteCreatedDate: action.data.voteCreatedDate,
            lastComment: action.data.lastComment,
            newComment: '',
            summary: {
              totalApproved: action.data.voteSummary.totalApproved,
              totalDisapproved: action.data.voteSummary.totalDisapproved,
              totalOnHold: action.data.voteSummary.totalOnHold,
              totalRecused: action.data.voteSummary.totalRecused,
            },
            hasRecused: action.data.activeVote === voteType.RECUSED,
            showVotes: true,
            votersNamesLoaded: false,
            approvedBy: null,
            disapprovedBy: null,
            onHoldBy: null,
            recusedBy: null,
          },
          ...state.loadedNominations.slice(idx + 1),
        ],
        filteredNominations: [
          ...state.filteredNominations.slice(0, idy),
          {
            ...state.filteredNominations[idy],
            activeVote: action.data.activeVote,
            activeVoteDescription: action.data.activeVoteDescription,
            voteCreatedDate: action.data.voteCreatedDate,
            lastComment: action.data.lastComment,
            newComment: '',
            summary: {
              totalApproved: action.data.voteSummary.totalApproved,
              totalDisapproved: action.data.voteSummary.totalDisapproved,
              totalOnHold: action.data.voteSummary.totalOnHold,
              totalRecused: action.data.voteSummary.totalRecused,
            },
            hasRecused: action.data.activeVote === voteType.RECUSED,
            showVotes: true,
            votersNamesLoaded: false,
            approvedBy: null,
            disapprovedBy: null,
            onHoldBy: null,
            recusedBy: null,
          },
          ...state.filteredNominations.slice(idy + 1),
        ],
      };

    case 'SET_NEW_COMMENT':
      idx = state.loadedNominations.findIndex((item) => item.id === action.data);
      idy = state.filteredNominations.findIndex((item) => item.id === action.data);
      return {
        ...state,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),
          {
            ...state.loadedNominations[idx],
            newComment: '',
            lastComment: state.loadedNominations[idx].newComment,
          },
          ...state.loadedNominations.slice(idx + 1),
        ],
        filteredNominations: [
          ...state.filteredNominations.slice(0, idy),
          {
            ...state.filteredNominations[idy],
            newComment: '',
            lastComment: state.filteredNominations[idy].newComment,
          },
          ...state.filteredNominations.slice(idy + 1),
        ],
        voteRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'OPEN_NOMINATION_DETAILS_MODAL':
      return {
        ...state,
        detailModalOpened: true,
        detailedNomination: action.data,
      };

    case 'CLOSE_NOMINATION_DETAILS_MODAL':
      return { ...state, detailModalOpened: false };

    case 'TOGGLE_COMMENT_BOX':
      idx = state.loadedNominations.findIndex((item) => item.id === action.data);
      idy = state.filteredNominations.findIndex((item) => item.id === action.data);
      return {
        ...state,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),
          {
            ...state.loadedNominations[idx],
            showCommentBox: !state.loadedNominations[idx].showCommentBox,
          },
          ...state.loadedNominations.slice(idx + 1),
        ],
        filteredNominations: [
          ...state.filteredNominations.slice(0, idy),
          {
            ...state.filteredNominations[idy],
            showCommentBox: !state.filteredNominations[idy].showCommentBox,
          },
          ...state.filteredNominations.slice(idy + 1),
        ],
      };

    case 'TOGGLE_NOMINATION_SELECTED':
      idx = state.loadedNominations.findIndex((item) => item.id === action.data);
      return {
        ...state,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),
          {
            ...state.loadedNominations[idx],
            selected: !state.loadedNominations[idx].selected,
          },
          ...state.loadedNominations.slice(idx + 1),
        ],
      };

    case 'TOGGLE_NOMINATION_ALL':
      return {
        ...state,
        filteredNominations: state.filteredNominations.map((item) => {
          item.selected = action.data && [votingStatus.APPROVED, votingStatus.DISAPPROVED].includes(item.votingStatus);
          return item;
        }),
      };

    case 'UPDATE_COMMENT':
      idx = state.loadedNominations.findIndex((item) => item.id === action.data.id);
      idy = state.filteredNominations.findIndex((item) => item.id === action.data.id);
      return {
        ...state,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),
          {
            ...state.loadedNominations[idx],
            newComment: action.data.newComment,
          },
          ...state.loadedNominations.slice(idx + 1),
        ],
        filteredNominations: [
          ...state.filteredNominations.slice(0, idy),
          {
            ...state.filteredNominations[idy],
            newComment: action.data.newComment,
          },
          ...state.filteredNominations.slice(idy + 1),
        ],
      };

    case 'UPDATE_ACCORDION_ACTIVE_INDEXES':
      return { ...state, accordionActiveIndexes: action.data };

    case 'SET_MESSAGE_MODAL_OPEN':
      return { ...state, isMessageModalOpen: true };

    case 'SET_MESSAGE_MODAL_CLOSE':
      return { ...state, isMessageModalOpen: false };

    case 'UPDATE_NOMINATION_CHANGES': {
      idx = state.loadedNominations.findIndex((item) => item.id === action.data.id);
      idy = state.filteredNominations.findIndex((item) => item.id === action.data.id);
      return {
        ...state,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),
          {
            ...state.loadedNominations[idx],
            ...action.data,
            isNominationChanged: true,
          },
          ...state.loadedNominations.slice(idx + 1),
        ],
        filteredNominations: [
          ...state.filteredNominations.slice(0, idy),
          {
            ...state.filteredNominations[idy],
            ...action.data,
            isNominationChanged: true,
          },
          ...state.filteredNominations.slice(idy + 1),
        ],
      };
    }

    case 'SET_NOMINATION_CHANGE_FOR_MULTIPLE_NOMINATIONS': {
      const modifiedNominationIds = action.data?.map((x) => x.id);
      const stateCopy = {
        ...state,
        loadedNominations: [
          ...state.loadedNominations.map((item) => {
            if (modifiedNominationIds.includes(item.id)) {
              const modifiedData = action.data.filter((x) => x.id === item.id)[0];
              return {
                ...item,
                ...modifiedData,
                isNominationChanged: true,
              };
            }
            return item;
          }),
        ],
        filteredNominations: [
          ...state.filteredNominations.map((item) => {
            if (modifiedNominationIds.includes(item.id)) {
              const modifiedData = action.data.filter((x) => x.id === item.id)[0];
              return {
                ...item,
                ...modifiedData,
                isNominationChanged: true,
              };
            }
            return item;
          }),
        ],
      };
      return stateCopy;
    }

    case 'SET_NOMINATION_CHANGE_FLAG_FALSE': {
      idx = state.loadedNominations.findIndex((item) => item.id === action.data);
      idy = state.filteredNominations.findIndex((item) => item.id === action.data);
      return {
        ...state,
        loadedNominations: [
          ...state.loadedNominations.slice(0, idx),
          { ...state.loadedNominations[idx], isNominationChanged: false },
          ...state.loadedNominations.slice(idx + 1),
        ],
        filteredNominations: [
          ...state.filteredNominations.slice(0, idy),
          { ...state.filteredNominations[idy], isNominationChanged: false },
          ...state.filteredNominations.slice(idy + 1),
        ],
      };
    }

    case 'SET_NOMINATIONS_FOR_SIGNOFF': {
      return {
        ...state,
        loadedNominations: state.loadedNominations.filter((item) => action.data.includes(item.id)),
        filteredNominations: state.filteredNominations.filter((item) => action.data.includes(item.id)),
      };
    }
    case 'SET_NOMINATION_FOR_SIGNOFF': {
      idx = state.loadedNominations.findIndex((item) => item.id === action.data);
      idy = state.filteredNominations.findIndex((item) => item.id === action.data);
      return {
        ...state,
        loadedNominations: [...state.loadedNominations.slice(0, idx), ...state.loadedNominations.slice(idx + 1)],
        filteredNominations: [...state.filteredNominations.slice(0, idy), ...state.filteredNominations.slice(idy + 1)],
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const useBureauVotingForm = () => {
  const { sessionStore } = useContext(SessionContext);
  const [store, dispatch] = useReducer(reducer, initialState);
  const { getAvailableBureauCyclesForFeature } = useAuthorization();
  const service = useVotingService();
  const signOffService = useSignOffService();
  const nominationService = useNominationService();
  const { checkCyclePermission } = useAuthorization();
  const [isReadOnly, setIsReadOnly] = useState(true);
  const { currentMissionId, employeeUser } = sessionStore;

  const { mode } = useParams();

  const isSignOffMode = useCallback(() => mode.toLowerCase() === 'signoff', [mode]);

  const getCurrentNominationStatus = useCallback(() => {
    return mode.toLowerCase() === 'signoff'
      ? [nominationStatus.PENDING_BUREAU_COMMITTEE_CHAIR, nominationStatus.PENDING_BUREAU_COMMITTEE_VOTE]
      : [nominationStatus.PENDING_BUREAU_COMMITTEE_VOTE];
  }, [mode]);

  const getTitle = useCallback(() => {
    switch (mode.toLowerCase()) {
      case 'signoff':
        return 'Bureau Committee Sign Off';
      default:
        return 'Bureau Committee Voting';
    }
  }, [mode]);

  const getSelected = () => store.filteredNominations.filter((item) => item.selected);

  const getNominationById = (id) => store.filteredNominations.find((item) => item.id === id);

  const helperFunctions = {
    isBureauVoting: () => true,

    moveToSignOff: async (nominations) => {
      const requestDto = nominations.map((nomination) => ({
        nominationId: nomination.id,
        employeeId: sessionStore.employeeUser.employeeId,
      }));
      const response = await service.sendBureauNominationToSignOff(requestDto);
      if (response && response.ok) {
        await helperFunctions.updateCycleCounters();
        dispatch({ type: 'SET_NOMINATIONS_FOR_SIGNOFF', data: nominations });
      }
    },

    sendVote: async (nominationId, voteOption) => {
      dispatch({ type: 'VOTE_REQUEST_STARTED', data: nominationId });

      if (voteOption === voteType.RECUSED) {
        dispatch({ type: 'UPDATE_VOTE', data: { nominationId, voteOption } });
      }

      if (voteOption !== voteType.APPROVED) {
        dispatch({ type: 'TOGGLE_COMMENT_BOX', data: nominationId });
      }

      const nomination = store.filteredNominations.find((item) => item.id === nominationId);
      const requestData = {
        nominationId,
        employeeId: sessionStore.employeeUser.employeeId,
        vote: voteOption,
        decision: decisionType.NONE,
        comment: nomination.newComment,
      };
      const response = await service.vote(requestData);
      if (response && response.ok) {
        if (voteOption === voteType.NONE) {
          dispatch({ type: 'SET_NEW_COMMENT', data: nomination.id });
        } else {
          dispatch({ type: 'SET_VOTE_RESULT', data: response.data });
        }
      }
      return response.ok;
    },

    sendDecision: async (nominationId, decisionOption) => {
      dispatch({ type: 'VOTE_REQUEST_STARTED', data: nominationId });
      const nomination = store.filteredNominations.find((item) => item.id === nominationId);
      const requestData = {
        nominationId,
        employeeId: sessionStore.employeeUser.employeeId,
        vote: voteType.NONE,
        decision: decisionOption,
        comment: nomination.newComment,
        approvedAmount: nomination.approvedAmount ?? nomination.recommendedAmount,
        formOfRecognition: nomination.formOfRecognition,
        awardTypeId: nomination.awardTypeId,
      };

      let moveForward = true;

      if (decisionOption === decisionType.CHANGE) {
        moveForward = false;

        const nomineesList = nomination.nominees.map((item) => ({
          nominationId: nomination.id,
          employeeId: item.key,
        }));

        const nominationData = {
          nominationId: nomination.id || 0,
          userEmployeeId: sessionStore.employeeUser.employeeId,
          awardTypeId: nomination.awardTypeId,
          otherReason: nomination.otherReason,
          nominatorId: nomination.nominatorId,
          missionId: nomination.missionId,
          postId: nomination.postId,
          sectionId: nomination.sectionId,
          cycleId: nomination.cycleId,
          citation: nomination.citation,
          justification: nomination.justification,
          formOfRecognition: nomination.formOfRecognition,
          proposedFormOfRecognition: nomination.proposedFormOfRecognition,
          recommendedFormOfRecognition: nomination.recommendedFormOfRecognition,
          approvedFormOfRecognition: nomination.approvedFormOfRecognition,
          proposedAmount: nomination.proposedAmount,
          recommendedAmount: nomination.recommendedAmount,
          approvedAmount: nomination.approvedAmount,
          fiscalStrip: nomination.fiscalStrip,
          nominationEmployees: nomineesList,
          nominationAction: nominationAction.CHANGE,
        };
        const notResponse = await nominationService.saveNomination(nominationData);

        if (notResponse && notResponse.ok) {
          window.location.reload(true);
          dispatch({
            type: 'SET_NOMINATION_CHANGE_FLAG_FALSE',
            data: nomination.id,
          });
        }
      }

      const response = await service.setDecision(requestData);

      if (response && response.ok && decisionOption !== decisionType.NONE) {
        dispatch({ type: 'REMOVE_NOMINATION', data: nomination.id });
        helperFunctions.updateCycleCounters();
      }
      if (decisionOption === decisionType.NONE) {
        dispatch({ type: 'SET_NEW_COMMENT', data: nomination.id });
      }
      if (moveForward === true) {
        dispatch({ type: 'RESET_FILTERED_NOMINATIONS' });
        return response.ok;
      }
      return null;
    },

    approvesStatusToDecision: (status) => {
      if (status === votingStatus.APPROVED) return decisionType.ENDORSE;
      if (status === votingStatus.DISAPPROVED) return decisionType.DISAPPROVED;
      return decisionType.NONE;
    },

    signOffSelected: async () => {
      const nominationsToSignOff = [];
      getSelected().forEach((item) => {
        nominationsToSignOff.push({
          nominationId: item.id,
          employeeId: sessionStore.employeeUser.employeeId,
          decision:
            item.overrideVoting === true
              ? decisionType.CHANGE
              : helperFunctions.approvesStatusToDecision(item.votingStatus),
          comment: null,
          approvedAmount: item.approvedAmount ?? item.recommendedAmount,
          formOfRecognition: item.formOfRecognition,
          awardTypeId: item.awardTypeId,
        });
      });

      const response = await service.signOffNominations(nominationsToSignOff);
      if (response.ok) {
        dispatch({
          type: 'SET_NOMINATIONS',
          data: differenceBy(store.loadedNominations, getSelected(), 'id'),
        });
      }
      return response.ok;
    },

    getVotersNames: async (nominationId) => {
      const nomination = store.loadedNominations.find((item) => item.id === nominationId);
      if (nomination.votersNamesLoaded) {
        return;
      }
      if (store.votersNamesRequest === votingStatus.REQUEST_IN_PROGRESS) {
        return;
      }

      dispatch({ type: 'VOTERS_NAMES_REQUEST_STARTED' });
      const response = await service.getBureauVoters(nominationId);
      const approvedBy = response.data.filter((item) => item.vote === voteType.APPROVED).map((item) => item.name);
      const disapprovedBy = response.data.filter((item) => item.vote === voteType.DISAPPROVED).map((item) => item.name);
      const onHoldBy = response.data.filter((item) => item.vote === voteType.ONHOLD).map((item) => item.name);
      const recusedBy = response.data.filter((item) => item.vote === voteType.RECUSED).map((item) => item.name);
      dispatch({ type: 'SET_VOTERS_NAMES', data: { nominationId, approvedBy, disapprovedBy, onHoldBy, recusedBy } });
    },

    onClickItem: async (index) => {
      const nomination = store.filteredNominations[index];
      if (nomination.detailsLoaded) {
        return;
      }

      const requestData = {
        nominationId: nomination.id,
        userEmployeeId: sessionStore.employeeUser.employeeId,
        missionId: nomination.missionId,
      };
      const response = await service.getNominationDetails(requestData);
      if (response && response.ok) {
        dispatch({ type: 'SET_NOMINATION_DETAILS', data: response.data });
      }
    },

    onClickCheck: (nominationId) => {
      dispatch({ type: 'TOGGLE_NOMINATION_SELECTED', data: nominationId });
      dispatch({ type: 'RESET_FILTERED_NOMINATIONS' });
    },

    onClickSelectAll: (flag) => {
      dispatch({ type: 'TOGGLE_NOMINATION_ALL', data: flag });
    },

    onClickStatusFilter: (selectedStatus) => {
      dispatch({ type: 'SET_FILTER_STATUS', data: selectedStatus });
    },

    onClickTypeFilter: (selectedType) => {
      dispatch({ type: 'SET_FILTER_TYPE', data: selectedType });
    },

    onClickSummaryFilter: (selectedSummary) => {
      dispatch({ type: 'SET_FILTER_SUMMARY', data: selectedSummary });
    },

    onClickMoreDetails: async (nomination) => {
      dispatch({ type: 'OPEN_NOMINATION_DETAILS_MODAL', data: nomination });
      const response = await nominationService.getNominationHistory({
        userEmployeeId: sessionStore.employeeUser.employeeId,
        nominationId: nomination.id,
      });
      if (response && response.ok) {
        dispatch({
          type: 'SET_NOMINATION_HISTORY_AND_COMMENTS',
          data: { history: response.data, nominationId: nomination.id },
        });
      }
    },

    loadHistoryDetails: () => {
      return new Promise((resolve) => {
        store.loadedNominations.map(async (nomination) => {
          if (nomination.historyLoaded) {
            return;
          }
          const response = await nominationService.getNominationHistory({
            userEmployeeId: sessionStore.employeeUser.employeeId,
            nominationId: nomination.id,
          });
          if (response && response.ok) {
            dispatch({
              type: 'SET_NOMINATION_HISTORY_AND_COMMENTS',
              data: { history: response.data, nominationId: nomination.id },
            });
          }
        });

        resolve();
      });
    },

    onClickShowComments: (nominationId) => {
      dispatch({ type: 'TOGGLE_COMMENT_BOX', data: nominationId });
    },

    onChangeNewComment: (nominationId, value) => {
      dispatch({ type: 'UPDATE_COMMENT', data: { id: nominationId, newComment: value } });
    },

    getDefaultCycleId: () => {
      const availableCycles = helperFunctions.getAvailableCycles();
      if (availableCycles.length) {
        return availableCycles[0].id;
      }
      return 0;
    },

    setCurrentCycle: (cycleId) => {
      let newCycleId = cycleId;
      if (!newCycleId) {
        newCycleId = helperFunctions.getDefaultCycleId();
      }
      dispatch({ type: 'SET_CURRENT_CYCLE', data: newCycleId });
    },

    setCurrentPostId: (postId) => {
      dispatch({ type: 'SET_CURRENT_POST', data: postId });
    },

    setFilterTerm: (term) => dispatch({ type: 'SET_FILTER_TERM', data: term }),

    getAvailableCycles: () =>
      getAvailableBureauCyclesForFeature(helperFunctions.isSignOffMode() ? 'bureau_signoff' : 'bureau_voting'),

    isPendingMode: () => mode.toLowerCase() === 'pending',

    loadNominationsPendingSignoff: async () => {
      dispatch({ type: 'LIST_REQUEST_STARTED' });

      const requestData = {
        employeeId: sessionStore.employeeUser.employeeId,
        missionId: currentMissionId,
        cycleId: store.currentCycleId,
        postId: store.currentPostId,
        statusList: getCurrentNominationStatus(),
      };
      const response = await service.getBureauNominations(requestData);
      if (response && response.ok) {
        dispatch({ type: 'SET_NOMINATIONS', data: response.data });
      }
    },

    loadCurrentUser: () => {
      dispatch({ type: 'SET_CURRENT_USER', data: employeeUser });
    },

    loadCycleSummary: async () => {
      dispatch({ type: 'CYCLE_SUMMARY_REQUEST_STARTED' });
      const requestData = {
        userEmployeeId: sessionStore.employeeUser.employeeId,
        missionId: currentMissionId,
        cycleId: store.currentCycleId,
        statusList: getCurrentNominationStatus(),
      };
      const response = await signOffService.getBureauCycleSummary(requestData);
      if (response && response.ok) {
        dispatch({ type: 'SET_CYCLE_SUMMARY', data: response.data });
      }
    },

    updateCycleCounters: async () => {
      const requestData = {
        employeeId: sessionStore.employeeUser.employeeId,
        missionId: currentMissionId,
        cycleId: store.currentCycleId,
        statusList: getCurrentNominationStatus(),
      };
      const response = await signOffService.getCycleCounters(requestData);
      if (response && response.ok) {
        dispatch({ type: 'UPDATE_CYCLE_COUNTERS', data: response.data });
      }
    },

    toggleAccordionActiveIndex: (idx) => {
      const indexesArr = [...store.accordionActiveIndexes];
      const foundIndex = indexesArr.indexOf(idx);
      if (foundIndex === -1) {
        indexesArr.push(idx);
      } else {
        indexesArr.splice(foundIndex, 1);
      }
      dispatch({ type: 'UPDATE_ACCORDION_ACTIVE_INDEXES', data: indexesArr });
    },

    setAccordionActiveIndexes: (indexes) => {
      dispatch({ type: 'UPDATE_ACCORDION_ACTIVE_INDEXES', data: indexes });
    },

    isDecisionButtonsDisabled: useCallback(() => {
      if (store.isCycleDefined) {
        if (checkCyclePermission('can_signoff_bureau', store.currentCycleId)) {
          setIsReadOnly(false);
        }
      }
      return isReadOnly;
    }, [store, checkCyclePermission, isReadOnly]),

    isVotingOpen: useCallback(() => {
      if (!store.cycleSummary.cycle) return false;

      const currentDate = new Date(store.cycleSummary.currentDate).setHours(0, 0, 0, 0);
      const jcacStart = new Date(store.cycleSummary.cycle.committeeStartDate);
      const jcacEnd = new Date(store.cycleSummary.cycle.committeeEndDate);
      return currentDate >= jcacStart && currentDate <= jcacEnd;
    }, [store.cycleSummary]),

    canUserVote: useCallback(() => {
      return checkCyclePermission('can_vote_bureau', store.currentCycleId);
    }, [checkCyclePermission, store.currentCycleId]),

    onCloseMessageModalClick: () => {
      dispatch({ type: 'SET_MESSAGE_MODAL_CLOSE' });
    },

    onSaveNominationChanges: (updatedNominations) => {
      if (typeof updatedNominations === 'object' && !Array.isArray(updatedNominations)) {
        dispatch({ type: 'UPDATE_NOMINATION_CHANGES', data: updatedNominations });
      } else {
        dispatch({ type: 'SET_NOMINATION_CHANGE_FOR_MULTIPLE_NOMINATIONS', data: updatedNominations });
      }

      dispatch({ type: 'SET_CHANGE_MODAL_CLOSE' });
      dispatch({ type: 'SET_MESSAGE_MODAL_OPEN' });
    },

    isSignOffMode,
    getSelected,
    getNominationById,
    getTitle,
  };

  return { formStore: store, dispatch, helperFunctions };
};

export default useBureauVotingForm;
