import React, { useContext, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { SessionContext } from 'contexts';
import { requestStatus, approvalStatus, formOfRecognition, nominationStatus } from 'constants/index';
import { formatDate } from 'utils/dateHelpers';
import { ActionButton, Grid, SkeletonTable } from 'components';
import { InputText } from 'primereact/inputtext';
import FiscalStripModal from 'views/NominationsList/FiscalStripModal';

function NomineesList({
  formStore,
  selectedNomination,
  helperFunctions,
  onClickApproveNominee,
  onClickRemoveNotEligibleNominee,
}) {
  const { sessionStore } = useContext(SessionContext);
  const { isRejectedNomineesVisible } = formStore;
  const { employeeId } = sessionStore.employeeUser;
  let { nomination } = formStore;
  nomination = selectedNomination ?? nomination;
  const [nomineesList, setNomineesList] = useState(nomination?.nominees);
  let loadRequest = requestStatus.REQUEST_NOT_INITIATED;

  if (selectedNomination !== undefined && selectedNomination !== null) {
    loadRequest = selectedNomination.nominationLoadRequest;
  } else {
    loadRequest = formStore.nominationLoadRequest;
  }

  const mode = helperFunctions.getMode();
  const isNewMode = useCallback(() => mode === 'new', [mode]);
  const isEditMode = useCallback(() => mode === 'edit', [mode]);
  const isApproveMode = useCallback(() => mode === 'approve', [mode]);
  const isHRReviewMode = useCallback(() => mode === 'review', [mode]);
  const isFinancialMode = useCallback(() => mode === 'financialinfo', [mode]);
  const isFinancialSignOffMode = useCallback(() => mode === 'finance', [mode]);
  const isExecSignOffMode = useCallback(() => mode === 'exec', [mode]);
  const isHistoryButtonVisible = useCallback(
    () =>
      mode === 'review' ||
      mode === 'ExternalApproval' ||
      mode === 'ongoing' ||
      mode === 'disapproved' ||
      mode === 'sendtoops' ||
      mode === 'completed',
    [mode]
  );
  useEffect(() => {
    const newList = isRejectedNomineesVisible
      ? nomination?.nominees
      : nomination?.nominees.filter((item) => {
          return item.approvalStatus !== approvalStatus.DISAPPROVED;
        });
    setNomineesList(newList);
  }, [isRejectedNomineesVisible, nomination?.nominees]);

  const sectionBodyTemplate = (rowData) => {
    return <span>{`${rowData.sectionName || rowData.sectionShortName} @ ${rowData.postName}`}</span>;
  };

  const getFormOfRecognitionUnit = useCallback((option) => {
    switch (option) {
      case formOfRecognition.CASH_ONLY:
        return 'USD';
      case formOfRecognition.TIMEOFF_ONLY:
        return 'Hours';
      default:
        return '';
    }
  }, []);

  const amountBodyTemplate = () => {
    return (
      <span>
        {helperFunctions.isManagementMode() ? nomination.recommendedAmount : nomination.approvedAmount}
        &nbsp;
        {getFormOfRecognitionUnit(nomination.formOfRecognition)}
      </span>
    );
  };

  const positionBodyTemplate = (rowData) => {
    const currentNominee = nomination.nominees.find((_) => _.employeeId === rowData.employeeId);
    return (
      <>
        <div className="text-nowrap">
          {rowData.positionTitle || ''}
          {(isHRReviewMode() || isNewMode()) && (
            <ActionButton
              tooltip="Edit previous job title"
              className="btn-edit-previous-position p-button-text p-button-plain px-2"
              onClick={() => helperFunctions.onClickToggleEditPosition(rowData)}
              icon={['far', 'pencil']}
            />
          )}
        </div>
        {rowData.isPositionEditable && (
          <div className="d-flex">
            <input
              className="form-control"
              type="text"
              value={currentNominee.previousPositionTitle || ''}
              onChange={(e) => helperFunctions.onChangePreviousPosition(rowData, e.target.value)}
              onBlur={() => helperFunctions.onClickToggleEditPosition(rowData)}
              onKeyUp={(e) => e.key === 'Enter' && helperFunctions.onClickToggleEditPosition(rowData)}
              placeholder="Previous Job Title"
            />
            <ActionButton
              tooltip="Position held during period covered by nomination if different than present"
              className="btn-edit-previous-position p-button-text p-button-plain px-2"
              icon={['far', 'question-circle']}
            />
          </div>
        )}
        {!rowData.isPositionEditable && rowData.previousPositionTitle && (
          <div className="previous-position mb-0">{rowData.previousPositionTitle}</div>
        )}
      </>
    );
  };

  function renderApproveRejectButtons(nomineeItem) {
    const isCurrentUserTheNomineeSupervisor = () => nomineeItem.usdhSupervisorId === employeeId;
    const isSameSupervisor = nomineeItem.usdhSupervisorId === nomineeItem.originalUSDHSupervisorId;
    const approvedBy = !isSameSupervisor ? nomineeItem.originalUSDHSupervisorName : null;
    if (nomineeItem.approvalStatus !== approvalStatus.PENDING) {
      return (
        <div className="text-right">
          {nomineeItem.approvalStatus === approvalStatus.APPROVED && (
            <span>
              Approved on {formatDate(nomineeItem.approvalDate)}
              {approvedBy !== null && <span className="previous-position"> by {approvedBy}</span>}
            </span>
          )}
          {nomineeItem.approvalStatus === approvalStatus.DISAPPROVED && (
            <span className="previous-position">
              Disapproved on {formatDate(nomineeItem.approvalDate)}{' '}
              {approvedBy !== null && <span className="previous-position"> by {approvedBy}</span>}
            </span>
          )}
        </div>
      );
    }

    if (isCurrentUserTheNomineeSupervisor()) {
      return (
        <div>
          <Button
            label="Approve"
            icon="pi pi-check"
            className="p-button-primary btn-sm w-100"
            onClick={() => onClickApproveNominee(nomineeItem.id, approvalStatus.APPROVED)}
          />
          <Button
            label="Disapprove"
            icon="pi pi-times"
            className="p-button-danger btn-sm w-100 mt-1"
            onClick={() => onClickApproveNominee(nomineeItem.id, approvalStatus.DISAPPROVED)}
          />
        </div>
      );
    }
    return <div>Pending Supervisor Approval</div>;
  }

  const actionBodyTemplate = (rowData) => {
    // eslint-disable-next-line no-return-assign
    return (
      <>
        <div className="d-flex">
          {(isNewMode() || isEditMode()) && (
            <ActionButton
              className="p-button-text p-button-plain px-2"
              onClick={() => helperFunctions.removeNomineeIdx(rowData)}
              icon={['fas', 'user-minus']}
              tooltip="Remove nominee"
            />
          )}

          {isHistoryButtonVisible() && (
            <ActionButton
              tooltip="Nominee History"
              className="p-button-text p-button-plain px-2"
              onClick={() => helperFunctions.loadEmployeeHistory(rowData.employeeId)}
              icon={['fas', 'stream']}
            />
          )}
          {isHRReviewMode() &&
            !(rowData.approvedByRole === 30 && rowData.approvalStatus === approvalStatus.DISAPPROVED) && (
              <ActionButton
                className="p-button-text p-button-plain px-2"
                onClick={() =>
                  onClickRemoveNotEligibleNominee(
                    rowData.id,
                    rowData.approvalStatus === approvalStatus.DISAPPROVED
                      ? approvalStatus.APPROVED
                      : approvalStatus.DISAPPROVED
                  )
                }
                icon={
                  rowData.approvalStatus === approvalStatus.DISAPPROVED && rowData.approvedByRole === 60
                    ? ['fas', 'user-plus']
                    : ['fas', 'user-minus']
                }
                style={{
                  color:
                    rowData.approvalStatus === approvalStatus.DISAPPROVED && rowData.approvedByRole === 60
                      ? 'green'
                      : 'orangered',
                }}
                tooltip={
                  rowData.approvalStatus === approvalStatus.DISAPPROVED && rowData.approvedByRole === 60 // HR
                    ? 'Restore nominee'
                    : 'Remove nominee'
                }
              />
            )}
          {(isFinancialMode() || isFinancialSignOffMode()) && (
            <>
              <ActionButton
                tooltip="Add/Edit Fiscal Strip"
                className="p-button-text p-button-plain px-2"
                onClick={() => helperFunctions.openFiscalStripModal(rowData.employeeId, rowData.fiscalStrip)}
                icon={['far', 'pencil']}
              />
              <ActionButton
                tooltip="Assign this fiscal strip to all the nominees"
                className="p-button-text p-button-plain px-2"
                onClick={() => {
                  helperFunctions.onClickAssignFiscalStripToAllNominees(
                    selectedNomination?.nominationId,
                    rowData.fiscalStrip
                  );
                }}
                icon={['fas', 'paper-plane']}
              />

              {(isFinancialMode() || isFinancialSignOffMode()) && formStore?.isFiscalStripModalOpen && (
                <FiscalStripModal
                  fiscalData={formStore.fiscalData}
                  agenciesAndBureaus={formStore.availableParams}
                  isFiscalStripModalOpen={formStore?.isFiscalStripModalOpen}
                  onClickToggleModal={helperFunctions.closeFiscalStripModal}
                  nominationId={nomination.id}
                  onSave={helperFunctions.onUpdateFiscalStrip}
                />
              )}
            </>
          )}
        </div>
        <div className="float-left">{isApproveMode() && nomination.nominationStatus === nominationStatus.PENDING_USDH_APPROVAL && renderApproveRejectButtons(rowData)}</div>
      </>
    );
  };

  const fiscalStripBodyTemplate = (currentNominee) => {
    function nomineeStatusFiscalStrip() {
      let nomineeFiscalStrip = '';
      if (currentNominee.approvalStatus === 2) {
        nomineeFiscalStrip = 'NO FISCAL STRIP (nominee disapproved)';
        currentNominee.fiscalStrip = nomineeFiscalStrip;
      }
      return currentNominee.fiscalStrip;
    }
    return (
      <div>
        {currentNominee.isFiscalStripEditable ? (
          <>
            <div className="d-flex">
              <InputText
                className="form-control show-item"
                style={{ fontSize: 14 }}
                value={currentNominee.fiscalStrip ?? ''}
              />
            </div>
            {currentNominee.fiscalStrip && currentNominee.fiscalStrip.length > 70 && (
              <i className="text-danger">Fiscal Strip cannot be more than 70 characters</i>
            )}
          </>
        ) : (
          <div>{currentNominee.fiscalStrip || nomineeStatusFiscalStrip()}</div>
        )}
      </div>
    );
  };

  const accordionHeader = () => (
    <div>
      Nominees (<span>{nomineesList.length}</span>)
    </div>
  );

  return (
    <>
      <div className="row">
        <div className="col-6 d-flex align-items-end" />

        <div className="col-6">
          {isNewMode() || isEditMode() ? (
            <div className="float-right">
              <Button
                label="Add Nominee(s)"
                icon="pi pi-user-plus"
                className="p-button-primary"
                disabled={isHRReviewMode()}
                onClick={helperFunctions.onClickOpenEmployeeSearchModal}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="row col-12 table-responsive pt-2 mx-0 px-0">
        {loadRequest !== requestStatus.REQUEST_COMPLETED && <SkeletonTable colsSize={4} rowsSize={4} />}
        {loadRequest === requestStatus.REQUEST_COMPLETED && (
          <Accordion activeIndex={0} className="nominees-accordion">
            <AccordionTab header={accordionHeader()}>
              <Grid items={nomineesList} dataKey="employeeId" sortMode="single" editMode="row">
                <Column rowEditor />
                <Column field="name" header="Name" sortable />
                <Column field="Section" header="Section/Post" body={sectionBodyTemplate} sortable />
                {!isFinancialMode() && !isFinancialSignOffMode() && (
                  <Column field="positionTitle" header="Job Title" body={positionBodyTemplate} sortable />
                )}
                {(isFinancialMode() || isFinancialSignOffMode()) && (
                  <Column field="fiscalStrip" header="Fiscal Strip" body={fiscalStripBodyTemplate} sortable />
                )}

                {isFinancialMode() ||
                  (isFinancialSignOffMode() && (
                    <Column field="amount" header="Amount" body={amountBodyTemplate} sortable />
                  ))}

                <Column field="usdhSupervisorName" header="USDH Supervisor" sortable />
                {!isExecSignOffMode() ? <Column rowEditor header="Action" body={actionBodyTemplate} /> : null}
              </Grid>
            </AccordionTab>
          </Accordion>
        )}
      </div>
    </>
  );
}
NomineesList.propTypes = {
  formStore: PropTypes.object.isRequired,
  selectedNomination: PropTypes.object,
  helperFunctions: PropTypes.object.isRequired,
  onClickRemoveNotEligibleNominee: PropTypes.func,
  onClickApproveNominee: PropTypes.func,
};

NomineesList.defaultProps = {
  selectedNomination: null,
  onClickRemoveNotEligibleNominee: null,
  onClickApproveNominee: null,
};

export default React.memo(NomineesList);
