import React from 'react';
import adgLogo from 'assets/images/adg.png';
import dosSeal from 'assets/images/dos_seal3.png';

function Footer() {
  return (
    <footer className="row d-print-none">
      <div className="col-12 col-sm-6 text-sm-left">
        <img src={dosSeal} className="footer-logo" alt="U.S. Department of State Logo" />
        <h4 className="text-text mt-3">U.S. Department of State</h4>
      </div>

      <div className="col-12 col-sm-6 text-sm-right mt-3">
        <span className="font-weight-light">Powered by</span>
        <img
          style={{ marginLeft: '6px', marginTop: '-7px' }}
          src={adgLogo}
          height="30"
          alt="ADG (Application Development Group)"
          title="ADG (Application Development Group)"
        />
      </div>
    </footer>
  );
}

export default Footer;
