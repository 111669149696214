import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { faIdBadge } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { SkeletonTable } from 'components';
import { useNavigationLinks, useAuthorization } from 'hooks';
import { useEmployeeService } from 'hooks/admin';
import { requestStatus } from 'constants/index';

const initialState = {
  listSuperviseesResult: [],
  listSuperviseesStatus: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'START_EMPLOYEES_BY_SUPERVISOR_LOAD':
      return { ...state, listSuperviseesStatus: requestStatus.REQUEST_IN_PROGRESS };

    case 'UPDATE_LIST_SUPERVISEES_RESULT':
      return { ...state, listSuperviseesResult: action.data, listSuperviseesStatus: requestStatus.REQUEST_COMPLETED };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function SupervisedBy() {
  const { goToWithQueryParams, goTo } = useNavigationLinks();
  const { checkRole } = useAuthorization();
  const [state, dispatch] = useReducer(reducer, initialState);
  const employeeService = useEmployeeService();
  const { id } = useParams();

  useEffect(() => {
    const loadEmployeesBySupervisor = async () => {
      dispatch({ type: 'START_EMPLOYEES_BY_SUPERVISOR_LOAD' });
      const response = await employeeService.getEmployeesBySupervisorId(id);
      if (response && response.ok) {
        dispatch({
          type: 'UPDATE_LIST_SUPERVISEES_RESULT',
          data: response.data,
        });
      }
    };

    if (state.listSuperviseesStatus === requestStatus.REQUEST_NOT_INITIATED) {
      loadEmployeesBySupervisor();
    }
  }, [id, employeeService, state.listSuperviseesStatus]);

  useEffect(() => {
    if (!checkRole('hr_officer')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);


  const nameWithBadgeTemplate = (rowData) => {
    let colorClass = 'badge-les';
    let titleDesc = 'Local Staff';
    if (!rowData.isLocalStaff) {
      colorClass = 'badge-usdh';
      titleDesc = 'USDH';
    }
    return (
      <>
        <i className={colorClass} title={titleDesc}>
          <FontAwesomeIcon size="lg" icon={faIdBadge} fixedWidth />
        </i>
        <span>{rowData.name}</span>
      </>
    );
  };

  const supervisorName = state.listSuperviseesResult.map((item) => item.supervisorName);

  return (
    <>
      <h5>Employees Supervised by {supervisorName[0]} </h5>

      <div>
        {state.listSuperviseesStatus === requestStatus.REQUEST_IN_PROGRESS && (
          <SkeletonTable colsSize={3} rowsSize={5} />
        )}
        {state.listSuperviseesStatus === requestStatus.REQUEST_COMPLETED && (
          <DataTable
            value={state.listSuperviseesResult}
            className="p-datatable-striped all-awards-datatable-result"
            scrollable
            scrollHeight="400px"
            autoLayout
            metaKeySelection={false}
            dataKey="id"
            emptyMessage="No records found."
          >
            <Column className="w-25" field="name" header="Name" body={nameWithBadgeTemplate} />
            <Column className="w-25" field="positionTitle" header="Title" />
            <Column className="w-20" field="email" header="Email" />
          </DataTable>
        )}
      </div>
      <div>
        <Button
          label="OK"
          icon="pi pi-check"
          className="p-button-primary mr-1 mt-2 w-5"
          onClick={() => goToWithQueryParams('employee_search', { key: 'restoreResult', value: 'yes' })}
        />
      </div>
    </>
  );
}

export default SupervisedBy;
