export const ckEditorConfig = {
  language: 'en',
  styleSet: 'default',
  customConfig: '/vendor/ckeditor/config.js',
  toolbar: [
    { name: 'clipboard', items: ['Undo', 'Redo'] },
    { name: 'styles', items: ['Styles', 'Format'] },
    { name: 'basicstyles', items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat'] },
    {
      name: 'paragraph',
      items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'],
    },
    { name: 'links', items: ['Link', 'Unlink'] },
    { name: 'insert', items: ['EmbedSemantic', 'Table'] },
    { name: 'tools', items: ['Maximize'] },
    { name: 'editing', items: ['Scayt'] },
  ],
  removePlugins: 'image,exportpdf',
  format_tags: 'p;h1;h2;h3;pre',
  removeDialogTabs: 'image:advanced;link:advanced',
};

export default ckEditorConfig;
