/* eslint-disable no-template-curly-in-string */

import * as yup from 'yup';

const useCycleSettingsValidation = () => {
  const validateCycleSettingsSchema = yup.object().shape({
    year: yup.number().positive('Year should be informed.').integer(),
    number: yup.number().positive('Number should be informed.').integer().max(9999, 'Number must be less then 10000.'),
    name: yup.string().required('Name is required.'),
  });
  const validate = async (cycleSettings) => {
    const validationResult = {
      isValid: true,
      errors: [],
    };

    await validateCycleSettingsSchema
      .validate(cycleSettings, { abortEarly: false })
      .then((value) => {
        console.log('validated value: ', value);
      })
      .catch((err) => {
        validationResult.isValid = false;
        validationResult.errors = err.errors;
      });
    return validationResult;
  };

  return { validate };
};

export default useCycleSettingsValidation;
