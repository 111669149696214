import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { formatDate, formatDateAndTime, formatDistanceToUtcNow } from 'utils/dateHelpers';
import { approvalStatus, formOfRecognition } from 'constants/index';
import { ActionButton } from 'components';
import NominationHistoryModal from 'views/NominationForm/NominationHistoryModal';
import { useNominationForm } from 'hooks/nomination';
import NoteItem from '../NominationForm/NoteItem';

function VotingNominationDetailsModal({ isModalOpened, nomination, onClickClose }) {
  const { helperFunctions, formStore } = useNominationForm();
  const [isFilterCommentsOn, setFilterCommentsOn] = useState(false);
  const getFormOfRecognitionUnit = useCallback((option) => {
    switch (option) {
      case formOfRecognition.CASH_ONLY:
        return 'USD';
      case formOfRecognition.TIMEOFF_ONLY:
        return 'Hours';
      default:
        return '';
    }
  }, []);
  function renderNominationDetails() {
    return (
      <div className="row">
        <div className="col-3">
          <div>
            Nominator: <strong>{nomination.nominatorName}</strong>
          </div>
          <div>
            Position Title: <strong>{nomination.nominatorPositionTitle}</strong>
          </div>
          <div>
            Agency: <strong>{nomination.agencyName}</strong>
          </div>
          <div>
            Section: <strong>{nomination.sectionName}</strong>
          </div>
        </div>
        <div className="col-3">
          <div>
            Reason(s): <strong>{nomination?.awardReasons?.join(', ')}</strong>
          </div>
          <div>
            Award Type: <strong>{nomination.awardTypeName}</strong>
          </div>
          <div>{`${nomination.isGroup ? 'Group' : 'Individual'} Nomination`}</div>
        </div>
        <div className="col-3">
          <div className="mb-1">
            <strong>Nominees List:</strong>
          </div>
          <div className="max-h-100px overflow-auto scrollbar">
            {nomination?.nominees?.map((item) => (
              <div key={item.id}>
                <Button
                  className="p-0 p-buttom-sm p-button-text"
                  label={item.name ?? item.value}
                  onClick={() => helperFunctions.loadEmployeeHistory(item.employeeId ?? item.key)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="col-3">
          <div>
            <strong>Form of Recognition</strong>
          </div>
          <div>
            Reward: <strong>{nomination.formOfRecognitionDescription}</strong>
          </div>
          {nomination.formOfRecognition !== formOfRecognition.CERTIFICATE_ONLY ? (
            <>
              <div>Recommended Amount (per nominee):</div>
              <div>
                <strong>{`${getFormOfRecognitionUnit(nomination.formOfRecognition)} ${
                  nomination.approvedAmount ?? nomination.recommendedAmount
                }`}</strong>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }

  function renderJustificationAndCitation() {
    const showCitation = () => {
      return (
        !nomination.isHideCitation && (
          <div className="row mt-2">
            <div className="col-12">
              <h6>Citation</h6>
              <div className="overflow-auto voting-text-values min-h-80px max-h-80px p-2">{nomination.citation}</div>
            </div>
          </div>
        )
      );
    };

    return (
      <>
        <div className="row">
          <div className="col-12">
            <h6>Justification</h6>
            <div
              className="overflow-auto voting-text-values vote-details-justification p-2"
              dangerouslySetInnerHTML={{ __html: nomination.justification }}
            />
          </div>
        </div>
        {showCitation()}
      </>
    );
  }

  function renderHistoryAndComments() {
    if (!nomination.historyLoaded) {
      return (
        <>
          <h6>History & Comments</h6>
          <div className="px-2">
            <Skeleton height="30px" className="my-2" />
            <Skeleton height="80px" className="mt-2 mb-3" />
            <Skeleton height="30px" className="my-2" />
            <Skeleton height="80px" className="mt-2 mb-3" />
            <Skeleton height="30px" className="my-2" />
            <Skeleton height="80px" className="mt-2 mb-3" />
          </div>
        </>
      );
    }
    const renderBy = (event) => {
      if (event.impersonatorName)
        return (
          <>
            by {event.impersonatorName} on behalf of {event.employeeName}
          </>
        );
      return <>by {event.employeeName}</>;
    };
    const histoyEvents = nomination.history || [];
    const events = isFilterCommentsOn ? histoyEvents.filter((item) => item.nominationNote) : histoyEvents;
    return (
      <>
        <div className="d-flex">
          <h6 className="flex-fill">History & Comments</h6>
          <div className="small-text d-flex flex-fill justify-content-md-end">
            <span className="align-self-center mr-2">Show only comments ?</span>
            <InputSwitch checked={isFilterCommentsOn} onChange={() => setFilterCommentsOn(!isFilterCommentsOn)} />
          </div>
        </div>
        <div className="p-2 overflow-y-scroll overflow-x-hidden h-100">
          <div className="">
            {events.length > 0 &&
              events.map((event) => (
                <div key={event.id} className="history-event">
                  <div className="small-text">{event.eventDescription}</div>
                  {event.nominationNote && <NoteItem value={event.nominationNote.content} />}
                  <div className="small-text text-right font-italic">
                    {renderBy(event)} {formatDistanceToUtcNow(event.createdDate)} ago
                    <ActionButton
                      tooltip={formatDateAndTime(event.createdDate)}
                      icon={['far', 'watch']}
                      className="btn-edit-image px-1 m-0 p-button-text p-button-plain p-button-sm"
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </>
    );
  }

  if (isEmpty(nomination)) {
    return null;
  }

  return (
    <>
      <Modal isOpen={isModalOpened} toggle={onClickClose} size="lg" className="modal-full-width">
        <ModalHeader toggle={onClickClose}>
          Nomination Details - {nomination.nominationKey}
          <span className="ml-4">
            <small>
              Submitted on <strong>{formatDate(nomination.submissionDate)}</strong>&nbsp;(
              {formatDistanceToUtcNow(nomination.submissionDate)} ago)
            </small>
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="container-fluid p-1">
            {renderNominationDetails()}
            <div className="row mt-2">
              <div className="col-9">{renderJustificationAndCitation()}</div>
              <div className="col-3 pb-4 vote-details-comments">{renderHistoryAndComments()}</div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            label="Close/Cancel"
            icon="pi pi-times-circle"
            onClick={onClickClose}
            className="min-w-150px p-button-primary"
          />
        </ModalFooter>
      </Modal>
      <NominationHistoryModal
        isModalOpened={formStore.isNominationHistoryModalOpened}
        onClickCloseModal={helperFunctions.onClickCloseHistoryModal}
        employeeHistory={formStore.employeeHistory}
        employeeHistoryLoadRequest={formStore.employeeHistoryLoadRequest}
        hideNominessGender={nomination.hideNominessGender}
        nominationId={nomination.id}
      />
    </>
  );
}
VotingNominationDetailsModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  nomination: PropTypes.object.isRequired,
  onClickClose: PropTypes.func.isRequired,
};

export default React.memo(VotingNominationDetailsModal);
