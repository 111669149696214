import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  TextRun,
  TabStopType,
  TabStopPosition,
  Footer,
  PageNumber,
} from 'docx';
import { formatDate } from 'utils/dateHelpers';
import download from 'downloadjs';

export const DocumentCreator = (missionName, data) => {
  let newType = data[0].awardTypeName;

  const createNominationsHeading = (text) => {
    return new Paragraph({
      text,
      heading: HeadingLevel.HEADING_2,
      thematicBreak: true,
      alignment: AlignmentType.CENTER,
    });
  };

  const createNominees = (text) => {
    const textRuns = text.map((line) => new TextRun({ break: 1, text: line.name, bold: true, font:'Calibri' }));
    return new Paragraph({ children: textRuns, heading: HeadingLevel.HEADING_1 });
  };

  const createReasonHeader = (reason) => {
    return new Paragraph({
      children: [
        new TextRun({ text: `Reason: ${reason.map((item) => item.awardReasonName).join(',  ')}.`, bold: true, font:'Calibri'}),
      ],
    });
  };

  const createCitation = (citation) => {
    return new Paragraph({
      children: [new TextRun({ break: 1, text: 'Citation: ', font:'Calibri' }), new TextRun({ break: 1, text: citation, font:'Calibri' })],
    });
  };

  const createPageBreak = () => {
    return new Paragraph({
      text: '',
      pageBreakBefore: true,
      // children: [new TextRun(''), new PageBreak()],
    });
  };

  const createDivider = () => {
    return new Paragraph({
      thematicBreak: true,
      spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
      children: [new TextRun({ break: 1, text: '' })],
    });
  };

  const createGrouporIndividual = (dateText, isGroupText) => {
    return new Paragraph({
      tabStops: [
        {
          type: TabStopType.RIGHT,
          position: TabStopPosition.MAX,
        },
      ],

      children: [
        new TextRun({
          text: `${dateText}`,
          font: 'Calibri',
          bold: true,
        }),
        new TextRun({
          text: `\t${isGroupText}`,
          font: 'Calibri',

          bold: true,
        }),
      ],
    });
  };

  const doc = new Document({
    styles: {
      paragraphStyles: [
        {
          id: 'Heading1',
          name: 'Heading 1',
          basedOn: 'Normal',
          next: 'Normal',          
          quickFormat: true,
          run: {
            size: 28,
            bold: true,
            font: "Calibri",
            color: '#0000FF',
          },
          paragraph: {
            spacing: {
              after: 120,
            },
          },
        },
        {
          id: 'Heading2',
          name: 'Heading 2',
          basedOn: 'Normal',
          next: 'Normal',          
          quickFormat: true,
          run: {
            size: 38,
            bold: true,
            font: "Calibri",
            color: '#0000FF',
          },
          paragraph: {
            spacing: {
              after: 120,
            },
          },
        },
        {
          id: 'aside',
          name: 'Aside',
          basedOn: 'Normal',
          next: 'Normal',
          run: {
            font: "Calibri",
            color: '999999',
            italics: true,
          },
          paragraph: {
            indent: {
              left: 720,
            },
            spacing: {
              line: 276,
            },
          },
        },
        {
          id: 'wellSpaced',
          name: 'Well Spaced',
          basedOn: 'Normal',
          quickFormat: true,
          paragraph: {
            spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
          },
        },
        {
          id: 'ListParagraph',
          name: 'List Paragraph',
          basedOn: 'Normal',
          quickFormat: true,
        },
      ],
    },
    sections: [
      {
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    children: ['Page Number: ', PageNumber.CURRENT],
                  }),
                  new TextRun({
                    children: [' to ', PageNumber.TOTAL_PAGES],
                  }),
                ],
              }),
            ],
          }),
        },
        properties: {},

        children: [
          new Paragraph({
            text: `Award Ceremony for Mission ${missionName}`,
            heading: HeadingLevel.TITLE,
            alignment: AlignmentType.CENTER,
            style: 'Heading2',
            children: [new TextRun({ break: 1, text: '' })],
          }),
          createNominationsHeading(data[0].awardTypeName),
          // eslint-disable-next-line array-callback-return
          ...data
            .map((item) => {
              const arr = [];

              if (newType !== item.awardTypeName) {
                arr.push(createPageBreak());
              }

              if (newType !== item.awardTypeName) {
                arr.push(createNominationsHeading(item.awardTypeName));
              }

              // arr.push(createDate(formatDate(item.modifiedDate)));
              arr.push(
                createGrouporIndividual(
                  formatDate(item.modifiedDate),
                  item.isGroup ? 'GROUP Nomination' : ' Individual Nomination'
                )
              );
              arr.push(createReasonHeader(item.awardReasons));
              arr.push(createCitation(item.citation));
              arr.push(createNominees(item.nominationEmployees));
              newType = item.awardTypeName;
              // return createNominees(item.nominationEmployees);
              arr.push(createDivider());
              return arr;
            })
            .reduce((prev, curr) => prev.concat(curr), []),
        ],
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    download(blob, `Ceremony ${missionName}.docx`);
  });
};

export default DocumentCreator;
