import React, { useContext, useReducer, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { confirmDialog } from 'primereact/confirmdialog';
import { requestStatus, formOfRecognition, nominationAction, nominationFormMode, roleType, nominationStatus } from 'constants/index';
import { SessionContext, NotificationContext } from 'contexts';
import { UserSearch } from 'components';
import { useAnytimeService, useAnytimeValidation } from 'hooks/nomination';
import { useParams } from 'react-router-dom';

const initialState = {
  isMGTApprovalRequired: false,
  isCustomApprovalRequired: false,
  formOfRecognition: formOfRecognition.CERTIFICATE_ONLY,
  mgtOfficers: [],
  financialOfficers: [],
  financialAnalysts: [],
  timeKeepers: [],
  approver1: null,
  approver2: null,
  approver3: null,
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
  saveRequest: requestStatus.REQUEST_NOT_INITIATED,
  recommendedAmount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'START_LOAD_REQUEST':
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'SET_DATA':
      return {
        ...state,
        loadRequest: requestStatus.REQUEST_COMPLETED,
        formOfRecognition: action.data.formOfRecognition,
        nominationId: action.data.nominationId,
        mgtOfficers: action.data.mgtOfficers,
        financialOfficers: action.data.financialOfficers,
        financialAnalysts: action.data.financialAnalysts,
        timeKeepers: action.data.timeKeepers,
        recommendedAmount: action.data.recommendedAmount,
        isMGTApprovalRequired: action.data.isMGTApprovalRequired,
        isCustomApprovalRequired: action.data.isCustomApprovalRequired,
        approver1: action.data.anyTimeApprovers && action.data.anyTimeApprovers[0],
        approver2: action.data.anyTimeApprovers && action.data.anyTimeApprovers[1],
        approver3: action.data.anyTimeApprovers && action.data.anyTimeApprovers[2],
      };

    case 'UPDATE_DATA':
      return { ...state, ...action.data };

    case 'START_SAVE_REQUEST':
      return { ...state, saveRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'COMPLETE_SAVE_REQUEST':
      return {
        ...state,
        saveRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'UPDATE_ASSIGNEES':
      return { ...state, ...action.data };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function NominationApproverModal({ formStore, helperFunctions, isModalOpened, navigateToList }) {
  const [store, dispatch] = useReducer(reducer, initialState);
  const { mode } = useParams();
  const service = useAnytimeService();
  const { showError, showSuccess } = useContext(NotificationContext);
  const { sessionStore } = useContext(SessionContext);
  const { validate } = useAnytimeValidation();

  const saveApprovers = async () => {
    const requestData = {
      nominationId: formStore.nomination.nominationId,
      userEmployeeId: sessionStore.employeeUser.employeeId,
      isMGTApprovalRequired: store.isMGTApprovalRequired,
      isCustomApprovalRequired: store.isCustomApprovalRequired,
      mgtOfficers: store.mgtOfficers,
      approver1: store.approver1,
      approver2: store.approver2,
      approver3: store.approver3,
      financialOfficers: store.financialOfficers,
      financialAnalysts: store.financialAnalysts,
      timeKeepers: store.timeKeepers,
      recommendedAmount: formStore.nomination.recommendedAmount,
      recommendedFormOfRecognition: formStore.nomination.recommendedFormOfRecognition,
      formOfRecognition: formStore.nomination.formOfRecognition,
      nominationFormAction: mode.toLowerCase() === 'ongoing' ? nominationAction.NONE : nominationAction.FORWARD,
    };

    console.log('SetNominationApprover data:', requestData);

    const validationResult = await validate(requestData);
    if (!validationResult.isValid) {
      showError(validationResult.errors.join('\n'));
    } else {
      const response = await service.setNominationApprover(requestData);
      if (response && response.ok) {
        showSuccess('Nomination sent for anytime approval');
        navigateToList();
      }
    }
  };

  const onClickSetApprovers = async () => {
    if (!store.isMGTApprovalRequired && !store.isCustomApprovalRequired) {
      confirmDialog({
        message:
          'You need to enable the switch if you want the nomination to go to Management Approval or Custom Approval. Are you sure to continue?',
        header: 'Warning Message',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: async () => {
          await saveApprovers();
        },
      });
    } else {
      await saveApprovers();
    }
    dispatch({ type: 'COMPLETE_SAVE_REQUEST' });
  };

  useEffect(() => {
    const loadProfile = async () => {
      dispatch({ type: 'START_LOAD_REQUEST' });
      const response = await service.load(formStore.nomination.nominationId, mode.toLowerCase() === 'review' ? nominationFormMode.REVIEW : nominationFormMode.ONGOING);
      if (response && response.ok) {
        response.data.formOfRecognition = formStore.nomination.formOfRecognition;
        dispatch({ type: 'SET_DATA', data: response.data });
      }
    };

    if (
      store.loadRequest === requestStatus.REQUEST_NOT_INITIATED &&
      formStore.nomination &&
      formStore.nomination.nominationId > 0
    ) {
      loadProfile();
    }
  }, [formStore.nomination, service, store.loadRequest, isModalOpened, mode]);

  function disableUserSearch(role) {
    if(mode.toLowerCase() === 'review') return false;
    let allowedStatuses = [];

    if(role === roleType.MGTOfficer) {
      allowedStatuses = [nominationStatus.PENDING_MANAGEMENT_APPROVAL];
    }

    if(role === roleType.AnytimeApprover) {
      allowedStatuses = [nominationStatus.PENDING_ANYTIME_APPROVER, nominationStatus.PENDING_MANAGEMENT_APPROVAL];
    }
    
    if(role === roleType.FinancialAnalyst) {
      allowedStatuses = [nominationStatus.PENDING_MANAGEMENT_APPROVAL, nominationStatus.PENDING_FINANCIAL_INFORMATION, nominationStatus.PENDING_ANYTIME_APPROVER];
    }

    if(role === roleType.FinancialOfficer) {
      allowedStatuses = [nominationStatus.PENDING_MANAGEMENT_APPROVAL, nominationStatus.PENDING_ANYTIME_APPROVER, nominationStatus.PENDING_FINANCIAL_INFORMATION, nominationStatus.PENDING_FINANCIAL_SIGNOFF];
    }

    if(role === roleType.TimeKeeper) {
      allowedStatuses = [nominationStatus.PENDING_ANYTIME_APPROVER, nominationStatus.PENDING_MANAGEMENT_APPROVAL];
    }

    return !allowedStatuses.includes(formStore.nomination.nominationStatus);
  }

  return (
    <Modal
      isOpen={isModalOpened}
      toggle={helperFunctions.onClickCloseApproverModal}
      size="xl"
      className="nomination-approver-modal"
    >
      <ModalHeader toggle={helperFunctions.onClickCloseApproverModal}>
        Choose the approver(s) for the nomination
      </ModalHeader>
      <ModalBody>
        <div className="p-grid p-formgrid p-d-flex">
          <div className="p-field">
            <div className="row ml-2">
              <div className="col-4">Require management approval?</div>
              <div className="col-8">Select management officer(s)</div>
            </div>
            <div className="row ml-2">
              <div className="col-4">
                <InputSwitch
                  id="isMGTApprovalRequired"
                  checked={store.isMGTApprovalRequired}
                  onChange={(e) => dispatch({ type: 'UPDATE_DATA', data: { isMGTApprovalRequired: e.target.value } })}
                  disabled={disableUserSearch(roleType.MGTOfficer)}
                  
                />
              </div>
              <div className="col-8">   
                <UserSearch
                  value={store.mgtOfficers || []}
                  setValue={(assignees) => dispatch({ type: 'UPDATE_ASSIGNEES', data: { mgtOfficers: assignees } })}
                  isMulti
                  isDisabled={!store.isMGTApprovalRequired || disableUserSearch(roleType.MGTOfficer)}
                  role={roleType.MGTOfficer}
                />
              </div>
            </div>
          </div>
          <hr className="hr-gray" />
          <div className="p-field p-md-12">
            <div className="row ml-2">
              <div className="col-4">Require custom approval?</div>
              <div className="col-8">Select first approver</div>
            </div>
            <div className="row ml-2">
              <div className="col-4">
                <InputSwitch
                  id="isCustomApprovalRequired"
                  checked={store.isCustomApprovalRequired}
                  onChange={(e) =>
                    dispatch({ type: 'UPDATE_DATA', data: { isCustomApprovalRequired: e.target.value } })
                  }
                  disabled={disableUserSearch(roleType.AnytimeApprover)}
                  
                />
              </div>
              <div className="col-8">
                <UserSearch
                  value={store.approver1 || []}
                  setValue={(assignee) => dispatch({ type: 'UPDATE_ASSIGNEES', data: { approver1: assignee } })}
                  isDisabled={!store.isCustomApprovalRequired || disableUserSearch(roleType.AnytimeApprover)}
                />
              </div>
            </div>
            <div className="row ml-2">
              <div className="col-4" />
              <div className="col-8">Select second approver (optional)</div>
            </div>
            <div className="row ml-2">
              <div className="col-4" />
              <div className="col-8">
                <UserSearch
                  value={store.approver2 || []}
                  setValue={(assignee) => dispatch({ type: 'UPDATE_ASSIGNEES', data: { approver2: assignee } })}
                  isDisabled={!store.isCustomApprovalRequired || disableUserSearch(roleType.AnytimeApprover)}
                />
              </div>
            </div>
            <div className="row ml-2">
              <div className="col-4" />
              <div className="col-8">Select third approver (optional)</div>
            </div>
            <div className="row ml-2">
              <div className="col-4" />
              <div className="col-8">
                <UserSearch
                  value={store.approver3 || []}
                  setValue={(assignee) => dispatch({ type: 'UPDATE_ASSIGNEES', data: { approver3: assignee } })}
                  isDisabled={!store.isCustomApprovalRequired || disableUserSearch(roleType.AnytimeApprover)}
                />
              </div>
            </div>
          </div>
          {
            
          }
          <div
            id="divFundingCite"
            className={`p-field p-md-12 ${
              formStore.nomination.formOfRecognition === formOfRecognition.CASH_ONLY ? '' : 'hidden'
            }`}
          >
            <hr className="hr-gray" />
            <div className="row ml-2">
              <div className="col-4">Funding Cite</div>
              <div className="col-8">Select financial analyst(s)</div>
            </div>
            <div className="row ml-2">
              <div className="col-4" />
              <div className="col-8">
                <UserSearch
                  value={store.financialAnalysts || []}
                  setValue={(assignees) =>
                    dispatch({ type: 'UPDATE_ASSIGNEES', data: { financialAnalysts: assignees } })
                  }
                  isMulti
                  role={roleType.FinancialAnalyst}
                  isDisabled={disableUserSearch(roleType.FinancialAnalyst)}
                />
              </div>
            </div>
            <div className="row ml-2">
              <div className="col-4" />
              <div className="col-8">Select financial officer(s)</div>
            </div>
            <div className="row ml-2">
              <div className="col-4" />
              <div className="col-8">
                <UserSearch
                  value={store.financialOfficers || []}
                  setValue={(assignees) =>
                    dispatch({ type: 'UPDATE_ASSIGNEES', data: { financialOfficers: assignees } })
                  }
                  isMulti
                  role={roleType.FinancialOfficer}
                  isDisabled={disableUserSearch(roleType.FinancialOfficer)}
                />
              </div>
            </div>
          </div>
          <div
            id="divTimeoff"
            className={`p-field p-md-12 ${
              formStore.nomination.formOfRecognition === formOfRecognition.TIMEOFF_ONLY ? '' : 'hidden'
            }`}
          >
            <hr className="hr-gray" />
            <div className="row ml-2">
              <div className="col-4">Timeoff</div>
              <div className="col-8">Select time keeper(s)</div>
            </div>
            <div className="row ml-2">
              <div className="col-4" />
              <div className="col-8">
                <UserSearch
                  value={store.timeKeepers || []}
                  setValue={(assignee) => dispatch({ type: 'UPDATE_DATA', data: { timeKeepers: assignee } })}
                  isMulti
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          label="Save & Submit"
          icon="pi pi-send"
          className="p-button-primary"
          onClick={() => onClickSetApprovers()}
        />
        <Button
          label="Close/Cancel"
          icon="pi pi-times-circle"
          className="p-button-secondary mr-2"
          onClick={() => helperFunctions.onClickCloseApproverModal()}
        />
      </ModalFooter>
    </Modal>
  );
}

NominationApproverModal.propTypes = {
  formStore: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object.isRequired,
  isModalOpened: PropTypes.bool.isRequired,
  navigateToList: PropTypes.func.isRequired,
};
export default NominationApproverModal;
