import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

import { processType } from 'constants/index';

function TypeBadge({ type }) {
  let typeName = 'N/D';
  let badgeColor = 'secondary';

  if (type === processType.ANYTIME) {
    typeName = 'Anytime';
    badgeColor = 'primary';
  }
  if (type === processType.ANNUAL) {
    typeName = 'Annual';
    badgeColor = 'info';
  }
  return (
    <Badge color={badgeColor} className="ml-1">
      {typeName}
    </Badge>
  );
}

TypeBadge.propTypes = {
  type: PropTypes.number.isRequired,
};

export default React.memo(TypeBadge);
