import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Row, Col, Label } from 'reactstrap';

import { SaveCancel } from 'components';

function PostProfileBasicSettings({ store, dispatch, onSaveClick, onCancelClick }) {
  return (
    <>
      <Row className="mt-3">
        <Col xs="12" md="12" lg="12" xl="10">
          <FormGroup>
            <Label>Post Short Name</Label>
            <input
              type="text"
              className="form-control max-w-150px"
              id="postShortName"
              value={store.postShortName || ''}
              maxLength="6"
              onChange={(e) =>
                dispatch({
                  type: 'UPDATE_DATA',
                  data: { postShortName: e.target.value.toUpperCase() },
                })
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="mt-3">
        <SaveCancel onCancelClick={onCancelClick} onSaveClick={onSaveClick} />
      </div>
    </>
  );
}
PostProfileBasicSettings.propTypes = {
  store: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

export default PostProfileBasicSettings;
