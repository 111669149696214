import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';

function ConfirmationWithComment({
  isModalOpened,
  onClickCloseModal,
  onClickActionButton,
  title,
  isCommentRequired,
  comment,
  setComment,
  actionButton,
}) {
  const NOTE_MIN_LENGTH = 5;

  return (
    <Modal centered scrollable className="mh-100" isOpen={isModalOpened} toggle={onClickCloseModal} size="lg">
      <ModalHeader toggle={onClickCloseModal}>{title || 'Confirmation'}</ModalHeader>
      <ModalBody>
        <InputTextarea
          value={comment}
          className="form-control"
          autoResize
          rows={4}
          placeholder={`Add your comment (minimum of ${NOTE_MIN_LENGTH} characters)`}
          onChange={(evt) => setComment(evt.target.value)}
        />
        {isCommentRequired && <small>* Comment is mandatory</small>}
      </ModalBody>

      <ModalFooter>
        <Button
          className={actionButton.cssClass}
          label={actionButton.label}
          disabled={isCommentRequired && comment.trim().length < NOTE_MIN_LENGTH}
          onClick={() => onClickActionButton(actionButton)}
          icon={actionButton.icon}
        />
        <Button
          label="Cancel"
          icon="pi pi-times-circle"
          onClick={() => onClickCloseModal()}
          className="p-button-secondary w-100px"
        />
      </ModalFooter>
    </Modal>
  );
}
ConfirmationWithComment.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  onClickCloseModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isCommentRequired: PropTypes.bool,
  comment: PropTypes.string,
  setComment: PropTypes.func.isRequired,
  onClickActionButton: PropTypes.func.isRequired,
  actionButton: PropTypes.object,
};
ConfirmationWithComment.defaultProps = {
  isCommentRequired: false,
  comment: '',
  actionButton: {
    clientId: '',
    label: 'Confirm',
    icon: 'pi pi-check',
    action: 0,
    successMessage: 'Operation successful.',
    cssClass: '',
  },
};

export default ConfirmationWithComment;
