import React, { useReducer, useEffect, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import {
  Button as ButtonStrap,
  Label,
  Row,
  Col,
  Table,
  Card,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShippingFast, faMegaphone } from '@fortawesome/pro-light-svg-icons';
import { ActionButton, SkeletonTable, TypeBadge } from 'components';
import { SessionContext, NotificationContext } from 'contexts';
import { useFormatting, useNavigationLinks, useAuthorization } from 'hooks';
import { useAwardTypeService } from 'hooks/admin';
import { formOfRecognition, requestStatus, processType } from 'constants/index';
import './ManageAwards.css';

const initialState = {
  loadedAwardTypes: [],
  loadedCycles: [],
  editAwardType: null,
  currentCycleId: null,
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
  isModalOpened: false,
  typeFilter: processType.ANYTIME,
};

const reducer = (state, action) => {
  let idx = -1;
  let nextLoadRequest = state.loadRequest;
  let nextCurrentCycleId = -1;
  switch (action.type) {
    case 'START_LOAD_REQUEST':
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'OPEN_EDIT_MODAL':
      return { ...state, isModalOpened: true, editAwardType: action.data };

    case 'CLOSE_EDIT_MODAL':
      return { ...state, isModalOpened: false };

    case 'UPDATE_AWARD_TYPE':
      idx = state.loadedAwardTypes.findIndex(
        (item) => item.id === state.editAwardType.id
      );
      return {
        ...state,
        loadedAwardTypes: [
          ...state.loadedAwardTypes.slice(0, idx),
          { ...state.editAwardType },
          ...state.loadedAwardTypes.slice(idx + 1),
        ],
        isModalOpened: false,
      };

    case 'UPDATE_CYCLE':
      if (state.currentCycleId !== action.data)
        nextLoadRequest = requestStatus.REQUEST_NOT_INITIATED;
      return {
        ...state,
        currentCycleId: action.data,
        loadRequest: nextLoadRequest,
      };

    case 'LOAD_DATA':
      return {
        ...state,
        loadRequest: requestStatus.REQUEST_COMPLETED,
        loadedAwardTypes: action.data,
      };
    case 'LOAD_CYCLES':
      nextCurrentCycleId = action.data.length
        ? action.data[0].id
        : nextCurrentCycleId;
      return {
        ...state,
        loadedCycles: action.data,
        currentCycleId: nextCurrentCycleId,
      };

    case 'UPDATE_TYPE_FILTER':
      if (state.typeFilter !== action.data)
        nextLoadRequest = requestStatus.REQUEST_NOT_INITIATED;
      return {
        ...state,
        typeFilter: action.data,
        loadRequest: nextLoadRequest,
      };

    case 'UPDATE_DATA':
      return { ...state, ...action.data };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const formOfRecognitionOptions = [
  { value: formOfRecognition.CASH_ONLY, label: 'Cash Only' },
  { value: formOfRecognition.TIMEOFF_ONLY, label: 'Time Off Only' },
  { value: formOfRecognition.CERTIFICATE_ONLY, label: 'Certificate Only' },
  { value: formOfRecognition.CASH_OR_TIMEOFF, label: 'Cash or Time Off' },
];

function ManageAwards() {
  const { sessionStore } = useContext(SessionContext);
  const [store, dispatch] = useReducer(reducer, initialState);
  const service = useAwardTypeService();
  const { formatCycle } = useFormatting();
  const { showSuccess, showError } = useContext(NotificationContext);
  const { getURL, goTo } = useNavigationLinks();
  const { checkRole } = useAuthorization();
  const { currentMissionId, employeeUser } = sessionStore;

  useEffect(() => {
    const loadAwardTypes = async () => {
      dispatch({ type: 'START_LOAD_REQUEST' });

      const response = await service.getGlobalList(store.typeFilter);
      console.log('currentMission:', currentMissionId);

      if (response && response.ok) {
        dispatch({ type: 'LOAD_DATA', data: response.data });
        console.log(response.data);
      }
    };

    if (store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadAwardTypes();
    }
  }, [
    currentMissionId,
    employeeUser,
    service,
    store.currentCycleId,
    store.loadRequest,
    store.loadedCycles.length,
    store.typeFilter,
  ]);

  useEffect(() => {
    if (!checkRole('hr_officer')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  const onClickSave = async () => {
    const requestData = {
      id: store.editAwardType.id,
      missionId: null,
      cycleId: null,
      awardTypeId: store.editAwardType.awardTypeId,
      formOfRecognition: store.editAwardType.formOfRecognition,
      minCashAmount: store.editAwardType.minCashAmount,
      maxCashAmount: store.editAwardType.maxCashAmount,
      minHoursAmount: store.editAwardType.minHoursAmount,
      maxHoursAmount: store.editAwardType.maxHoursAmount,
      IsActive: store.editAwardType.isActive,
      IsArchived: store.editAwardType.isArchived,
    };
    const response = await service.saveSettings(requestData);
    if (response && response.ok) {
      showSuccess('Type settings saved.');
      dispatch({ type: 'UPDATE_AWARD_TYPE' });
    } else {
      showError('Error trying to save type settings.');
    }
  };

  const onClickCloseModal = () => dispatch({ type: 'CLOSE_EDIT_MODAL' });

  // eslint-disable-next-line no-shadow
  const onClickEdit = (awardTypeId) => {
    goTo('edit_global_award', { id: awardTypeId });
  };

  const updateAwardTypeData = (awardTypeEditFragment) => {
    dispatch({
      type: 'UPDATE_DATA',
      data: {
        editAwardType: { ...store.editAwardType, ...awardTypeEditFragment },
      },
    });
  };

  const onChangeFormOfRecognition = (event) => {
    const value = parseInt(event.target.value, 10);
    const description = formOfRecognitionOptions.find(
      (item) => item.value === value
    ).label;
    updateAwardTypeData({
      formOfRecognition: value,
      formOfRecognitionDescription: description,
    });
  };

  let cashEnabled = false;
  let hoursEnabled = false;
  if (store.editAwardType) {
    cashEnabled = [
      formOfRecognition.CASH_OR_TIMEOFF,
      formOfRecognition.CASH_ONLY,
    ].includes(store.editAwardType.formOfRecognition);
    hoursEnabled = [
      formOfRecognition.CASH_OR_TIMEOFF,
      formOfRecognition.TIMEOFF_ONLY,
    ].includes(store.editAwardType.formOfRecognition);
  }

  function renderFormOfRecognitionDescription(RecognitionId) {
    let recognitionDescription = '';
    if (RecognitionId === 1) {
      recognitionDescription = 'Cash Only';
    }
    if (RecognitionId === 2) {
      recognitionDescription = 'Time Off Only';
    }
    if (RecognitionId === 3) {
      recognitionDescription = 'Certificate Only';
    }
    if (RecognitionId === 4) {
      recognitionDescription = 'Cash or Time Off';
    }

    return recognitionDescription;
  }

  const currentCycle = store.loadedCycles.find(
    (x) => x.id === store.currentCycleId
  );

  const renderEditModal = () => (
    <Modal isOpen={store.isModalOpened} toggle={onClickCloseModal}>
      <ModalHeader toggle={onClickCloseModal}>
        Rules for {store.editAwardType.name} - Cycle:{' '}
        {formatCycle(currentCycle)}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <Label for="modalIsActive">Active ?</Label>
              <div>
                <InputSwitch
                  checked={store.editAwardType.isActive}
                  onChange={(e) =>
                    updateAwardTypeData({ isActive: e.target.value })
                  }
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="modalFormOfRecognition">Form of Recognition</Label>
              <div>
                <Dropdown
                  value={store.editAwardType.formOfRecognition}
                  onChange={(e) => onChangeFormOfRecognition(e)}
                  className="w-100"
                  options={formOfRecognitionOptions}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="modalMinCash">Minimum Cash</Label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">USD</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  id="modalMinCash"
                  value={store.editAwardType.minCashAmount}
                  min="0"
                  step="1"
                  onChange={(e) =>
                    updateAwardTypeData({
                      minCashAmount: Number(e.target.value),
                    })
                  }
                  disabled={!cashEnabled}
                />
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="modalMaxCash">Maximum Cash</Label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">USD</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  id="modalMaxCash"
                  value={store.editAwardType.maxCashAmount}
                  min="0"
                  step="1"
                  onChange={(e) =>
                    updateAwardTypeData({
                      maxCashAmount: Number(e.target.value),
                    })
                  }
                  disabled={!cashEnabled}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="modalMinHours">Minimum Time Off</Label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Hours</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  id="modalMinHours"
                  value={store.editAwardType.minHoursAmount}
                  step="1"
                  min="0"
                  max="40"
                  onChange={(e) =>
                    updateAwardTypeData({
                      minHoursAmount: Number(e.target.value),
                    })
                  }
                  disabled={!hoursEnabled}
                />
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="modalMaxHours">Maximum Time Off</Label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Hours</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  id="modalMaxHours"
                  value={store.editAwardType.maxHoursAmount}
                  maxLength="10"
                  step="1"
                  min="0"
                  max="40"
                  onChange={(e) =>
                    updateAwardTypeData({
                      maxHoursAmount: Number(e.target.value),
                    })
                  }
                  disabled={!hoursEnabled}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          label="Save"
          icon="pi pi-save"
          className="p-button-primary w-100px"
          onClick={() => onClickSave()}
        />
        <Button
          label="Cancel"
          icon="pi pi-times-circle"
          className="p-button-secondary w-100px"
          onClick={() => onClickCloseModal()}
        />
      </ModalFooter>
    </Modal>
  );

  const renderResultTable = () => (
    <div className="table-responsive">
      <Table className="w-100 mb-0">
        <thead className="thead-light">
          <tr>
            <th>Award Type</th>
            <th>Process Type</th>
            <th>Form of Recognition</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {store.loadedAwardTypes.map((item) => (
            <tr
              key={`${item.awardTypeId}_${item.id}}`}
              className={`${item.isArchived ? 'award-type-disabled' : ''}`}
            >
              <td>{item.name}</td>
              <td>
                <div style={{ lineHeight: '18px' }}>
                  <TypeBadge type={item.awardProcessType} />
                </div>
              </td>
              <td>
                {renderFormOfRecognitionDescription(item.formOfRecognitionType)}
              </td>

              <td className="text-center">
                <ActionButton
                  className="p-button-text p-button-plain px-2"
                  onClick={() => onClickEdit(item.id)}
                  icon={['far', 'pencil']}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  return (
    <>
      <h4>Global Award Types</h4>
      <Row className="mb-3">
        <Col xs="8" md="8" lg="6" xl="6">
          <ButtonGroup className="w-100">
            <ButtonStrap
              className="w-150"
              color={
                store.typeFilter === processType.ANYTIME
                  ? 'primary'
                  : 'outline-secondary'
              }
              onClick={() =>
                dispatch({
                  type: 'UPDATE_TYPE_FILTER',
                  data: processType.ANYTIME,
                })
              }
            >
              <FontAwesomeIcon
                icon={faShippingFast}
                fixedWidth
                className="mr-2"
              />
              Anytime
            </ButtonStrap>
            <ButtonStrap
              className="w-150"
              color={
                store.typeFilter === processType.ANNUAL
                  ? 'primary'
                  : 'outline-secondary'
              }
              onClick={() =>
                dispatch({
                  type: 'UPDATE_TYPE_FILTER',
                  data: processType.ANNUAL,
                })
              }
            >
              <FontAwesomeIcon icon={faMegaphone} fixedWidth className="mr-2" />
              Annual
            </ButtonStrap>
          </ButtonGroup>
        </Col>

        <Col xs="12">
          <Link
            to={getURL('new_global_award')}
            className="float-right btn btn-primary mb-2"
          >
            Add New Award Type
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12" lg="12" xl="12">
          {store.loadRequest === requestStatus.REQUEST_IN_PROGRESS && (
            <SkeletonTable rowsSize={5} colsSize={4} />
          )}
          {store.loadRequest !== requestStatus.REQUEST_IN_PROGRESS && (
            <Card>{renderResultTable()}</Card>
          )}
        </Col>
      </Row>
      {store.editAwardType && renderEditModal()}
    </>
  );
}

export default ManageAwards;
