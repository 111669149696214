const responseStatus = {
  OK: 200,
  BAD_REQUEST: 400,
  NOT_AUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  BAD_GATEWAY: 502,
};

export default responseStatus;
