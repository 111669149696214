import ApiSauce, { CancelToken } from 'apisauce';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import apiConfig from 'config/apiConfig';
import { responseStatus } from 'constants/index';

// set up cancel token from axios to be able to cancel network requests
const cancelSource = CancelToken.source();

const apiConfigWithCancelToken = {
  ...apiConfig,
  cancelToken: cancelSource.token,
};

const instance = ApiSauce.create(apiConfigWithCancelToken);

instance.setAccessToken = (token) => {
  if (token) {
    instance.accessToken = token;
  }
};

instance.setCurrentOrganizationId = (organizationId) => {
  if (organizationId) {
    instance.currentOrganizationId = organizationId;
  }
};

instance.setAlertMessageCallback = (alertMessageFn) => {
  if (!instance.alertMessageFn && alertMessageFn) {
    instance.alertMessageFn = alertMessageFn;
  }
};

// Add a request interceptor
instance.addAsyncRequestTransform(
  (config) =>
    // Check and acquire a token before the request is sent
    new Promise((resolve, reject) => {
      if (instance.accessToken) {
        config.headers.Authorization = `Bearer ${instance.accessToken}`;
        resolve(config);
      } else {
        // Do something with error of acquiring the token
        console.log('connection rejected - access token not present');
        reject(config);
      }
    }),
  (error) => {
    console.log('error: ', error);
    return Promise.reject(error);
  }
);

instance.addAsyncRequestTransform(
  (config) =>
    new Promise((resolve) => {
      config.headers.CurrentOrganizationId = instance.currentOrganizationId;
      resolve(config);
    }),
  (error) => {
    console.log('error: ', error);
    return Promise.reject(error);
  }
);

instance.addAsyncResponseTransform(async (response) => {
  // TODO: Improve responses handling

  if (response.status === responseStatus.BAD_REQUEST) {
    instance.alertMessageFn('The operation failed. See error messages for more details.');
    console.info('response.data', response.data);
    return;
  }
  if (response.status === responseStatus.UNPROCESSABLE_ENTITY) {
    instance.alertMessageFn(response.data, 'Unprocessable Request', faExclamationTriangle, 'color-text-warning');
    console.info('response.data', response.data);
    return;
  }
  if (response.status === responseStatus.NOT_AUTHORIZED) {
    console.log('API permission error');
    console.log('response data:', response.data);
    window.location = '/NotAuthorized';
    return;
  }
  if (response.status === responseStatus.FORBIDDEN) {
    console.log('API permission error');
    console.log('response data:', response.data);
    window.location = '/Forbidden';
    return;
  }
  if (response.status === responseStatus.NOT_FOUND && response.config.url === '/user/currentuser') {
    window.location = '/NotAuthorizedOAS/';
    return;
  }
  if (response.status === responseStatus.BAD_GATEWAY) {
    instance.alertMessageFn(response.data.detail, response.data.title, faExclamationTriangle, 'color-text-danger');
    console.info('response.data', response.data);
    return;
  }
  if (response.config.headers.bubble_404) return;

  if (response.status !== responseStatus.OK) {
    console.log('API error', response.problem);
    console.info('response.data', response.data);
    window.location = `/ErrorPage/${response.problem}`;
  }
});

instance.logoutCallback = undefined;

export default instance;
