import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Accordion, AccordionTab } from 'primereact/accordion';
import NomineesList from 'views/NominationForm/NomineesList';

import { requestStatus } from 'constants/index';

function SignOffNominationDetails({ formStore, nomination, helperFunctions, onClickMoreDetails }) {
  useEffect(() => {
    if (nomination.id && nomination.nominationLoadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      helperFunctions.loadNomination(nomination.id);
    }
  }, [nomination.nominationLoadRequest, formStore, helperFunctions, nomination.id]);

  return (
    <>
      {nomination.nominationLoadRequest === requestStatus.REQUEST_COMPLETED && (
        <NomineesList
          formStore={formStore}
          selectedNomination={nomination}
          helperFunctions={helperFunctions}
          rightBarPlaceholder
        />
      )}
      <Accordion className="m-auto">
        <AccordionTab header="Justification and Citation" className="container-fluid py-4 px-0">
          <div className="row m-2">
            <div className="col-6 font-weight-bold">Justification</div>

            <div
              className="col-12 voting-text-values min-h-100px max-h-100px overflow-y-scroll"
              dangerouslySetInnerHTML={{ __html: nomination.justification }}
            />
          </div>
          <div className="row m-2">
            <div className="col-12 font-weight-bold">Citation</div>
            <div className="col-12 voting-text-values min-h-50px">{nomination.citation}</div>
          </div>
        </AccordionTab>
      </Accordion>
      <div className="col-6 pr-0">
        <Button className="p-0" color="link" size="sm" onClick={() => onClickMoreDetails(nomination)}>
          More Details
        </Button>
      </div>
    </>
  );
}
SignOffNominationDetails.propTypes = {
  formStore: PropTypes.object.isRequired,
  nomination: PropTypes.object.isRequired,
  onClickMoreDetails: PropTypes.func.isRequired,
  helperFunctions: PropTypes.object.isRequired,
};
export default SignOffNominationDetails;
