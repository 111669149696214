import useApi from './useApi';

const useReportSearchService = () => {
  const api = useApi();

  const getSearchAvailableParameters = async (missionId, isBureau = false) =>
    api.get(`/reportSearch/getAvailableParameters/${missionId}/${isBureau}`);

  const nominationsSearch = async (searchDataRequest) =>
    api.post('/reportSearch/nominationsSearch/', searchDataRequest);

  const getTimeOff = async (reportTimeoffRequest) => api.post('/reportSearch/timeOff/', reportTimeoffRequest);

  const financeReportSearch = async (reportTimeoffRequest) =>
    api.post('/reportSearch/FinanceReportSearch/', reportTimeoffRequest);

  const bureauReportSearch = async (reportTimeoffRequest) =>
    api.post('/reportSearch/bureauReportSearch/', reportTimeoffRequest);

  return {
    getSearchAvailableParameters,
    nominationsSearch,
    getTimeOff,
    financeReportSearch,
    bureauReportSearch
  };
};

export default useReportSearchService;
