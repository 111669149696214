import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const TIMEOUT_COUNT = 60;

function TimeoutWarning({ closeTimeoutWarning, timeoutLogout }) {
  const [count, setCount] = useState(TIMEOUT_COUNT);
  useEffect(() => {
    let timeout;
    if (count === 0) {
      timeoutLogout();
    } else {
      timeout = setTimeout(() => setCount(count - 1), 1000);
    }
    return () => clearTimeout(timeout);
  });

  return (
    <Modal isOpen toggle={closeTimeoutWarning}>
      <ModalHeader toggle={closeTimeoutWarning}>Timeout Warning</ModalHeader>
      <ModalBody>
        You are being timed out due to inactivity. Please choose to stay signed in or to logged out. Otherwise, you will
        be logged out automatically.
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={closeTimeoutWarning}>
          Stay Signed In
        </Button>
        <Button color="secondary" onClick={closeTimeoutWarning}>
          {`Log Out (${count})`}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

TimeoutWarning.propTypes = {
  closeTimeoutWarning: PropTypes.func.isRequired,
  timeoutLogout: PropTypes.func.isRequired,
};

export default React.memo(TimeoutWarning);
