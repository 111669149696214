import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';

function Grid({
  children,
  items,
  selectedRecords,
  dataKey,
  onSelectionChange,
  sortMode,
  sortOrder,
  sortField,
  rowGroupMode,
  groupRowsBy,
  showGridlines,
  globalFilter,
  onValueChange, 
}) {
  const minRows = 10;
  const showPaginator = items?.length > minRows;

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;
  return (
    <DataTable
      sortField={sortField}
      sortOrder={sortOrder}
      className="p-datatable-striped"
      metaKeySelection={false}
      selection={selectedRecords}
      onSelectionChange={onSelectionChange}
      paginator={showPaginator}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReports"
      paginatorLeft={paginatorLeft}
      paginatorRight={paginatorRight}
      rows={minRows}
      rowsPerPageOptions={[10, 20, 50]}
      value={items}
      dataKey={dataKey}
      responsive
      autoLayout
      rowReorder
      sortMode={sortMode}
      emptyMessage="No records found"
      rowGroupMode={rowGroupMode}
      groupRowsBy={groupRowsBy}
      showGridlines={showGridlines}
      globalFilter={globalFilter}
      onValueChange={onValueChange}
    >
      {children}
    </DataTable>
  );
}

Grid.propTypes = {
  items: PropTypes.array.isRequired,
  children: PropTypes.array,
  selectedRecords: PropTypes.array,
  dataKey: PropTypes.string.isRequired,
  onSelectionChange: PropTypes.func,
  sortMode: PropTypes.string,
  rowGroupMode: PropTypes.string,
  sortField: PropTypes.string,
  sortOrder: PropTypes.number,
  groupRowsBy: PropTypes.string,
  showGridlines: PropTypes.bool,
  globalFilter: PropTypes.array,
  onValueChange: PropTypes.func,
  
};
Grid.defaultProps = {
  sortMode: 'single',
  children: null,
  onSelectionChange: null,
  selectedRecords: null,
  sortOrder: null,
  sortField: null,
  rowGroupMode: null,
  groupRowsBy: null,
  showGridlines: false,
  globalFilter: [],
  onValueChange:null,
  
};

export default (React.memo(Grid))
