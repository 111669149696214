import { useReducer } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { requestStatus } from 'constants/index';
import { useUserService, useNavigationLinks, useImpersonationService } from 'hooks';
import { getDateUtcNow } from 'utils/dateHelpers';
import useApi from './useApi';

const initialSessionModel = {
  sessionRequest: requestStatus.REQUEST_NOT_INITIATED,
  openNetId: null,
  timezoneOffset: new Date().getTimezoneOffset() * -1,
  employeeUser: {
    employeeId: 0,
    name: null,
    displayName: null,
    email: null,
    positionTitle: null,
    postId: 0,
    postName: null,
    sectionId: 0,
    sectionName: null,
    sectionShortName: null,
    missionId: 0,
    missionName: null,
    turnOffNotifications: false,
  },
  userPermissions: [],
  userRoles: [],
  availableCycles: [],
  availableOrganizations: [],
  currentOrganizationId: null,
  currentMissionId: null,
  isBureauUser: false,
  isBureauEnabled: false,
  assignedCycleRoles: [],
  anytimeRoles: [],
  isImpersonating: false,
  impersonatorUserId: 0,
  impersonatorUserName: null,
  impersonatorUserEmail: null,
  impersonationStartedAt: null,
  hasProxyingUsers: false,
  proxyingUsers: [],
  proxyUserId: 0,
  proxyUserName: null,
  proxyUserEmail: null,
  applicationVersion: null,
};

const sessionReducer = (state, action) => {
  switch (action.type) {
    case 'START_SESSION_REQUEST':
      return { ...state, sessionRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'SET_USER_DATA':
      return {
        ...state,
        ...action.data,
        oktaRequest: requestStatus.REQUEST_COMPLETED,
        sessionRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'LOGOUT_USER':
      return { ...initialSessionModel };

    case 'SET_IMPERSONATION':
      return { ...state, impersonationStartedAt: getDateUtcNow() };

    case 'TERMINATE_IMPERSONATION':
      return { ...state, impersonationStartedAt: null, sessionRequest: requestStatus.REQUEST_NOT_INITIATED };

    case 'UPDATE_NOTIFICATION_TOGGLE':
      return { ...state, employeeUser: { ...state.employeeUser, turnOffNotifications: action.data } };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const useSession = () => {
  const [sessionStore, sessionDispatch] = useReducer(sessionReducer, initialSessionModel);
  const { oktaAuth, authState } = useOktaAuth();
  const { goHome, goTo, goLogout } = useNavigationLinks();
  const userService = useUserService();
  const impersonationService = useImpersonationService();
  const api = useApi();
  const setUserData = async () => {
    if (!userService.isUserAuthenticated()) return;
    sessionDispatch({ type: 'START_SESSION_REQUEST' });

    api.currentOrganizationId = sessionStorage.getItem('orgId');
    const response = await userService.getUserData();
    if (response && response.ok) {
      api.currentOrganizationId = response.data.currentOrganizationId;
      sessionDispatch({
        type: 'SET_USER_DATA',
        data: {
          openNetId: response.data.openNetId,
          employeeUser: {
            employeeId: response.data.employeeId,
            name: response.data.name,
            displayName: response.data.displayName,
            email: response.data.email,
            positionTitle: response.data.employeePositionTitle,
            missionId: response.data.missionId,
            missionName: response.data.missionName,
            postId: response.data.postId,
            postName: response.data.postName,
            sectionId: response.data.sectionId,
            sectionName: response.data.sectionName,
            sectionShortName: response.data.sectionShortName,
            turnOffNotifications: response.data.turnOffNotifications,
          },
          userRoles: response.data.roles,
          userPermissions: response.data.permissions,
          availableCycles: response.data.availableCycles,
          availableBureauCycles: response.data.availableBureauCycles,
          currentOrganizationId: response.data.currentOrganizationId,
          currentMissionId: response.data.currentMissionId,
          isBureauUser: response.data.isBureauUser,
          isBureauEnabled: response.data.isBureauEnabled,
          currentMissionName: response.data.currentMissionName,
          availableOrganizations: response.data.availableOrganizations,
          assignedCycleRoles: response.data.assignedCycleRoles,
          anytimeRoles: response.data.anytimeRoles,
          isImpersonating: response.data.isImpersonating,
          temporaryOrgId: response.data.temporaryOrgId,
          impersonatorUserId: response.data.impersonatorUserId,
          impersonatorUserName: response.data.impersonatorUserName,
          impersonatorUserEmail: response.data.impersonatorUserEmail,
          impersonationStartedAt: null,
          hasProxyingUsers: response.data.hasProxyingUsers,
          proxyingUsers: response.data.proxyingUsers,
          proxyUserId: response.data.proxyUserId,
          proxyUserName: response.data.proxyUserName,
          proxyUserEmail: response.data.proxyUserEmail,
          applicationVersion: response.data.applicationVersion,
          missionPosts: response.data.missionPosts,
        },
      });
    } else goTo('not_authorized_oas');
  };

  const initSession = async () => {
    console.log('init session');
    try {
      await setUserData();
    } catch (e) {
      console.log('[init session error]', e);
    }
  };

  const startImpersonation = async (targetImpersonatedId) => {
    await impersonationService.impersonate(targetImpersonatedId);
    await setUserData();
    sessionDispatch({ type: 'SET_IMPERSONATION' });
  };  

  const terminateImpersonation = async () => {
    await impersonationService.stopImpersonate();
    // sessionDispatch({ type: 'TERMINATE_IMPERSONATION' });
    sessionDispatch({ type: 'LOGOUT_USER' });
    await setUserData();
  };

  const startSignIntoMission = async (impersonatedMissionId) => {
    await impersonationService.signIntoMission(impersonatedMissionId);
    await setUserData();
    sessionDispatch({ type: 'SET_IMPERSONATION' });
  };

  const terminateSignIntoMission = async (impersonatedMissionId) => {
    await impersonationService.terminateSignIntoMission(impersonatedMissionId);
    sessionDispatch({ type: 'LOGOUT_USER' });
    await setUserData();
  }

  const logoutSession = async () => {
    if (sessionStore.isImpersonating) {
      await terminateImpersonation();
      goHome();
      return;
    }
    sessionDispatch({ type: 'LOGOUT_USER' });
    sessionStorage.removeItem('privacyStatementRead');
    oktaAuth.tokenManager.clear();
    await oktaAuth.signOut();
    goLogout();
  };

  const isAuthenticated = () => authState && authState.isAuthenticated;

  const switchMission = async (orgId) => {
    sessionStore.currentOrganizationId = orgId;
    api.currentOrganizationId = sessionStore.currentOrganizationId;
    sessionStorage.setItem('orgId', orgId);
    await setUserData();
    sessionDispatch({ type: 'TERMINATE_IMPERSONATION' });
    goHome();
  };

  const getCurrentMissionId = () => sessionStore.currentMissionId || 0;

  const updateNotificationToggle = (flag) => sessionDispatch({ type: 'UPDATE_NOTIFICATION_TOGGLE', data: flag });

  const sessionHelpers = {
    isAuthenticated,
    initSession,
    logoutSession,
    startImpersonation,
    startSignIntoMission,
    terminateSignIntoMission,
    terminateImpersonation,
    switchMission,
    getCurrentMissionId,
    updateNotificationToggle,
  };

  return { sessionStore, sessionHelpers };
};

export default useSession;
