import useApi from '../useApi';

export const useAwardTypeService = () => {
  const api = useApi();

  const getList = async (data) => {
    data.cycleId = data.awardProcessType !== 1 ? data.cycleId : null;
    return api.post('/awardtype/GetList', data);
  };

  const getGlobalList = async (data) => {
    return api.get(`/awardtype/GetGlobalList/${data}`);
  };

  const getCyclesByMission = async (missionId) => await api.get(`/awardType/GetCyclesByMission/${missionId}`);

  const load = async (awardTypeId) => {
    if (!awardTypeId) {
      return null;
    }
    return await api.get(`/awardtype/Load/${awardTypeId}`);
  };

  const save = async (data) => await api.post('/awardtype/Save', data);

  const saveSettings = async (data) => await api.post('/awardtype/SaveSettings', data);

  return {
    getList,
    getGlobalList,
    getCyclesByMission,
    load,
    save,
    saveSettings,
  };
};

export default useAwardTypeService;
