import React, { useReducer, useContext, useEffect } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { Card, Table } from 'reactstrap';

import { ActionButton } from 'components';
import { usePostProfileService } from 'hooks/admin';
import { SessionContext } from 'contexts';
import { requestStatus } from 'constants/index';
import { useNavigationLinks } from 'hooks';

const initialState = {
  postProfiles: [],
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'START_LOAD_REQUEST':
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };
    case 'LOAD_POSTS_LIST':
      return { ...state, postProfiles: action.data, loadRequest: requestStatus.REQUEST_COMPLETED };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function PostProfileList() {
  const { sessionStore } = useContext(SessionContext);
  const [store, dispatch] = useReducer(reducer, initialState);
  const service = usePostProfileService();
  const { goTo } = useNavigationLinks();
  const missionId = sessionStore.currentMissionId;

  useEffect(() => {
    const loadPostsList = async () => {
      dispatch({ type: 'START_LOAD_REQUEST' });
      const response = await service.getPostsByMission(missionId);
      if (response && response.ok) {
        dispatch({ type: 'LOAD_POSTS_LIST', data: response.data });
      }
    };
    if (store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadPostsList();
    }
  }, [missionId, service, store.loadRequest]);

  const editPost = (post) => {
    goTo('edit_post_profile', { key: post.postId });
  };

  const isLoading = store.loadRequest === requestStatus.REQUEST_IN_PROGRESS;

  function getSkeletonLines() {
    const lines = [];
    for (let i = 0; i < 5; i += 1) {
      lines.push(
        <tr key={`_temp_${i}`}>
          <td className="col-4">
            <Skeleton width="75%" className="m-2" />
          </td>
          <td className="col-5">
            <Skeleton width="75%" className="m-2" />
          </td>
          <td className="col-3 text-right">
            <Skeleton width="100%" className="m-2" />
          </td>
        </tr>
      );
    }
    return lines;
  }

  return (
    <>
      <h4>Mission Admin - Post Profiles</h4>
      <Card>
        <div className="table-responsive">
          <Table className="w-100 mb-0">
            <thead className="thead-light">
              <tr>
                <th>Post Name</th>
                <th>Short Name</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && getSkeletonLines()}
              {!isLoading &&
                store.postProfiles.map((post) => (
                  <tr key={post.postId}>
                    <td>{post.postName}</td>
                    <td>{post.postShortName}</td>
                    <td className="text-center">
                      <ActionButton
                        tooltip="Edit Post Profile"
                        className="p-button-text p-button-plain px-2"
                        icon={['far', 'pencil']}
                        onClick={() => editPost(post)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Card>
    </>
  );
}

export default PostProfileList;
