import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNominationForm } from 'hooks/nomination';

const NominationFormContext = React.createContext({});

function NominationFormProvider({ children }) {
  const { formStore, dispatch, helperFunctions } = useNominationForm();
  const contextValue = useMemo(
    () => ({ formStore, dispatch, helperFunctions }),
    [dispatch, formStore, helperFunctions]
  );

  return <NominationFormContext.Provider value={contextValue}>{children}</NominationFormContext.Provider>;
}
NominationFormProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { NominationFormContext, NominationFormProvider };
