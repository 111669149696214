import { useContext, useCallback } from 'react';
import flatMap from 'lodash/flatMap';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';

import { SessionContext } from 'contexts';

const sideBarMenuItems = require('../sideBarMenuItems.json');

const permissionsByFeature = {
  voting: ['CAN_VIEW_JCAC_BOARD'],
  can_vote: ['CAN_VOTE_NOMINATIONS'],
  can_vote_bureau: ['CAN_VOTE_BUREAU_NOMINATIONS'],
  can_push_to_chair: ['CAN_MANUALLY_PUSH_TO_CHAIR'],
  can_signoff_bureau: ['CAN_ENDORSE_VOTED_NOMINATION'],
  turnoff_notifications: ['CAN_TURNOFF_NOTIFICATIONS'],
  view_jcac_signoff: ['CAN_VIEW_JCAC_SIGNOFF'],
  jcac_chair: ['CAN_SIGNOFF_JCAC_RECOMMENDATION'],
  nomination_approvers: ['CAN_APPROVE_NOMINATION'],
  supervisors: ['CAN_VIEW_PENDING_APPROVAL'],
  proxy: ['CAN_IMPERSONATE_PROXY', 'CAN_IMPERSONATE_SPECIAL'],
  create_nomination: ['IS_MISSION_EMPLOYEE'],
};

const rolesByFeature = {
  assign_proxy: ['JCACChair', 'FinancialOfficer', 'ExecOfficer'],
  jcac_signoff: ['JCACChair'],
  jcac_voting: ['JCACMember'],
  exec_signoff: ['ExecOfficer'],
  fmo_signoff: ['FinancialOfficer'],
  hr_officer: ['USDHAwardsCoordinator', 'LocalAwardsCoordinator'],
  fm_input_fiscaldata: ['FinancialAnalyst'],
  system_roles: ['SystemAdmin'],
  global_impersonation: ['SystemAdmin'],
  timeKeeping: ['TimeKeeper'],
  bureau_coordinator: ['BureauAwardsCoordinator'],
  bureau_voting: ['BureauAwardsCoordinator', 'BureauCommitteeMember', 'BureauCommitteeChair'],
  bureau_signoff: ['BureauCommitteeChair'],
};

const filterMenuTree = (menuItems, userPermissions) => {
  menuItems.forEach((menu) => {
    if (!menu.subItems) return;
    const filteredSubItems = menu.subItems.filter(
      (item) => !item.permissions || item.permissions.find((perm) => perm && userPermissions.includes(perm))
    );

    menu.subItems = filteredSubItems;
  });

  return menuItems.filter(
    (item) => (item.subItems && item.subItems.length) || item.key === 'home' || item.key === 'my_nominations'
  );
};

const useAuthorization = () => {
  const { sessionStore } = useContext(SessionContext);

  const checkRole = useCallback(
    (feature) => {
      const cycleRoles = flatMap(sessionStore.assignedCycleRoles, 'roleKey');
      const anytimeRoles = flatMap(sessionStore.anytimeRoles, 'roleKey');
      const allRoles = uniq([...sessionStore.userRoles, ...cycleRoles, ...anytimeRoles]);

      const roles = rolesByFeature[feature];

      return allRoles.some((item) => roles?.includes(item));
    },
    [sessionStore.anytimeRoles, sessionStore.assignedCycleRoles, sessionStore.userRoles]
  );

  const checkPermission = useCallback(
    (feature) => {
      if (!sessionStore.userPermissions) return false;

      const permissions = permissionsByFeature[feature];

      return sessionStore.userPermissions.some((item) => permissions?.includes(item));
    },
    [sessionStore.userPermissions]
  );

  const checkCyclePermission = useCallback(
    (feature, cycleId) => {
      if (!sessionStore.assignedCycleRoles) return false;

      const permissions = permissionsByFeature[feature];
      const assignedCyclesRolesFound = sessionStore.assignedCycleRoles.filter((item) => item.cycleId === cycleId);

      if (!assignedCyclesRolesFound.length) return false;

      return permissions.every((per) => assignedCyclesRolesFound.flatMap((x) => x.permissions).includes(per));
    },
    [sessionStore.assignedCycleRoles]
  );

  const getAvailableCyclesForFeature = (feature) => {
    const cycleRoles = rolesByFeature[feature];
    const assignedCycleIds = uniq(
      flatMap(
        sessionStore.assignedCycleRoles.filter((item) => cycleRoles.includes(item.roleKey)),
        'cycleId'
      )
    );

    if (feature === 'jcac_voting') {
      const availCycles = sessionStore.availableCycles.filter((item) => assignedCycleIds.includes(item.id));
      const availStartDates = availCycles.filter((d) => new Date(d.committeeStartDate) - new Date() <= 0);
      const availStopDates = availStartDates.filter((d) => new Date() - new Date(d.committeeEndDate) <= 0);
      return availStopDates;
    }

    // Beside JCACChair, USDHAwardsCoordinator and LocalAwardsCoordinator need access so don't need to check Assigned Roles
    if (feature === 'jcac_signoff') {
      const availStartDates = sessionStore.availableCycles.filter(
        (d) => new Date(d.committeeStartDate) - new Date() <= 0
      );
      const availStopDates = availStartDates.filter((d) => new Date() - new Date(d.committeeEndDate) <= 0);
      return availStopDates;
    }

    return sessionStore.availableCycles;
  };

  const getAvailableBureauCyclesForFeature = (feature) => {
    const cycleRoles = rolesByFeature[feature];

    const assignedCycleIds = uniq(
      flatMap(
        sessionStore.assignedCycleRoles.filter((item) => cycleRoles.includes(item.roleKey)),
        'cycleId'
      )
    );
    const availCycles =
      sessionStore.userRoles.includes('BureauAwardsCoordinator') && feature === 'bureau_voting'
        ? sessionStore.availableBureauCycles
        : sessionStore.availableBureauCycles.filter((item) => assignedCycleIds.includes(item.id));
    const currDate = new Date().setHours(0, 0, 0, 0);
    if (feature === 'bureau_voting') {
      const availStartDates = availCycles.filter((d) => new Date(d.votingStartDate) - currDate <= 0);
      const availStopDates = availStartDates.filter((d) => currDate - new Date(d.votingEndDate) <= 0);
      return availStopDates;
    }

    if (feature === 'bureau_signoff') {
      const availStartDates = availCycles.filter((d) => new Date(d.openingDate) - currDate <= 0);
      const availStopDates = availStartDates.filter((d) => currDate - new Date(d.closingDate) <= 0);
      return availStopDates;
    }

    return sessionStore.availableCycles;
  };

  const getMenuItemsForUser = () => {
    const menuItems = cloneDeep(sideBarMenuItems);
    const cyclePermissions = flatMap(sessionStore.assignedCycleRoles, 'permissions');
    const anytimePermissions = flatMap(sessionStore.anytimeRoles, 'permissions');
    const userPermissions = uniq([...sessionStore.userPermissions, ...cyclePermissions, ...anytimePermissions]);
    return filterMenuTree(menuItems, userPermissions);
  };

  return {
    checkPermission,
    checkCyclePermission,
    checkRole,
    getAvailableCyclesForFeature,
    getAvailableBureauCyclesForFeature,
    getMenuItemsForUser,
  };
};

export default useAuthorization;
